import { exhaustiveCaseGuard, vReqT } from "src/helpers/utils";
import { defineComponent, onMounted, onUnmounted } from "vue";
import { GameCalendarUiElement } from "./GameScheduler.shared";
import dayjs from "dayjs";
import { DAYJS_FORMAT_IL_FULL_1 } from "src/helpers/formatDate";
import { Btn2, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2";

export const ConfirmDeleteCalendarElement = defineComponent({
  props: {
    calendarElement: vReqT<GameCalendarUiElement>(),
  },
  emits: {
    cancel: () => true,
    confirm: () => true,
  },
  setup(props, {emit}) {
    return () => {
      if (props.calendarElement.type === "game") {
        return (
          <div>
            <div>Delete game?</div>
            <div class="text-center mt-4">
              <div>
                {dayjs(props.calendarElement.data.gameStart).format(DAYJS_FORMAT_IL_FULL_1)} -
                {" "}
                {dayjs(props.calendarElement.data.gameEnd).format("h:mm a")}
              </div>
              <div class="text-center">
                <u>{props.calendarElement.data.homeTeamName}</u> vs.
                {" "}
                <u>{props.calendarElement.data.visitorTeamName}</u>
              </div>
              <div class="flex gap-2 mt-4">
                <Btn2 class="px-2 py-1" onClick={() => emit("confirm")}>Yes, delete</Btn2>
                <Btn2 class="px-2 py-1" onClick={() => emit("cancel")} enabledClasses={btn2_redEnabledClasses}>No, cancel</Btn2>
              </div>
            </div>
          </div>
        )
      }
      else if (props.calendarElement.type === "fieldBlock") {
        return (
          <div>
            <div>Delete field block?</div>
            <div class="text-center mt-4">
              <div>
                {dayjs(props.calendarElement.data.slotStart).format(DAYJS_FORMAT_IL_FULL_1)} -
                {" "}
                {dayjs(props.calendarElement.data.slotEnd).format("h:mm a")}
              </div>
              <div class="flex gap-2 mt-4">
                <Btn2 class="px-2 py-1" onClick={() => emit("confirm")}>Yes, delete</Btn2>
                <Btn2 class="px-2 py-1" onClick={() => emit("cancel")} enabledClasses={btn2_redEnabledClasses}>No, cancel</Btn2>
              </div>
            </div>
          </div>
        )
      }
      else {
        exhaustiveCaseGuard(props.calendarElement)
      }
    }
  }
})
