import Axios, { AxiosInstance, AxiosResponse } from "axios";
import { CheckedOmit, exhaustiveCaseGuard } from "src/helpers/utils";
import * as iltypes from "src/interfaces/InleagueApiV1"
import { isInleagueApiError2 } from "./InleagueApiV1";
import { Child, CoachTitle, CompetitionRegistration, DateTimelike, Datelike, Floatlike, Guid, Integerlike, Numeric, Registration, Team, TeamSeason } from "src/interfaces/InleagueApiV1";

export async function listTeams(axios: AxiosInstance, args: {competitionUID: Guid, divID: Guid | undefined, includeHosted?: boolean}) : Promise<Team[]> {
  const response = await axios.get(`v1/teams/${args.competitionUID}`, {params: {divID: args.divID, includeHosted: args.includeHosted}});
  return response.data.data;
}

export async function getTeam(axios: AxiosInstance, args: {teamID: iltypes.Guid}) : Promise<iltypes.WithDefinite<iltypes.Team, "currentTeamSeason">> {
  const response = await axios.get(`v1/team/${args.teamID}`)
  return response.data.data;
}

export async function getTeamSeason(axios: AxiosInstance, args: {teamID: iltypes.Guid, seasonUID: iltypes.Guid}) : Promise<iltypes.TeamSeason | null> {
  try {
    const response = await axios.get(`v1/team/${args.teamID}/season/${args.seasonUID}`)
    return response.data.data;
  }
  catch (err) {
    // TODO: better error than 404? Because that is maybe ambiguous with other errors. We want "teamID and seasonUID were legit but there is no team season"
    if (Axios.isAxiosError(err) && isInleagueApiError2(err) && err.response.status === 404) {
      return null;
    }
    else {
      throw err;
    }
  }
}

export interface CreateTeamArgs {
  competitionUID: Guid,
  divID: Guid,
  teamLetter: string,
  /**
   * May be ignored and set to default value as per backend areaPlay rules
   */
  region: Integerlike | undefined,
  /**
   * May be ignored and set to default value as per backend areaPlay rules
   */
  teamCity: string | undefined,
}

export interface UpdateTeamArgs {
  teamID: Guid,
  teamLetter: string,
  active: boolean,
  /**
   * _Must_ be `undefined` if region update is not supported;
   * "update supported" depends on (league, team).
   * At this time, the rules to satisfy to allow updating region are "application.areaPlay && team.clientID === application.clientID",
   * but see backend code for any possible changes.
   */
  region: Integerlike | undefined,
  teamCity: string | undefined,
}

export async function createTeam(ax: AxiosInstance, args: CreateTeamArgs) : Promise<Team> {
  const response = await ax.post(`v1/team`, args)
  return response.data.data;
}

export async function updateTeam(ax: AxiosInstance, args: UpdateTeamArgs) : Promise<Team> {
  const response = await ax.put(`v1/team/${args.teamID}`, args)
  return response.data.data;
}

export async function deleteTeam(ax: AxiosInstance, args: {teamID: Guid}) : Promise<void> {
  await ax.delete(`v1/team/${args.teamID}`)
}

export interface TeamCreateUpdateDeleteViewMeta {
  deletability: {[teamID: Guid]: boolean},
  clientInfoByClientID: {[clientID: Guid]: {regionName: string}}
}

export async function getTeamCreateUpdateDeleteViewMeta(ax: AxiosInstance, args: {competitionUID: Guid, divID: Guid}) : Promise<TeamCreateUpdateDeleteViewMeta> {
  const response = await ax.get(`v1/teams/add-edit-del/meta`, {params: {competitionUID: args.competitionUID, divID: args.divID}});
  return response.data.data;
}


export interface UpdateTeamSeasonArgs {
  teamID: iltypes.Guid,
  seasonUID: iltypes.Guid,
  updatables: {
    colorJersey: string,
    colorJerseySolidStripe: string,
    colorShorts: string,
    colorSocks: string,
    teamName: string,
    practiceTime: string,
    practiceLoc: string,
  }
}

export async function updateTeamSeason(axios: AxiosInstance, args: UpdateTeamSeasonArgs) : Promise<void> {
  await axios.put(`v1/team/${args.teamID}/season/${args.seasonUID}`, args.updatables);
}

export interface PlayerAssignment {
  assignmentID: iltypes.Guid,
  teamID: iltypes.Guid,
  uniform: string,
  seasonID: iltypes.Integerlike
  seasonUID: iltypes.Guid,
  registrationID: iltypes.Guid,
  childID: Guid,
  dateAssigned: iltypes.Datelike,
  clientID: iltypes.Guid,
  dateUpdated: iltypes.Datelike,
  assignedBy: iltypes.Guid,
  emailed: iltypes.Numbool,
  teamDesignation: string,
}

export interface PlayerLoan {
  loanID: iltypes.Guid,
  childID: iltypes.Guid,
  teamID: iltypes.Guid,
  clientID: iltypes.Guid,
  seasonID: iltypes.Integerlike,
  seasonUID: iltypes.Guid,
  loanedBy: iltypes.Guid,
  dateLoaned: iltypes.Datelike,
  uniform: string,
}

//
// Ultimately we shouldn't have "non-tagged" player-assignments/player-loans, but such things do exist, so these are separate types.
//
interface PlayerAssignmentBase {
  baseType: "assignment" | "loan"
  /**
   * The "assignmentID" for an assignment, and the "loanID" for a loan
   */
  baseID: string,
  /**
   * "base" team name
   */
  team: string,
  /**
   * "current" team name, which may be falsy, in which case defer to `team`. This
   * is dependent on season, and should be the teamName value from the team_season record
   * for the "contextual" season (`PlayerAssignment` and `PlayerLoan` should have a seasonUID property)
   *
   */
  teamName: string
}

export interface TaggedPlayerAssignment extends PlayerAssignmentBase, PlayerAssignment {
  baseType: "assignment"
}

export interface TaggedPlayerLoan extends PlayerAssignmentBase, PlayerLoan {
  baseType: "loan"
  expirationDate: "" | DateTimelike,
}

// TODO: we want the backend to return {playerAssignment: {}, child: {}, registration: {}}
// rather than {...playerAssignment, child: {}, registration: {}}
export interface GetTeamSeasonPlayersResponse {
  playerAssignments: (
    & TaggedPlayerAssignment
    & {
      registration: TeamAssignmentView_Registration
      child: TeamAssignmentView_Child
    }
  )[]
  playerLoans: (
    & TaggedPlayerLoan
    & {
      registration: TeamAssignmentView_Registration
      child: TeamAssignmentView_Child
    }
  )[]
}

type TeamAssignmentView_Child = Child & {
  parent1Street: string,
  parent1Street2: string,
  parent1City: string
  parent1Zip: string,
  parent1State: string,
}

type TeamAssignmentView_Registration = Registration & {
  ratingRecent: "" | Floatlike,
  ratingAvg: "" | Floatlike,
  hasFamiliallyRelatedCoachesOnTheseTeams: {
    /**
     * One of:
     *  - "team seasonal name (for the contextually known season based on the registration)"
     *  - base team name (non-seasonal) if the above didn't exist
     */
    teamName: string,
    teamID: Guid
  }[],
  /**
   * "relevant competition registrations", where "relevant" means "is compreg for comp for team or is compreg for comp that is an assignment source for team",
   * where a team for comp C0 can potentially have players registered for comp C1,C2,... assigned to it.
   * There can be many assignment sources, so there can be many "relevant" competition registrations.
   */
  programRegistrations: CompetitionRegistration[],
}

export async function getTeamSeasonPlayers(axios: AxiosInstance, args: {teamID: iltypes.Guid, seasonUID: iltypes.Guid}) : Promise<GetTeamSeasonPlayersResponse> {
  const response = await axios.get(`v1/team/${args.teamID}/players/${args.seasonUID}`);
  return response.data.data;
}

export interface ListTeamSeasonPlayersResponse {
  [teamID: Guid]: GetTeamSeasonPlayersResponse
}

export async function listTeamSeasonPlayers(axios: AxiosInstance, args: {teamIDs: Guid[], seasonUID: Guid}) : Promise<ListTeamSeasonPlayersResponse> {
  const {teamIDs, seasonUID} = args;
  const response = await axios.get(`v1/teams/players`, {params: {teamIDs, seasonUID}});
  return response.data.data;
}

export type PlayerForTeamAssignmentView = {
  /**
   * They __might__ be assigned or loaned; if not present, they are to be considered an "unassigned" player
   * If a player is both assigned to some team and loaned to other teams, each instance of an assignment or loan will be a separate "player" object
   * (under the assumption that this representation will be useful because each will be for a separate team)
   */
  assignment?: TaggedPlayerAssignment | TaggedPlayerLoan,
  registration: TeamAssignmentView_Registration
  child: TeamAssignmentView_Child
}

/**
 * @param args.includeNeighboringDivisions - will not be respected if the target competition does not have its `neighborDivs` flag set.
 */
export async function listUnassignedPlayers(ax: AxiosInstance, args: {seasonUID: Guid, competitionUID: Guid, divID: Guid, includeNeighboringDivs: boolean}) : Promise<PlayerForTeamAssignmentView[]> {
  const {seasonUID, competitionUID, divID, includeNeighboringDivs} = args;
  const response = await ax.get(`v1/teams/players/unassigned`, {params: {seasonUID, competitionUID, divID, includeNeighboringDivs}})
  return response.data.data
}

export interface UpdateTeamSeasonPlayersArgs {
  teamID: iltypes.Guid,
  seasonUID: iltypes.Guid,
  playerAssignments: {
    assignmentID: iltypes.Guid,
    uniform: string
  }[],
  playerLoans: {
    loanID: iltypes.Guid,
    uniform: string,
  }[]
}

export async function updateTeamSeasonPlayers(axios: AxiosInstance, args: UpdateTeamSeasonPlayersArgs) : Promise<void> {
  await axios.put(`v1/team/${args.teamID}/players/${args.seasonUID}`, args);
}

export type TeamSeasonReportEntry =
  PlayerAssignment & {
    registration: CheckedOmit<iltypes.Registration, "player"> & {
      child: CheckedOmit<iltypes.Child, "parent1" | "parent2"> & {
        family: {
          parent1: {
            firstName: string,
            lastName: string,
            email: string,
            primaryPhone: string,
            street: string,
            city: string,
            state: string,
            zip: string,
          }
          parent2: null | TeamSeasonReportEntry["registration"]["child"]["family"]["parent1"]
        }
      }
    }
  }

export interface RosterAuthZ {
  affinity: boolean,
  coachIdCards: boolean,
  medicalRelease: boolean,
  playerIDCards: boolean,
  lineUpCards: boolean,
  eaysoRoster: boolean,
  contactInfo: boolean,
}

/**
 * @deprecated see use site comments
 */
export async function getRoster_AuthZ(axios: AxiosInstance, args: {teamIDs: Guid[], seasonUID: Guid}): Promise<RosterAuthZ> {
  const response = await axios.get(`v1/teams/roster/authZ`, {params: {seasonUID: args.seasonUID, teamIDs: args.teamIDs.join(",")}})
  return response.data.data;
}

export async function getRoster_contactInfo(axios: AxiosInstance, args: {teamIDs: Guid[], seasonUID: Guid}): Promise<TeamSeasonReportEntry[]> {
  const response = await axios.get(`v1/teams/roster/contactInfo`, {params: {seasonUID: args.seasonUID, teamIDs: args.teamIDs.join(",")}})
  return response.data.data;
}

export interface CreateTeamSeasonArgs {
  seasonUID: iltypes.Guid,
  teamID: iltypes.Guid,
}

function mungeIlPdfResourceResponse(response: AxiosResponse) : {fileName: string, blob: Blob} {
  const fileNamePattern = /filename=(.+)(?:;|$)/i;
  const contentDisposition = response.headers["content-disposition"];
  const fileName = fileNamePattern.exec(contentDisposition)?.[1] ?? "__unnamed_inleague_file__"

  return {
    fileName,
    blob: response.data
  }
}

export async function getAffinityRosterPDF(axios: AxiosInstance, args: {teamIDs: iltypes.Guid[], seasonUID: iltypes.Guid, sortByUniform: boolean}) {
  const response = await axios.get(`v1/teams/roster/affinity`, {
    params: {
      isApi: true,
      teamIDs: args.teamIDs.join(","),
      seasonUID: args.seasonUID,
      sortByUniform: args.sortByUniform
    },
    responseType: "blob",
  })

  return mungeIlPdfResourceResponse(response)
}

export interface MedicalReleaseListingEntry {
  /**
   * unique key
   */
  assignmentID: string,
  playerID: Guid,
  playerFirstName: string,
  playerLastName: string,
  teamName: string,
  uniform: string,
  releaseURL: string,
}

export async function getRoster_medicalReleaseListing(axios: AxiosInstance, args: {teamIDs: Guid[], seasonUID: Guid}) : Promise<MedicalReleaseListingEntry[]> {
  const response = await axios.get(`v1/teams/roster/medicalRelease`, {
    params: {
      teamIDs: args.teamIDs.join(","),
      seasonUID: args.seasonUID,
    },
  })

  return response.data.data;
}

export interface ExcludablePlayer {
  assignmentID: string,
  playerID: Guid,
  playerFirstName: string,
  playerLastName: string,
  teamName: string,
  teamID: Guid,
  uniform: string,
  releaseURL: string,
}

export async function getRoster_excludablePlayers(axios: AxiosInstance, args: {teamIDs: Guid[], seasonUID: Guid}) : Promise<ExcludablePlayer[]> {
  const response = await axios.get(`v1/teams/roster/excludable`, {
    params: {
      teamIDs: args.teamIDs.join(","),
      seasonUID: args.seasonUID,
    },
  })

  return response.data.data;
}

export interface PictureDayRoster {
  team: {
    teamID: Guid,
    division: string,
    divNum: Integerlike,
    divGender: string,
    teamName: string,
    team: string,
    teamLetter: string,
  },
  coaches: {
    assignmentID: Guid,
    userID: Guid,
    firstName: string,
    lastName: string,
    title: CoachTitle,
    // contact info will be missing for leagues with "paranoidCoaches=1"
    email?: string,
    primaryPhone?: string,
  }[],
  playerAssignments: {
    assignmentID: Guid,
    firstName: string,
    lastName: string,
    uniform: string,
  }[]
}

export interface PictureDayRosterResponse {
  season: {
    seasonUID: Guid,
    seasonID: Integerlike,
    seasonName: string,
    seasonYear: string,
  },
  rosters: PictureDayRoster[]
}

export async function getRoster_pictureDay(ax: AxiosInstance, args: {teamIDs: Guid[], seasonUID: Guid}) : Promise<PictureDayRosterResponse> {
  const response = await ax.get(`v1/teams/roster/pictureDay`, {params: {teamIDs: args.teamIDs.join(","), seasonUID: args.seasonUID}})
  return response.data.data;
}

export interface TeamPlayerCoachSummary_PlayerLoan extends TeamPlayerCoachSummary_Player_Base {
  type: "playerLoan",
  loanID: Guid
}

export interface TeamPlayerCoachSummary_PlayerAssignment extends TeamPlayerCoachSummary_Player_Base {
  type: "playerAssignment"
  assignmentID: Guid,
}

export type TeamPlayerCoachSummary_Player =
  | TeamPlayerCoachSummary_PlayerLoan
  | TeamPlayerCoachSummary_PlayerAssignment

export function getTeamPlayerCoachSummary_PlayerKey(v: TeamPlayerCoachSummary_Player) : Guid {
  switch (v.type) {
    case "playerAssignment": return v.assignmentID;
    case "playerLoan": return v.loanID;
    default: exhaustiveCaseGuard(v);
  }
}

interface TeamPlayerCoachSummary_Player_Base {
  type: "playerAssignment" | "playerLoan"
  uniform: string,
  registration: {
    registrationID: Guid,
    childID: Guid,
    dateCreated: DateTimelike,
    child: {
      playerFirstName: string,
      playerLastName: string,
      stackSID: string,
      playerBirthDate: Datelike,
      parent1Email: string,
      parent1FirstName: string,
      parent1LastName: string,
      parent1Phone: string,
    }
  }
}

export interface TeamPlayerCoachSummary {
  team: {
    colorJersey: string,
    colorJerseySolidStripe: string,
    colorShorts: string,
    colorSocks: string,
    teamID: Guid,
    division: string,
    divNum: Integerlike,
    divGender: string,
    teamName: string,
    team: string,
    teamLetter: string,
    instanceConfig: {
      regionArea: string,
      regionSection: string,
      region: string,
      regionName: string,
    }
  },
  coaches: {
    assignmentID: Guid,
    userID: Guid,
    firstName: string,
    lastName: string,
    title: CoachTitle,
    // contact info will be missing for leagues with "paranoidCoaches=1"
    email?: string,
    primaryPhone?: string,
    volunteerCerts: {
      certificationDesc: string
    }[],
    stackSID: string,
  }[],
  playerAssignments: TeamPlayerCoachSummary_PlayerAssignment[]
  playerLoans: TeamPlayerCoachSummary_PlayerLoan[]
}

export interface TeamPlayerCoachSummaryResponse {
  season: {
    seasonUID: Guid,
    seasonID: Integerlike,
    seasonName: string,
    seasonYear: string,
    registrationYear: string,
  },
  rosters: TeamPlayerCoachSummary[]
}

export async function getRoster_teamPlayerCoachSummary_Data(ax: AxiosInstance, args: {teamIDs: Guid[], seasonUID: Guid}) : Promise<TeamPlayerCoachSummaryResponse> {
  const response = await ax.get(`v1/teams/roster/teamPlayerCoachSummary`, {params: {teamIDs: args.teamIDs.join(","), seasonUID: args.seasonUID}})
  return response.data.data;
}

export interface TeamAssignmentsViewTeamsResponse {
  [teamID: Guid]: TeamForTeamAssignmentsView
}
export interface TeamForTeamAssignmentsView {
  team: Team,
  teamSeason: null | TeamSeason
  embargoDate: DateTimelike,
  coaches: {
    title: CoachTitle,
    firstName: string,
    lastName: string,
  }[],
  seasonalRating: null | {
    seasonID: Integerlike,
    teamAvg: "" | Floatlike,
    teamAvgStd: "" | Floatlike,
    teamID: Guid,
  }
}

/**
 * misc. team info specific to the team assignments view
 */
export async function getTeamAssignmentsViewTeams(ax: AxiosInstance, args: {seasonUID: Guid} & ({teamIDs: Guid[]} | {competitionUID: Guid, divID: Guid})) : Promise<TeamAssignmentsViewTeamsResponse> {
  const response = await ax.get(`v1/teams/teamAssignmentsViewTeams`, {params: args});
  return response.data.data;
}

interface CreatePlayerAssignmentRequest {
  teamID: Guid,
  registrationID: Guid,
  seasonUID: Guid
}

interface CreatePlayerLoanRequest {
  teamID: Guid,
  registrationID: Guid,
  seasonUID: Guid,
  expirationDate: null | DateTimelike
}

interface DeletePlayerAssignmentRequest {
  assignmentID: Guid
}

interface DeletePlayerLoanRequest {
  loanID: Guid
}

export async function assignPlayerToTeam(ax: AxiosInstance, args: CreatePlayerAssignmentRequest) : Promise<void> {
  const {teamID, registrationID, seasonUID} = args;
  await ax.post(`v1/roster/${teamID}`, {registrationID, seasonUID})
}

export async function removePlayerFromTeam(ax: AxiosInstance, args: DeletePlayerLoanRequest) : Promise<void> {
  await ax.delete(`v1/roster`, {params: args});
}

export interface BulkCreateUpdateDeleteTeamAssignments {
  /**
   * If restoring a snapshot, we need some additional info.
   * Requiring season/comp/div at the "top level" like this requires that
   * all such properties in other nested objects are identical
   * (i.e. that all assignments.add[N].seasonUID === restoringSnapshotFor.seasonUID)
   */
  restoringSnapshotFor?: {
    seasonUID: Guid,
    competitionUID: Guid,
    divID: Guid,
    /**
     * Should be the list of teamIDs the frontend is working with wrt the season/comp/div.
     * This is primarily a sanity check that fe/be are talking about the same thing.
     */
    affectedTeamIDs: Guid[]
  },
  assignments: {
    add: CreatePlayerAssignmentRequest[]
    /**
     * should be empty when restoring a snapshot, the backend will figure out what it needs to delete (that is, "everything existing for the current season/comp/div")
     */
    delete: DeletePlayerAssignmentRequest[],
  },
  loans: {
    /**
     * should be empty when restoring a snapshot
     */
    update: {loanID: Guid, expirationDate: null | Datelike}[]
    add: CreatePlayerLoanRequest[],
    /**
     * should be empty when restoring a snapshot
     */
    delete: DeletePlayerLoanRequest[],
  }
}

export async function bulkCreateUpdateDeleteTeamAssignments(ax: AxiosInstance, args: BulkCreateUpdateDeleteTeamAssignments) : Promise<void> {
  await ax.post(`v1/roster`, args)
}

export interface SuggestedTeamAssignmentsResponse {
  leftovers: unknown[],
  teams: {
    avgAge: number,
    players: {
      childID: Guid,
      WL: 0,
      preAssigned: boolean,
      lastName: string,
      familyID: Guid,
      firstName: string,
      registrationID: Guid,
      ratingAvg: Numeric,
      rating: unknown, // have seen values like "false" (e.g. the string "false") here, not sure what this is
      playerLastName: string,
      playerFirstName: string,
      ratingRecent: string,
      age_calc: Integerlike,
      age: Integerlike
    }[]
    numSlots: -3,
    teamLetter: string,
    avgRating: 0,
    id: Guid
  }[]
}

export interface SuggestedTeamAssignmentsOptions {
  useWaitlist: boolean,
  useRecentRating: boolean,
  assignSiblings: boolean,
  assignCoachesKids: boolean,
  useAgeBalancing: boolean,
  numPerTeam: Integerlike,
  unratedRating: "" | Numeric,
}

export interface TeamPool {
  poolUID: Guid,
  poolId: Integerlike,
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  poolName: string
}

export async function getSuggestedTeamAssignments(ax: AxiosInstance, args: SuggestedTeamAssignmentsOptions & {
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid
}) : Promise<SuggestedTeamAssignmentsResponse> {
  const response = await ax.get(`v1/teams/players/suggested-assignments`, {params: args});
  return response.data.data;
}

export async function createTeamPool(ax: AxiosInstance, args: {competitionUID: Guid, seasonUID: Guid, divID: Guid, name: string}) : Promise<TeamPool> {
  const response = await ax.post(`v1/teams/pool`, args);
  return response.data.data;
}

export async function addTeamsToPool(ax: AxiosInstance, args: {poolUID: Guid, teamIDs: Guid[]}) : Promise<void> {
  await ax.post(`v1/teams/pool/${args.poolUID}/teams`, {teamIDs: args.teamIDs})
}

export async function removeTeamsFromPool(ax: AxiosInstance, args: {poolUID: Guid, teamIDs: Guid[]}) : Promise<void> {
  await ax.delete(`v1/teams/pool/${args.poolUID}/teams`, {params: {teamIDs: args.teamIDs}})
}

export async function deleteTeamPool(ax: AxiosInstance, args: {poolUID: Guid}) : Promise<TeamPool> {
  const response = await ax.delete(`v1/teams/pool/${args.poolUID}`);
  return response.data.data;
}

export interface TeamForTeamPoolEditor {
  teamID: string,
  team: string,
  teamLetter: string,
  teamName: string,
}

export type TeamPoolForTeamPoolEditor = TeamPool & {teams: TeamForTeamPoolEditor[]}
export interface GetTeamPoolForEditTeamPoolViewResponse {
  pools: TeamPoolForTeamPoolEditor[],
  availableTeams: TeamForTeamPoolEditor[]
}

export async function getTeamPoolsForEditTeamPoolsView(ax: AxiosInstance, args: {seasonUID: Guid, competitionUID: Guid, divID: Guid}) : Promise<GetTeamPoolForEditTeamPoolViewResponse> {
  const response = await ax.get(`v1/teams/pool/forEditTeamPoolsView`, {params: {
    seasonUID: args.seasonUID,
    competitionUID: args.competitionUID,
    divID: args.divID
  }});
  return response.data.data;
}
