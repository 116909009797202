import { computed, defineComponent, ref, watch } from "vue"

import { axiosInstance } from "src/boot/AxiosInstances"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import * as iltypes from "src/interfaces/InleagueApiV1"

import { propsDef } from "./R_TournamentTeamConfigurator.route";
import { exhaustiveCaseGuard, parseIntOrFail } from "src/helpers/utils";

import { PlayerRoster } from "./TournamentTeamConfigurator.players"
import { CoachRoster } from "./TournamentTeamConfigurator.coaches"
import { RefereeRoster } from "./TournamentTeamConfigurator.referees";
import { AdminRoster } from "./TournamentTeamConfigurator.admins";

import { NavigationGuardWithThis, useRouter } from "vue-router";

import authService from "src/helpers/authService";
import { QuestionAnswerMap, TournamentTeamRegistrationQuestionsForm, formAnswersToApiSubmittable } from "./TournamentTeam.questionsForm";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { isAuthorizedToManageTournament, teamDesignation } from "./TournamentTeamUtils";
import { User } from "src/store/User";

import { TournamentTeamExpandedForConfigurator, getTournamentTeamExpandedForConfigurator } from "./TournamentTeamConfigurator.shared";
import { Client } from "src/store/Client";
import { tournamentTeamStore } from "./Store/TournTeamStore";
import { TournamentTeamFromListTournamentTeamsEndpoint } from "src/composables/InleagueApiV1.Tournament";

const handleRouteChange : NavigationGuardWithThis<any> = async function (to, _from, next) : Promise<void> {
  const tournamentTeamID = parseIntOrFail(to.params.tournamentTeamID);

  const hasPerms = await tournamentTeamStore.getIsAuthorizedToManageTournamentTeam(axiosInstance, tournamentTeamID)

  if (hasPerms) {
    return next();
  }
  else {
    return next({name: "forbidden"})
  }
}

export default defineComponent({
  name: "R_TournamentRegistration",
  props: propsDef,
  beforeRouteEnter: handleRouteChange,
  beforeRouteUpdate: handleRouteChange,
  setup(props) {


    interface ResolvedBlob {
      readonly registrationPageItems: iltournament.TournTeamRegPageItem[]
      readonly registrationAnswers: iltournament.TournamentRegistrationAnswer[]
      readonly tournamentTeam: TournamentTeamExpandedForConfigurator
    }
    type Awaitables =
      | {readonly ready: false}
      | ({readonly ready: true} & ResolvedBlob);

    const awaitables = ref<Awaitables>({ready: false})

    // this supports direct assignment to tournamentTeam.admin rather than splicing in place,
    // but we have to pay the "assert lifecycle" tax. We don't do this for `officials` so we probably
    // don't need to do it for team admins...?
    const mut_teamAdmins = computed({
      get() {
        if (!awaitables.value.ready) {
          throw Error("should not be dereferenced when !ready")
        }
        return awaitables.value.tournamentTeam.admin
      },
      set(v) {
        if (!awaitables.value.ready) {
          throw Error("should not be dereferenced when !ready")
        }
        awaitables.value.tournamentTeam.admin = v;
      },
    })

    watch(() => props.tournamentTeamID, async () => {
      awaitables.value = {ready: false}

      awaitables.value = {
        ready: true,
        registrationPageItems: await iltournament.getTournamentTeamRegistrationPageItems(axiosInstance, {tournamentTeamID: props.tournamentTeamID}),
        registrationAnswers: await iltournament.getTournamentTeamRegistrationAnswers(axiosInstance, {tournamentTeamID: props.tournamentTeamID}),
        tournamentTeam: await getTournamentTeamExpandedForConfigurator(axiosInstance, props.tournamentTeamID)
      };
    }, {immediate: true});

    const handleSubmitQuestionAnswers = async (answerMap: QuestionAnswerMap) : Promise<void> => {
      try {
        await iltournament.submitTournamentTeamRegistrationAnswers(
          axiosInstance, {
            tournamentTeamID: props.tournamentTeamID,
            answers: formAnswersToApiSubmittable(answerMap)
          });
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const readonlyQuestionIDs = computed<Set<iltypes.Guid>>(() => {
      if (!awaitables.value.ready) {
        return new Set()
      }

      if (isRegistrar.value) {
        return new Set();
      }

      const editabilityChecksApply = (() => {
        switch (awaitables.value.tournamentTeam.status) {
          case "PENDING":
            return false;
          case "APPROVED":
          case "DROPPED_BY_HOST":
          case "DROPPED_BY_SUBMITTER":
          case "PAID_AWAITING_APPROVAL":
            return true;
          default: exhaustiveCaseGuard(awaitables.value.tournamentTeam.status);
        }
      })();

      if (!editabilityChecksApply) {
        // "no questions are readonly"
        // assuming, of course, that the user has some general edit permission
        return new Set();
      }

      return new Set<iltypes.Guid>(
        awaitables.value.registrationPageItems
          .filter((v) : v is iltournament.TournTeamRegPageItem_Question => v.type === iltypes.PageItemType.QUESTION)
          .filter((v) => v.containedItem.isEditable)
          .map(v => v.questionID)
      );
    })

    const isRegistrar = computed(() => authService(User.value.roles, "registrar"));
    const isTournAdmin = computed(() => awaitables.value.ready ? isAuthorizedToManageTournament(awaitables.value.tournamentTeam.competitionUID, User.value) : false);
    const isTournTeamAdmin = computed(() => awaitables.value.ready ? awaitables.value.tournamentTeam.admin.find(admin => admin.userID === User.value.userID) : false);

    return () => (
      <div data-test="R_TournamentRegistration">
        {
          awaitables.value.ready
            ? (
              <>
                <div>Tournament team {teamDesignation(awaitables.value.tournamentTeam)}</div>
                <div class="text-xs">{awaitables.value.tournamentTeam.areaTeam.teamname}</div>
                {
                  (() => {
                    switch (awaitables.value.tournamentTeam.status) {
                      case "DROPPED_BY_HOST":
                        // fallthrough
                      case "DROPPED_BY_SUBMITTER":
                        // do we ever want to configure a dropped team? maybe we want to show details about how it was prior to it being dropped
                        return null;
                      case "PENDING":
                        return (
                          <>
                            {
                              isTournAdmin.value
                                ? (
                                  // tourn admin can edit questions here in pending state
                                  <>
                                    <Rosters resolved={awaitables.value}/>
                                    <TeamManagers/>
                                    <Questions resolved={awaitables.value}/>
                                  </>
                                )
                                // a "just" tournTeamManager can edit rosters here
                                : <>
                                  <Rosters resolved={awaitables.value}/>
                                  <TeamManagers/>
                                </>
                            }
                          </>
                        );
                      case "PAID_AWAITING_APPROVAL":
                        // fallthrough
                      case "APPROVED":
                        return (
                          <>
                            <Rosters resolved={awaitables.value}/>
                            <TeamManagers/>
                            <Questions resolved={awaitables.value}/>
                          </>
                        )
                      default: exhaustiveCaseGuard(awaitables.value.tournamentTeam.status);
                    }

                    function TeamManagers() {
                      return (
                        <div class="shadow-md my-4">
                          <div class="bg-black p-1 text-white">Team managers</div>
                          <div class="p-2 border border-slate-200 border-t-0">
                            <AdminRoster tournamentTeamID={props.tournamentTeamID} mut_existingAdmins={mut_teamAdmins}/>
                          </div>
                        </div>
                      )
                    }

                    function Questions({resolved}: {resolved: ResolvedBlob}) {
                      return (
                        <>
                          <div class="shadow-md my-4">
                            <div class="bg-black p-1 text-white">
                              <div>Registration questions</div>
                              {
                                !isRegistrar.value ? <div class="text-xs">readonly view of existing answers</div> : null
                              }
                            </div>
                            <div class="p-2 border border-slate-200 border-t-0">
                              <TournamentTeamRegistrationQuestionsForm
                                key={props.tournamentTeamID}
                                pageItems={resolved.registrationPageItems}
                                existingAnswers={resolved.registrationAnswers}
                                readonlyQuestionIDs={readonlyQuestionIDs.value}
                                doShowPoints={isRegistrar.value}
                                submitLabel="Update"
                                doHideSubmitButton={
                                  // if all questions are readonly, don't show the submit button
                                  resolved
                                    .registrationPageItems
                                    .filter(v => v.type === iltypes.PageItemType.QUESTION).length === readonlyQuestionIDs.value.size
                                }
                                onSubmit={handleSubmitQuestionAnswers}
                              />
                            </div>
                          </div>
                        </>
                      )
                    }
                    function Rosters({resolved}: {resolved: ResolvedBlob}) {
                      return (
                        <>
                          {
                            resolved.tournamentTeam.tournament_playerRosterSubmissionConfig === iltournament.TournTeamPlayerRosterSubmissionConfig.allowed
                              ? (
                                <div class="shadow-md my-4">
                                  <div class="bg-black p-1 text-white">Player roster</div>
                                  <div class="p-2 border border-slate-200 border-t-0">
                                    <PlayerRoster tournamentTeamID={props.tournamentTeamID} mut_existingPlayerLinks={resolved.tournamentTeam.playerLinks}/>
                                  </div>
                                </div>
                              )
                              : null
                          }
                          <div class="shadow-md my-4">
                            <div class="bg-black p-1 text-white">Referee roster</div>
                            <div class="p-2 border border-slate-200 border-t-0">
                              <RefereeRoster
                                tournTeamOrUndefinedIfUnaffiliatedOrMultiple={resolved.tournamentTeam}
                                targetBinding={{type: "tournamentTeam", tournamentTeamID: props.tournamentTeamID}}
                                mut_existingOfficials={resolved.tournamentTeam.officials}
                              />
                            </div>
                          </div>
                          <div class="shadow-md my-4">
                            <div class="bg-black p-1 text-white">Coach roster</div>
                            <div class="p-2 border border-slate-200 border-t-0">
                              <CoachRoster tournamentTeam={resolved.tournamentTeam} mut_existingOfficials={resolved.tournamentTeam.officials}/>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })()
                }
              </>
            )
            : null
        }
      </div>
    )
  }
})
