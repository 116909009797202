import dayjs from "dayjs";
import { bestUpcomingOrCurrentCompetitionStartDayOfWeek } from "src/helpers/Competition";
import { createWeekOptions } from "src/helpers/formatDate";
import { Competition, CompetitionSeason, Datelike } from "src/interfaces/InleagueApiV1";

/**
 * Create a map of (datelike -> uilabel),
 * mapping "dates" to the ui version of that date,
 * representing all available weeks in some particular season.
 */
export function createWeekOptionsForCompetitionSeason(
  comp: Competition,
  compSeason: CompetitionSeason,
  weeksCount = compSeason.seasonWeeks
) : {[startOfWeek: Datelike]: /* ui label */ string} {
  return createWeekOptions(
    new Date(compSeason.seasonStart),
    comp.startDayOfWeek,
    weeksCount,
    new Date(compSeason.seasonStart)
  );
}

//
// Choose "current week" out of a list of available weeks, where the list of available weeks is expected to contain "current week"
//
// Given competition.startDayOfWeek of N (which defaults to Saturday, or 7)
// If today's day of the week is equal to N or N+1 (which in the above case is 7 or 1, for Sunday)
//   then show the 'current' week (starting on the Saturday, which is either 'the next Saturday' or if today is Sunday, 'yesterday')
// If today's day of the week is any other day
//   default to the next N (e.g. today is Monday 4/24 so show N == Saturday, 4/29)
//
export function chooseCurrentWeekFromAvailableWeeks(
  comp: Pick<Competition, "startDayOfWeek" | "competitionUID">,
  availableWeeks: Datelike[],
  today = dayjs()
) : Datelike | undefined {
  const target = bestUpcomingOrCurrentCompetitionStartDayOfWeek(comp.startDayOfWeek, today)

  //
  // scan through and compare based on dayjs-parsed values;
  // but note that what we return to caller is the literally provided string datelike value from the provided list,
  // which gives some wiggle room for caller-provided formats, as long as they are parseable by dajs
  //
  const ret = availableWeeks.find(someDatelike => dayjs(someDatelike).isSame(target, "day"))

  if (ret === undefined) {
    //
    // we might want to actually throw or at least log this to sentry. We shouldn't ever get here.
    // throw `couldn't find startDayOfWeek, competitionUID=${comp.competitionUID}, (jsday)today=${todaysDayOfWeek}, (jsday)startDayOfWeek=${N}, target=${target.toISOString()}`
    //
    return undefined;
  }
  else {
    return ret;
  }
}
