import { faChevronRight, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dayjs from "dayjs";
import { SoccerBall } from "src/components/SVGs";
import { GetRegistrationQuestionAnswersResponse } from "src/composables/InleagueApiV1.Registration";
import { TeamForTeamAssignmentsView } from "src/composables/InleagueApiV1.Teams";
import { dayjsOr } from "src/helpers/formatDate";
import { vReqT, sortByMany, SetEx, arrayFindOrFail, sortBy, accentAwareCaseInsensitiveCompare } from "src/helpers/utils";
import { Guid, RegistrationQuestion } from "src/interfaces/InleagueApiV1";
import { Client } from "src/store/Client";
import { defineComponent, ref, computed } from "vue";
import { CommonTeamAssignmentsPlayerListing } from "./CommonTeamAssignmentsPlayerListing";
import { getTeamName, unassignedPlayerDragMimeType, k_activeDropTargetOutlineClasses, k_allTeamsAvgTeamID, TeamAverageRatings, globalTeamAssignmentsDragData, SelectedQuestions, assignedPlayerDragMimeType, PlayerForTeamAssignmentViewEx } from "./TeamAssignments.shared";
import { Btn2 } from "src/components/UserInterface/Btn2";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { vueDirective_ilDropTarget, ilDropTarget, ilDraggable, vueDirective_ilDraggable } from "src/modules/ilDraggable"

export interface TeamElementSlots {
  moveElementArrow?: () => JSX.Element | null
}

export const TeamElement = defineComponent({
  props: {
    team: vReqT<TeamForTeamAssignmentsView>(),
    players: vReqT<PlayerForTeamAssignmentViewEx[]>(),
    selectedQuestionIDs: vReqT<SelectedQuestions>(),
    selectedPlayerIDs: vReqT<SetEx<Guid>>(),
    registrationQuestions: vReqT<RegistrationQuestion[]>(),
    registrationQuestionAnswers: vReqT<GetRegistrationQuestionAnswersResponse>(),
    busyByPlayerID: vReqT<Set<Guid>>(),
    busyByTeamID: vReqT<Set<Guid>>(),
    teamAverageRatings: vReqT<TeamAverageRatings>(),
    isFocused: vReqT<boolean>(),
    isExpanded: vReqT<boolean>(),
    showDragGrip: vReqT<boolean>(),
    showTentativeAssignmentInfo: vReqT<boolean>(),
    droppable: vReqT<Set<typeof unassignedPlayerDragMimeType | typeof assignedPlayerDragMimeType>>(),
    offerFocus: vReqT<boolean>(),
    moveSelectedPlayersButtonLabel: vReqT<string>(),
    wholeTeamDragConfig: vReqT<ilDraggable | null>(),
  },
  directives: {
    ilDropTarget: vueDirective_ilDropTarget,
    ilDraggable: vueDirective_ilDraggable,
  },
  emits: {
    focus: () => true,
    toggleExpand: () => true,
    unexpand: () => true,
    /**
     * Can be a drag and drop from:
     *   - "unassigned" players, in which case originTeam is null
     *   - some other team, in which case originTeam is the team the players are being moved from
     */
    didDropPlayers: (_: {originTeam: TeamForTeamAssignmentsView | null, players: PlayerForTeamAssignmentViewEx[]}) => true,
    /**
     * move from team A to team B. Note that the parent will have to figure out (via contextual info, or asking the user) what team to move to
     */
    didRequestMovePlayers: (_: {players: PlayerForTeamAssignmentViewEx[]}) => true,
    /**
     * remove from team A __back__ to some originating team (back to unassigned, or back to "currently assigned" team if an assignment is itself a tentative move)
     */
    deleteAssignments: (_: {players: PlayerForTeamAssignmentViewEx[]}) => true,
    undeleteLoans: (_: {players: PlayerForTeamAssignmentViewEx[]}) => true,
  },
  setup(props, config) {
    const emit = config.emit
    const slots = config.slots as TeamElementSlots

    const activeDropTargetOutlineClasses = ref("")

    const teamName = computed(() => getTeamName(props.team))

    const headerCoachListing = computed(() => {
      const listing = props
        .team
        .coaches
        .filter(v => v.title === "Head Coach" || v.title === "Co-Coach")
        .sort(sortByMany(
          sortBy(_ => _.title === "Head Coach" ? 0 : 1), // head coaches first
          (l, r) => accentAwareCaseInsensitiveCompare(l.lastName, r.lastName),
          (l, r) => accentAwareCaseInsensitiveCompare(l.firstName, r.firstName),
        ))
        .map(v => `${v.firstName} ${v.lastName}`)
        .join(", ")

      return listing ? `(${listing})` : "";
    });

    const headerPlayerCount = computed<string>(() => {
      const playerCount = props.players.filter(v => v.clientData.type === "assignment").length
      const loanCount = props.players.filter(v => v.clientData.type === "loan").length

      const playerBlurb = playerCount === 1
        ? "1 player"
        : `${playerCount} players`

      if (loanCount === 0) {
        return `(${playerBlurb})`
      }

      const loanBlurb = loanCount === 1
        ? `1 loan`
        : `${loanCount} loans`;

      return `(${playerBlurb}, ${loanBlurb})`
    })

    const dragDataContainsPlayersAlreadyForThisTeam = () => {
      const originTeamIsThisTeam = globalTeamAssignmentsDragData.assignedPlayersBeingDragged?.originTeam.team.teamID === props.team.team.teamID;
      if (originTeamIsThisTeam) {
        return false;
      }

      const draggedPlayerIDs = [
        ...globalTeamAssignmentsDragData.loansBeingDragged?.players.map(v => v.apiData.child.childID) ?? [],
        ...globalTeamAssignmentsDragData.assignedPlayersBeingDragged?.players.map(v => v.apiData.child.childID) ?? [],
        ...globalTeamAssignmentsDragData.unassignedPlayersBeingDragged?.map(v => v.apiData.child.childID) ?? [],
      ]
      const ourPlayerIDs = props.players.map(v => v.apiData.child.childID)
      return new SetEx(draggedPlayerIDs).intersect(ourPlayerIDs).size > 0;
    }

    const playerAssignmentsDropTargetConfig : ilDropTarget = {
      onEnter: (dataTransfer: DataTransfer) => {
        if (new SetEx(dataTransfer.types).intersect(props.droppable).size > 0) {
          activeDropTargetOutlineClasses.value = k_activeDropTargetOutlineClasses
          if (dragDataContainsPlayersAlreadyForThisTeam()) {
            globalTeamAssignmentsDragData.showDuplicateWarning = true;
          }
          return true;
        }
        return false;
      },
      onDragOver: "sameAsOnEnter",
      onLeaveOrEnd: () => {
        activeDropTargetOutlineClasses.value = ""
        globalTeamAssignmentsDragData.showDuplicateWarning = false
      },
      onDrop: dataTransfer => {
        if (new SetEx(dataTransfer.types).intersect(props.droppable).size === 0) {
          return;
        }

        if (dataTransfer.types.includes(unassignedPlayerDragMimeType)) {
          if (!globalTeamAssignmentsDragData.unassignedPlayersBeingDragged) {
            return;
          }
          emit("didDropPlayers", {originTeam: null, players: globalTeamAssignmentsDragData.unassignedPlayersBeingDragged});
        }
        else if (dataTransfer.types.includes(assignedPlayerDragMimeType)) {
          if (!globalTeamAssignmentsDragData.assignedPlayersBeingDragged) {
            return;
          }
          emit("didDropPlayers", globalTeamAssignmentsDragData.assignedPlayersBeingDragged);
        }
        else {
          // no other cases
        }
      }
    };

    const k_embargoDateFormat = "dddd, MMMM D, YYYY h:mm a"
    const embargoDate = computed(() => dayjsOr(props.team.embargoDate));

    const moveSelectedPlayers = () => {
      const selectedPlayers = [...props.selectedPlayerIDs].map(playerID => arrayFindOrFail(props.players, v => v.apiData.child.childID === playerID));
      emit("didRequestMovePlayers", {players: selectedPlayers});
    }

    return () => {
      return (
        <div
          class={[`shadow-md rounded-md bg-white`, activeDropTargetOutlineClasses.value]}
          v-ilDropTarget={playerAssignmentsDropTargetConfig}
          data-test={`TeamElement/teamID=${props.team.team.teamID}`}
        >
          <div class="p-2 bg-gray-200 rounded-t-md">
            <div class="flex">
              <div style="flex-grow:4;" class="flex flex-wrap gap-1 items-center">
                <div class="flex items-center">
                  <div class="cursor-grab py-1 px-2 rounded-md hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)]" v-ilDraggable={props.wholeTeamDragConfig}>
                    <FontAwesomeIcon icon={faGripDotsVertical}/>
                  </div>
                  {
                    !props.isFocused
                      ? (
                        <div class="p-1 rounded-md cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)]" onClick={() => { emit("toggleExpand")}}>
                          <FontAwesomeIcon icon={faChevronRight} class={`transition-all ${props.isExpanded ? "rotate-90" : ""}`}/>
                        </div>
                      )
                      : null
                  }
                </div>
                {
                  props.busyByTeamID.has(props.team.team.teamID)
                    ? <SoccerBall color={Client.value.clientTheme.color} width="1.15em" height="1.15em"/>
                    : null
                }

                <span>{teamName.value}</span>

                {
                  // for flex container gap purposes, render literally nothing if there's nothing
                  headerCoachListing.value
                    ? <span>{headerCoachListing.value}</span>
                    : null
                }

                <span>{headerPlayerCount.value}</span>
              </div>
              <div style="flex-grow: 1;" class="flex justify-end items-start">
                {
                  !props.isFocused && props.offerFocus
                    ? <t-btn type="button" margin={false} style="padding:.25em;" onClick={() => emit("focus")}>
                      <div class="flex gap-1 items-center whitespace-nowrap">
                        <FontAwesomeIcon icon={faArrowUp}/>
                        Work with this team
                      </div>
                    </t-btn>
                    : null
                }
              </div>
              <div class="ml-auto">
                {slots.moveElementArrow?.()}
              </div>
            </div>

            <div class="text-xs flex gap-1 flex-wrap">
              <span style="padding:.25em .35em;" class="bg-white border border-2 border-green-700 rounded-md">Lifetime rating: {props.teamAverageRatings.lifetimeByTeamID.getDisplayValue(props.team.team.teamID)}</span>
              <span style="padding:.25em .35em;" class="bg-white border border-2 border-green-700 rounded-md">Avg current rating: {props.teamAverageRatings.mostRecentSeasonByTeamID.getDisplayValue(props.team.team.teamID)}</span>
              {props.team.teamSeason
                ? <>
                  {props.team.teamSeason.practiceLoc ? <span  style="padding:.25em .35em;" class="bg-white border border-2 border-green-700 rounded-md">Practice Location: {props.team.teamSeason.practiceLoc}</span> : null}
                  {props.team.teamSeason.practiceTime ? <span style="padding:.25em .35em;" class="bg-white border border-2 border-green-700 rounded-md">Practice Time: {props.team.teamSeason.practiceTime}</span> : null}
                </>
                : null
              }
            </div>
          </div>
          {
            props.isExpanded
              ? (
                <div class="p-2">
                  <div class="mb-2 text-sm">
                    <div class="p-1 border border-slate-400 rounded-md">
                    {
                      !embargoDate.value
                        ? (
                          <div>
                            <div>Roster Embargo Status: Lifted</div>
                            <div class="border-b border-slate-200 border-dashed my-1"/>
                            <div>Player assignments will be available to coaches and family members immediately.</div>
                          </div>
                        )
                        : embargoDate.value.isAfter(dayjs())
                        ? (
                          <div>
                            <div>Roster Embargo Status: Active</div>
                            <div class="border-b border-slate-400 border-dashed my-1"/>
                            <div>Player assignments for this team will be available to coaches and family members on: {embargoDate.value.format(k_embargoDateFormat)}</div>
                          </div>
                        )
                        : (
                          <div>
                            <div>Roster Embargo Status: Lifted</div>
                            <div class="border-b border-slate-200 border-dashed my-1"/>
                            <div>Player assignments for this team became available on: {embargoDate.value.format(k_embargoDateFormat)}</div>
                          </div>
                        )
                    }
                    </div>
                  </div>
                  {/*TODO: HMR doesn't work for this?*/}
                  <CommonTeamAssignmentsPlayerListing
                    selectedPlayerIDs={props.selectedPlayerIDs}
                    players_unfiltered={props.players}
                    players={props.players}
                    filter={null}
                    hasSomeNonEmptyFilters={false}
                    selectedQuestions={props.selectedQuestionIDs}
                    registrationQuestions={props.registrationQuestions}
                    registrationQuestionAnswers={props.registrationQuestionAnswers}
                    busyByPlayerID={props.busyByPlayerID}
                    showTentativeAssignmentInfo={props.showTentativeAssignmentInfo}
                    mode="already-assigned-listing"
                    team={props.team}
                    showDragGrip={props.showDragGrip}
                    onDeleteAssignments={args => emit("deleteAssignments", args)}
                    onUndeleteLoans={args => emit("undeleteLoans", args)}
                  />
                  <div class="mt-2">
                    <Btn2
                      type="button"
                      class="p-1"
                      onClick={moveSelectedPlayers}
                      disabled={props.selectedPlayerIDs.size === 0}
                    >{props.moveSelectedPlayersButtonLabel}</Btn2>
                  </div>
                </div>
              )
              : (
                // A little bit of whitespace on the card element, because it looks kind of weird
                // if the whole thing is collapsed to "just" a header. Though, this looks kind of weird, too.
                <div class="p-1 rounded-b-md bg-white"></div>
              )
          }
        </div>
      )
    }
  }
})
