<template lang="pug">
.overflow-auto(v-if='game' data-test="GameScore")
  .bg-white.overflow-hidden(class='sm:rounded-lg')
    .py-5.border-b.border-gray-200(class='sm:px-6')
      div.font-medium.text-gray-900.w-full(class="flex flex-col items-center md:flex-row" data-test="headline")
        div(class='w-full md:w-5/12 flex flex-col items-center md:items-end' data-test="home")
          div.text-lg {{ game.homeTeam.team }}
          div.text-xs(v-if='game.homeTeam.ID && game.homeTeam.teamName') ({{ FIXME_trim(game.homeTeam.teamName) }})
          div.text-xs.font-normal.mt-1 {{ homeCoaches }}
        div(class='w-full md:w-1/6 flex justify-center') vs.
        div(class='w-full md:w-5/12 flex flex-col items-center md:items-start' data-test="visitor")
          div.text-lg {{ game.visitorTeam.team }}
          div.text-xs(v-if='game.homeTeam.ID && game.visitorTeam.teamName') ({{ FIXME_trim(game.visitorTeam.teamName) }})
          div.text-xs.font-normal.mt-1 {{ visitorCoaches }}

      .w-full.flex.flex-col.items-center
        div.mt-2.text-sm.leading-5.text-gray-500.text-center
          div {{ gameStartDateTime.date }}
          div {{ gameStartDateTime.time }}
          div {{ game.fieldName }}
    .px-4.py-5.border-gray-200.border-b.flex.flex-col.items-center(class='sm:px-6')
      div.flex.flex-col.items-center
        div.text-base.leading-5.font-medium.text-gray-900
          | Final Score
        div.mt-1
        div.text-xs.leading-5.text-gray-500 ({{ game.homeTeam.team }} / {{ game.visitorTeam.team }})
        div.text-sm.leading-5.text-gray-500.font-medium {{ finalScore }}
      div.flex.flex-col.items-center.mt-1
        div.text-base.leading-5.text-gray-900.font-medium
          | Halftime Score
        div.mt-1
        div.text-xs.leading-5.text-gray-500
            | ({{ game.homeTeam.team }} / {{ game.visitorTeam.team }})
        div.text-sm.leading-5.text-gray-500.font-medium {{ halftimeScore }}
    .px-4.flex.flex-col.border-gray-200.border-b(
      v-if='game.scoreTransactions && game.scoreTransactions.length',
      data-cy='scoreTransactions'
    )
      .py-2.text-base.leading-5.font-medium.text-gray-900.borderb-
        | Transactions
      .-my-2.py-6.overflow-x-auto(class='sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8')
        .align-middle.inline-block.min-w-full.shadow.overflow-hidden.border-b.border-gray-200(
          class='sm:rounded-lg'
        )
          table.min-w-full.divide-y.divide-gray-200
            thead
              tr
                th.px-2.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase
                  | Team
                th.px-2.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase
                  | Transaction
                th.px-2.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase
                  | Comment
                th.px-2.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase
                  | Score Adjustment
                th.px-2.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase
                  | Recorded By
            tbody(v-if='game.scoreTransactions')
              tr.bg-white(
                v-for='transaction in game.scoreTransactions',
                v-if='game.scoreTransactions',
                data-cy='scoreTransaction'
              )
                td.px-2.py-4.whitespace-nowrap(
                  v-if='transaction.transactionID'
                )
                  p.text-sm.leading-5.font-medium.text-gray-900(
                    data-cy='transactionsTeamName'
                  )
                    | {{ transaction.fullTeamName }}
                td.px-2.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500(
                  v-if='transaction.transactionID'
                )
                  | {{ transaction.scoreTransactionTypeLabel }}
                td.px-2.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.font-light.italic(
                  v-if='transaction.transactionID'
                )
                  | {{ transaction.comment }}
                td.px-2.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.font-light(
                  v-if='transaction.transactionID'
                )
                  | {{ transaction.points }}
                td.px-2.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.font-light(
                  v-if='transaction.transactionID'
                )
                  | {{ transaction.submitterFirstName }} {{ transaction.submitterLastName }}

    div.py-6.px-4.text-base.leading-5.font-medium.text-gray-900.flex.flex-col.items-center(
      v-if='game.scoreComment'
    )
      div Comments:
      div.mt-1.text-sm.leading-5.text-gray-600.italic.font-light {{ game.scoreComment }}
  .h-full.w-full.bg-transparent.top-0.left-0.fixed(
    v-if='edit && hover',
    @click='hover = false',
    :style='{ hover: z - 40 }'
  )
  div.w-60.mx-auto(data-cy="editScore").mt-10
    div(v-if="!game.userAuthorizedForMatchReport")
      div Please contact your league administrator for authorization to enter scores for this game.
    template(v-else)
      FormKit(
          type='form',
          @validation='validation = $event',
          v-model='form',
          v-if='edit',
          label='Submit',
          data-test='submitScore',
          @submit='toggleSubmitScore(true)'
        )
          FormKitSchema(
            :schema='schema',
            :data='form',
          )

  template(v-if="game.userAuthorizedForMatchReport")
    .border-2.border-solid.border-gray-300.rounded-lg.px-5.py-2.mt-10.max-w-sm.mx-auto(
      v-if='edit && game.scoreTransactions && game.scoreTransactions.length'
    )
      div(v-for='(transaction, idx) in game.scoreTransactions')
        GameTransaction(
          :transNum='idx + 1',
          :compTransactions='transactionTypes',
          :transaction='transaction',
          :homeTeam='game.homeTeam',
          :visitorTeam='game.visitorTeam',
          @deleteTrans='removeTransaction'
        )
      .flex.mt-4.mb-2
        div(@click='addTransaction', data-cy='addTransaction')
          font-awesome-icon.text-green-700.text-lg(
            :icon='["fas", "plus-circle"]'
          )
        .font-medium.ml-2 Add Transaction
        button.ml-2(v-tooltip.bottom="{content: 'e.g. yellow cards and other score modifiers', trigger: 'hover click'}")
          font-awesome-icon.text-sm(:icon='["fas", "question-circle"]')
    div.w-60.mx-auto
      div(v-if='edit && game.scoreTransactions && !game.scoreTransactions.length')
        .flex
          div(@click='addTransaction')
            font-awesome-icon.text-green-700.text-lg(
              :icon='["fas", "plus-circle"]'
            )
          .font-medium.ml-2 Add Transaction
          button.ml-2(
            v-tooltip.bottom="{content: 'e.g. yellow cards and other score modifiers', trigger: 'hover click'}"
          )
            font-awesome-icon.text-sm(:icon='["fas", "question-circle"]')
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  Ref,
  getCurrentInstance,
  onMounted,
  ComputedRef,
  computed,
  watch,
} from 'vue'
import GameTransaction from './GameTransaction.vue'
import { HomeTeam, TransactionType, VisitorTeam } from 'src/composables/InleagueApiV1.Game'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { GameScoreDetails, ScoreForm } from 'src/interfaces/score'
import { formatDateWithoutTime } from 'src/helpers/formatDate'
import { useRoute, useRouter } from 'vue-router'

import { IziToast } from 'izitoast'
import dayjs from "dayjs";
import { useIziToast, parseIntOr } from 'src/helpers/utils'
import { Games } from 'src/store/Games'

export default defineComponent({
  name: 'GameScore',
  components: {
    GameTransaction,
  },
  setup() {
    const router = useRouter()
    const iziToast = useIziToast();
    const $route = useRoute()
    const gameID = ref('')
    const form = ref({}) as Ref<ScoreForm>
    const transaction = ref(false)
    const teams = ref({}) as Ref<{
      HomeTeam?: HomeTeam
      VisitorTeam?: VisitorTeam
    }>
    /**
     * Starts out life null. Definitely assigned in onMounted, but there are computeds that run prior to that
     * where we need to guard against nullity.
     * We should load this here and bounce to a context where we can type it as definitely not null.
     */
    const game = ref<GameScoreDetails | null>(null)
    const coachNamesForTeam = (adhocCoachInfo: GameScoreDetails["adhocCoachInfo"], which: GameScoreDetails["adhocCoachInfo"][number]["which"]) : string => {
      return adhocCoachInfo
          .filter(v => v.which === which && (v.title === "Head Coach" || v.title === "Co-Coach"))
          .map(v => `${v.firstName} ${v.lastName}`).join(", ");
    }
    const homeCoaches = computed<string>(() => {
      return game.value ? coachNamesForTeam(game.value.adhocCoachInfo, "home") : "";
    })
    const visitorCoaches = computed<string>(() => {
      return game.value ? coachNamesForTeam(game.value.adhocCoachInfo, "visitor") : "";
    })

    const gameStartDateTime = computed<{date: string, time: string}>(() => {
      const v = dayjs(game.value?.gameStart);
      if (!v.isValid()) {
        // sensible in the "we haven't loaded a game yet and `game` effectively null" case
        // If we have loaded a game, we shouldn't hit this; but, this is nicer looking that "NaN invalid date" or etc.
        return {date: game.value?.gameStart || "", time: ""};
      }
      else {
        // e.g. 'December 14th, 2018' '1:30 pm'
        return {date: v.format("MMMM Do, YYYY"), time: v.format("h:mm a")}
      }
    })
    const hover = ref(false)
    const validation: any = ref({})
    const edit = ref(false)
    const transactionTypes = ref([]) as Ref<TransactionType[]>



    const submissionVerified = computed(() => {
      return Games.value.submissionVerified
    })

    const toggleSubmitScore = (bool: boolean) => {
      if (!game.value) {
        throw "expected a non-null game here";
      }

      Games.directCommit_setSubmitScore(bool)
      if (!game.value.scoreTransactions.length) {
        Games.directCommit_setSubmissionVerified(bool)
      }
    }

    const setFormData = () => {
      if (!game.value) {
        throw "expected a non-null `game` object"
      }
      const frm = {
        homeGoals: game.value.homeTeam.goals,
        homeGoalsHalftime: game.value.homeTeam.goalsHalfTime,
        visitorGoals: game.value.visitorTeam.goals,
        visitorGoalsHalftime: game.value.visitorTeam.goalsHalfTime,
        scoreComment: game.value.scoreComment ? game.value.scoreComment : '',
      }
      form.value = frm
    }

    const getTransactionTypes = async () => {
      try {
        const response = await axiosInstance.get(`v1/scoreTransactionTypes`)
        transactionTypes.value = response.data.data as TransactionType[]
      } catch (err) {
        // console.log('error in getTransactionTypes, GameScore', err)
      }
    }

    /**
     * mutates game.value
     */
    const getGame = async () => {
      try {
        const response = await axiosInstance.get(
          `v1/game/${gameID.value}/gameScores`
        )
        game.value = response.data.data as GameScoreDetails
        setFormData()
        return response
      } catch (err) {
        return err
      }
    }

    const postScore = async () => {
      try {
        const response = await axiosInstance.put(
          `v1/game/${gameID.value}/gameScores`,
          form.value
        )

        // we want the side effect here
        await getGame()
        if (!game.value) {
          throw "expected a non-null game here";
        }

        // Partial update of games having this gameID stored anywhere within the games store.
        // We only update the fields we edit here.
        // note that the shape is different than the source object (esp. with respect to access path to score info)
        await Games.updateGame({
          gameID: game.value.gameID,
          homeGoals: game.value.homeTeam.goals,
          homeGoalsHalftime: game.value.homeTeam.goalsHalfTime.toString(),
          visitorGoals: game.value.visitorTeam.goals,
          visitorGoalsHalftime: game.value.visitorTeam.goalsHalfTime.toString(),
          scoreComment: game.value.scoreComment ? game.value.scoreComment : '',
        })

        iziToast.success({message: "Scores updated."});

        router.push({name: 'scores'})
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const addTransaction = () => {
      if (!game.value) {
        throw "expected a non-null game here";
      }

      if (!game.value.scoreTransactions) game.value.scoreTransactions = []
      game.value.scoreTransactions.push({
        points: 0,
        teamID: '',
        playerName: '',
        registrationID: '',
        teamName: '',
        comment: '',
        label: '',
        transactionType: '',
      })
    }

    const removeTransaction = (transNum: number) => {
      if (!game.value) {
        throw "expected a non-null game here";
      }

      game.value.scoreTransactions.splice(transNum - 1, 1)
    }

    const schema: ComputedRef = computed(() => {
      if (!game.value) {
        return [];
      }

      return [
        {
          $formkit: 'number',
          name: 'homeGoalsHalftime',
          model: 'homeGoalsHalftime',
          validations: 'number',
          label: `${game.value.homeTeam.fullName} Halftime Score`,
          autocomplete: 'no',
          'input-has-errors-class': 'border-red-500',
          refs: 'homeHalftimeScore',
        },
        {
          $formkit: 'number',
          name: 'homeGoals',
          model: 'homeGoals',
          validations: 'required|number',
          label: `${game.value.homeTeam.fullName} Score*`,
          autocomplete: 'no',
          'input-has-errors-class': 'border-red-500',
          refs: 'homeScore',
        },
        {
          $formkit: 'number',
          name: 'visitorGoalsHalftime',
          model: 'visitorGoalsHalftime',
          validations: 'number',
          label: `${game.value.visitorTeam.fullName} Halftime Score`,
          autocomplete: 'no',
          'input-has-errors-class': 'border-red-500',
          refs: 'visitorHalftimeScore',
        },
        {
          $formkit: 'number',
          name: 'visitorGoals',
          model: 'visitorGoals',
          validations: 'required|number',
          label: `${game.value.visitorTeam.fullName} Score*`,
          autocomplete: 'no',
          'input-has-errors-class': 'border-red-500',
          refs: 'visitorScore',
        },
        {
          $formkit: 'textarea',
          name: 'scoreComment',
          model: 'scoreComment',
          label: `Comments`,
          'label-class': 'font-medium text-smt-label block mb-2 mt-5',
          'input-has-errors-class': 'border-red-500',
          refs: 'Comments',
        },
      ]
    })

    onMounted(async () => {
      gameID.value = $route.params.id as string

      await getGame()
      await getTransactionTypes()

      if (game.value!.userAuthorizedForMatchReport) {
        edit.value = true
      }
    })

    watch(submissionVerified, async (verified: boolean) => {
      if (
        verified &&
        (Object.keys(validation.value).length === 0 ||
          validation.value.errors.length === 0)
      ) {
        await postScore()
      } else if (verified) {
        iziToast.error({
          message: 'Score not submitted. Please provide all required fields',
        })
      }
      Games.directCommit_setSubmissionVerified(false)
    })

    const scoreOrTBD = (v: any) : string | number => {
      return parseIntOr(v, "TBD");
    }

    const finalScore = computed<string>(() => {
      if (!game.value) {
        return "";
      }
      const home = scoreOrTBD(game.value.homeTeam.goals);
      const visitor = scoreOrTBD(game.value.visitorTeam.goals);
      return `${home} / ${visitor}`
    })

    const halftimeScore = computed<string>(() => {
      if (!game.value) {
        return "";
      }
      const home = scoreOrTBD(game.value.homeTeam.goalsHalfTime);
      const visitor = scoreOrTBD(game.value.visitorTeam.goalsHalfTime);
      return `${home} / ${visitor}`;
    })

    return {
      transaction,
      toggleSubmitScore,
      teams,
      game,
      edit,
      hover,
      form,
      schema,
      formatDateWithoutTime,
      addTransaction,
      removeTransaction,
      transactionTypes,
      gameStartDateTime,
      // some places in the template need their strings trimmed
      // a) shouldn't be necessary to do so in the template
      // b) we don't have strong enough types to be confident that they're always strings (hence the guard on string runtime type)
      // mostly this appears to be a cf string-interpolation-adding-a-trailing-space issue
      FIXME_trim: (s: any) => typeof s === "string" ? s.trim() : s,
      halftimeScore,
      finalScore,
      homeCoaches,
      visitorCoaches
    }
  },
})
</script>

<style scoped>
.tooltip {
  width: 200px;
  background: #718096;
  color: #ffffff;
  text-align: center;
  padding: 10px 20px 10px 20px;
  border-radius: 7px;
  top: calc(100% + 11px);
  left: 50%;
  transform: translate-x(-50%);
}
.tooltip-box {
  position: relative;
}
.triangle {
  border-width: 0 6px 6px;
  border-color: transparent;
  border-bottom-color: #718096;
  position: absolute;
  top: -6px;
  left: 20%;
  transform: translate-x(-50%);
}
</style>



