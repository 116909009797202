import { FormKit, FormKitMessages } from "@formkit/vue"
import { Btn2 } from "src/components/UserInterface/Btn2"
import { vReqT, UiOption, requireNonNull, arrayFindOrFail, forceCheckedIndexedAccess, SetEx } from "src/helpers/utils"
import { Guid } from "src/interfaces/InleagueApiV1"
import { defineComponent, computed, watch, ref } from "vue"
import { NewPoolForm, PoolData } from "./Shared"
import { TeamForTeamPoolEditor, TeamPool, TeamPoolForTeamPoolEditor } from "src/composables/InleagueApiV1.Teams"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faTrash } from "@fortawesome/pro-solid-svg-icons"

export const PoolListingElement = defineComponent({
  props: {
    poolData: vReqT<PoolData>(),
    newPoolForm: vReqT<NewPoolForm>(),
  },
  emits: {
    createNewPool: () => true,
    addSelectedTeamsToPool: (_: TeamPool) => true,
    removeSelectedTeamsFromPool: (_: TeamPool) => true,
    deleteTeamPool: (_: TeamPool) => true,
  },
  setup(props, {emit}) {
    return () => {
      return (
        <div style="--fk-bg-input: white;">
          <div class="mb-4 rounded-md" data-test="createNewPool">
            <FormKit type="form" actions={false} onSubmit={() => emit("createNewPool")} key={props.newPoolForm.renderKey}>
              <div class="flex gap-2 items-start" style="--fk-margin-outer:none;">
                <FormKit
                  type="text"
                  v-model={props.newPoolForm.name} validation={[["required"], ["length", STRLEN_POOL_NAME_MIN, STRLEN_POOL_NAME_MAX]]}
                  name="Name"
                  data-test="name"
                />
                <Btn2 type="submit" class="p-2">Create new pool</Btn2>
              </div>
              <div class="hidden">
                {/*don't show global "sorry form is not filled out properly" message*/}
                <FormKitMessages/>
              </div>
            </FormKit>
          </div>
          <PoolAvailableTeamsElement
            data-test="PoolAvailableTeamsElement"
            class="my-6 bg-white"
            availableTeams={props.poolData.apiData.availableTeams}
            selectedAddTheseTeamIDs={props.poolData.selectedAddTheseTeamIDs}
            availableTargetPools={props.poolData.apiData.pools}
            onAddSelectedTeams={pool => emit("addSelectedTeamsToPool", pool)}
          />
          {
            props.poolData.apiData.pools.length === 0
              ? (
                <div class="shadow-md rounded-md">
                  <div class="p-2 bg-gray-200 rounded-t-md">
                    Pools
                  </div>
                  <div class="p-2">
                    No current pools
                  </div>
                </div>
              )
              : props.poolData.apiData.pools.map(pool => {
                  return (
                    <PoolEditorElement
                      data-test={pool.poolName}
                      key={pool.poolUID}
                      class="my-6 bg-white"
                      pool={pool}
                      selectedRemoveTheseTeamIDs={requireNonNull(props.poolData.selectedRemoveTheseTeamIDs[pool.poolUID])}
                      onAddSelectedTeamsToPool={() => emit("addSelectedTeamsToPool", pool)}
                      onRemoveSelectedTeamsFromPool={() => emit("removeSelectedTeamsFromPool", pool)}
                      onDeleteTeamPool={() => emit("deleteTeamPool", pool)}
                    />
                  )
              })
          }
        </div>
      )
    }
  }
})

const PoolEditorElement = defineComponent({
  props: {
    pool: vReqT<TeamPoolForTeamPoolEditor>(),
    selectedRemoveTheseTeamIDs: vReqT<SetEx<Guid>>(),
  },
  emits: {
    addSelectedTeamsToPool: () => true,
    removeSelectedTeamsFromPool: () => true,
    deleteTeamPool: () => true,
  },
  setup(props, {emit}) {
    return () => {
      return (
        <div class="rounded-md shadow-md">
          <div class="px-2 py-1 bg-gray-200 rounded-t-md flex items-center gap-2">
            <span
              class="rounded-md p-1 cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)]"
              onClick={() => emit("deleteTeamPool")}
              data-test="deleteTeamPool"
            >
              <FontAwesomeIcon icon={faTrash}/>
            </span>
            <span>{props.pool.poolName}</span>
          </div>
          <div class="p-2">
            {
              props.pool.teams.length === 0
                ? <div>No teams currently assigned to this pool</div>
                : (
                  <div>
                    {
                      props.pool.teams.map(v => {
                        return (
                          <div key={`committed/${v.teamID}`} class="flex items-center gap-2">
                              <input
                                type="checkbox"
                                checked={props.selectedRemoveTheseTeamIDs.has(v.teamID)}
                                onInput={() => props.selectedRemoveTheseTeamIDs.invert(v.teamID)}
                                data-test={v.teamID}
                              />
                            <div>{teamNameText(v)}</div>
                          </div>
                        )
                      })
                    }
                    <Btn2
                      class="mt-2 p-2"
                      disabled={props.selectedRemoveTheseTeamIDs.size === 0}
                      onClick={() => emit("removeSelectedTeamsFromPool")}
                      data-test="removeSelectedTeamsFromPool"
                    >Remove selected teams</Btn2>
                  </div>
                )
            }
          </div>
        </div>
      )
    }
  }
})

const PoolAvailableTeamsElement = defineComponent({
  props: {
    availableTeams: vReqT<TeamForTeamPoolEditor[]>(),
    selectedAddTheseTeamIDs: vReqT<SetEx<Guid>>(),
    availableTargetPools: vReqT<TeamPoolForTeamPoolEditor[]>(),
  },
  emits: {
    addSelectedTeams: (_: TeamPoolForTeamPoolEditor) => true,
  },
  setup(props, {emit}) {
    const selectedTargetPoolUID = ref<"" | Guid>("")
    const poolOptions = computed<UiOption[]>(() => {
      if (props.availableTargetPools.length === 0) {
        return [{label: "No available pools", value: "", attrs: {disabled: true}}]
      }
      else {
        return props.availableTargetPools.map(pool => {
          return {
            label: pool.poolName,
            value: pool.poolUID
          }
        })
      }
    })

    watch(() => poolOptions.value, () => {
      if (!poolOptions.value.find(opt => opt.value === selectedTargetPoolUID.value)) {
        selectedTargetPoolUID.value = ""
      }

      selectedTargetPoolUID.value ||= forceCheckedIndexedAccess(poolOptions.value, 0)?.value || "";
    }, {immediate: true})

    /**
     * If there is no selected pool this will throw
     */
    const getSelectedPool = () => {
      return arrayFindOrFail(props.availableTargetPools, pool => pool.poolUID === selectedTargetPoolUID.value)
    }

    return () => {
      return (
        <div class="rounded-md shadow-md">
          <div class="px-2 py-1 bg-gray-200 rounded-t-md flex items-center gap-2">
            <span>Available Teams</span>
          </div>
          <div class="p-2">
            {
              props.availableTeams.length === 0
                ? <div>No teams are available for assignment to pools for the selected season+program+division.</div>
                : (
                  <div>
                    {
                      props.availableTeams.map(v => {
                        return (
                          <div key={`available/${v.teamID}`} class="flex items-center gap-2">
                              <input
                                type="checkbox"
                                checked={props.selectedAddTheseTeamIDs.has(v.teamID)}
                                onInput={() => props.selectedAddTheseTeamIDs.invert(v.teamID)}
                                data-test={v.teamID}
                              />
                            <div>{teamNameText(v)}</div>
                          </div>
                        )
                      })
                    }
                    <div class="my-2" style="--fk-margin-outer:none; --fk-padding-input:.25em;">
                      <div class="text-sm font-medium">Assign to...</div>
                      <FormKit type="select" v-model={selectedTargetPoolUID.value} options={poolOptions.value} data-test="targetTeam"/>
                    </div>
                    <Btn2
                      class="mt-2 p-2"
                      disabled={props.selectedAddTheseTeamIDs.size === 0 || !selectedTargetPoolUID.value}
                      onClick={() => emit("addSelectedTeams", getSelectedPool())}
                      data-test="commit"
                    >Assign selected teams</Btn2>
                  </div>
                )
            }
          </div>
        </div>
      )
    }
  }
})

const STRLEN_POOL_NAME_MIN = 1
const STRLEN_POOL_NAME_MAX = 150

const teamNameText = (v: TeamForTeamPoolEditor) => {
  if (v.team && v.teamName) {
    return `${v.team} - ${v.teamName}`
  }
  else {
    return v.team;
  }
}
