import { defineComponent, ExtractPropTypes, PropType } from "vue"
import { EventBoundSignupGetters } from "./R_EventRosterImpl.iltsx"
import { RouterLink } from "vue-router";

import * as ilapi from "src/composables/InleagueApiV1"
import { EventRosterListingEventBus } from "./R_EventRosterImpl.ilx";


const propsDef = {
  signup: {
    required: true,
    type: Object as PropType<ilapi.event.EventSignup>
  },
  eventBoundSignupGetters: {
    required: true,
    type: Object as PropType<EventBoundSignupGetters>
  },
  eventRosterListingEventBus: {
    required: true,
    type: Object as PropType<EventRosterListingEventBus>
  }
} as const;

export const component = defineComponent({
  props: propsDef,
  render() {
    if (this.signup.paid && this.signup.invoiceInstanceID) {
      const routeLocation = this.eventBoundSignupGetters.getInvoiceRouteLocation(this.signup);
      if (!routeLocation) {
        // shouldn't happen; we have invoiceInstanceID, so should definitely get a route location
        return "";
      }
      return (
        <RouterLink to={routeLocation}>
          <a data-test="cancel-paid-signup" class="underline text-blue-700">Refund or cancel invoice</a>
        </RouterLink>
      )
    }
    else if (this.signup.paid && this.signup.invoiceNo != "") {
      return <a class="text-blue-700 underline" href={this.eventBoundSignupGetters.legacyEventSignupRefundURL(this.signup)} target="_blank" data-test="legacy-refund-link">Refund or cancel invoice</a>
    }
    else {
      return (
        <div class="flex justify-center">
          <t-btn style="padding:.5em;" onclick={() => this.eventRosterListingEventBus.triggerCancelSignup(this.signup)}>
            <div data-test="cancel-non-paid-signup" class="text-xs">Cancel unpaid signup</div>
          </t-btn>
        </div>
      )
    }
  }
});

export type Props = ExtractPropTypes<typeof propsDef>;

declare global {
  function EventSignupCancellationButton(props: Props) : JSX.Element;
}
