import { faFileExport, faClipboard, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Reflike, UiOption, arrayFindIndexOrFail, downloadFromObjectURL, exhaustiveCaseGuard, forceCheckedIndexedAccess, sortBy, sortByDayJS, sortByMany, useAsyncState } from "src/helpers/utils";
import { vReqT, parseIntOr } from "src/helpers/utils";
import { ColDef, freshSortState, sortDefsAsSorter, SortArrow, getColDefHtml, rowsToXlsxSimpleSheetDef, rowsToCsv } from "src/modules/TableUtils";
import { defineComponent, computed, ref, Fragment } from "vue";

import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { Paginated } from "src/modules/PaginationUtils";
import { SimplePaginationNav } from "src/modules/PaginationElements";
import { multiSheetXlsxFromSheetDefs } from "src/modules/XlsxUtils";
import { DefaultMenuButton, Menu, MenuItem, MenuItemSlots } from "./Menu";
import { RefAssignmentsByUserID, isAuthorizedToManageTournament, teamDesignation, tournTeamStatusUiString } from "./TournamentTeamUtils";
import { dayjsFormatOr } from "src/helpers/formatDate";
import dayjs from "dayjs";
import { TournamentTeamOfficialType, TournamentTeamOverviewReport, TournamentTeamOverviewReport_Coach, TournamentTeamOverviewReport_Ref, TournamentTeamOverviewReport_Team } from "src/composables/InleagueApiV1.Tournament";
import { Division, Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { FilterManager, TournTeamDisplayFilter, computeTournTeamStatusForFiltering } from "./TournTeamDisplayFilter";
import { Client } from "src/store/Client";
import { SelectManyPane, SlotProps as SelectManySlotProps } from "../RefereeSchedule/SelectManyPane";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FormKit } from "@formkit/vue";
import { User } from "src/store/User";

export const TournamentOverviewReport = defineComponent({
  props: {
    seasonName: vReqT<string>(),
    competitionName: vReqT<string>(),
    competitionUID: vReqT<Guid>(),
    report: vReqT<iltournament.TournamentTeamOverviewReport>(),
    /**
     * this might be deprecated with the introduction of `refAssignmentsByTeamByUser`
     */
    refAssignmentsByUserID: vReqT<RefAssignmentsByUserID>(),
    openRefEditModal: vReqT<(_: AugmentedRefRow) => void>(),
  },
  setup(props) {
    const itemsPerPage = ref<iltypes.Integerlike | "ALL">(25)
    const itemsPerPageOptions : UiOption[] = [
      {label: "25", value: "25"},
      {label: "50", value: "50"},
      {label: "All", value: "ALL"}
    ]

    const htmlTeamColDefs = freshTeamColDefs("html")

    const teamSortState = freshSortState(htmlTeamColDefs)
    teamSortState.reconfigure([
      {colID: TeamColName.division, dir: "asc"},
      {colID: TeamColName.region, dir: "asc"},
      {colID: TeamColName.teamLetter, dir: "asc"}
    ])

    const applySort = (rows: readonly iltournament.TournamentTeamOverviewReport_Team[]) => {
      return [...rows].sort(sortDefsAsSorter(teamSortState.sortList))
    }

    const sortedRows = computed(() => applySort(props.report.tournamentTeams));

    const pagination = computed(() => Paginated(parseIntOr(itemsPerPage.value, "ALL"), sortedRows))

    const filenamePrefix = computed(() => `TournamentTeam_${props.seasonName}_${props.competitionName}`.replaceAll(/[ \\\/]/g, "_"));

    const downloadAsXlsx = async () : Promise<void> => {
      const teamInfo = rowsToXlsxSimpleSheetDef(freshTeamColDefs("xlsx"), sortedRows.value, "TeamInfo");
      const answers = rowsToXlsxSimpleSheetDef(freshAnswersColDefs("xlsx", sortedRows.value), sortedRows.value, "Answers")
      const refs = rowsToXlsxSimpleSheetDef(freshRefColDefs("xlsx"), sortedRows.value.flatMap(row => extractAugmentedRowsForRefRowify(props.refAssignmentsByUserID, props.report.refAssignmentsByUserID, row)), "Refs");
      const coaches = rowsToXlsxSimpleSheetDef(freshCoachColDefs("xlsx"), sortedRows.value.flatMap(extractAugmentedRowsForCoachRowify), "Coaches")
      const unaffiliatedRefs = rowsToXlsxSimpleSheetDef(freshRefColDefs("xlsx"), props.report.unaffiliatedRefs.map(ref => ({
        teamName: "Unaffiliated",
        teamDesignation: "Unaffiliated",
        refAssignmentsByUserID: props.refAssignmentsByUserID,
        refAssignmentsByTeamByUser: props.report.refAssignmentsByUserID,
        ...ref
      })), "Unaffiliated Refs")

      const bytes = await multiSheetXlsxFromSheetDefs([teamInfo, answers, refs, coaches, unaffiliatedRefs])

      downloadFromObjectURL(bytes, filenamePrefix.value + ".xlsx")
    }

    const toCsv = {
      teamInfo: () => rowsToCsv(freshTeamColDefs("xlsx"), sortedRows.value),
      answers: () => rowsToCsv(freshAnswersColDefs("xlsx", sortedRows.value), sortedRows.value),
      refs: () => rowsToCsv(freshRefColDefs("xlsx"), sortedRows.value.flatMap(row => extractAugmentedRowsForRefRowify(props.refAssignmentsByUserID, props.report.refAssignmentsByUserID, row))),
      coaches: () => rowsToCsv(freshCoachColDefs("xlsx"), sortedRows.value.flatMap(extractAugmentedRowsForCoachRowify)),
      unaffiliatedRefs: () => rowsToCsv(freshRefColDefs("xlsx"), props.report.unaffiliatedRefs.map(ref => ({
        teamName: "Unaffiliated",
        teamDesignation: "Unaffiliated",
        refAssignmentsByUserID: props.refAssignmentsByUserID,
        refAssignmentsByTeamByUser: props.report.refAssignmentsByUserID,
        ...ref
      }))),
    } as const;

    const downloadAsCsv = {
      teamInfo: () => {
        const csv = toCsv.teamInfo();
        downloadFromObjectURL(csv, filenamePrefix.value + "_team.csv")
      },
      answers: () => {
        const csv = toCsv.answers();
        downloadFromObjectURL(csv, filenamePrefix.value + "_answers.csv")
      },
      refs: () => {
        const csv = toCsv.refs();
        downloadFromObjectURL(csv, filenamePrefix.value + "_refs.csv")
      },
      coaches: () => {
        const csv = toCsv.coaches();
        downloadFromObjectURL(csv, filenamePrefix.value + "_coaches.csv")
      },
      unaffiliatedRefs: () => {
        const csv = toCsv.unaffiliatedRefs();
        downloadFromObjectURL(csv, filenamePrefix.value + "_refs.csv")
      },
    } as const;

    const copyToClipboardAsCsv = {
      teamInfo: () => {
        const csv = toCsv.teamInfo();
        navigator.clipboard.writeText(csv)
      },
      answers: () => {
        const csv = toCsv.answers();
        navigator.clipboard.writeText(csv)
      },
      refs: () => {
        const csv = toCsv.refs();
        navigator.clipboard.writeText(csv)
      },
      coaches: () => {
        const csv = toCsv.coaches();
        navigator.clipboard.writeText(csv)
      },
      unaffiliatedRefs: () => {
        const csv = toCsv.unaffiliatedRefs();
        navigator.clipboard.writeText(csv)
      }
    } as const;

    const selectedTableSections = ref({
      [TableSection.registrationAnswers]: false,
      [TableSection.referees]: false,
      [TableSection.unaffiliatedRefs]: false,
      [TableSection.coaches]: false,
    })

    return () => {
      return (
        <div>
          <div class="max-w-7xl w-full border border-slate-100 shadow-md bg-white p-1">
            <div class="flex p-2">
              <div>
                <div class="flex items-center gap-1">
                  <input type="checkbox" name="TournTeamReport_tableType" v-model={selectedTableSections.value[TableSection.registrationAnswers]}/>
                  <div>Registration Answers</div>
                </div>
                <div class="flex items-center gap-1">
                  <input type="checkbox" name="TournTeamReport_tableType" v-model={selectedTableSections.value[TableSection.referees]}/>
                  <div>Referees</div>
                </div>
                <div class={`flex items-center gap-1 ${props.report.unaffiliatedRefs.length === 0 ? "opacity-50" : ""}`} >
                  <input type="checkbox" v-model={selectedTableSections.value[TableSection.unaffiliatedRefs]} disabled={props.report.unaffiliatedRefs.length === 0}/>
                  <div>Unaffiliated referees {props.report.unaffiliatedRefs.length === 0 ? "(none)" : ""}</div>
                </div>
                <div class="flex items-center gap-1">
                  <input type="checkbox" name="TournTeamReport_tableType" v-model={selectedTableSections.value[TableSection.coaches]}/>
                  <div>Coaches</div>
                </div>
              </div>
              <div class="p-2 flex items-start ml-auto">
                <div class="flex gap-2 ml-auto">
                  <Menu>
                    {{
                      button: () => (
                        <t-btn margin={false}>
                          <FontAwesomeIcon icon={faFileExport}/>
                          <span class="ml-1 text-xs">As CSV</span>
                        </t-btn>
                      ),
                      menuItems: () => (
                        <>
                          <MenuItem>
                            <DefaultMenuButton onClick={() => downloadAsCsv.teamInfo()}>Team info</DefaultMenuButton>
                          </MenuItem>
                          <MenuItem>
                            <DefaultMenuButton onClick={() => downloadAsCsv.answers()}>Registration answers</DefaultMenuButton>
                          </MenuItem>
                          <MenuItem>
                            <DefaultMenuButton onClick={() => downloadAsCsv.refs()}>Refs</DefaultMenuButton>
                          </MenuItem>
                          <MenuItem>
                            <DefaultMenuButton onClick={() => downloadAsCsv.coaches()}>Coaches</DefaultMenuButton>
                          </MenuItem>
                          <MenuItem>
                            <DefaultMenuButton onClick={() => downloadAsCsv.unaffiliatedRefs()}>Unaffiliated Refs</DefaultMenuButton>
                          </MenuItem>
                        </>
                      )
                    } satisfies MenuItemSlots}
                  </Menu>
                  <t-btn onClick={downloadAsXlsx} margin={false} v-tooltip={{content: "Contains 5 sheets (team info, answers, refs, coaches, unaffiliated refs)"}}>
                    <FontAwesomeIcon icon={faFileExport}/>
                    <span class="ml-1 text-xs">As Excel</span>
                  </t-btn>
                  <Menu>
                    {{
                      button: () => (
                        <t-btn margin={false}>
                          <FontAwesomeIcon icon={faClipboard}/>
                          <span class="ml-1 text-xs">Copy</span>
                        </t-btn>
                      ),
                      menuItems: () => (
                        <>
                          <MenuItem>
                            <DefaultMenuButton onClick={() => copyToClipboardAsCsv.teamInfo()}>Team info</DefaultMenuButton>
                          </MenuItem>
                          <MenuItem>
                            <DefaultMenuButton onClick={() => copyToClipboardAsCsv.answers()}>Registration answers</DefaultMenuButton>
                          </MenuItem>
                          <MenuItem>
                            <DefaultMenuButton onClick={() => copyToClipboardAsCsv.refs()}>Refs</DefaultMenuButton>
                          </MenuItem>
                          <MenuItem>
                            <DefaultMenuButton onClick={() => copyToClipboardAsCsv.coaches()}>Coaches</DefaultMenuButton>
                          </MenuItem>
                          <MenuItem>
                            <DefaultMenuButton onClick={() => copyToClipboardAsCsv.unaffiliatedRefs()}>Unaffiliated Refs</DefaultMenuButton>
                          </MenuItem>
                        </>
                      )
                    } satisfies MenuItemSlots}
                  </Menu>
                </div>
              </div>
            </div>
          </div>
          {
            selectedTableSections.value[TableSection.unaffiliatedRefs]
              ? (
                <div class="max-w-7xl w-full overflow-x-auto border border-slate-100 shadow-md bg-white p-1 mt-4">
                  {
                    props.report.unaffiliatedRefs.length === 0
                      ? <div>No unaffiliated referees</div>
                      : (
                        <div class="p-2 w-full">
                          <RefsLayout
                            openRefEditModal={props.openRefEditModal}
                            refs={props.report.unaffiliatedRefs.map(ref => ({
                              teamDesignation: "Unafilliated",
                              teamName: "Unaffiliated",
                              refAssignmentsByUserID: props.refAssignmentsByUserID,
                              refAssignmentsByTeamByUser: props.report.refAssignmentsByUserID,
                              ...ref
                            }))}
                            teamDesignation={"Unaffiliated"}
                            competitionUID={props.competitionUID}
                          />
                        </div>
                      )
                  }
                </div>
              )
              : null
          }
          <div class="max-w-7xl w-full overflow-x-auto border border-slate-100 shadow-md bg-white p-1 mt-4">
            <table class="w-full">
              <tr>
                {
                  htmlTeamColDefs.map(colDef => {
                    return (
                      <th class="p-2 align-top text-left">
                        <div class="flex items-center text-sm">
                          <span>{colDef.label}</span>
                          {
                            ((sorter?: typeof teamSortState.sortersByColID["<someid>"]) => {
                              return sorter
                                ? (
                                  <span class="ml-1" onClick={() => sorter.sortAndPrioritize()}>
                                    <SortArrow class="p-1 rounded-md" dir={sorter.dir}/>
                                  </span>
                                )
                                : null
                            })(teamSortState.sortersByColID[colDef.id])
                          }
                        </div>
                      </th>
                    )
                  })
                }
              </tr>
              {
                props.report.tournamentTeams.length === 0
                  ? <tr><td colspan="999" class="p-4">No tournament teams found</td></tr>
                  : null
              }
              {
                pagination.value.pageData.itemsThisPage.map((row, i) => {
                  const bgColor = i % 2 ? "bg-gray-50" : ""

                  const trMaybeBottomBorderClass = (rowID: "first" | TableSection) => {
                    // uh there's probably a nicer css way to do this,
                    // and this isn't robust if we reorder the actual layout (need to remember to reorder this, too)
                    const order : [rowID: "first" | TableSection, isVisible: () => boolean][] = [
                      ["first", () => true],
                      [TableSection.registrationAnswers, () => selectedTableSections.value[TableSection.registrationAnswers]],
                      [TableSection.referees, () => selectedTableSections.value[TableSection.referees]],
                      [TableSection.coaches, () => selectedTableSections.value[TableSection.coaches]],
                    ]

                    const idx = arrayFindIndexOrFail(order, v => v[0] === rowID)

                    for (let i = idx+1; i < order.length; i++) {
                      if (order[i][1]()) {
                        // rows "after" the current row exist, so return no class
                        return "";
                      }
                    }

                    return "border-b border-slate-400";
                  }

                  return (
                    <Fragment key={row.tournamentTeamID}>
                      <tr class={`${bgColor} ${trMaybeBottomBorderClass("first")}`}>
                        {
                          htmlTeamColDefs.map(colDef => <td class={`p-2 align-top text-left`}>{getColDefHtml(colDef, row)}</td>)
                        }
                      </tr>
                      {
                        selectedTableSections.value[TableSection.registrationAnswers]
                          ? (
                            <tr class={`${bgColor} ${trMaybeBottomBorderClass(TableSection.registrationAnswers)}`}>
                              <td colspan="999" class="p-2 justify-left align-top">
                                <div class="border-b border-slate-200 mb-2"/>
                                <div class="text-sm font-medium">Question answers</div>
                                {
                                  row.tournamentQuestionLinks.map(q => {
                                    return (
                                      <div class="pl-2 flex items-center">
                                        <div class="text-sm">{q.label}&nbsp;&mdash;&nbsp;</div>
                                        {
                                          q.answers.map(_ => _.answer).join(",") || "N/A"
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </td>
                            </tr>
                          )
                          : null
                      }
                      {
                        selectedTableSections.value[TableSection.referees]
                          ? (
                            <tr class={`${bgColor} ${trMaybeBottomBorderClass(TableSection.referees)}`}>
                              <td colspan="999" class="p-2 justify-left align-top">
                                <div class="border-b border-slate-200 mb-2"/>
                                <RefsLayout
                                  refs={extractAugmentedRowsForRefRowify(props.refAssignmentsByUserID, props.report.refAssignmentsByUserID, row)}
                                  teamDesignation={teamDesignation(row)}
                                  openRefEditModal={props.openRefEditModal}
                                  competitionUID={props.competitionUID}
                                />
                              </td>
                            </tr>
                          )
                          : null
                      }
                      {
                        selectedTableSections.value[TableSection.coaches]
                          ? (
                            <tr class={`${bgColor} ${trMaybeBottomBorderClass(TableSection.coaches)}`}>
                              <td colspan="999" class="p-2 justify-left align-top">
                                <div class="border-b border-slate-200 mb-2"/>
                                <CoachesLayout row={row}/>
                              </td>
                            </tr>
                          )
                          : null
                      }
                    </Fragment>
                  )
                })
              }
            </table>
            <div>
              {
                props.report.tournamentTeams.length > 0
                  ? (
                    <div class="flex">
                      <div class="ml-auto p-2">
                        <SimplePaginationNav mut_pagination={pagination.value} mut_itemsPerPage={itemsPerPage} itemsPerPageOptions={itemsPerPageOptions}/>
                      </div>
                    </div>
                  )
                  : null
              }
            </div>
          </div>
        </div>
      )
    }
  }
})

const RefsLayout = defineComponent({
  props: {
    teamDesignation: vReqT<string>(),
    refs: vReqT<AugmentedRefRow[]>(),
    openRefEditModal: vReqT<(_: AugmentedRefRow) => void>(),
    competitionUID: vReqT<Guid>(),
  },
  setup(props) {
    const htmlRefColDefs = computed(() => freshRefColDefs("html", {openRefEditModal: props.openRefEditModal, competitionUID: props.competitionUID}))

    const sortState = freshSortState(htmlRefColDefs.value);

    const sortedRows = computed(() => {
      return props.refs.sort(sortState.asSorter())
    })

    const itemsPerPage = ref<"ALL" | iltypes.Integerlike>("10")
    const itemsPerPageOptions : UiOption[] = [
      {label: "10", value: "10"},
      {label: "20", value: "20"},
      {label: "All", value: "ALL"},
    ]

    const pagination = computed(() => Paginated(parseIntOr(itemsPerPage.value, "ALL"), sortedRows))

    return () => {
      return (
        <div class="w-full overflow-x-auto">
          <div class="text-sm font-medium">Referees for {props.teamDesignation}</div>
          {
            props.refs.length === 0
              ? <div class="flex items-center justify-center p-4"><span>None found</span></div>
              : (
                  <>
                    <table class="w-full">
                      {
                        htmlRefColDefs.value.map(colDef => {
                          return (
                            <th class="p-1 align-top text-left">
                              <div class="flex items-center text-sm">
                                <span>{colDef.label}</span>
                                {
                                  ((sorter?: typeof sortState.sortersByColID["<someid>"]) => {
                                    return sorter
                                      ? (
                                        <span class="ml-1" onClick={() => sorter.sortAndPrioritize()}>
                                          <SortArrow class="p-1 rounded-md" dir={sorter.dir}/>
                                        </span>
                                      )
                                      : null
                                  })(sortState.sortersByColID[colDef.id])
                                }
                              </div>
                            </th>
                          )
                        })
                      }
                      {
                        pagination
                          .value
                          .pageData
                          .itemsThisPage
                          .map((rowData, i) => {
                            const styles = i % 2 ? {backgroundColor: "rgba(0,0,0,.03125)"} : {}
                            return (
                              <Fragment key={rowData.tournamentTeamOfficialID}>
                                <tr style={styles}>
                                  {
                                    htmlRefColDefs.value.map((colDef) => {
                                      return <td class="p-1 align-top text-left">{getColDefHtml(colDef, rowData)}</td>
                                    })
                                  }
                                </tr>
                                <tr style={styles}>
                                  <td colspan="999" class="px-1 pb-1">
                                    <div class="text-xs font-medium">Comments:</div>
                                    <div>{rowData.comments || "N/A"}</div>
                                  </td>
                                </tr>
                              </Fragment>
                            )
                          })
                      }
                    </table>
                    <div>
                    {
                      pagination.value.pageData.allItems.length > 0
                        ? (
                          <div class="flex text-xs">
                            <div class="ml-auto p-2">
                              <SimplePaginationNav itemsLabel={"Refs"} mut_pagination={pagination.value} mut_itemsPerPage={itemsPerPage} itemsPerPageOptions={itemsPerPageOptions}/>
                            </div>
                          </div>
                        )
                        : null
                    }
                    </div>
                  </>
              )
          }
        </div>
      )
    }
  }
})

const CoachesLayout = defineComponent({
  props: {
    row: vReqT<iltournament.TournamentTeamOverviewReport_Team>(),
  },
  setup(props) {
    const htmlCoachColDefs = freshCoachColDefs("html");
    const sortState = freshSortState(htmlCoachColDefs);

    const sortedRows = computed(() => {
      return extractAugmentedRowsForCoachRowify(props.row).sort(sortState.asSorter())
    })

    const itemsPerPage = ref<"ALL" | iltypes.Integerlike>("10")
    const itemsPerPageOptions : UiOption[] = [
      {label: "10", value: "10"},
      {label: "20", value: "20"},
      {label: "All", value: "ALL"},
    ]

    const pagination = computed(() => Paginated(parseIntOr(itemsPerPage.value, "ALL"), sortedRows))

    return () => {
      return (
        <div class="w-full overflow-x-auto">
          <div class="text-sm font-medium">Coaches for {teamDesignation(props.row)}</div>
          {
            props.row.tournamentTeamOfficialCoaches.length === 0
              ? <div class="flex items-center justify-center p-4"><span>None found</span></div>
              : (
                  <>
                    <table class="w-full">
                      {
                        htmlCoachColDefs.map(colDef => {
                          return (
                            <th class="p-1 align-top text-left">
                              <div class="flex items-center text-sm">
                                <span>{colDef.label}</span>
                                {
                                  ((sorter?: typeof sortState.sortersByColID["<someid>"]) => {
                                    return sorter
                                      ? (
                                        <span class="ml-1" onClick={() => sorter.sortAndPrioritize()}>
                                          <SortArrow class="p-1 rounded-md" dir={sorter.dir}/>
                                        </span>
                                      )
                                      : null
                                  })(sortState.sortersByColID[colDef.id])
                                }
                              </div>
                            </th>
                          )
                        })
                      }
                      {
                        pagination
                          .value
                          .pageData
                          .itemsThisPage
                          .map((rowData, i) => {
                            const styles = i % 2 ? {backgroundColor: "rgba(0,0,0,.03125)"} : {}
                            return (
                              <Fragment key={rowData.tournamentTeamOfficialID}>
                                <tr style={styles}>
                                  {
                                    htmlCoachColDefs.map((colDef) => {
                                      return <td class="p-1 align-top text-left">{getColDefHtml(colDef, rowData)}</td>
                                    })
                                  }
                                </tr>
                                <tr style={styles}>
                                  <td colspan="999" class="px-1 pb-1">
                                    <div class="text-xs font-medium">Comments:</div>
                                    <div>{rowData.comments || "N/A"}</div>
                                  </td>
                                </tr>
                              </Fragment>
                            )
                          })
                      }
                    </table>
                    <div>
                    {
                      pagination.value.pageData.allItems.length > 0
                        ? (
                          <div class="flex text-xs">
                            <div class="ml-auto p-2">
                              <SimplePaginationNav itemsLabel={"Coaches"} mut_pagination={pagination.value} mut_itemsPerPage={itemsPerPage} itemsPerPageOptions={itemsPerPageOptions}/>
                            </div>
                          </div>
                        )
                        : null
                    }
                    </div>
                  </>
              )
          }
        </div>
      )
    }
  }
})

enum TeamColName {
  teamDesignation = "teamDesignation",
  division = "division",
  region = "region",
  teamLetter = "teamLetter",
  teamName = "teamName",
  submitterEmail = "submitterEmail",
  submitterName = "submitterName",
  playerCount = "playerCount",
  refCount = "refCount",
  status = "status",
  points = "points",
  dateCreated = "dateCreated",
}

function freshTeamColDefs(mode: "xlsx" | "html") : ColDef<iltournament.TournamentTeamOverviewReport_Team, TeamColName>[] {
  const colDefs : ColDef<iltournament.TournamentTeamOverviewReport_Team, TeamColName>[] = [
    {
      id: TeamColName.teamDesignation,
      html: "never",
      label: "Team designation",
      xlsx: v => `${v.div_gender}${v.div_divNum}-${v.team_region}-${v.mungedTeamLetter.id}`,
    },
    {
      id: TeamColName.division,
      label: "Division",
      html: v => `${v.div_gender}${v.div_divNum}`,
      sort: {
        cb: sortByMany(sortBy(_ => parseIntOr(_.div_divNum, -1)), sortBy(_ => _.div_gender)),
        dir: "not-sorted"
      }
    },
    {
      id: TeamColName.region,
      label: "Region",
      html: v => parseIntOr(v.team_region, ""),
      sort: {
        cb: sortBy(_ => parseIntOr(_.team_region, -1)),
        dir: "not-sorted"
      }
    },
    {
      id: TeamColName.teamLetter,
      label: "Team letter",
      html: v => parseIntOr(v.mungedTeamLetter.id, ""),
      sort: {
        cb: sortBy(_ => _.mungedTeamLetter.id),
        dir: "asc"
      },
    },
    {
      id: TeamColName.teamName,
      label: "Team name",
      html: v => v.areaTeamName,
      sort: {
        cb: sortBy(_ => _.areaTeamName),
        dir: "not-sorted"
      }
    },
    {
      id: TeamColName.playerCount,
      label: "Player count",
      html: v => parseIntOr(v.playerCount, ""),
      sort: {
        cb: sortBy(_ => parseIntOr(_.playerCount, -1)),
        dir: "not-sorted"
      }
    },
    {
      id: TeamColName.refCount,
      label: "Referee count",
      html: v => parseIntOr(v.tournamentTeamOfficialReferees.length, ""),
      sort: {
        cb: sortBy(_ => _.tournamentTeamOfficialReferees.length),
        dir: "not-sorted"
      }
    },
    {
      id: TeamColName.submitterName,
      label: "Submitter name",
      html: v => (
        <>
          <div class="text-sm">{v.submitterFirstName} {v.submitterLastName}</div>
          <div class="text-sm">{v.submitterEmail}</div>
        </>
      ),
      xlsx: v => `${v.submitterFirstName} ${v.submitterLastName}`
    },
    {
      id: TeamColName.submitterEmail,
      label: "Submitter email",
      html: "never",
      xlsx: v => v.submitterEmail,
      sort: {
        cb: sortBy(_ => _.submitterEmail),
        dir: "not-sorted"
      }
    },
    {
      id: TeamColName.status,
      label: "Status",
      html: v => tournTeamStatusUiString(v),
    },
    {
      id: TeamColName.points,
      label: "Points",
      html: v => parseIntOr(v.answerPointsSum, ""),
      sort: {
        cb: sortBy(_ => _.answerPointsSum),
        dir: "not-sorted",
      }
    },
    {
      id: TeamColName.dateCreated,
      label: "Registration date",
      sort: {
        cb: sortByDayJS(_ => dayjs(_.dateCreated)),
        dir: "not-sorted",
      },
      html: v => (
        <div>
          <div>{dayjsFormatOr(v.dateCreated, "MM/DD/YY")}</div>
          <div class="text-xs">{dayjsFormatOr(v.dateCreated, "hh:mm a")}</div>
        </div>
      ),
      xlsx: v => dayjsFormatOr(v.dateCreated, "MM/DD/YYYY")
    }
  ];

  switch (mode) {
    case "html":
      return colDefs.filter(colDef => colDef.html !== "never")
    case "xlsx":
      return colDefs.filter(colDef => colDef.xlsx !== "never")
    default:
      exhaustiveCaseGuard(mode);
  }
}

// a little munging to repivot some things for a better dump-to-excel
export type AugmentedRefRow =
  & TournamentTeamOverviewReport_Ref
  & {
    teamName: string,
    teamDesignation: string,
    refAssignmentsByUserID: RefAssignmentsByUserID,
    refAssignmentsByTeamByUser: TournamentTeamOverviewReport["refAssignmentsByUserID"]
    infoIfAffiliated?: {
      // reference to report that "owns" this ref row
      owningTeamReport: TournamentTeamOverviewReport_Team
    }
  }

// consider: teamDesignation might be deprecated by way of `teamDesignationComponents`
type AugmentedCoachRow =
  & TournamentTeamOverviewReport_Coach
  & {
    teamName: string,
    teamDesignation: string,
    /**
     * well, coaches are always affiliated, but it doesn't hurt here to paranoid
     */
    infoIfAffiliated?: {
      // reference to report that "owns" this ref row
      owningTeamReport: TournamentTeamOverviewReport_Team
    }
  }

function extractAugmentedRowsForRefRowify(
  refAssignmentsByUserID: RefAssignmentsByUserID,
  refAssignmentsByTeamByUser: TournamentTeamOverviewReport["refAssignmentsByUserID"],
  row: iltournament.TournamentTeamOverviewReport_Team
) : AugmentedRefRow[] {
  return row
    .tournamentTeamOfficialReferees
    .map(v => ({
      ...v,
      teamName: row.areaTeamName,
      teamDesignation: teamDesignation(row),
      refAssignmentsByUserID,
      refAssignmentsByTeamByUser,
      infoIfAffiliated: {
        owningTeamReport: row,
      }
    }) satisfies AugmentedRefRow)
}

function extractAugmentedRowsForCoachRowify(row: iltournament.TournamentTeamOverviewReport_Team) : AugmentedCoachRow[] {
  return row
      .tournamentTeamOfficialCoaches
      .map(v => ({
        ...v,
        teamName: row.areaTeamName,
        teamDesignation: teamDesignation(row),
        infoIfAffiliated: {
          owningTeamReport: row
        }
      }))
}

enum RefColName {
  teamDesignation = "teamDesignation",
  division = "division",
  region = "region",
  teamName = "teamName",
  headCoachNames = "headCoachNames",
  name = "name",
  maxCR = "maxCR",
  maxAR = "maxAR",
  refCertInfo_a = "refCertInfo_a",
  refEmail = "refEmail",
  refPhone = "refPhone",
  riskStatus_status = "riskStatus_status",
  riskStatus_code = "riskStatus_code",
  riskStatus_expirationDate = "riskStatus_expirationDate",
  comments = "comments",
  dateSubmitted = "dateSubmitted",
  dateSubmitted_time = "dateSubmitted_time",
  refAssignments_1 = "refAssignments_1",
  refAssignments_2 = "refAssignments_2",
  name_xlsx_first = "name_xlsx_first",
  name_xlsx_last = "name_xlsx_last",
}

type RefColDefsHtmlConfig = {
  openRefEditModal: (_: AugmentedRefRow) => void,
  competitionUID: Guid,
}

function freshRefColDefs(mode: "html", config: RefColDefsHtmlConfig) : ColDef<AugmentedRefRow, RefColName>[];
function freshRefColDefs(mode: "xlsx") : ColDef<AugmentedRefRow, RefColName>[];
function freshRefColDefs(mode: "xlsx" | "html", config?: RefColDefsHtmlConfig) : ColDef<AugmentedRefRow, RefColName>[] {
  // we probably shouldn't even get here if we don't have edit permission but we'll try to make an affordance for this
  const hasEditPermission = config ? isAuthorizedToManageTournament(config.competitionUID, User.value) : false;

  const colDefs : ColDef<AugmentedRefRow, RefColName>[] = [
    {
      id: RefColName.teamDesignation,
      html: "never",
      label: "Team designation",
      xlsx: v => v.teamDesignation,
    },
    {
      id: RefColName.division,
      html: "never",
      label: "Division",
      xlsx: v => v.infoIfAffiliated ? `${v.infoIfAffiliated.owningTeamReport.div_gender}${v.infoIfAffiliated.owningTeamReport.div_divNum}` : "",
    },
    {
      id: RefColName.region,
      html: "never",
      label: "Region",
      xlsx: v => v.infoIfAffiliated ? parseIntOr(v.infoIfAffiliated.owningTeamReport.region, "") : "",
    },
    {
      id: RefColName.teamName,
      label: "Team",
      html: "never",
      xlsx: v => v.teamName
    },
    {
      id: RefColName.headCoachNames,
      label: "Head coaches",
      html: v => (v.infoIfAffiliated?.owningTeamReport.tournamentTeamOfficialCoaches ?? [])
        .filter(_ => _.officialType === TournamentTeamOfficialType.HEAD_COACH)
        .map(v => `${v.assignment.firstName} ${v.assignment.lastName}`)
        .join(", ")
    },
    {
      id: RefColName.name,
      label: "Name",
      html: v => {
        if (!config) {
          throw "impossible as per overload";
        }

        if (hasEditPermission) {
          return (
            <div class="flex gap-2 items-start">
              <span onClick={() => config.openRefEditModal(v)} class="text-sm cursor-pointer hover:bg-[rgb(0,0,0,.0625)] active:bg-[rgb(0,0,0,.125)] p-1 rounded-md">
                <FontAwesomeIcon icon={faPencil}/>
              </span>
              <span>{v.firstName} {v.lastName}</span>
            </div>
          );
        }
        else {
          return <span>{v.firstName} {v.lastName}</span>;
        }
      },
      xlsx: "never",
      sort: {
        cb: sortByMany(sortBy(_ => _.lastName), sortBy(_ => _.firstName)),
        dir: "asc"
      },
    },
    {
      id: RefColName.name_xlsx_first,
      label: "First Name",
      html: "never",
      xlsx: v => v.firstName,
    },
    {
      id: RefColName.name_xlsx_last,
      label: "Last Name",
      html: "never",
      xlsx: v => v.lastName,
    },
    {
      id: RefColName.maxCR,
      label: "Max CR",
      html: v => parseIntOr(v.ref_maxCR, "N/A"),
      sort: {
        cb: sortBy(_ => _.ref_maxCR),
        dir: "not-sorted"
      }
    },
    {
      id: RefColName.maxAR,
      label: "Max AR",
      html: v => parseIntOr(v.ref_maxAR, "N/A"),
      sort: {
        cb: sortBy(_ => _.ref_maxAR),
        dir: "not-sorted"
      }
    },
    {
      id: RefColName.refCertInfo_a,
      label: "User highest ref grade",
      html: v => v.user?.RefereeGrade || "N/A",
    },
    {
      id: RefColName.refEmail,
      label: "Email",
      html: v => v.user?.email || v.email,
      sort: {
        cb: sortBy(_ => _.user?.email || _.email),
        dir: "not-sorted",
      }
    },
    {
      id: RefColName.refPhone,
      label: "Phone",
      html: v => v.user?.phone || "",
    },
    {
      id: RefColName.riskStatus_status,
      label: "Risk status",
      html: v => {
        if (v.user) {
          return (
            <div class="text-sm">
              <div>Risk status: {v.user.RiskStatus}</div>
              <div>Risk status code: {v.user.RiskStatusCode}</div>
              <div>Risk status expiration: {v.user.RiskStatusExpiration}</div>
            </div>
          )
        }
        else {
          return <span/>
        }
      },
      xlsx: v => v.user?.RiskStatus || ""
    },
    {
      id: RefColName.riskStatus_code,
      label: "Risk status code",
      html: "never",
      xlsx: v => v.user?.RiskStatusCode || ""
    },
    {
      id: RefColName.riskStatus_expirationDate,
      label: "Risk status expiration",
      html: "never",
      xlsx: v => v.user?.RiskStatusExpiration || ""
    },
    {
      id: RefColName.comments,
      label: "Comments",
      html: "never",
      xlsx: v => v.comments
    },
    {
      id: RefColName.refAssignments_1,
      label: "Ref assignments",
      html: v => {
        if (!v.infoIfAffiliated) {
          // an "unaffiliated" ref doesn't have any ref assignments
          return "";
        }
        if (!v.user?.userID) {
          // shouldn't happen, but not much we can do if we end up with no user/userID
          return ""
        }
        return (
          <div>
            {v.refAssignmentsByTeamByUser[v.user.userID]?.length ?? 0}
          </div>
        )
      },
      xlsx: v => {
        if (!v.infoIfAffiliated) {
          // an "unaffiliated ref" doesn't have any ref assignments
          return "";
        }
        if (!v.user?.userID) {
          // shouldn't happen, but not much we can do if we end up with no user/userID
          return ""
        }
        return v.refAssignmentsByTeamByUser[v.user.userID]?.length ?? 0
      },
      sort: {
        // the "non affiliated" all get grouped together (but also, affiliated/non-affiliated are on separate sheets or html divs anyway)
        cb: sortBy(_ => {
          if (!_.infoIfAffiliated) {
            // an "unaffiliated" ref doesn't have any ref assignments
            return 99999;
          }
          if (!_.user?.userID) {
            // shouldn't happen, but not much we can do if we end up with no user/userID
            return 99999;
          }
          return _.refAssignmentsByTeamByUser[_.user.userID]?.length ?? 0
        }),
        dir: "not-sorted",
      }
    },
    {
      id: RefColName.dateSubmitted,
      label: "Submitted",
      html: v => (
        <div>
          <div>{dayjsFormatOr(v.createdOn, "MM/DD/YY")}</div>
          <div>{dayjsFormatOr(v.createdOn, "hh:mm a")}</div>
        </div>
      ),
      xlsx: v => dayjsFormatOr(v.createdOn, "MM/DD/YY"),
      sort: {
        cb: sortByDayJS(_ => dayjs(_.createdOn)),
        dir: "not-sorted"
      }
    },
    {
      id: RefColName.dateSubmitted_time,
      label: "Submitted time",
      html: "never",
      xlsx: v => dayjsFormatOr(v.createdOn, "hh:mm a"),
    }
  ];

  switch (mode) {
    case "html":
      return colDefs.filter(colDef => colDef.html !== "never")
    case "xlsx":
      return colDefs.filter(colDef => colDef.xlsx !== "never")
    default:
      exhaustiveCaseGuard(mode);
  }
}

enum CoachColName {
  teamDesignation = "teamDesignation",
  division = "division",
  region = "region",
  teamName = "teamName",
  name = "name",
  email = "email",
  phone = "phone",
  comments = "comments",
  riskStatus_status = "riskStatus_status",
  riskStatus_code = "riskStatus_code",
  riskStatus_expirationDate = "riskStatus_expirationDate",
  name_xlsx_first = "name_xlsx_first",
  name_xlsx_last = "name_xlsx_last",
}

function freshCoachColDefs(mode: "xlsx" | "html") : ColDef<AugmentedCoachRow, CoachColName>[] {
  const colDefs : ColDef<AugmentedCoachRow, CoachColName>[] = [
    {
      id: CoachColName.teamDesignation,
      html: "never",
      label: "Team designation",
      xlsx: v => v.teamDesignation,
    },
    {
      id: CoachColName.division,
      html: "never",
      label: "Division",
      xlsx: v => v.infoIfAffiliated ? `${v.infoIfAffiliated.owningTeamReport.div_gender}${v.infoIfAffiliated.owningTeamReport.div_divNum}` : "",
    },
    {
      id: CoachColName.region,
      html: "never",
      label: "Region",
      xlsx: v => v.infoIfAffiliated ? parseIntOr(v.infoIfAffiliated.owningTeamReport.region, "") : "",
    },
    {
      id: CoachColName.teamName,
      label: "Team",
      html: "never",
      xlsx: v => v.teamName
    },
    {
      id: CoachColName.name,
      label: "Name",
      html: v => `${v.assignment.firstName} ${v.assignment.lastName}`,
      xlsx: "never",
      sort: {
        cb: sortByMany(sortBy(_ => _.assignment.lastName), sortBy(_ => _.assignment.firstName)),
        dir: "asc"
      },
    },
    {
      id: CoachColName.name_xlsx_first,
      label: "First Name",
      html: "never",
      xlsx: v => v.assignment.firstName,
    },
    {
      id: CoachColName.name_xlsx_last,
      label: "Last Name",
      html: "never",
      xlsx: v => v.assignment.lastName,
    },
    {
      id: CoachColName.email,
      label: "Email",
      html: v => v.assignment.email,
      sort: {
        cb: sortBy(_ => _.assignment.email),
        dir: "not-sorted"
      },
    },
    {
      id: CoachColName.phone,
      label: "Phone",
      html: v => v.assignment.phone,
    },
    {
      id: CoachColName.comments,
      label: "Comments",
      html: "never",
      xlsx: v => v.comments
    },
    {
      id: CoachColName.riskStatus_status,
      label: "Risk status",
      html: v => {
        if (v.assignment.type === "coachAssignment") {
          return (
            <div class="text-sm">
              <div>Risk status: {v.assignment.RiskStatus}</div>
              <div>Risk status code: {v.assignment.RiskStatusCode}</div>
              <div>Risk status expiration: {v.assignment.RiskStatusExpiration}</div>
            </div>
          )
        }
        else {
          return <span/>
        }
      },
      xlsx: v => v.assignment.type === "coachAssignment" ? v.assignment.RiskStatus : ""
    },
    {
      id: CoachColName.riskStatus_code,
      label: "Risk status code",
      html: "never",
      xlsx: v => v.assignment.type === "coachAssignment" ? v.assignment.RiskStatusCode : ""
    },
    {
      id: CoachColName.riskStatus_expirationDate,
      label: "Risk status expiration",
      html: "never",
      xlsx: v => v.assignment.type === "coachAssignment" ? v.assignment.RiskStatusExpiration : ""
    }
  ];

  switch (mode) {
    case "html":
      return colDefs.filter(colDef => colDef.html !== "never")
    case "xlsx":
      return colDefs.filter(colDef => colDef.xlsx !== "never")
    default:
      exhaustiveCaseGuard(mode);
  }
}

/**
 * It is expected that all rows share the same number of tournamentQuestionLinks, though their answers will differ.
 * Rows having no answer for some question Q should still contain a question link for question Q, because it's relevant to the whole tournament.
 *
 * @mode: "xlsx" only, we don't support "html" version for this
 */
function freshAnswersColDefs(
  mode: "xlsx", // we don't support "html" version for this
  rows: iltournament.TournamentTeamOverviewReport_Team[]
) : ColDef<iltournament.TournamentTeamOverviewReport_Team>[] {
  const colDefs : ColDef<iltournament.TournamentTeamOverviewReport_Team>[] = [
    {
      id: "__xlsx_teamDesignation",
      label: "Team designation",
      html: "never",
      xlsx: v => teamDesignation(v),
    },
    {
      id: "__xlsx_teamName",
      label: "Team name",
      html: "never",
      xlsx: v => v.areaTeamName,
    },
    {
      id: "__xlsx_pointsSum",
      label: "Points sum",
      html: "never",
      xlsx: v => parseIntOr(v.answerPointsSum, ""),
    }
  ]

  // As per the comments above, use the first row as the basis for "all possible questions"
  // also, it might be empty so keep ourselves honest by required optional access
  forceCheckedIndexedAccess(rows, 0)
    ?.tournamentQuestionLinks
    .forEach(qLink => {
      colDefs.push({
        id: qLink.questionID,
        label: qLink.label,
        // we dont' expect to draw this into html
        html: _ => "never",
        xlsx: v => {
          const qForThisRow = v.tournamentQuestionLinks.find(_ => _.questionID === qLink.questionID)
          const r = qForThisRow?.answers.map(_ => _.answer).join(",") || "N/A"
          return r;
        }
      })
    })

  switch (mode) {
    case "xlsx":
      return colDefs.filter(colDef => colDef.xlsx !== "never")
    default:
      exhaustiveCaseGuard(mode);
  }
}

enum TableSection {
  registrationAnswers = 1,
  referees,
  coaches,
  unaffiliatedRefs,
}

export const DivCounts = defineComponent({
  props: {
    report: vReqT<TournamentTeamOverviewReport>(),
  },
  setup(props) {
    const filter = ref(TournTeamDisplayFilter())
    const filterManager = FilterManager(
      filter,
      // This abstraction leaky and/or wrong -- we're not interested in the filtering behavior here,
      // just the controller-like aspect of the way it manages a <SelectMany>
      // So, we give it a "constant nothing" to filter
      {value: []}
    );
    const colDefs = freshDivCountColDefs();
    const sortState = freshSortState(colDefs)

    const asyncState = useAsyncState(async () => {
      return {
        divisionsByDivID: await Client
          .getDivisions()
          .then(divs => new Map(divs.map(div => [div.divID, div])))
      }
    })

    const rowData = computed(() => {
      if (!asyncState.value.ready) {
        return []
      }
      else {
        const listing = buildTournTeamDivCountListing(props.report, filter.value, asyncState.value.divisionsByDivID)
        return listing.sort(sortState.asSorter())
      }
    })

    return () => {
      if (!asyncState.value.ready) {
        return null;
      }
      return (
        <div class="p-2 border border-slate-100 shadow-md bg-white">
          <div class="w-full rounded-2xl">
            <Disclosure>
              {
                ({open}: {open: boolean}) => {
                  return (
                    <>
                      <DisclosureButton
                        class="flex items-center rounded-lg bg-green-100 p-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500/75"
                      >
                        <FontAwesomeIcon icon={faChevronDown}
                          class={`${open ? '' : '-rotate-90'} h-5 w-5 text-green-500 transition-all`}
                        />
                        <span class="ml-1">Aggregate counts by division</span>
                      </DisclosureButton>
                      <DisclosurePanel class="px-4 pb-2 pt-4 text-sm">
                        <div>
                          <div class="flex items-start mb-2">
                            <div class="max-w-xl" data-il-selectManyContainer>
                              <SelectManyPane {...filterManager.selectManyPropsAndHandlers}>
                                {
                                  ({isOpen, open}: SelectManySlotProps["default"]) => {
                                    return (
                                      <div class="text-sm">
                                        <span
                                          onClick={() => open()}
                                          class={`text-blue-700 cursor-pointer underline bg-white ${isOpen.value ? 'bg-slate-200' : 'hover:bg-slate-100 active:bg-slate-200'}`}
                                          style="border-radius:50%; padding:.5em; margin-left:-.5em;"
                                        >
                                          <FontAwesomeIcon {...{style: "outline:none;"}} icon={faListCheck} v-tooltip={{content: 'Select multiple'}}/>
                                        </span>
                                        <span class="ml-1" >{filterManager.statusOptionsMessage}</span>
                                      </div>
                                    )
                                  }
                                }
                              </SelectManyPane>
                            </div>
                          </div>
                          <div class="max-h-64 overflow-y-auto">
                            <table class="w-full">
                              {
                                colDefs.map(colDef => {
                                  return (
                                    <th class="p-1 align-top text-left">
                                      <div class="flex items-center text-sm">
                                        <span>{colDef.label}</span>
                                        {
                                          ((sorter?: typeof sortState.sortersByColID["<someid>"]) => {
                                            return sorter
                                              ? (
                                                <span class="ml-1" onClick={() => sorter.sortAndPrioritize()}>
                                                  <SortArrow class="p-1 rounded-md" dir={sorter.dir}/>
                                                </span>
                                              )
                                              : null
                                          })(sortState.sortersByColID[colDef.id])
                                        }
                                      </div>
                                    </th>
                                  )
                                })
                              }
                              {
                                rowData
                                  .value
                                  .map((rowData, i) => {
                                    const styles = i % 2 ? {backgroundColor: "rgba(0,0,0,.03125)"} : {}
                                    return (
                                      <Fragment key={rowData.division.divID}>
                                        <tr style={styles}>
                                          {
                                            colDefs.map((colDef) => {
                                              return <td class="p-1 align-top text-left">{getColDefHtml(colDef, rowData)}</td>
                                            })
                                          }
                                        </tr>
                                      </Fragment>
                                    )
                                  })
                              }
                            </table>
                          </div>
                        </div>
                      </DisclosurePanel>
                    </>
                  )
                }
              }
            </Disclosure>
          </div>
        </div>
      )
    }
  }
})

function freshDivCountColDefs() : ColDef<{division: Division, count: number}, "name" | "descriptor" | "count">[] {
  return [
    {
      id: "descriptor",
      label: "Descriptor",
      html: v => `${v.division.gender}${v.division.divNum}`,
      sort: {
        cb: sortByMany(sortBy(_ => parseIntOr(_.division.divNum, -1)), sortBy(_ => _.division.gender)),
        dir: "asc"
      }
    },
    {
      id: "name",
      label: "Name",
      html: v => v.division.displayName,
      sort: {
        cb: sortBy(_ => _.division.displayName),
        dir: "not-sorted"
      }
    },
    {
      id: "count",
      label: "Count",
      html: v => v.count,
      sort: {
        cb: sortBy(_ => _.count),
        dir: "not-sorted"
      }
    }
  ]
}

/**
 * All divisions present within the report should be present within `divisionsByDivID`
 */
function buildTournTeamDivCountListing(report: TournamentTeamOverviewReport, filter: TournTeamDisplayFilter, divisionsByDivID: Map<Guid, Division>) {
  const builder : Record<Guid, number> = {}

  for (const tournTeam of report.tournamentTeams) {
    builder[tournTeam.div_divID] ??= 0;

    const status = computeTournTeamStatusForFiltering(tournTeam)

    if (!status || !filter.status[status]) {
      continue;
    }

    builder[tournTeam.div_divID] += 1
  }

  const result : {division: Division, count: number}[] = []

  for (const divID of Object.keys(builder)) {
    result.push({
      // we expect this lookup to always succeed, why __wouldn't__ we have all the divisions we need
      division: divisionsByDivID.get(divID)!,
      count: builder[divID]
    });
  }

  return result;
}

type TournTeamStatusFilter = ReturnType<typeof FilterManager>

export const FilterRefiner = defineComponent({
  props: {
    teamOptions: vReqT<UiOption[]>(),
    mut_statusFilter: vReqT<TournTeamStatusFilter>(),
    mut_selectedTeamID: vReqT<Reflike<Integerlike | "ALL">>(),
    mut_refName: vReqT<Reflike<string>>(),
  },
  setup(props) {
    const refName = ref(props.mut_refName.value)

    const commitRefNameFilter = () => {
      props.mut_refName.value = refName.value;
    }

    return () => {
      return (
        <div class="p-2 border border-slate-100 shadow-md bg-white">
          <div class="w-full rounded-2xl">
            <Disclosure>
              {
                ({open}: {open: boolean}) => {
                  return (
                    <>
                      <DisclosureButton
                        class="flex items-center rounded-lg bg-green-100 p-2 text-left text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500/75"
                      >
                        <FontAwesomeIcon icon={faChevronDown}
                          class={`${open ? '' : '-rotate-90'} h-5 w-5 text-green-500 transition-all`}
                        />
                        <span class="ml-1">Refine results...</span>
                      </DisclosureButton>
                      <DisclosurePanel class="px-4 pb-2 pt-4 text-sm">
                        <>
                          <div class="max-w-xl my-2" data-il-selectManyContainer>
                            <SelectManyPane {...props.mut_statusFilter.selectManyPropsAndHandlers}>
                              {
                                ({isOpen, open}: SelectManySlotProps["default"]) => {
                                  return (
                                    <div>
                                      <span
                                        onClick={() => open()}
                                        class={`text-blue-700 cursor-pointer underline bg-white ${isOpen.value ? 'bg-slate-200' : 'hover:bg-slate-100 active:bg-slate-200'}`}
                                        style="border-radius:50%; padding:.5em; margin-left:-.5em;"
                                      >
                                        <FontAwesomeIcon {...{style: "outline:none;"}} icon={faListCheck} v-tooltip={{content: 'Select multiple'}}/>
                                      </span>
                                      <span class="ml-1" >{props.mut_statusFilter.statusOptionsMessage}</span>
                                    </div>
                                  )
                                }
                              }
                            </SelectManyPane>
                          </div>
                          <FormKit type="select" options={props.teamOptions} label="Team" v-model={props.mut_selectedTeamID.value}/>
                          <div style="--fk-margin-outer:none;">
                            <div class="text-xs font-medium mb-1">Referee name</div>
                            <FormKit type="form" actions={false} onSubmit={commitRefNameFilter}>
                              <div class="flex gap-2 items-start">
                                <FormKit type="text" v-model={refName.value}/>
                                <t-btn type="submit">Ok</t-btn>
                              </div>
                            </FormKit>
                            {
                              props.mut_refName.value
                                ? <span class="il-link text-xs ml-1" onClick={() => {refName.value = ""; commitRefNameFilter();}}>Clear ref name filter</span>
                                : null
                            }
                          </div>
                        </>
                      </DisclosurePanel>
                    </>
                  )
                }
              }
            </Disclosure>
          </div>
        </div>
      )
    }
  }
})
