import { DeepReadonly, PropType, computed, defineComponent, getCurrentInstance, onMounted, reactive, ref, watch } from "vue";

import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import type { IziToast } from "izitoast";
import { axiosInstance, AxiosErrorWrapper, defaultLoggedInErrorResponseHandler_noToastOn404, defaultSetGlobalSingletonLoadingStateFlagInterceptors, freshAxiosInstance } from "src/boot/axios";
import { FK_nodeRef, UiOption, exhaustiveCaseGuard, flowCapture, parseIntOr, sortBy, sortByMany, useIziToast } from "src/helpers/utils";
import { FormKit, FormKitMessages } from "@formkit/vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faFloppyDisk, faPlusSquare, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { Tabs } from "src/components/UserInterface/Tabs";
import dayjs from "dayjs";

import type { FormKitNode } from "@formkit/core";
import { DAYJS_FORMAT_HTML_DATETIME, dayjsFormatOr, dayjsOr } from "src/helpers/formatDate";


import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { QuillWrapper2 } from "../UserInterface/QuillWrapper2";

import { tournamentStore } from "./Store/TournamentStore"
import { Client } from "src/store/Client";
import { TournTeamPlayerRosterSubmissionConfig } from "src/composables/InleagueApiV1.Tournament";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";

interface TournamentDivisionFormEntry {
  type: "tentative" | "persisted",
  readonly divID: iltypes.Guid,
  readonly divName: string,
  readonly tournamentTeamCount: number,
  fee: "" | number,
  holdPaymentFee: "" | number,
  maxTeams: "" | iltypes.Integerlike
  minRequiredTournTeamRefCount: "" | iltypes.Integerlike,
}

interface TournamentForm_Base {
  kind: {
    mode: "create" | "update"
  },
  form: {
    registrationStart: iltypes.DateTimelike | "",
    registrationEnd: iltypes.DateTimelike | "",
    divisions: TournamentDivisionFormEntry[],
    requireRefsOnSubmission: boolean,
    minRequiredTournTeamRefCount: iltypes.Integerlike,
    localTeamsSubjectToRegFee: boolean,
    localTeamsSubjectToHoldPaymentFee: boolean,
    html_tournTeamReg_complete: string,
    html_tournTeamReg_splash_A: string,
    html_tournTeamReg_regConfirmationEmail_header: string,
    html_tournTeamReg_regConfirmationEmail_footer: string,
    playerRosterSubmissionConfig: iltypes.Integerlike<TournTeamPlayerRosterSubmissionConfig>,
    contactEmail: string,
  },
  /**
   * misc. stuff associated with the form but that is not form data
   */
  aux: {
    /**
     * compSeasonDiv records that are available for this (comp, season).
     * These are not filtered by existing div entries for the tournament,
     * which is to say, e.g.
     *  - tournament for comp C, season S has divs D0 and D1
     *  - compSeasonDiv records globally exist for (C, S, {D0, D1, D2, D3})
     *  - this list will contain (C, S, {D0, D1, D2, D3})
     */
    compSeasonDivs: DeepReadonly<iltypes.CompetitionSeasonDivision[]>,
    competitionName: string,
    seasonName: string,
  }
}

interface TournamentForm_Create extends TournamentForm_Base {
  kind: {
    mode: "create",
    competitionUID: iltypes.Guid,
    seasonUID: iltypes.Guid,
  }
}

interface TournamentForm_Update extends TournamentForm_Base {
  kind: {
    mode: "update",
    tournamentID: iltypes.Integerlike,
    tournamentWasGeneratedThisSession: boolean,
  }
}

type TournamentForm =
  | TournamentForm_Create
  | TournamentForm_Update

function freshTournamentDivisionFormEntry(division: {divID: iltypes.Guid, divName: string}) : TournamentDivisionFormEntry {
  return {
    type: "tentative",
    divID: division.divID,
    divName: division.divName,
    tournamentTeamCount: 0,
    fee: "",
    holdPaymentFee: "",
    maxTeams: "",
    minRequiredTournTeamRefCount: "",
  }
}

function tournamentDivisionFormEntryFromExisting(v: iltournament.TournamentDivision) : TournamentDivisionFormEntry {
  return {
    type: "persisted",
    divID: v.divID,
    divName: v.divName,
    // Depending on where we got this from (pulled from server as expanded from tournament, or freshly div created),
    // we may or may not have a tournamentTeamCount property. If its absent, we consider it the same as zero
    tournamentTeamCount: v.tournamentTeamCount ?? 0,
    fee: v.fee,
    holdPaymentFee: v.holdPaymentFee,
    maxTeams: v.maxTeams,
    minRequiredTournTeamRefCount: v.minRequiredTournTeamRefCount
  }
}

function freshTournamentForm(
  targetInfo: {
    competitionUID: iltypes.Guid,
    competitionName: string,
    seasonUID: iltypes.Guid
    seasonName: string,
  },
  compSeasonDivs: DeepReadonly<iltypes.CompetitionSeasonDivision[]>
) : TournamentForm {
  return {
    kind: {
      mode: "create",
      competitionUID: targetInfo.competitionUID,
      seasonUID: targetInfo.seasonUID
    },
    form: {
      registrationStart: "",
      registrationEnd: "",
      divisions: [],
      requireRefsOnSubmission: false,
      minRequiredTournTeamRefCount: 0,
      localTeamsSubjectToRegFee: false,
      localTeamsSubjectToHoldPaymentFee: false,
      html_tournTeamReg_splash_A: "",
      html_tournTeamReg_complete: "",
      html_tournTeamReg_regConfirmationEmail_header: "",
      html_tournTeamReg_regConfirmationEmail_footer: "",
      contactEmail: "",
      playerRosterSubmissionConfig: TournTeamPlayerRosterSubmissionConfig.allowed,
    },
    aux: {
      compSeasonDivs,
      competitionName: targetInfo.competitionName,
      seasonName: targetInfo.seasonName,
    }
  }
}

function tournamentFormFromExisting(v: DeepReadonly<iltournament.TournamentForAdminPage>, compSeasonDivs: DeepReadonly<iltypes.CompetitionSeasonDivision[]>, tournamentWasGeneratedThisSession?: boolean) : TournamentForm {
  return {
    kind: {
      mode: "update",
      tournamentID: v.tournamentID,
      tournamentWasGeneratedThisSession: !!tournamentWasGeneratedThisSession,
    },
    form: {
      registrationStart: dayjsFormatOr(v.registrationStart, DAYJS_FORMAT_HTML_DATETIME, ""),
      registrationEnd: dayjsFormatOr(v.registrationEnd, DAYJS_FORMAT_HTML_DATETIME, ""),
      divisions: [...v.divisions]
        .sort(sortByMany(sortBy(_ => parseIntOr(_.divNum, -1)), sortBy(_ => _.gender)))
        .map(tournamentDivisionFormEntryFromExisting),
      requireRefsOnSubmission: !!v.requireRefsOnSubmission,
      minRequiredTournTeamRefCount: v.minRequiredTournTeamRefCount,
      localTeamsSubjectToRegFee: !!v.localTeamsSubjectToRegFee,
      localTeamsSubjectToHoldPaymentFee: !!v.localTeamsSubjectToHoldPaymentFee,
      html_tournTeamReg_splash_A: v.html_tournTeamReg_splash_A,
      html_tournTeamReg_complete: v.html_tournTeamReg_complete,
      html_tournTeamReg_regConfirmationEmail_header: v.html_tournTeamReg_regConfirmationEmail_header,
      html_tournTeamReg_regConfirmationEmail_footer: v.html_tournTeamReg_regConfirmationEmail_footer,
      contactEmail: v.contactEmail,
      playerRosterSubmissionConfig: v.playerRosterSubmissionConfig,
    },
    aux: {
      compSeasonDivs,
      competitionName: v.competitionName,
      seasonName: v.seasonName
    }
  }
}

const TournamentForm = defineComponent({
  name: "TournamentForm",
  props: {
    kind: {
      required: true,
      type: Object as PropType<TournamentForm["kind"]>
    },
    mut_form: {
      required: true,
      type: Object as PropType<TournamentForm["form"]>
    },
    aux: {
      required: true,
      type: Object as PropType<TournamentForm["aux"]>
    },
  },
  emits: {
    submitTournamentInfo: () => true,
  },
  setup(props, {emit}) {
    const iziToast = useIziToast();
    const divListManager = (() => {
      const selectedDivID = ref("");
      const divOptions = computed<UiOption[]>(() => {
        const divIsInForm = (divID: iltypes.Guid) : boolean => !!props.mut_form.divisions.find(divFormEntry => divFormEntry.divID === divID);
        const compSeasonDivsNotAlreadyInForm = props.aux.compSeasonDivs.filter(({divID}) => !divIsInForm(divID))
        const result = compSeasonDivsNotAlreadyInForm
          .map(compSeasonDiv => {
            return {
              label: compSeasonDiv.displayName || compSeasonDiv.division,
              value: compSeasonDiv.divID
            }
          });

        if (result.length === 0) {
          return [{label: "No available divisions", value: "", attrs: {disabled: true}}];
        }
        else {
          return [{label: "Select a division...", value: ""}, ...result];
        }
      });

      //
      // when options changes, if the selected option is no longer available, force set the selected value to nil
      //
      watch(() => divOptions.value, () => {
        if (divOptions.value.some(opt => opt.value === selectedDivID.value)) {
          return;
        }
        else {
          selectedDivID.value = "";
        }
      })

      const addLocalTentativeDiv = () : void => {
        const divID = selectedDivID.value
        const divName = divOptions.value.find(opt => opt.value === divID)?.label;
        if (divID === "" || !divName) {
          // nothing was selected,
          // or, we didn't find the selection in the list?
          return;
        }
        props.mut_form.divisions.push(freshTournamentDivisionFormEntry({divID, divName}))
      }

      const persistTentativeDivs = async () : Promise<void> => {
        if (props.kind.mode === "create") {
          throw Error("illegal state -- cannot be invoked in create mode")
        }

        const tournamentID = flowCapture(props.kind.tournamentID);

        const maybeOK = await GlobalInteractionBlockingRequestsInFlight.withSpinner(() =>
          tournamentStore
            .createOrUpdateTournamentDivisions(axiosInstance, {
              tournamentID: tournamentID,
              divDefs: props
                .mut_form
                .divisions
                .filter(v => v.type === "tentative")
            })
        );

        if (maybeOK.ok) {
          const fresh = maybeOK.result;
          props.mut_form.divisions = [
            // include fresh
            ...fresh.map(tournamentDivisionFormEntryFromExisting),
            // drop old tentative
            ...props.mut_form.divisions.filter(v => v.type !== "tentative")
          ];

          iziToast.success({message: "Changes saved."});
        }
      }

      const updatePersistedDivs = async () : Promise<void> => {
        if (props.kind.mode === "create") {
          throw Error("illegal state -- cannot be invoked in create mode")
        }

        const maybeOK = await tournamentStore.createOrUpdateTournamentDivisions(axiosInstance, {
          tournamentID: props.kind.tournamentID,
          divDefs: props
            .mut_form
            .divisions
            .filter(v => v.type === "persisted")
        })

        if (maybeOK.ok) {
          iziToast.success({message: "Changes saved."});
        }
      }

      const deletePersistedDiv = async (divID: iltypes.Guid) : Promise<void> => {
        if (props.kind.mode === "create") {
          throw Error("illegal state -- cannot be invoked in create mode")
        }

        const idx = props.mut_form.divisions.findIndex(div => div.divID === divID);
        if (idx === -1) {
          return; // shouldn't happen
        }
        const target = props.mut_form.divisions[idx];
        if (target.type !== "persisted") {
          throw Error("deletePersistedDiv got non-persisted div");
        }

        const tournamentID = flowCapture(props.kind.tournamentID);

        const maybeOK = await GlobalInteractionBlockingRequestsInFlight.withSpinner(() =>
          tournamentStore.deleteTournamentDivisions(axiosInstance, {
            tournamentID: tournamentID,
            divIDs: [target.divID]
          })
        );

        if (maybeOK.ok) {
          props.mut_form.divisions.splice(idx, 1);
          iziToast.success({message: "Changes saved."});
        }
      }

      const deleteLocalTentativeDiv = (divID: iltypes.Guid) : void =>  {
        const idx = props.mut_form.divisions.findIndex(div => div.divID === divID);
        if (idx === -1) {
          return; // shouldn't happen
        }
        const target = props.mut_form.divisions[idx];
        if (target.type !== "tentative") {
          throw Error("deleteTentativeDiv got non-tentative div");
        }
        props.mut_form.divisions.splice(idx, 1);
      }

      return {
        selectedDivID,
        divOptions,
        addLocalTentativeDiv,
        deleteLocalTentativeDiv,
        persistTentativeDivs,
        updatePersistedDivs,
        deletePersistedDiv,
        fk_refs: {
          form: FK_nodeRef(),
          addDiv: FK_nodeRef(),
        }
      }
    })();

    const handleSubmitTournamentInfo = async () : Promise<void> => {
      emit("submitTournamentInfo");
    }

    const formValidity = computed(() => {
      type ValidityResult =
        | {isValid: true, msg?: undefined}
        | {isValid: false, msg: string}

      const regStartBeforeRegEnd : ValidityResult = (() => {
        const dayjsOrNull =  (v: string) => { const r = dayjs(v); return r.isValid() ? r : null; }
        const start = dayjsOrNull(props.mut_form.registrationStart);
        const end = dayjsOrNull(props.mut_form.registrationEnd);
        if (start === null && end === null) {
          return {isValid: true}
        }
        else if (start === null || end === null) {
          return {isValid: false, msg: "Registration start and end must both be specified or neither be specified."}
        }
        else if (start.isBefore(end)) {
          return {isValid: true}
        }
        else {
          return {isValid: false, msg: "Registration start must be before registration end."}
        }
      })()

      const hasSomeBlockingError = !regStartBeforeRegEnd.isValid

      return {
        regStartBeforeRegEnd,
        hasSomeBlockingError
      }
    });

    // formkit -- the only thing we're using it for is to prevent "submit" on an invalid form.
    // It also does handle "form pristine -> dirty -> invalid -> dirty -> etc..." state transitions, but
    // it's really byzantine. We want it to output the error message we generated, using <FormKit ... validationMessages={{validatorName: someComputed.value.foo.bar}}. No dice.
    // Maybe it copies those into itself in a non-reactive way.
    const fk_startBeforeEnd = (node: FormKitNode) : boolean => {
      (function TOUCH_NODE_BECAUSE_FORMKIT_RUNS_THIS_ASYNC_OR_SOMETHING() { node.value; })();
      return formValidity.value.regStartBeforeRegEnd.isValid;
    }

    return () => (
      <div data-test="TournamentForm">
        <TournamentForm/>
        {
          props.kind.mode === "create"
            ? null
            : <DivListingForm defaultToAddDivTab={props.kind.tournamentWasGeneratedThisSession}/>
        }
      </div>
    );

    function TournamentForm() {
      return (
        <FormKit type="form" actions={false} onSubmit={handleSubmitTournamentInfo}>
          <div class="border-slate-200 shadow-md">
          <div class="p-1 bg-black text-white">Tournament Configuration - {props.aux.competitionName} ({props.aux.seasonName})</div>
            <div class="p-2">
              <FormKit type="group" validation={[["fk_startBeforeEnd"]]} validationRules={{fk_startBeforeEnd}}>
                <FormKit type="datetime-local" label="Registration start" v-model={props.mut_form.registrationStart}/>
                <FormKit type="datetime-local" outer-class="reset" label="Registration end" v-model={props.mut_form.registrationEnd}/>
                <FormKit type="checkbox" label="Require referees on team submission" v-tooltip={{content: "By default, referees may be submitted after the initial team registration is submitted. If this option is enabled, a full referee slate must be submitted with the registration form."}} v-model={props.mut_form.requireRefsOnSubmission}/>
                <FormKit type="number" min={0} label="Default Number of Referees Required per submission" v-tooltip="The number of referees each team is required to submit. May be overridden on a divisional basis." v-model={props.mut_form.minRequiredTournTeamRefCount} validation={[["required"], ["min", 0]]}/>
                <FormKit type="checkbox" min={0} label="Local teams subject to registration fee" v-tooltip="If enabled, teams from the local (hosting) league must pay the tournament registration fee. If disabled, local teams do not have to pay a registration fee." v-model={props.mut_form.localTeamsSubjectToRegFee} validation={[["required"], ["min", 0]]}/>
                <FormKit type="checkbox" min={0} label="Local teams subject to referee deposit" v-tooltip="If enabled, teams from the local (hosting) league will have their payment method held for a referee deposit. If disabled, local teams are exempt from the referee deposit." v-model={props.mut_form.localTeamsSubjectToHoldPaymentFee} validation={[["required"], ["min", 0]]}/>
                <FormKit type="email" label="League contact email" v-model={props.mut_form.contactEmail} validation={[["required"]]} data-test="contactEmail"/>
                <FormKit
                  type="select"
                  options={
                    [
                      {label: "Allowed", value: TournTeamPlayerRosterSubmissionConfig.allowed},
                      {label: "Disabled", value: TournTeamPlayerRosterSubmissionConfig.disabled},
                    ]
                  }
                  v-model={props.mut_form.playerRosterSubmissionConfig}
                  label="Player roster submissions"
                />

                {
                  props.mut_form.registrationStart !== "" || props.mut_form.registrationEnd !== ""
                    ? <a href="javascript:void(0)" onClick={() => { props.mut_form.registrationStart = ""; props.mut_form.registrationEnd = ""; }} class="block text-blue-700 cursor-pointer underline">Clear Registration Dates</a>
                    : null
                }
                {
                  formValidity.value.regStartBeforeRegEnd.isValid
                    ? null
                    : <div class="text-sm text-red-600">{formValidity.value.regStartBeforeRegEnd.msg}</div>
                }
              </FormKit>
              <div class="my-2">
                <div class="my-4">
                  <Disclosure>
                    {({open}: {open: boolean, close: () => void}) => (
                        <>
                          <DisclosureButton
                            class="border-b border-black pr-2 flex items-center text-left text-sm font-medium"
                          >
                            <FontAwesomeIcon icon={["fas", "chevron-right"]} class={`${open ? 'rotate-90 transform' : ''} transition-transform`}/>
                            <span class="ml-1">Registration Instructions / Completion Text (click to edit)</span>
                          </DisclosureButton>
                          <DisclosurePanel class="px-4 pt-4 pb-2 text-sm text-gray-800">
                            <PerTournamentClientContentChunklikeEditor mut_form={props.mut_form}/>
                          </DisclosurePanel>
                        </>
                      )
                    }
                  </Disclosure>
                </div>
                <div class="my-4">
                  <Disclosure>
                    {({open}: {open: boolean, close: () => void}) => (
                        <>
                          <DisclosureButton
                            class="border-b border-black pr-2 flex items-center text-left text-sm font-medium"
                          >
                            <FontAwesomeIcon icon={["fas", "chevron-right"]} class={`${open ? 'rotate-90 transform' : ''} transition-transform`}/>
                            <span class="ml-1">Registration confirmation email text (click to edit)</span>
                          </DisclosureButton>
                          <DisclosurePanel class="px-4 pt-4 pb-2 text-sm text-gray-800">
                            <PerTournamentEmailContentChunklikeEditor mut_form={props.mut_form}/>
                          </DisclosurePanel>
                        </>
                      )
                    }
                  </Disclosure>
                </div>
              </div>
              <t-btn type="submit" class={`${formValidity.value.hasSomeBlockingError ? 'bg-gray-200' : ''} my-2`} disable={formValidity.value.hasSomeBlockingError} margin={false}>
                {
                  props.kind.mode === "create"
                    ? <span>Enable tournament</span>
                    : <span>Save changes</span>
                }
              </t-btn>
            </div>
          </div>
        </FormKit>
      );
    }

    function DivListingForm({defaultToAddDivTab} : {defaultToAddDivTab?: boolean}) {
      return (
        <div class="border-slate-200 shadow-md my-4">
          <div class="p-1 bg-black text-white">Active Divisions -- {props.aux.competitionName} ({props.aux.seasonName})</div>
          <div class="p-2 border">
            <Tabs selectedIndex={defaultToAddDivTab ? 1 : undefined} tabDefs={[
              {
                keepAlive: true,
                label: "Current",
                ["data-test"]: "divtab/current",
                render: () => (
                  <div class="p-2" data-test="divtab/container/current">
                    <div class="flex items-center justify-center">
                      {
                        props.mut_form.divisions.filter(v => v.type === "persisted").length === 0
                          ? <div>Select 'Add a New Division' to open a division for this tournament.</div>
                          : null
                      }
                    </div>
                    <FormKit type="form" actions={false} onSubmit={() => divListManager.updatePersistedDivs()}>
                      {
                        props
                          .mut_form
                          .divisions
                          .filter(v => v.type === "persisted")
                          .map((tournamentDiv, idx) => {
                            const cannotDelete = tournamentDiv.tournamentTeamCount > 0
                            return (
                              <div key={tournamentDiv.divID} data-test={tournamentDiv.divID}>
                                {
                                  (idx > 0) ? <div class="my-2 border-b border-gray-200"/> : null
                                }
                                <div class="flex">
                                  <div>Division {tournamentDiv.divName}</div>
                                  <div class="ml-auto" v-tooltip={cannotDelete ? {content: "This division has associated tournament teams, and cannot be deleted."} : {}}>
                                    <t-btn
                                      type="button" data-test="delete" margin={false}
                                      disable={cannotDelete}
                                      class={cannotDelete ? "bg-gray-200" : ""}
                                      onClick={() => divListManager.deletePersistedDiv(tournamentDiv.divID)}
                                    >
                                      <FontAwesomeIcon icon={faTrash}/>
                                      <span class="ml-2">Delete</span>
                                    </t-btn>
                                  </div>
                                </div>
                                <div class="px-2 flex gap-4 flex-wrap">
                                  <FormKit type="number" data-test="maxTeams" v-model={tournamentDiv.maxTeams} label="Max # of Teams Allowed" validation={[["min", 0], ["max", 255]]}/>
                                  <FormKit type="number" data-test="fee" step=".01" v-model={tournamentDiv.fee} label="Team Registration Fee" validation={[["min", 0]]}/>
                                  <FormKit type="number" data-test="holdPaymentFee" step=".01" v-model={tournamentDiv.holdPaymentFee} label="Referee Deposit Amount" validation={[["min", 0]]}/>
                                  <FormKit type="number" data-test="minRequiredTournTeamRefCount" min={0} step="1" v-model={tournamentDiv.minRequiredTournTeamRefCount} label="Number of Referees Required" validation={[["min", 0]]}/>
                                </div>
                              </div>
                            )
                          })
                      }
                      {
                        props.mut_form.divisions.some(v => v.type === "persisted")
                          ? (
                            <t-btn margin={false} class="my-4" data-test="submit" type="submit">
                              <FontAwesomeIcon icon={faFloppyDisk}/>
                                <span class="ml-2">Save</span>
                            </t-btn>
                          )
                          : null
                      }
                    </FormKit>
                  </div>
                )
              },
              {
                keepAlive: true,
                label: "Add a New Division",
                ["data-test"]: "divtab/add-new",
                render: () => (
                  <div class="p-2" data-test="divtab/container/add-new">
                    <div>
                      <FormKit type="form" actions={false} onSubmit={() => divListManager.addLocalTentativeDiv()} ref={divListManager.fk_refs.form}>
                        <FormKit ref={divListManager.fk_refs.form} type="select" data-test="divID" options={divListManager.divOptions.value} v-model={divListManager.selectedDivID.value} validation={[["required"]]}/>
                        <t-btn type="submit" data-test="add-div" margin={false} disable={divListManager.selectedDivID.value === ""} class={`${divListManager.selectedDivID.value === "" ? "bg-gray-300" : ""}`}>
                          <FontAwesomeIcon icon={faPlusSquare}/>
                          <span class="ml-2">Add</span>
                        </t-btn>
                        <FormKitMessages class="hidden" node={divListManager.fk_refs.form.value?.node}/>
                        <FormKitMessages class="hidden" node={divListManager.fk_refs.addDiv.value?.node}/>
                      </FormKit>
                    </div>
                    {
                      props.mut_form.divisions.some(v => v.type === "tentative")
                        ? <div class="my-2 border-b border-gray-200"/>
                        : null
                    }
                    <FormKit type="form" actions={false} onSubmit={divListManager.persistTentativeDivs}>
                      <div>
                        {
                          props
                            .mut_form
                            .divisions
                            .filter(v => v.type === "tentative")
                            .map((tournamentDiv, idx) => (
                              <div key={tournamentDiv.divID} data-test={tournamentDiv.divID}>
                                {
                                  (idx > 0) ? <div class="my-2 border-b border-gray-200"/> : null
                                }
                                <FormKit type="group" actions={false}>
                                  <div class="flex">
                                    <div>Division {tournamentDiv.divName}</div>
                                    <div class="ml-auto">
                                      <t-btn type="button" data-test="remove" margin={false} onClick={() => divListManager.deleteLocalTentativeDiv(tournamentDiv.divID)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                        <span class="ml-2">Remove</span>
                                      </t-btn>
                                    </div>
                                  </div>
                                  <div class="px-2 flex gap-4 flex-wrap">
                                    <FormKit type="number" data-test="maxTeams" v-model={tournamentDiv.maxTeams} label="Max # of Teams Allowed" validation={[["min", 0]]}/>
                                    <FormKit type="number" step=".01" data-test="fee" v-model={tournamentDiv.fee} label="Team Registration Fee" validation={[["min", 0]]}/>
                                    <FormKit type="number" step=".01" data-test="holdPaymentFee" v-model={tournamentDiv.holdPaymentFee} label="Referee Deposit Amount" validation={[["min", 0]]}/>
                                    <FormKit type="number" data-test="minRequiredTournTeamRefCount" min={0} step="1" v-model={tournamentDiv.minRequiredTournTeamRefCount} label="Number of Referees Required" validation={[["min", 0]]}/>
                                  </div>
                                </FormKit>
                              </div>
                            ))
                        }
                      </div>
                      {
                        // show save button if there are some to save
                        props.mut_form.divisions.some(v => v.type === "tentative")
                          ? (
                            <t-btn
                              type="submit" margin={false} data-test="submit"
                              disable={!props.mut_form.divisions.some(v => v.type === "tentative")}
                              class={`${!props.mut_form.divisions.some(v => v.type === "tentative") ? "bg-gray-300" : ""} mt-4`}
                            >
                              <FontAwesomeIcon icon={faFloppyDisk}/>
                              <span class="ml-2">Save</span>
                            </t-btn>
                          )
                          : null
                      }
                    </FormKit>
                  </div>
                )
              },
            ]}/>
          </div>
        </div>
      )
    }
  }
})

export const TournamentHostingManagerImpl = defineComponent({
  name: "TournamentHostingManagerImpl",
  props: {
    seasonUID: {
      required: true,
      type: null as any as PropType<iltypes.Guid>
    },
    competitionUID: {
      required: true,
      type: null as any as PropType<iltypes.Guid>
    }
  },
  setup(props) {

    const iziToast = useIziToast()

    type AsyncResolver =
      | {readonly ready: false}
      | {
        readonly ready: true,
        readonly partialCompetition: {competitionUID: iltypes.Guid, competitionName: string},
        readonly partialSeason: {seasonUID: iltypes.Guid, seasonName: string},
        /**
         * null in the case of "no such tournament, therefore we have no form to show; the user can initialize a form but hasn't done so yet"
         */
        readonly tournamentForm: TournamentForm | null
      }

    const asyncResolver = ref<AsyncResolver>({ready: false})

    const initFormForCreateTournament = async () : Promise<void> => {
      if (!asyncResolver.value.ready) {
        throw Error("illegal/impossible state");
      }

      try {
        const compSeasonDivs = await tournamentStore.getCompSeasonDivListing(axiosInstance, {seasonUID: props.seasonUID, competitionUID: props.competitionUID})
        const tournamentForm = freshTournamentForm({
            ...asyncResolver.value.partialCompetition,
            ...asyncResolver.value.partialSeason,
          },
          compSeasonDivs.value
        );

        asyncResolver.value = {
          ...asyncResolver.value,
          tournamentForm
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const handleSubmitTournamentInfo = async () => {
      if (!asyncResolver.value.ready || !asyncResolver.value.tournamentForm) {
        throw Error("illegal/impossible state -- shouldn't submit the form in this case");
      }

      const {kind, form} = asyncResolver.value.tournamentForm;

      if (kind.mode === "create") {
        const maybeOK = await GlobalInteractionBlockingRequestsInFlight.withSpinner(() =>
          tournamentStore.createTournament(axiosInstance, {
            competitionUID: kind.competitionUID,
            seasonUID: kind.seasonUID,
            ...commonSpreadables(form)
          })
        );

        if (!maybeOK.ok) {
          // ???? we expect to have rendered some error test via an axios error handler at this point
          return;
        }

        const compSeasonDivs = await tournamentStore.getCompSeasonDivListing(axiosInstance, {seasonUID: props.seasonUID, competitionUID: props.competitionUID})

        asyncResolver.value = {
          ...asyncResolver.value,
          tournamentForm: tournamentFormFromExisting(maybeOK.result.value, compSeasonDivs.value, /*wasFreshlyGeneratedThisSession*/true)
        }

        iziToast.success({message: "Tournament enabled."});
      }
      else if (kind.mode === "update") {
        const maybeOK = await GlobalInteractionBlockingRequestsInFlight.withSpinner(() =>
          tournamentStore.updateTournament(axiosInstance, {
            tournamentID: kind.tournamentID,
            ...commonSpreadables(form)
          })
        );

        if (!maybeOK.ok) {
          // ???
          return;
        }

        iziToast.success({message: "Changes saved."});
      }
      else {
        exhaustiveCaseGuard(kind);
      }

      function commonSpreadables(v: TournamentForm_Base["form"]) {
        return {
          registrationStart: dayjsOr(v.registrationStart)?.toISOString() ?? null,
          registrationEnd: dayjsOr(v.registrationEnd)?.toISOString() ?? null,
          requireRefsOnSubmission: v.requireRefsOnSubmission,
          minRequiredTournTeamRefCount: v.minRequiredTournTeamRefCount,
          localTeamsSubjectToRegFee: v.localTeamsSubjectToRegFee,
          localTeamsSubjectToHoldPaymentFee: v.localTeamsSubjectToHoldPaymentFee,
          html_tournTeamReg_splash_A: v.html_tournTeamReg_splash_A,
          html_tournTeamReg_complete: v.html_tournTeamReg_complete,
          html_tournTeamReg_regConfirmationEmail_header: v.html_tournTeamReg_regConfirmationEmail_header,
          html_tournTeamReg_regConfirmationEmail_footer: v.html_tournTeamReg_regConfirmationEmail_footer,
          contactEmail: v.contactEmail,
          playerRosterSubmissionConfig: v.playerRosterSubmissionConfig,
        } as const;
      }
    }

    onMounted(async () => {
      const loggedInAxiosNoToastOn404 = freshLoggedInAxiosNoToastOn404(iziToast);
      const tournament = await tournamentStore.maybeGetTournamentIfExists(loggedInAxiosNoToastOn404, {competitionUID: props.competitionUID, seasonUID: props.seasonUID})

      if (tournament) {
        const compSeasonDivs = await tournamentStore.getCompSeasonDivListing(axiosInstance, {seasonUID: props.seasonUID, competitionUID: props.competitionUID})
        asyncResolver.value = {
          ready: true,
          partialCompetition: {competitionUID: tournament.value.competitionUID, competitionName: tournament.value.competitionName},
          partialSeason: {seasonUID: tournament.value.seasonUID, seasonName: tournament.value.seasonName},
          tournamentForm: tournamentFormFromExisting(tournament.value, compSeasonDivs.value),
        }
      }
      else {
        // no such tournament, we can offer the user to create it (yes, just one per comp/season) though
        const competitionName = (await Client.getCompetitionByUID( props.competitionUID ))?.competition ?? "Undefined Program";
        const seasonName = (await Client.getSeasonByUID( props.seasonUID ))?.seasonName ?? "Undefined Season";

        asyncResolver.value = {
          ready: true,
          partialCompetition: {competitionUID: props.competitionUID, competitionName: competitionName},
          partialSeason: {seasonUID: props.seasonUID, seasonName: seasonName},
          tournamentForm: null,
        }
      }
    })

    return () => {
      if (!asyncResolver.value.ready) {
        return null;
      }
      else {
        return (
          <div data-test="Tournament">
            {
              asyncResolver.value.tournamentForm === null
                ? (
                  <div class="flex flex-col items-center justify-center gap-4">
                    {asyncResolver.value.partialCompetition.competitionName} is not currently hosting a tournament for {asyncResolver.value.partialSeason.seasonName}.

                    <t-btn margin={false} onClick={initFormForCreateTournament} data-test="enable-tournament">Enable tournament</t-btn>
                  </div>
                )
                : <TournamentForm
                    onSubmitTournamentInfo={handleSubmitTournamentInfo}
                    kind={asyncResolver.value.tournamentForm.kind}
                    mut_form={asyncResolver.value.tournamentForm.form}
                    aux={asyncResolver.value.tournamentForm.aux}
                  />
            }
          </div>
        )
      }
    }
  }
})

function freshLoggedInAxiosNoToastOn404(iziToast: IziToast) {
  return freshAxiosInstance({
    useCurrentBearerToken: true,
    requestInterceptors: [defaultSetGlobalSingletonLoadingStateFlagInterceptors.request],
    responseInterceptors: [
      {
        ok: defaultSetGlobalSingletonLoadingStateFlagInterceptors.responseOK,
        error: defaultLoggedInErrorResponseHandler_noToastOn404(iziToast)
      }

    ]}
  )
}

const ChunkOrChunklikeEditor = defineComponent({
  props: {
    modelValue: {
      required: false,
      type: null
    },
    maxLength: {
      required: false,
      type: Number
    }
  },
  setup(props, {emit}) {
    const localText = computed({
      get() { return props.modelValue },
      set(v: string) { emit("update:modelValue", v); }
    });

    return () => (
      <div>
        <QuillWrapper2 configureFor="email" v-model={localText.value}/>
        <div class="flex flex-col items-end justify-start">
          {
            props.maxLength !== undefined
              ? (
                <>
                  <div class="text-xs">{localText.value.length}/{props.maxLength}</div>
                  <div class="text-xs">Characters introduced for html formatting are included in this count.</div>
                </>
              )
              : null
          }
        </div>
      </div>
    )
  }
})

const PerTournamentClientContentChunklikeEditor = defineComponent({
  props: {
    mut_form: {
      required: true,
      type: null as any as PropType<TournamentForm["form"]>
    }
  },
  setup(props) {
    const MAX_LENGTH = 2000
    return () => (
      <>
        <div class="my-2">
          <div>Registration / Landing Page Instructions (prior to division selection)</div>
          <ChunkOrChunklikeEditor
            v-model={props.mut_form.html_tournTeamReg_splash_A}
            maxLength={MAX_LENGTH}
          />
        </div>
        <div class="my-2">
          <div>Text Displayed upon Completing Registration</div>
          <ChunkOrChunklikeEditor
            v-model={props.mut_form.html_tournTeamReg_complete}
            maxLength={MAX_LENGTH}
          />
        </div>
      </>
    )
  }
})

const PerTournamentEmailContentChunklikeEditor = defineComponent({
  props: {
    mut_form: {
      required: true,
      type: null as any as PropType<TournamentForm["form"]>
    }
  },
  setup(props) {
    const MAX_LENGTH = 2000
    return () => (
      <>
        <div class="my-2">
          <div>Tournament team registration confirmation email header</div>
          <ChunkOrChunklikeEditor
            v-model={props.mut_form.html_tournTeamReg_regConfirmationEmail_header}
            maxLength={MAX_LENGTH}
          />
        </div>
        <div class="my-2">
          <div>Tournament team registration confirmation email footer</div>
          <ChunkOrChunklikeEditor
            v-model={props.mut_form.html_tournTeamReg_regConfirmationEmail_footer}
            maxLength={MAX_LENGTH}
          />
        </div>
      </>
    )
  }
})
