import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { User } from "./User"
import type * as iltypes from "src/interfaces/InleagueApiV1";
import * as ilapi from "src/composables/InleagueApiV1"
import { axiosInstance } from "src/boot/AxiosInstances";
import * as ClearOnLogout from "./ClearOnLogout"
import { ComputedRef, Ref, computed, reactive, ref } from "vue";
import { arrayFindOrFail } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";

interface GetCompetitionsRequestState {
  /**
   * The results of asking the backend for competitions changes based on if the user is logged in or not
   * So a change to "is user logged in" should invalidate the cache
   */
  userWasLoggedInAtTimeOfLastLoad: boolean,
}

/**
 * Factory to construct a cache based on the "disabled" status of a competition.
 *
 * We maintain 2 caches -- one with "disabled" comps and one without. This is sort of an optimization for the
 * "typical user" case who will not need disabled competitions, but generally the cost to retrieve either list
 * is about the same. We could alternatively always pull disabled and filter on the client.
 * This cost analysis might change if there are 100 disabled competitions and only 2 enabled, but the data
 * pattern is currently somewhere on the order of 10 enabled and 1 disabled.
 */
function CompetitionStoreFactory(args: {includeDisabled: boolean}) {
  let mostRecentRequestConfig : Readonly<GetCompetitionsRequestState> | null = null;
  const p = ReactiveReifiedPromise<iltypes.Competition[]>();
  const resolved = ref<iltypes.Competition[]>([])

  const clear = () => {
    mostRecentRequestConfig = null;
    p.reset();
    resolved.value = []
  }

  async function get(forceBustCache?: boolean) : Promise<Ref<readonly iltypes.Competition[]>> {
    const makeFreshRequest = forceBustCache
      || (mostRecentRequestConfig === null)
      || (mostRecentRequestConfig && mostRecentRequestConfig.userWasLoggedInAtTimeOfLastLoad !== User.isLoggedIn)

    if (makeFreshRequest) {
      mostRecentRequestConfig = {
        userWasLoggedInAtTimeOfLastLoad: User.isLoggedIn,
      }

      p.run(() => {
        return ilapi
          .getCompetitions(axiosInstance, {includeDisabled: args.includeDisabled})
          .then(comps => comps.map(_ => reactive(_)))
      });
    }

    // wait for possible network request
    resolved.value = await p.getResolvedOrFail();

    return resolved;
  }

  return {
    get,
    get promise() { return p.underlying },
    clear
  }
}

const all = CompetitionStoreFactory({includeDisabled: true})
const noDisabled = CompetitionStoreFactory({includeDisabled: false})

ClearOnLogout.register(all)
ClearOnLogout.register(noDisabled);

export async function getCompetitionsOrFail(args?: {includeDisabled?: boolean, bustCache?: boolean}) : Promise<Ref<readonly iltypes.Competition[]>> {
  const includeDisabled = !!args?.includeDisabled;
  if (includeDisabled) {
    return await all.get(args?.bustCache ?? false);
  }
  else {
    return await noDisabled.get(args?.bustCache ?? false);
  }
}

export async function getCompetitionOrFail(competitionUID: Guid, args?: {includeDisabled?: boolean, bustCache?: boolean}) : Promise<iltypes.Competition> {
  const comps = (await getCompetitionsOrFail(args)).value
  return arrayFindOrFail(comps, comp => comp.competitionUID === competitionUID);
}

export async function updateCompetition(competition: iltypes.Competition) : Promise<void> {
  await ilapi.updateCompetition(axiosInstance, {competition});

  // we don't know which cache the target comp is in;
  // and we don't know which cache the target will be placed in after the update.
  // So, we need to "bust cache" on all caches, if those caches are already populated.

  if (all.promise.status !== "idle") {
    await getCompetitionsOrFail({includeDisabled: true, bustCache: true})
  }

  if (noDisabled.promise.status !== "idle") {
    await getCompetitionsOrFail({includeDisabled: false, bustCache: true})
  }
}
