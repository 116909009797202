import { ExtractPropTypes, PropType } from "vue";
import { ExtractEmitsHandlers } from "src/helpers/utils";
import { Child, CoreQuestion, Guid, Registration } from "src/interfaces/InleagueApiV1";

export function propsDef() {
  return {
    variant: {
      required: true as const,
      type: Object as PropType<PropsVariant>
    }
  }
}

export function emitsDef() {
  return {
    // todo: tighter types on these
    updateCoreQuestionAnswers: (...v: any[]) => true,
    formErrors: (...v: any[]) => true,
    coreError: (...v: any[]) => true,
    /**
     * kludgy workaround to notify parent of receipt of corequestions
     * This component is responsible for retreiving them, but sometimes the parent needs
     * to know exactly which core questions we're dealing with; this maybe implies
     * we should retrieve in parent and pass down.
     */
    notifyCoreQuestionsReceipt: (v: CoreQuestion[]) => true
  }
}

export type Props = ExtractPropTypes<ReturnType<typeof propsDef>>;
export type Emits = ExtractEmitsHandlers<ReturnType<typeof emitsDef>>;

/**
 * The core question component can be mounted in 3 different modes
 */
type PropsVariant =
 | P_PurePreview
 | P_ChildSeasonCentricPreview
 | P_Real

interface P_PurePreview {
  type: "general-form-preview"
}

interface P_ChildSeasonCentricPreview {
  type: "child-season-centric-preview"
  playerID: Guid
  seasonUID: Guid
}

interface P_Real {
  type: "actual-form"
  /**
   * null if there is no existing registration,
   * i.e. this is the first time a user is interacting with a registration for some (child, season)
   */
  maybeExistingRegistration: Registration | null
  playerDetails: Child,
  seasonUID: Guid,
  competitionUIDs: Guid[],
}
