import { FormKit } from "@formkit/vue"
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import dayjs from "dayjs"
import { SoccerBall } from "src/components/SVGs"
import { Btn2 } from "src/components/UserInterface/Btn2"
import { GenerateTentativeMatchupsResponse, Round } from "src/composables/InleagueApiV1.Matchmaker"
import { assertTruthy, UiOption, UiOptions, vReqT } from "src/helpers/utils"
import { Datelike, Guid, Integerlike, Team } from "src/interfaces/InleagueApiV1"
import { Client } from "src/store/Client"
import { computed, defineComponent } from "vue"
import { RouteLocationRaw, RouterLink } from "vue-router"
import { getRoundRobinInfo, teamCountAdjustedWithMaybeByeTeam } from "./MatchmakerUtils"

export const Phase1Elem = defineComponent({
  props: {
    data: vReqT<Phase1_CreateRounds>(),
    poolOptions: vReqT<UiOptions<"" | "ALL" | Integerlike>>(),
    disableSubmit: vReqT<boolean>(),
  },
  emits: {
    createRounds: () => true,
  },
  setup(props, {emit}) {
    return () => {
      return (
        <div style="--fk-margin-outer:none; --fk-padding-input:.5em;" class="my-4" data-test="Phase1Elem">
          <FormKit type="form" onSubmit={() => emit("createRounds")} actions={false}>
            <div class="flex gap-1">
              <FormKit type="checkbox" v-model={props.data.includeGamesWithTeams}/>
              <div class="text-sm">Include Games with Existing Matchups</div>
            </div>
            <div class="text-sm font-medium">Start Date</div>
            <FormKit type="date" v-model={props.data.startDate} validation={[["required"]]} data-test="startDate"/>
            <div class="text-sm font-medium">End Date</div>
            <FormKit type="date" v-model={props.data.endDate} validation={[["required"]]} data-test="endDate"/>
            <div class="text-sm font-medium">Pool</div>
            <FormKit type="select" disabled={props.poolOptions.disabled} options={props.poolOptions.options} v-model={props.data.poolID} data-test="poolID"/>
            <div class="text-sm font-medium">Individual round length (days)</div>
            <FormKit type="number" v-model={props.data.roundLengthInDays} validation={[["required"], ["min", 1], ["max", 30]]} data-test="roundLengthInDays"/>
            <div class="text-sm font-medium">Number of round robin cycles</div>
            <FormKit type="number" v-model={props.data.numRoundRobinCycles} validation={[["required"], ["min", 1], ["max", 10]]} data-test="numRoundRobinCycles"/>
            <Btn2 class="my-2 px-2 py-1" disabled={props.disableSubmit} type="submit">Create Rounds</Btn2>
          </FormKit>
        </div>
      )
    }
  }
})

export const Phase2Elem = defineComponent({
  props: {
    data: vReqT<Phase2_ConfirmRounds>(),
    gameSchedulerRoute: vReqT<RouteLocationRaw | null>(),
    /**
     * does not include potential bye team
     */
    teamCountForCompSeasonDivPool: vReqT<number>(),
  },
  emits: {
    deleteRounds: () => true,
    generateMatchups: () => true,
    setExcluded: (_: {roundWrapper: RoundWrapper, value: boolean}) => true,
  },
  setup(props, {emit}) {
    const excludedProxy = (r: RoundWrapper) => {
      return {
        get value() : boolean {
          return !!r.round.excluded
        },
        set value(v) {
          emit("setExcluded", {roundWrapper: r, value: v})
        }
      }
    }

    const roundRobinInfo = computed(() => getRoundRobinInfo({teamCount: teamCountAdjustedWithMaybeByeTeam(props.teamCountForCompSeasonDivPool)}))

    function MaybeWarning() {
      return props.data.warning ? <div class="text-red-600 my-4 border p-2 rounded-md">{props.data.warning}</div> : null
    }

    const disableSubmit = computed(() => {
      const hasNoUsableRounds = props.data.rounds.filter(v => !v.round.excluded).length === 0
      const atLeastOneIsBusy = !!props.data.rounds.find(v => v.uiState.busy)

      return hasNoUsableRounds || atLeastOneIsBusy
    })

    return () => {
      return (
        <div style="--fk-border:none; --fk-margin-outer:none; --fk-margin-option:none;" class="text-sm max-w-screen-lg">
          <MaybeWarning/>
          {props.data.rounds.map((roundWrapper, roundIdx) => {
            const {round, uiState} = roundWrapper
            const excluded = excludedProxy(roundWrapper)

            const ui_roundRobinCycleNum = roundRobinInfo.value.roundRobinCycleNum(roundIdx) + 1
            const ui_relativeRoundNum = roundRobinInfo.value.relativeRoundNumFromSeriesAbsoluteRoundNum(roundIdx) + 1

            return (
              <div class="rounded-md my-4 bg-white w-full il-box-shadow-2" key={round.roundID}>
                <div class="w-full">
                  <div class="p-2 flex">
                    <div>
                      <div class="text-xs">Round Robin #{ui_roundRobinCycleNum}</div>
                      <div>Round {ui_relativeRoundNum}/{roundRobinInfo.value.roundsPerRoundRobinCycle}: {dayjs(round.startDate).format("ddd, MMM DD")} &ndash; {dayjs(round.endDate).format("ddd, MMM DD")}</div>
                    </div>
                    <div class="ml-auto flex items-center gap-1">
                      <input type="checkbox" v-model={excluded.value} class="disabled:bg-gray-200" checked={excluded.value} disabled={uiState.busy}/>
                      <div>Exclude this round</div>
                    </div>
                  </div>
                  <div class="w-full relative">
                    <table class="w-full" data-test={`roundRobin=${ui_roundRobinCycleNum}/round=${ui_relativeRoundNum}`}>
                      <tr class="bg-gray-200">
                        <td class="p-1"></td>
                        <td class="p-1">Game Num</td>
                        <td class="p-1">Date & Time</td>
                        <td class="p-1">Field</td>
                        <td class="p-1">Teams</td>
                      </tr>
                      {round.games.map((game, i) => {
                        const bgClass = i % 2 ? "bg-gray-100" : "white";
                        return (
                          <tr class={bgClass}>
                            <td class="align-top p-1">{i+1}</td>
                            <td class="align-top p-1">Game #{game.gameNum}</td>
                            <td class="align-top p-1">{dayjs(game.gameStart).format("ddd, MMM DD")} at {dayjs(game.gameStart).format("h:mm a")}</td>
                            <td class="align-top p-1">{game.field.fieldName}</td>
                            <td class="align-top p-1">
                              <div>Home: {game.homeTeam?.seasonal?.teamName || game.homeTeam?.team || "TBD"}</div>
                              <div>Visitor: {game.visitorTeam?.seasonal?.teamName || game.visitorTeam?.team || "TBD"}</div>
                            </td>
                          </tr>
                        )
                      })}
                    </table>
                    {round.excluded ? <div class="absolute top-0 left-0 w-full h-full opacity-75 bg-white"/> : null}
                    {uiState.busy ? <div class="absolute top-0 left-0 w-full h-full bg-white p-2">
                      <div class="flex items-center gap-2">
                        <SoccerBall color={Client.value.clientTheme.color} width="1.3em" height="1.3em" timeForOneRotation="1.5s"/>
                        <span class="text-xs">Working on it...</span>
                      </div>
                    </div> : null}
                  </div>
                </div>
              </div>
            )
          })}
          <div>
            <div class="grid grid-cols-3 gap-2 items-start">
              {props.gameSchedulerRoute
                ? <RouterLink to={props.gameSchedulerRoute}>
                    <Btn2 class="px-2 py-1 w-full">Edit Rounds in Game Scheduler</Btn2>
                  </RouterLink>
                : <Btn2 class="px-2 py-1 w-full" disabled={true}>Edit Rounds in Game Scheduler</Btn2>
              }
              <div>
                <Btn2 class="px-2 py-1 w-full" onClick={() => emit("deleteRounds")} disabled={props.data.rounds.length === 0}>Delete These Rounds<sup>*</sup></Btn2>
                <div class="text-sm my-2">
                  <sup>*</sup>Deleting rounds will not delete game slots or existing match-ups. It will only remove game slots from their current rounds.
                </div>
              </div>
              <div>
                <div class="p-2 border rounded-md">
                  {(props.teamCountForCompSeasonDivPool % 2) === 1
                    ? (
                      <div class="flex gap-2 items-center">
                        <div>Bye Position</div>
                        <input type="radio" name="bye" v-model={props.data.byePosition} value="first"/>
                        <div>First</div>
                        <input type="radio" name="bye" v-model={props.data.byePosition} value="last"/>
                        <div>Last</div>
                      </div>
                    )
                    : null
                  }
                  <div class="flex items-center gap-2">
                    <input type="checkbox" v-model={props.data.balanceHomeAndAway}/>
                    <div>Balance Home & Away</div>
                  </div>
                  <div class="flex items-center gap-2">
                    <input type="checkbox" v-model={props.data.includeGamesWithExistingMatchups}/>
                    <div>Include Games With Existing Matchups</div>
                  </div>
                </div>
                <Btn2 class="mt-2 px-2 py-1 w-full" onClick={() => emit("generateMatchups")} disabled={disableSubmit.value}>Generate Matchups</Btn2>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
})

export const Phase3Elem = defineComponent({
  props: {
    data: vReqT<Phase3_ConfirmTentativeTeams>(),
    /**
     * does not include potential bye team
     */
    teamCountForCompSeasonDivPool: vReqT<number>(),
  },
  emits: {
    saveTentativeMatchups: () => true,
    cancel: () => true,
  },
  setup(props, {emit}) {
    const chunkedByRoundRobinCycle = computed(() => {
      if (props.data.rounds.length === 0) {
        return []
      }

      const roundRobinInfo = getRoundRobinInfo({teamCount: teamCountAdjustedWithMaybeByeTeam(props.teamCountForCompSeasonDivPool)})

      // n.b. zero is the first cycleNum
      const maxCycleNum = roundRobinInfo.roundRobinCycleNum(props.data.rounds.length - 1)

      const result : RoundWrapper[][] = [...new Array(maxCycleNum + 1)].map(() => [])

      for (let i = 0; i < props.data.rounds.length; i++) {
        const cycleNum = roundRobinInfo.roundRobinCycleNum(i)
        assertTruthy(result[cycleNum]) // should exist due to initial construction of `result`
        result[cycleNum].push(props.data.rounds[i])
      }

      return result;
    })

    return () => {
      return (
        <div class="max-w-screen-lg">
          <div class="p-2 my-4 flex bg-yellow-300 rounded-md items-center gap-2">
            <FontAwesomeIcon icon={faTriangleExclamation} class="fa-xl pl-1"/>
            <span>These matchups will not be saved until you click "Approve Matchups."</span>
          </div>
          {
            chunkedByRoundRobinCycle.value.map((rounds, roundRobinCycleIdx) => {
              return (
                <div class="rounded-md my-4 il-box-shadow-2 bg-white">
                  <div class="px-2 py-1">Round Robin #{roundRobinCycleIdx+1}</div>
                  {rounds.map((round, roundIdx) => {
                    return (
                      <div>
                        {roundIdx === 0 ? null : <div class="border-b"/>}
                        <table class="w-full">
                          <tr>
                            <td class="p-1"></td>
                            <td class="p-1">Round</td>
                            <td class="p-1">Game Num</td>
                            <td class="p-1">Date</td>
                            <td class="p-1">Start</td>
                            <td class="p-1">End</td>
                            <td class="p-1">Field</td>
                            <td class="p-1">Home</td>
                            <td class="p-1">Visitor</td>
                          </tr>
                          {round.round.games.length === 0
                            ? <tr><td class="p-2" colspan="999">No assignments generated</td></tr>
                            : null
                          }
                          {round.round.games.map((game, rowIdx) => {
                            const bg = rowIdx % 2 ? "bg-white" : "bg-gray-100"
                            return <tr class={bg}>
                              <td class="p-1 text-gray-400">{rowIdx+1}</td>
                              <td class="p-1">{roundIdx + 1}</td>
                              <td class="p-1">{game.gameNum}</td>
                              <td class="p-1">{dayjs(game.gameStart).format("ddd, DD-MMM-YY")}</td>
                              <td class="p-1">{dayjs(game.gameStart).format("h:mm A")}</td>
                              <td class="p-1">{dayjs(game.gameEnd).format("h:mm A")}</td>
                              <td class="p-1">{game.field.fieldAbbrev}</td>
                              <td class="p-1">{game.homeTeam?.team}</td>
                              <td class="p-1">{game.visitorTeam?.team}</td>
                            </tr>
                          })}
                        </table>
                        {round.leftovers?.length
                          ? (
                            <div>
                              <div class="p-2">
                                <div class="rounded-md p-2 flex gap-2 items-center bg-yellow-300">
                                  <FontAwesomeIcon icon={faTriangleExclamation}/>
                                  <span>Note: Some matchups were not scheduled for this round</span>
                                </div>
                              </div>
                              <div class="overflow-y-auto max-h-96">
                                <table class="w-full">
                                  <tr>
                                    <td class="px-1"></td>
                                    <td class="px-1">Round</td>
                                    <td class="px-1">Home</td>
                                    <td class="px-1">Visitor</td>
                                  </tr>
                                  {round.leftovers.map((v,i) => {
                                    const bg = i % 2 ? "bg-white" : "bg-gray-100"
                                    return <tr>
                                      <td class={`${bg} px-1 text-gray-400`}>{i+1}</td>
                                      <td class={`${bg} px-1`}>{roundIdx + 1}</td>
                                      <td class={`${bg} px-1`}>{v.home.seasonal?.teamName || v.home.team}</td>
                                      <td class={`${bg} px-1`}>{v.visitor.seasonal?.teamName || v.visitor.team}</td>
                                    </tr>
                                  })}
                                </table>
                              </div>
                            </div>
                          )
                          : null
                        }
                      </div>
                    )
                  })}
                </div>
              )})
          }
          <div class="grid gap-2 grid-cols-2">
            <div>
              {(props.teamCountForCompSeasonDivPool % 2) === 1 // only offer in "odd team count" cases
                ? (
                  <div class="my-2 p-1 border rounded-md">
                    <div class="flex items-center gap-2">
                      <input type="checkbox" v-model={props.data.updateByeField}/>
                      <div class="text-sm">Update bye games to be on the bye field</div>
                    </div>
                  </div>
                )
                : null
              }
              <Btn2 class="px-2 py-1 w-full" onClick={() => emit("saveTentativeMatchups")}>Approve Matchups</Btn2>
            </div>
            <div class="flex">
              <Btn2 class="px-2 py-1 w-full mt-auto" onClick={() => emit("cancel")}>Cancel</Btn2>
            </div>
          </div>
        </div>
      )
    }
  }
})

export interface Phase1_CreateRounds {
  competitionUID: Guid,
  seasonUID: Guid,
  divID: Guid,
  poolID: "ALL" | Integerlike
  includeGamesWithTeams: boolean,
  startDate: Datelike,
  endDate: Datelike,
  numRoundRobinCycles: Integerlike,
  roundLengthInDays: Integerlike,
}

export function Phase1_CreateRounds(args: {competitionUID: Guid, divID: Guid, seasonUID: Guid, poolID: "ALL" | Integerlike, startDate: Datelike, endDate: Datelike}) : Phase1_CreateRounds {
  return {
    seasonUID: args.seasonUID,
    competitionUID: args.competitionUID,
    divID: args.divID,
    poolID: args.poolID,
    includeGamesWithTeams: false,
    startDate: args.startDate,
    endDate: args.endDate,
    numRoundRobinCycles: 1,
    roundLengthInDays: 7,
  }
}

export interface Phase2_ConfirmRounds {
  byePosition: "first" | "last",
  balanceHomeAndAway: boolean,
  includeGamesWithExistingMatchups: boolean,
  rounds: RoundWrapper[],
  warning: string,
}

export type RoundWrapper = {
  round: Round,
  leftovers: undefined | {
    home: Team,
    visitor: Team
  }[],
  uiState: {
    busy: boolean,
  }
}

export function Phase2_ConfirmRounds(rounds: Round[], warning: string) : Phase2_ConfirmRounds {
  return {
    byePosition: "last",
    balanceHomeAndAway: true,
    includeGamesWithExistingMatchups: false,
    rounds: rounds.map(v => ({
      round: v,
      leftovers: undefined,
      uiState: {
        busy: false
      }
    })),
    warning
  }
}

export interface Phase3_ConfirmTentativeTeams {
  updateByeField: boolean,
  rounds: RoundWrapper[]
}

export function Phase3_ConfirmTentativeTeams(r: GenerateTentativeMatchupsResponse) : Phase3_ConfirmTentativeTeams {
  return {
    updateByeField: false,
    rounds: r.rounds.map(v => {
      return {
        round: v,
        leftovers: v.leftovers,
        uiState: {
          busy: false,
        }
      }
    })
  }
}
