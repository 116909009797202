<template lang="pug">
div
  FormKit.smx-auto.w-full.max-w-md(class='md:mx-2', type='form', @submit='search' :actions="false")
    h1.ml-2.text-4xl.self-end.font-medium(class='md:text-4xl')
      font-awesome-icon.mr-2(:icon='["fas", "search"]')
      | User &amp; Volunteer Lookup
    br
    p Enter a search term that is part of a user's name, email address, or phone number.
    FormKit.m-2.w-96(
      v-model='searchParam',
      placeholder='Volunteer',
      data-test='volunteerLookup',
      name='Volunteer',
      validations='required',
      type='text',
      @keyup.enter='search'
    )
    FormKit(
      v-model='localOnly',
      label="Include locally registered users from other leagues"
      name='localOnly',
      type='checkbox',
      on-value="false",
      off-value="true",
      value="true",
      outerClass='mt-2'
    )
    div
      t-btn(:margin="false" type="submit")
        | Submit
  .max-w-xl
    .q-pa-md.quasar-style-wrap.mt-8.m-2(
      v-if='searchResults.length',
      data-cy='searchResultsTable'
    )
      q-table(
        :rows='searchResults',
        :columns='columns',
        :header='false',
        row-key='individualID',
        :rows-per-page-options='[0]',
        hide-pagination
      )
        template(v-slot:body-cell-Volunteer='props')
          q-td.cursor-pointer.full-width(:props='props', @click='toVolunteerView(props.row.ID)')
            router-link.font-medium(:to="{path: `/user-editor/${props.row.ID}`}") {{ props.row.firstName }} {{ props.row.lastName }}
            .text-xs {{ props.row.email }}
        template(v-slot:body-cell-familyProfile='props')
          q-td(:props='props')
            t-btn(
              :margin="false"
              type='button',
              @click='toFamilyProfile(props.row.ID)'
            )
              .whitespace-nowrap Family Profile
        template(
          v-if='authService(roles, "Registrar", "Webmaster", "inLeague")',
          v-slot:body-cell-Impersonate='props'
        )
          q-td(:props='props')
            ImpersonateButton(:userID='props.row.ID')
    div(v-else-if='searched', data-cy='noResults')
      | {{ `There were no results for ${searchParam}.` }}
</template>

<script lang="ts">
import { computed, defineComponent, ref, Ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { axiosInstance } from 'src/boot/axios'

import ImpersonateButton from 'src/components/Admin/ImpersonateUsers/ImpersonateButton.vue'
import { UserData } from 'src/interfaces/Store/user'
import authService from 'src/helpers/authService'
import * as FamilyProfile from "src/components/FamilyProfile/pages/FamilyProfile.ilx"
import { User } from 'src/store/User'

export default defineComponent({
  name: 'VolunteerLookupPage',
  components: {
    ImpersonateButton,
  },
  setup() {
    const searchResults = ref([]) as Ref<
      {
        individualID: string
        email: string
        firstName: string
        lastName: string
      }[]
    >
    const searchParam = ref('')
    const localOnly = ref(true)
    const searched = ref(false)


    const roles = computed(() => {
      return (User.value.userData as UserData).roles
    })

    const columns = computed(() => {
      const cols = [
        {
          name: 'Volunteer',
          label: 'Volunteers',
          required: true,
          align: 'left',
          field: (volunteer: {
            individualID: string
            FirstName: string
            LastName: string
            email: string
          }) => volunteer,
          sortable: true,
          classes: 'q-table',
        },
        {
          name: 'familyProfile',
          label: 'Family Profile',
          required: true,
          align: 'left',
          field: (volunteer: {
            individualID: string
            FirstName: string
            LastName: string
            email: string
          }) => volunteer,
          sortable: true,
          classes: 'q-table',
        },
      ]
      if (authService(roles.value, 'registrar', 'webmaster', 'inLeague')) {
        cols.push({
          name: 'Impersonate',
          label: 'Impersonate',
          required: true,
          align: 'left',
          field: fields => fields,
          sortable: true,
          classes: 'q-table',
        })
      }
      return cols
    })

    const router = useRouter()

    const search = async () => {
      if (searchParam.value) {
        try {
          const response = await axiosInstance.get(
            `v1/volunteers/search?search=${searchParam.value}&localOnly=${localOnly.value}`
          )
          searchResults.value = response.data.data
          searched.value = true
        } catch (err) {
        }
      } else {
      }
    }

    const toVolunteerView = async (ID: string) => {
      await router.push({
        name: 'user-editor',
        params: { userID: ID },
      })
    }

    const toFamilyProfile = async (userID: string) => {
      await router.push(
        FamilyProfile.asRouteLocationRaw({
          name: FamilyProfile.RouteName.user,
          userID: userID
        })
      );
    }

    watch(searchParam, (newVal: string, oldVal: string) => {
      if (newVal != oldVal) {
        searched.value = false
      }
    })

    return {
      toVolunteerView,
      search,
      router,
      columns,
      searched,
      searchParam,
      localOnly,
      searchResults,
      roles,
      authService,
      toFamilyProfile,
    }
  },
})
</script>

<style>
.noMargin {
  margin: 0 !important;
}
</style>
