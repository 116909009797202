import { type AxiosInstance } from "axios"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { isAxiosErrorLike } from "./InleagueApiV1";
import { exhaustiveCaseGuard, parseFloatOr, parseIntOrFail } from "src/helpers/utils";
import { InvoiceLastStatus_t } from "./InleagueApiV1.Payments";
import type { PlayerAssignment } from "./InleagueApiV1.Teams";
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1";

export interface Tournament_Raw {
  tournamentID: iltypes.Integerlike,
  contactEmail: string,
  competitionName: string,
  competitionUID: iltypes.Guid,
  /**
   * expandable
   */
  divisions?: TournamentDivision[],
  html_tournTeamReg_splash_A: string,
  html_tournTeamReg_complete: string,
  html_tournTeamReg_regConfirmationEmail_header: string,
  html_tournTeamReg_regConfirmationEmail_footer: string,
  localTeamsSubjectToRegFee: iltypes.Numbool,
	localTeamsSubjectToHoldPaymentFee: iltypes.Numbool,
  minRequiredTournTeamRefCount: number,
  /**
   * Assuming the values received over the wire are within the valid range of this enum,
   * the type holds
   */
  playerRosterSubmissionConfig: TournTeamPlayerRosterSubmissionConfig,
  name: string,
  /**
   * Date and time that tournament registration opens (if set); local league time
   */
  registrationStart: iltypes.DateTimelike
  /**
   * Date and time that tournament registration closes (if set); local league time
   */
  registrationEnd: iltypes.DateTimelike,
  requireRefsOnSubmission: iltypes.Numbool, // munge candidate, should always be bool
  seasonName: string,
  seasonUID: iltypes.Guid,
}

export type Tournament = Tournament_Raw;

function mungeTournamentRaw(v: Tournament_Raw) : Tournament {
  assertTournTeamPlayerRosterSubmissionConfig(v.playerRosterSubmissionConfig);

  if (v.divisions) {
    v.divisions = v.divisions.map(mungeTournamentDivision)
  }
  return v;
}

export type TournamentForAdminPage =
  & iltypes.WithDefinite<Tournament, "divisions">
  & {divisions: iltypes.WithDefinite<TournamentDivision, "tournamentTeamCount">[]}

export interface TournamentCrudArgs {
  create: {
    // key
    competitionUID: iltypes.Guid,
    seasonUID: iltypes.Guid
    // rest
    registrationStart: null | iltypes.DateTimelike,
    registrationEnd: null | iltypes.DateTimelike,
    requireRefsOnSubmission: iltypes.Numbool,
    minRequiredTournTeamRefCount: iltypes.Integerlike,
    localTeamsSubjectToRegFee: iltypes.Numbool,
    localTeamsSubjectToHoldPaymentFee: iltypes.Numbool,
    html_tournTeamReg_splash_A: string,
    html_tournTeamReg_complete: string,
    html_tournTeamReg_regConfirmationEmail_header: string,
    html_tournTeamReg_regConfirmationEmail_footer: string,
    contactEmail: string,
    playerRosterSubmissionConfig: iltypes.Integerlike<TournTeamPlayerRosterSubmissionConfig>,
  }
  update: {
    // key
    tournamentID: iltypes.Integerlike,
    // rest
    registrationStart: null | iltypes.DateTimelike,
    registrationEnd: null | iltypes.DateTimelike,
    requireRefsOnSubmission: iltypes.Numbool,
    minRequiredTournTeamRefCount: iltypes.Integerlike,
    localTeamsSubjectToRegFee: iltypes.Numbool,
    localTeamsSubjectToHoldPaymentFee: iltypes.Numbool,
    html_tournTeamReg_splash_A: string,
    html_tournTeamReg_complete: string,
    html_tournTeamReg_regConfirmationEmail_header: string,
    html_tournTeamReg_regConfirmationEmail_footer: string,
    contactEmail: string,
    playerRosterSubmissionConfig: iltypes.Integerlike<TournTeamPlayerRosterSubmissionConfig>,
  }
}

export async function createTournament(axios: AxiosInstance, args: TournamentCrudArgs["create"]) : Promise<TournamentForAdminPage> {
  const response = await axios.post(`v1/tournament`, args);
  return mungeTournamentRaw(response.data.data) as any
}


export async function updateTournament(axios: AxiosInstance, args: TournamentCrudArgs["update"]) : Promise<void> {
  await axios.post(`v1/tournament/${args.tournamentID}`, args);
}

export async function getTournament(
  axios: AxiosInstance,
  args:
    | {tournamentID: iltypes.Integerlike}
    | {competitionUID: iltypes.Guid, seasonUID: iltypes.Guid}
) : Promise<TournamentForAdminPage> {
  const response = await axios.get(`v1/tournament/`, {params: args});
  return mungeTournamentRaw(response.data.data) as any;
}

export async function getTournamentsForCompetition(axios: AxiosInstance, args: {competitionUID: iltypes.Guid}) : Promise<Tournament[]> {
  const response = await axios.get(`v1/tournaments/${args.competitionUID}`);
  return response.data.data.map(mungeTournamentRaw);
}

export interface TournTeamRegPageItemMenuSeason {
  seasonUID: iltypes.Guid,
  seasonID: iltypes.Integerlike,
  seasonName: string
}

export async function getTournamentTeamRegistrationPageItemMenuSeasons(axios: AxiosInstance) : Promise<TournTeamRegPageItemMenuSeason[]> {
  const response = await axios.get(`v1/tournaments/pageItems/menuSeasons`)
  return response.data.data;
}

/**
 * A "menu competition" assumes an already-selected seasonUID,
 * so we have seasonUID -> competitionUID -> tournamentID
 */
export interface TournTeamRegPageItemMenuCompetition {
  competitionUID: iltypes.Guid,
  competitionID: iltypes.Integerlike,
  tournamentID: iltypes.Integerlike,
  competition: string
}

export async function getTournamentTeamRegistrationPageItemMenuCompetitions(axios: AxiosInstance, args: {seasonUID: iltypes.Guid}) : Promise<TournTeamRegPageItemMenuCompetition[]> {
  const response = await axios.get(`v1/tournaments/pageItems/menuCompetitions`, {params: {seasonUID: args.seasonUID}})
  return response.data.data;
}

export async function getTournamentRegistrationPageItems(axios: AxiosInstance, args: ({tournamentID: iltypes.Integerlike} | {seasonUID: iltypes.Guid, competitionUID: iltypes.Guid}) & {includeDisabled: boolean}) : Promise<TournTeamRegPageItem[]> {
  const response = await axios.get(`/v1/tournaments/pageItems`, {params: args})
  return response.data.data;
}

export async function getTournamentTeamRegistrationPageItems(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike}) : Promise<TournTeamRegPageItem[]> {
  const response = await axios.get(`/v1/tournaments/team/${args.tournamentTeamID}/registrationPageItems`);
  return response.data.data;
}

export interface TournamentRegistrationAnswer {
	answer: string,
  points: "" | iltypes.Integerlike,
	modifiedBy: iltypes.UserID,
  /**
   * expandable
  */
  question?: iltypes.RegistrationQuestion
  questionID: iltypes.Guid,
  tournamentTeamID: iltypes.Integerlike,
}

export async function getTournamentTeamRegistrationAnswers(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike}) : Promise<iltypes.WithDefinite<TournamentRegistrationAnswer, "question">[]> {
  const response = await axios.get(`/v1/tournaments/team/${args.tournamentTeamID}/registrationAnswers`);
  return response.data.data;
}

export async function submitTournamentTeamRegistrationAnswers(
  axios: AxiosInstance,
  args: {
    tournamentTeamID: iltypes.Integerlike,
    answers: {[questionID: iltypes.Guid]: string | string[] | number | boolean}}
) : Promise<void> {
  const {tournamentTeamID, ...body} = args;
  const response = await axios.post(`/v1/tournaments/team/${args.tournamentTeamID}/registrationAnswers`, body);
  return response.data.data;
}

export async function getOrCreateTournamentTeamInvoice(
  axios: AxiosInstance,
  args: {
    tournamentTeamID: iltypes.Integerlike,
  }
) : Promise<{registrationInvoice: iltypes.Invoice, holdPaymentInvoice: iltypes.Invoice}> {
  const response = await axios.post(`/v1/tournaments/team/${args.tournamentTeamID}/invoice`);
  return response.data.data;
}

export async function getTournamentRegistrationPageItemGateFunctions(axios: AxiosInstance) : Promise<{name: string, label: string}> {
  const response = await axios.get(`v1/tournaments/gateFunctions`)
  return response.data.data;
}

export interface CreateTournTeamRegQuestionPageItemArgs {
  tournamentID: iltypes.Integerlike,
  type: iltypes.QuestionType,
  label: string,
  shortLabel: string,
  isEditable: boolean,
  isRequired: boolean,
  /**
   * undefined meaning no question options for question types TEXT and TEXTAREA,
   * otherwise, an array of at least one option
   */
  questionOptions:
    | {optionValue: string, optionText:string, points: iltypes.Integerlike}[]
    | undefined
  //
  // Doing gatefunctions?
  //
  // gateFunction: null | Record<string, any>,
  // gateFunctionID: string,
  // gateFunctionName: string,
  //
}

export async function createTournTeamRegQuestionPageItem(axios: AxiosInstance, args: CreateTournTeamRegQuestionPageItemArgs) : Promise<TournTeamRegPageItem_Question> {
  const response = await axios.post(`v1/tournaments/pageItem/question`, args)
  return response.data.data;
}

export interface UpdateTournTeamRegQuestionPageItemArgs {
  pageItemID: iltypes.Integerlike,
  isDisabled: boolean,
  containedItem: {
    label: string,
    shortLabel: string,
    isEditable: boolean,
    isRequired: boolean,
    questionOptions:
      | {optionValue: string, optionText:string, points: iltypes.Integerlike}[]
      | undefined
  }
}

export async function updateTournTeamRegQuestionPageItem(axios: AxiosInstance, args: UpdateTournTeamRegQuestionPageItemArgs) : Promise<TournTeamRegPageItem_Question> {
  const response = await axios.post(`v1/tournaments/pageItem/question/${args.pageItemID}`, args)
  return response.data.data;
}

export interface CreateTournTeamRegContentPageItemArgs {
  tournamentID: iltypes.Integerlike,
  label: string,
  shortLabel: string,
  defaultText: string,
}

export async function createTournTeamRegContentChunkPageItem(
  axios: AxiosInstance,
  args: CreateTournTeamRegContentPageItemArgs,
) : Promise<TournTeamRegPageItem_ContentChunk> {
  const response = await axios.post(`v1/tournaments/pageItem/contentChunk`, args)
  return response.data.data;
}

export interface UpdateTournTeamRegContentPageItemArgs {
  pageItemID: iltypes.Integerlike,
  label: string,
  shortLabel: string,
  defaultText: string,
  order: iltypes.Integerlike,
}

export async function updateTournTeamRegContentChunkPageItem(
  axios: AxiosInstance,
  args: UpdateTournTeamRegContentPageItemArgs,
) : Promise<TournTeamRegPageItem_ContentChunk> {
  const response = await axios.post(`v1/tournaments/pageItem/contentChunk/${args.pageItemID}`, args)
  return response.data.data;
}

export async function deleteTournTeamRegQuestionPageItem(axios: AxiosInstance, pageItemID: iltypes.Integerlike) : Promise<void> {
  await axios.delete(`v1/tournaments/pageItem/${pageItemID}`);
}

interface StackResultBase {
  resultType: "unverified-stack-user" | "needs-login",
}

export type StackResult =
  | StackResult_Unverified
  | StackResult_HasInleagueAccount

export interface StackResult_Unverified extends StackResultBase {
  resultType: "unverified-stack-user",
  idNum: string,
  firstName: string,
  lastName: string,
  email: string,
}

export interface StackResult_HasInleagueAccount extends StackResultBase {
  resultType: "needs-login",
  userName: string
}

export enum TournamentTeamOfficialType {
  HEAD_COACH = 1,
  ASST_COACH = 2,
  REFEREE = 3,
}

/**
 * `tournamentTeamID` and `tournamentID` are mutually exclusive -- exactly one will be non-nullish
 */
export interface TournamentTeamOfficial {
  tournamentTeamOfficialID: iltypes.Integerlike,
  /**
   * nullish if this official is linked to "just" a tournament
   */
  tournamentTeamID: "" | iltypes.Integerlike,
  /**
   * nullish if this official is linked to a particular tournament team
   */
  tournamentID: "" | iltypes.Integerlike,
  type: TournamentTeamOfficialType
  stackSID: string,
  firstName: string,
  lastName: string,
  ref_maxCR: "" | iltypes.Integerlike,
  ref_maxAR: "" | iltypes.Integerlike,
  comments: string,
  /**
   * empty string if there's not an associated inLeague user; otherwise, some userID
   * Generally there is always an associated user account, but that account may be "unclaimed"
   */
  userID: "" | iltypes.Guid,
  /**
   * whether the associated user account is unclaimed
   * (we've asked the user by way of their ayso email address to create an inleague account, but they haven't done so yet)
   * Nullish if there is no associated user account.
   */
  user_unclaimed: "" | iltypes.Numbool
  /**
   * Some endpoints return this. Computed from a few joins, but it might be inexpensive enough return all the time.
   * It's not possible to receive this if the official is "unaffiliated" (in which case there's no associated team, and so there's no designation)
   */
  teamDesignationComponents?: {
    teamRegion: Integerlike,
    teamNumber: Integerlike,
    divNum: string,
    divGender: string,
  }
}

export type TournTeamPlayerLink =
  | TournTeamPlayerLink_AreaPlayer
  | TournTeamPlayerLink_PlayerAssignment

export type TournTeamPlayerID = Pick<TournTeamPlayerLink_Base, "type" | "typeID">

export interface TournTeamPlayerLink_Base {
  type: "areaPlayer" | "playerAssignment"
  /**
   * `typeID` serves as a unique identifier for a player link ONLY when coupled with the type.
   * so (<typeID>) is not unique, but (<typeID>, "areaPlayer") is a unique identifier.
   */
  typeID: string,
  tournamentTeamID: iltypes.Integerlike,
  tournamentID: iltypes.Integerlike,
  aysoID: string,
}

export interface TournTeamPlayerLink_AreaPlayer extends TournTeamPlayerLink_Base {
  type: "areaPlayer",
  areaPlayer: AreaPlayer
}

export interface TournTeamPlayerLink_PlayerAssignment extends TournTeamPlayerLink_Base {
  type: "playerAssignment",
  playerAssignment:
    & PlayerAssignment
    & {
      firstName: string,
      lastName: string
    },
}

/**
 * A player record that serves as a substitute for an inleague `Child` record,
 * when the player is from a foreign league.
 */
export interface AreaPlayer {
  /**
   * PK for this record. Note that this is _not_ an FK to an inleague child record.
   */
  playerID: iltypes.Guid,

  /**
   * The tuple (aysoID, teamID, seasonID) is expected (but not yet enforced ...) to be globally unique,
   * such that there is exactly zero-or-one player registration per ayso player per (team, season).
   */
  aysoID: string,
  /**
   * Note: Use `aysoID`, it represents the same thing. This may change in the future.
   */
  __stackSID__useAysoID: [never],
  seasonID: iltypes.Integerlike,
  teamID: iltypes.Guid,

  birthCert: iltypes.Numbool,
  clientID: iltypes.Guid,
  dob: iltypes.Datelike,
  firstName: string,
  lastName: string,
  phone: string,
  team: string,
  uniform: string,
}

export interface TournamentTeam {
  tournamentTeamID: iltypes.Integerlike,
  /**
   * expandable
   */
  admin?: TournamentTeamAdmin[],
  /**
   * The sum of the points associated with this tournament team's registration question answers.
   * This is provided depending on the endpoint caller's permissions; only registrars will receive this value.
   */
  answerPointsSum?: number,
  /**
   * This is not "expandable" but is present from some endpoints.
   */
  areaTeam?: AreaTeam,
  competitionUID: iltypes.Guid,
  dateCreated: iltypes.Datelike,
  /**
   * The hold payment fee as of time-of-invoice-creation
   * This supports adjusting the fee downwards while retaining information about the initial value to allow returning to it if necessary.
   * If there is no current holdpayment invoice, this is nullish.
   */
  fee_holdPayment: "" | number,
  hasDeclaredIntentToPayByCheck: iltypes.Numbool,
  invoiceInstanceID_registration: "" | iltypes.Integerlike,
  invoiceInstanceID_holdPayment: "" | iltypes.Integerlike,
  localityDescriptor: "local" | "foreign",
  minRequiredTournTeamRefCount: number,
  modifiedBy: iltypes.Guid,
  /** expandable */
  officials?: TournamentTeamOfficial[],
  /**
   * n.b. all `payViaCheck_*` fields should falsy, or all should be truthy.
   * emptystring or some checkno.
   */
  payViaCheck_checkNo: string,
  /**
   * emptystring or some comments.
   */
  payViaCheck_comments: string,
  /**
   * emptystring or some userID.
   */
  payViaCheck_submittedBy: "" | iltypes.Guid,
  /**
   * emptystring or some datetime
   */
  payViaCheck_submittedOn: "" | iltypes.DateTimelike,
  /** expandable */
  playerLinks?: TournTeamPlayerLink[],
  region: iltypes.Integerlike,
  seasonUID: iltypes.Guid,
  status:
    | "PENDING"
    | "PAID_AWAITING_APPROVAL"
    | "APPROVED"
    | "DROPPED_BY_SUBMITTER"
    | "DROPPED_BY_HOST",
  submitterID: iltypes.Guid,
  /**
   * expandable
   */
  team?: iltypes.Team,
  teamID: iltypes.Guid,
  tournamentID: iltypes.Integerlike,
  tournament_playerRosterSubmissionConfig: TournTeamPlayerRosterSubmissionConfig,
}

export interface TournamentTeamAdmin {
  tournamentAdminID: iltypes.Integerlike,
	tournamentTeamID: iltypes.Integerlike,
	userID: iltypes.Guid,
	title: string,
	dateCreated: iltypes.DateTimelike,
  firstName: string,
  lastName: string,
}

interface CreateTournamentTeamArgsBase {
  /**
   * We were once a tagged union based on submitter type, i.e. if they were some foreign or local user
   * But that's not really a thing any more, and this can most likely be deleted; both variants are the same.
   * To create a "local" team, use the region ID for "this" league; to create a "foreign" team, use any (valid) regionID
   * other than the one for "this" league.
   * @deprecated
   */
  type: "foreign" | "local"
  tournamentID: iltypes.Integerlike,
  divID: iltypes.Guid,
  teamName: string,
  teamCity: string,
  teamState: string,
  int_region: iltypes.Integerlike,
}

/**
 * There was once a conceptual difference between "foreign" and "local" users
 * and the form shape for each, but this difference doesn't really exist any more
 */
export type CreateTournamentTeamArgs = CreateTournamentTeamArgsBase

export async function getTournamentTeam(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike, expand?: ("admin" | "playerLinks" | "officials")[]}) : Promise<TournamentTeamFromListTournamentTeamsEndpoint> {
  const response = await axios.get(`v1/tournaments/team/${args.tournamentTeamID}`, {params: {expand: args.expand}});
  return mungeTournamentTeamFromListTournamentTeamsEndpoint(response.data.data)
}

export async function createTournamentTeam(axios: AxiosInstance, args: CreateTournamentTeamArgs) : Promise<TournamentTeamFromListTournamentTeamsEndpoint> {
  const response = await axios.post(`v1/tournaments/team`, args);
  return mungeTournamentTeamFromListTournamentTeamsEndpoint(response.data.data);
}


export async function listTournaments(axios: AxiosInstance, args: {seasonUID: iltypes.Guid}) : Promise<Tournament[]> {
  const response = await axios.get(`v1/tournaments`, {params: args});
  return response.data.data.map(mungeTournamentRaw)
}

/**
 * There may at some point be multiple of these but this is the only known shape at this time
 * We munge out particulars because teamletter is freeform text, but with some structured meaning based on arbitrary rules.
 * We endeavor to encode those particular rules in the "type" field here.
 */
export type MungedTeamLetter = {type: "region-and-id", raw: string, region: string, id: string}

export type TournamentTeamFromListTournamentTeamsEndpoint =
  & iltypes.WithDefinite<TournamentTeam, "areaTeam" | "admin" | "team">
  & {
    div_divNum: iltypes.Integerlike,
    div_gender: string,
    /**
     * This is potentially structured text that may need to be decomposed.
     * For a {destructured, decomposed, post-processed, munged} team letter see `mungedTeamLetter`.
     */
    team_teamLetter: string,
    /**
     * Clientside-only property
     */
    mungedTeamLetter: MungedTeamLetter,
    invoice_holdPayment_lastStatus: InvoiceLastStatus_t,
    /**
     * Represents the current amount on the ref hold payment invoice;
     * this may differ from (but should never be greater than) TournamentTeam.fee_holdPayment,
     * see comments on TournamentTeam.fee_holdPayment for details.
     */
    invoice_holdPayment_amount: "" | number,
    coachTournTeamOfficials: {
      tournamentTeamOfficialID: iltypes.Integerlike,
      title: iltypes.CoachTitle,
      teamID: iltypes.Guid,
      tournamentTeamID: iltypes.Integerlike,
      userID: iltypes.Guid,
      firstName: string,
      lastName: string,
      /**
       * if at least one CoachAssignment exists for this record
       */
      hasSomeCoachAssignment: iltypes.Numbool
    }[]
  }

function mungeTournamentTeamFromListTournamentTeamsEndpoint(v: TournamentTeamFromListTournamentTeamsEndpoint) : TournamentTeamFromListTournamentTeamsEndpoint {
  v.mungedTeamLetter = mungeTeamLetter(v.team_teamLetter);
  return v;
}

function mungeTeamLetter(raw: string) : MungedTeamLetter {
  const regionAndIdMatch = /^(\d+)-(\d+)$/.exec(raw)
  if (regionAndIdMatch) {
    return {
      type: "region-and-id",
      raw,
      region: regionAndIdMatch[1],
      id: regionAndIdMatch[2],
    }
  }
  else {
    // fallback for bad match case, maybe we can do better?
    // Really we should find out why the expected format wasn't matched, probably a bug or an unhandled pattern.
    return {
      type: "region-and-id",
      raw,
      region: raw,
      id: raw,
    }
  }
}

/**
 * Parameterized only by `seasonUID`, but note that teams are unique to a tournament, which is itself unique by (season, comp)
 * However, there are not expected to be a large number of teams per season (as in, across all competitions for that season),
 * so pulling all by season, and then filtering locally is the supported use case.
 */
export async function listTournamentTeams(axios: AxiosInstance, args: {seasonUID: iltypes.Guid}) : Promise<TournamentTeamFromListTournamentTeamsEndpoint[]> {
  const response = await axios.get(`v1/tournaments/teams`, {params: args});
  return response.data.data.map(mungeTournamentTeamFromListTournamentTeamsEndpoint);
}

export async function listTournamentTeamAdmins(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike}) : Promise<TournamentTeamAdmin[]> {
  const response = await axios.get(`v1/tournaments/team/${args.tournamentTeamID}/admins`)
  return response.data.data;
}

export async function createTournamentTeamAdmins(
  axios: AxiosInstance,
  args: {
    tournamentTeamID: iltypes.Integerlike,
    each: {userID: iltypes.Guid, title: string}[]
}) : Promise<TournamentTeamAdmin[]> {
  const response = await axios.post(`v1/tournaments/teamAdmins`, args)
  return response.data.data;
}

export async function deleteTournamentTeamAdmins(
  axios: AxiosInstance,
  args: {
    tournamentTeamID: iltypes.Integerlike,
    userIDs: iltypes.Guid[]
}) : Promise<any> {
  const cdl_userIDs = args.userIDs.join(",");
  const response = await axios.delete(`v1/tournaments/teamAdmins`, {params: {tournamentTeamID: args.tournamentTeamID, userIDs: cdl_userIDs}})
  return response.data.data;
}

type CandidacyVariant =
  | {candidate: true, warning?: string}
  | {candidate: false, error: string}

export interface PlayerCandidate {
  stackSID: string, // loop through from request
  playerFirstName: string, // as per ayso lookup
  playerLastName: string, // as per ayso lookup
  /**
   * The only difference here between the two variants is that 'foreignTeam' has exactly one result,
   * whereas 'localTeam' can have 1+.
   */
  detail:
    | {
      type: "foreignTeam"
      candidacy: CandidacyVariant
    }
    | {
      type: "localTeam",
      // local teams must match existing players
      // if this list is empty, there were no matches
      // otherwise, we get all matching players, each indicating their candidacy (not all will be candidates, but it may be helpful to see the full list in order to orient users).
      // We can get multiple because (aysoID, lastName) is not a guaranteed unique identifier, although typically it appears
      // that they identify the same "logical" player (i.e. the same actual human being in reality), even if there are multiple db rows for the same.
      inLeagueCandidates: (
        & {childID: iltypes.Guid}
        & CandidacyVariant
      )[]
    }
}

/**
 * Returns null if nothing is found.
 */
export async function getPlayerCandidateByStackSID(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike, stackSID: string, lastName: string}) : Promise<PlayerCandidate | null> {
  try {
    const response = await axios.get(`v1/tournaments/team/${args.tournamentTeamID}/candidates/player`, {params: args})
    return response.data.data;
  }
  catch (err) {
    // a 404 is re-represented as returning null, otherwise rethrow
    if (isAxiosErrorLike(err) && err.response.status === 404) {
      return null;
    }
    else {
      throw err;
    }
  }
}

export interface TournamentTeamOfficialCandidate {
  stackSID: string,
  type: TournamentTeamOfficialType,
  firstName: string,
  lastName: string,
  /**
   * depending on permissions, a candidate that would otherwise be "not a candidate" may arrive as a candidate but with a warning rather than an error.
   * This will be provided to those users who are allowed to choose to disregard the warning and treat the the result as a candidate (generally this means
   * force-overriding duplicate official assignment checks for some tournament).
   * Otherwise, the user receive receives "isCandidate=false" and an error message.
   */
  candidacy:
    | {isCandidate: true, warning?: string}
    | {isCandidate: false, error: string}
  /**
   * If this field is present, then it is non-empty.
   * If this field is present, then it means there was 1 or more matching inleague user for this lookup.
   * Typically, if there are matches, there will be exactly 1. But, it is possible that multiple users match a single AYSOID, typically
   * among a family where they are sharing an AYSOID.
   */
  matchingInLeagueUsers?: {firstName: string, lastName: string, email: string, userID: iltypes.Guid}[]
}


/**
 * childID is required when adding a player to a "local" team
 */
export async function addTournamentTeamPlayerByStackSID(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike, stackSID: string, lastName: string, uniform: string, childID?: iltypes.Guid}) : Promise<TournTeamPlayerLink> {
  const response = await axios.post(`v1/tournaments/team/${args.tournamentTeamID}/areaPlayer`, args)
  return response.data.data;
}

export async function updateTournTeamPlayer(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike, playerID: TournTeamPlayerID, uniform: string}) : Promise<void> {
  const flatArgs = {
    type: args.playerID.type,
    typeID: args.playerID.typeID,
    tournamentTeamID: args.tournamentTeamID,
    uniform: args.uniform
  }
  await axios.post(`v1/tournaments/team/${args.tournamentTeamID}/areaPlayer/${args.playerID}`, flatArgs)
}

export async function removeAreaPlayerFromForeignTournamentTeamByStackSID(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike, playerID: TournTeamPlayerID}) : Promise<void> {
  await axios.delete(`v1/tournaments/team/${args.tournamentTeamID}/areaPlayer`, {params: {type: args.playerID.type, typeID: args.playerID.typeID}});
}

/**
 * Returns null if nothing is found.
 */
export async function getTournamentTeamOfficialCandidateByStackSID(axios: AxiosInstance, args: {binding: TournamentTeamOfficialBinding, stackSID: string, lastName: string, officialType: TournamentTeamOfficialType}) : Promise<TournamentTeamOfficialCandidate | null> {
  try {
    const {binding, ...rest} = args;

    const params = {
      ...rest,
      ...justEntityIdFromTournTeamOfficialBinding(binding)
    }

    const response = await axios.get(`v1/tournaments/official/candidate`, {params})

    return {
      ...response.data.data,
      // this is "definitely integer", though it may be serialized to us as a string
      type: parseIntOrFail(response.data.data.type)
    }
  }
  catch (err) {
    // a 404 is re-represented as returning null, otherwise rethrow
    if (isAxiosErrorLike(err) && err.response.status === 404) {
      return null;
    }
    else {
      throw err;
    }
  }
}

/**
 * A tournament team official is EXPLICITLY bound either to a tournamentTeam or to a tournament
 * Naturally, an official bound to a tourn team is transitively bound to that team's tournament, but officials
 * explicitly bound to "just" a tournament are "loose" for that tournament.
 *
 * Currently, a binding of "tournamentID" is only supported in conjunction with the REFEREE official type. i.e., coaches
 * can only be bound to a tournTeam, while refs can be bound to a tournteam or loose bound to 'just' a tournament.
 */
export type TournamentTeamOfficialBinding =
  | {type: "tournamentTeam", tournamentTeamID: iltypes.Integerlike}
  | {type: "tournament", tournamentID: iltypes.Integerlike}

function justEntityIdFromTournTeamOfficialBinding(binding: TournamentTeamOfficialBinding) : Omit<TournamentTeamOfficialBinding, "type"> {
  if (binding.type === "tournamentTeam") {
    return {tournamentTeamID: binding.tournamentTeamID}
  }
  else if (binding.type === "tournament") {
    return {tournamentID: binding.tournamentID}
  }
  else {
    exhaustiveCaseGuard(binding)
  }
}

export interface CreateTournamentTeamOfficalArgs {
  binding: TournamentTeamOfficialBinding,
  stackSID: string,
  lastName: string,
  officialType: TournamentTeamOfficialType,
  maxAR?: iltypes.Integerlike,
  maxCR?: iltypes.Integerlike,
  comments: string | undefined,
  /**
   * if lookup by AysoID yielded a userID (or userIDs), loop it through to immediately bind the (aysoID, userID) to the generated record,
   * rather than just (aysoID) as would otherwise be done in the typical case of "we found an ayso user but didn't find an inleague user"
   */
  selectedUserIdIfMatchingInleagueUser: iltypes.Guid | undefined
  /**
   * Note: This only applies in the REFEREE case at this time.
   * if lookup by AysoID yielded no matching inLeague users, submission will require a home region to link to the user that will be generated
   * upon submission.
   */
  selectedRegionIfWillBeGeneratingUser: iltypes.Integerlike | undefined,
}

export async function createTournamentTeamOfficialByStackSID(axios: AxiosInstance, args: CreateTournamentTeamOfficalArgs) : Promise<TournamentTeamOfficial> {
  const {binding, ...rest} = args;
  const body = {...rest, ...justEntityIdFromTournTeamOfficialBinding(binding)}
  const response = await axios.post(`v1/tournaments/official/`, body)
  return response.data.data;
}

export type UpdateTournamentTeamOfficalArgs =
  | {
    tournamentTeamOfficialID: iltypes.Integerlike,
    officialType: TournamentTeamOfficialType.REFEREE
    comments: string,
    maxCR: null | iltypes.Integerlike,
    maxAR: null | iltypes.Integerlike,
  }
  | {
    tournamentTeamOfficialID: iltypes.Integerlike,
    officialType: TournamentTeamOfficialType.HEAD_COACH | TournamentTeamOfficialType.ASST_COACH
    comments: string,
  }

export async function updateTournamentTeamOfficial(axios: AxiosInstance, args: UpdateTournamentTeamOfficalArgs) : Promise<TournamentTeamOfficial> {
  const response = await axios.put(`v1/tournaments/official/${args.tournamentTeamOfficialID}`, args)
  return response.data.data;
}

export async function deleteTournamentTeamOfficialByTournamentTeamOfficialID(axios: AxiosInstance, args: {tournamentTeamOfficialID: iltypes.Integerlike}) : Promise<void> {
  await axios.delete(`v1/tournaments/official/${args.tournamentTeamOfficialID}`);
}

export interface TournamentDivision {
  tournamentDivisionID: iltypes.Integerlike,
  tournamentID: iltypes.Integerlike,
  divID: iltypes.Guid,
  divName: string,
  divNum: iltypes.Integerlike,
  fee: number | "",
  gender: string,
  holdPaymentFee: number | "",
  maxTeams: number | "",
  minRequiredTournTeamRefCount: number | "",
  /**
   * Not present from all endpoints. Those that expose it should strip away optionality.
   */
  tournamentTeamCount?: number
}

function mungeTournamentDivision(v: Record<string, any>) : TournamentDivision {
  v.fee = parseFloatOr(v.fee, "");
  v.maxTeams = parseFloatOr(v.maxTeams, "");

  if (v.tournamentTeamCount !== undefined) {
    v.tournamentTeamCount = parseIntOrFail(v.tournamentTeamCount);
  }

  return v as TournamentDivision;
}

export async function getTournamentDivisions(axios: AxiosInstance, args: {tournamentID: iltypes.Integerlike}) : Promise<TournamentDivision[]> {
  const response = await axios.get(`v1/tournaments/${args.tournamentID}/divisions`)
  return response.data.data.map((v: any) => mungeTournamentDivision(v));
}

export interface CreateOrUpdateTournamentDivisionsArgs {
  tournamentID: iltypes.Integerlike,
  divDefs: {
    divID: iltypes.Guid,
    fee: number | "",
    holdPaymentFee: number | "",
    maxTeams: iltypes.Integerlike | ""
    minRequiredTournTeamRefCount: iltypes.Integerlike | "",
  }[]
}

export async function createOrUpdateTournamentDivisions(
  axios: AxiosInstance,
  args: CreateOrUpdateTournamentDivisionsArgs
) : Promise<TournamentDivision[]> {
  const response = await axios.post(`v1/tournaments/${args.tournamentID}/divisions`, args);
  return response.data.data.map(mungeTournamentDivision)
}

export async function deleteTournamentDivisions(
  axios: AxiosInstance,
  args: {
    tournamentID: iltypes.Integerlike,
    divIDs: iltypes.Guid[]
  }
) : Promise<void> {
  await axios.delete(`v1/tournaments/${args.tournamentID}/divisions`, {params: {divIDs: args.divIDs}});
}

export interface AreaTeam {
  teamname: string,
	teamcalc: string,
	teamCity: string,
	teamState: string,
	teamSection: string,
	teamRegion: iltypes.Integerlike,
	teamArea: string,
}

export async function approveTournamentTeam(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike}) : Promise<TournamentTeam> {
  const response = await axios.post(`v1/tournaments/team/${args.tournamentTeamID}/approve`);
  return response.data.data;
}

export async function dropTournamentTeam(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike}) : Promise<TournamentTeam> {
  const response = await axios.post(`v1/tournaments/team/${args.tournamentTeamID}/drop`);
  return response.data.data;
}

export async function getCreateTournamentTeamCandidateSeasons(axios: AxiosInstance) : Promise<iltypes.Season[]> {
  const response = await axios.get(`v1/tournaments/team/candidates/seasons`);
  return response.data.data;
}

export async function getCreateTournamentTeamCandidateCompetitions(axios: AxiosInstance, args: {seasonUID: iltypes.Guid}) : Promise<iltypes.Competition[]> {
  const response = await axios.get(`v1/tournaments/team/candidates/competitions`, {params: args});
  return response.data.data;
}

export async function getCreateTournamentTeamCandidateDivisions(axios: AxiosInstance, args: {tournamentID: iltypes.Integerlike}) : Promise<iltypes.Division[]> {
  const response = await axios.get(`v1/tournaments/team/candidates/divisions`, {params: args});
  return response.data.data;
}

export type TournTeamRegPageItem =
  | TournTeamRegPageItem_Question
  | TournTeamRegPageItem_ContentChunk

interface TournTeamRegPageItemBase {
  /**
   * ID of the pageitem; this is different than the ID of the contained item, which has its own ID
   */
  id: iltypes.Integerlike,
  tournamentID: iltypes.Integerlike,
  type: iltypes.PageItemType;
  order: iltypes.Integerlike;
  isDisabled: iltypes.Numbool;
  containedItem:
    | iltypes.RegistrationQuestion
    | iltypes.ContentChunk;
}

/**
 * A registration page item which contains a RegistrationQuestion
 */
export interface TournTeamRegPageItem_Question extends TournTeamRegPageItemBase {
  type: iltypes.PageItemType.QUESTION,
  /**
   * the questionID of the contained item
   */
  questionID: iltypes.Guid
  containedItem: iltypes.RegistrationQuestion,
}

/**
 * A registration page item which contains a ContentChunk
 */
export interface TournTeamRegPageItem_ContentChunk extends TournTeamRegPageItemBase {
  type: iltypes.PageItemType.CONTENT_CHUNK,
  contentID: iltypes.Integerlike,
  containedItem: iltypes.ContentChunk,
}

export async function reorderTournTeamRegPageItems(axios: AxiosInstance, args: {tournamentID: iltypes.Integerlike, moveThisIndex: iltypes.Integerlike, priorToThisIndex: iltypes.Integerlike}) : Promise<TournTeamRegPageItem[]> {
  const response = await axios.post(`v1/tournaments/${args.tournamentID}/pageItems/reorder`, args);
  return response.data.data;
}

export async function payViaCheck(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike, checkNo: string, comments: string}) : Promise<TournamentTeamFromListTournamentTeamsEndpoint> {
  const response = await axios.post(`v1/tournaments/team/${args.tournamentTeamID}/pay-via-check`, args);
  return mungeTournamentTeamFromListTournamentTeamsEndpoint(response.data.data);
}

export async function forceUpdatePayViaCheck(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike, checkNo: string, comments: string}) : Promise<TournamentTeamFromListTournamentTeamsEndpoint> {
  const response = await axios.put(`v1/tournaments/team/${args.tournamentTeamID}/pay-via-check`, args);
  return mungeTournamentTeamFromListTournamentTeamsEndpoint(response.data.data);
}

export interface NonAuthoritativeAysoIdClaimTokenResponse {
  /**
   * request arg looped back
   */
  aysoID: string,
  /**
   * request arg looped back
   */
  lastName: string,
  /**
   * The "non authoritative token"
   */
  jwt: string,
  /**
   * The email on file for this user as per the retrieved ayso record.
   * There may be many associated email addrs, but the backend currently only guarantees retrieving
   * one of the following:
   *  a) The first email in the supplied list of emails from ayso
   *  b) Nothing, if the list is empty or the value retrieved via (a) is not a valid email addr (as per cf `isvalid("email", ...)` checks)
   */
  ayso_email?: string,
  /**
   * If the lookup matches exactly a single inLeague user, this is that user's email addr, mostly
   * useful for populating a login modal.
   */
  inLeague_email?: string
}

/**
 * might be better off in a .user or .public.user module or thereabouts, but this is the only place we use it so it's good for now.
 */
export async function getNonAuthoritativeAysoIdClaimToken_public(axios: AxiosInstance, args: {aysoID: string, lastName: string}) : Promise<NonAuthoritativeAysoIdClaimTokenResponse> {
  const response = await axios.get(`public/tournaments/nonAuthoritativeAysoIdClaimToken`, {params: args})
  return response.data.data;
}

export type TournamentTeamRosterMenuSeason = Pick<iltypes.Season, "seasonUID" | "seasonID" | "seasonName">

export async function getTournamentTeamRosterMenuSeasons(axios: AxiosInstance) : Promise<TournamentTeamRosterMenuSeason[]> {
  const response = await axios.get(`v1/tournaments/rosters/menuSeasons`)
  return response.data.data;
}

export async function getTournamentTeamRosterMenuSeasons_public(axios: AxiosInstance, nonAuthoritativeAysoIdClaimToken: string) : Promise<TournamentTeamRosterMenuSeason[]> {
  const response = await axios.get(`public/tournaments/rosters/menuSeasons`, {params: {nonAuthoritativeAysoIdClaimToken}})
  return response.data.data;
}

export type TournamentTeamRosterMenuCompetition = Pick<iltypes.Competition, "competitionUID" | "competitionID" | "competition">;

export async function getTournamentTeamRosterMenuCompetitions(axios: AxiosInstance, args: {seasonUID: iltypes.Guid}) : Promise<TournamentTeamRosterMenuCompetition[]> {
  const response = await axios.get(`v1/tournaments/rosters/menuCompetitions`, {params: args})
  return response.data.data;
}

export async function getTournamentTeamRosterMenuCompetitions_public(axios: AxiosInstance, args: {seasonUID: iltypes.Guid}, nonAuthoritativeAysoIdClaimToken: string) : Promise<TournamentTeamRosterMenuCompetition[]> {
  const response = await axios.get(`public/tournaments/rosters/menuCompetitions`, {params: {...args, nonAuthoritativeAysoIdClaimToken}})
  return response.data.data;
}

export type TournamentTeamRoster =
  & iltypes.WithDefinite<TournamentTeam, "officials">
  & {players: {firstName: string, lastName: string, dob: iltypes.Datelike}[]}

export async function getTournamentTeamRosters(axios: AxiosInstance, args: {seasonUID: iltypes.Guid, competitionUID: iltypes.Guid}) : Promise<TournamentTeamRoster[]> {
  const response = await axios.get(`/v1/tournaments/rosters`, {params: {seasonUID: args.seasonUID, competitionUID: args.competitionUID}})
  return response.data.data;
}

export async function getTournamentTeamRosters_public(axios: AxiosInstance, args: {seasonUID: iltypes.Guid, competitionUID: iltypes.Guid}, nonAuthoritativeAysoIdClaimToken: string) : Promise<TournamentTeamRoster[]> {
  const response = await axios.get(`public/tournaments/rosters`, {params: {...args, nonAuthoritativeAysoIdClaimToken}})
  return response.data.data;
}

/**
 * This completes the registration journey for those users who opt to pay by check. They will receive an email that they are complete, with the exception of providing
 * their check to the league representative.
 */
export async function markIntentToPayByCheckCompletingTheRegistrationJourney(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike}) : Promise<void> {
  await axios.post(`v1/tournaments/team/${args.tournamentTeamID}/intentToPayByCheck`);
}

export interface TournamentTeamOverviewReport_Ref {
  tournamentTeamID: "" | iltypes.Integerlike,
  tournamentID: "" | iltypes.Integerlike,
  tournamentTeamOfficialID: iltypes.Integerlike,
  firstName: string,
  lastName: string,
  ref_maxCR: "" | iltypes.Integerlike,
  ref_maxAR: "" | iltypes.Integerlike,
  comments: string,
  createdOn: iltypes.DateTimelike,
  /**
   * Prefer `user.email` when available, but this can serve as a fallback.
   */
  email: string,
  /**
   * Will always be empty for an "unaffiliated" ref
   * Otherwise, will be zero-or-more referee assignments (is it always actually zero-or-one?)
   * We duplicatively include userID in this structure in order to state that if this is non-empty then
   * we expect user to be non-null, too.
   */
  refereeAssignments: {assignmentID: iltypes.Guid, userID: iltypes.Guid}[]
  user: null | {
    userID: iltypes.Guid,
    email: string,
    phone: string,
    RefereeGrade: string,
    RiskStatus: string,
    RiskStatusCode: string,
    RiskStatusExpiration: string,
  }
}

export type TournamentTeamOverviewReport_Coach = {
  tournamentTeamID: iltypes.Integerlike,
  tournamentTeamOfficialID: iltypes.Integerlike,
  comments: string,
  createdOn: iltypes.DateTimelike,
  officialType:
    | TournamentTeamOfficialType.HEAD_COACH
    | TournamentTeamOfficialType.ASST_COACH,
  assignment:
    | {
      type: "coachAssignment",
      userID: iltypes.Guid,
      firstName: string,
      lastName: string,
      email: string,
      phone: string,
      RiskStatus: string,
      RiskStatusCode: string,
      RiskStatusExpiration: string,
    }
    | {
      type: "areaCoach",
      firstName: string,
      lastName: string,
      email: string,
      phone: string,
    }
}

export type TournamentTeamOverviewReport_Team =
  & iltypes.WithDefinite<TournamentTeam, "answerPointsSum">
  & {
    areaTeamName: string,
    tournamentQuestionLinks: {
      label: string,
      questionID: string,
      answers: TournamentRegistrationAnswer[]
    }[]
    playerCount: number,
    tournamentTeamOfficialCoaches: TournamentTeamOverviewReport_Coach[],
    tournamentTeamOfficialReferees: TournamentTeamOverviewReport_Ref[],
    coachCount: number,
    competition: string,
    competitionID: number,
    submitterEmail: string,
    submitterFirstName: string,
    submitterLastName: string,
    div_divID: iltypes.Guid,
    div_divNum: iltypes.Integerlike,
    div_gender: string,
    team_region: iltypes.Integerlike,
    /**
     * raw value, generally structured text
     */
    team_teamLetter: string,
    /**
     * Client-side only value, munged out of `team_teamLetter`
     */
    mungedTeamLetter: MungedTeamLetter
  }

export type TournamentTeamOverviewReport = {
  unaffiliatedRefs: TournamentTeamOverviewReport_Ref[],
  tournamentTeams: TournamentTeamOverviewReport_Team[],
  refAssignmentsByUserID: {[userID: Guid]: /*assignmentID*/Guid[] | undefined}
}

function mungeTournamentOverviewReport(v: TournamentTeamOverviewReport_Team) : TournamentTeamOverviewReport_Team {
  v.mungedTeamLetter = mungeTeamLetter(v.team_teamLetter);
  return v;
}

/**
 * Get the list of seasons for which it makes sense to offer the current user in a "choose season to show the tourn team seasonal overview"
 */
export async function getTournamentOverviewReportMenu(axios: AxiosInstance) : Promise<iltypes.MenuTreeDef<"seasonUID" | "tournamentID", {tournamentID: {competitionName: string, competitionUID: Guid}}>> {
  const response = await axios.get(`v1/tournaments/reports/seasonal/menu`);
  return response.data.data;
}

/**
 * for some season, get all the tournament overview reports. The results will have been filtered by current user permission.
 */
export async function getTournamentOverviewReport(axios: AxiosInstance, args: {tournamentID: iltypes.Integerlike}) : Promise<TournamentTeamOverviewReport> {
  const response = await axios.get(`v1/tournaments/reports/${args.tournamentID}`);
  const report : TournamentTeamOverviewReport = response.data.data;
  report.tournamentTeams = report.tournamentTeams.map(mungeTournamentOverviewReport);
  return report;
}

/**
 * Leaf levels (`tournamentTeamID`) may contain the ALL sentinel value, in which case, they WILL have a tournamentTeamID value
 * We could choose to include it on every tournamentTeamID payload, but that's sort of wasteful (though this decision itself might just be premature optimization).
 */
export type TournTeamRefManagerMenu = iltypes.MenuTreeDef<
  "seasonUID" | "competitionUID" | "tournamentTeamID",
  {
    tournamentTeamID: {
      // present IFF the entityID for the level is the ALL sentinel
      tournamentID?: iltypes.Integerlike
    }
  },
  "all-affiliated" | "unaffiliated"
>
export async function getTournTeamRefManagerMenu(axios: AxiosInstance) : Promise<TournTeamRefManagerMenu> {
  const response = await axios.get(`v1/tournaments/menu/tournamentTeamRefManager`)
  return response.data.data;
}

export async function listTournamentTeamOfficials(axios: AxiosInstance, args: {allAffiliatedRefs: true, tournamentID: Integerlike} | {binding: TournamentTeamOfficialBinding}) : Promise<TournamentTeamOfficial[]> {
  if ("allAffiliatedRefs" in args) {
    const params = args
    const response = await axios.get(`v1/tournaments/officials/list`, {params})
    return response.data.data;
  }
  else {
    const params = justEntityIdFromTournTeamOfficialBinding(args.binding)
    const response = await axios.get(`v1/tournaments/officials/list`, {params})
    return response.data.data;
  }
}

export enum TournTeamPlayerRosterSubmissionConfig {
  allowed = 1,
  disabled = 2,
}

function assertTournTeamPlayerRosterSubmissionConfig(v: any) : asserts v is TournTeamPlayerRosterSubmissionConfig {
  const unsafe_v = v as TournTeamPlayerRosterSubmissionConfig;
  switch (unsafe_v) {
    case TournTeamPlayerRosterSubmissionConfig.allowed:
    case TournTeamPlayerRosterSubmissionConfig.disabled:
      return;
    default:
      exhaustiveCaseGuard(unsafe_v)
  }
}

export async function updateTournamentTeamHoldPaymentInvoiceFee(axios: AxiosInstance, args: {tournamentTeamID: iltypes.Integerlike, fee: number}) : Promise<void> {
  await axios.put(`v1/tournaments/team/${args.tournamentTeamID}/refHoldPayment`, {fee: args.fee});
}

export async function isAuthorizedToManageTournamentTeams(axios: AxiosInstance, args: {tournamentTeamIDs: iltypes.Integerlike[]}) : Promise<{[tournamentTeamID: iltypes.Integerlike]: 0 | 1}> {
  const response = await axios.get(`v1/tournaments/authZ/tournamentTeams`, {params: {tournamentTeamIDs: args.tournamentTeamIDs.join(",")}})
  return response.data.data;
}
