import { FormKit } from "@formkit/vue";
import { axiosAuthBackgroundInstance } from "src/boot/AxiosInstances";
import { xlsxExport_coachTeamAssignments_apiPath, xlsxExport_games_apiPath, xlsxExport_menu_getCompetitionSeason, xlsxExport_menu_getCompetitions, xlsxExport_menu_getSeasons, xlsxExport_player_apiPath } from "src/composables/Inleague.MojoExports";
import { DAYJS_FORMAT_HTML_DATE, dayjsOr } from "src/helpers/formatDate";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { arrayFindOrFail, forceCheckedIndexedAccess, noAvailableOptions, UiOptions } from "src/helpers/utils";
import { Competition, CompetitionSeason, Datelike, Guid, Season } from "src/interfaces/InleagueApiV1";
import { computed, defineComponent, onMounted, ref } from "vue";
import { Btn2 } from "../UserInterface/Btn2";
import { Client } from "src/store/Client";

export default defineComponent({
  setup() {
    const selectedSeasonUID = ref<"" | Guid>("")
    const selectedCompetitionUID = ref<"" | Guid>("")
    const dateFrom = ref<Datelike>("")
    const dateTo = ref<Datelike>("")
    const dateLookupBusy = ref(false)

    const competitions = ref<Competition[]>([])
    const competitionOptions = ref<UiOptions>(noAvailableOptions("Loading programs..."))

    const seasons = ref<Season[]>([])
    const seasonOptions = ref<UiOptions>(noAvailableOptions("Loading seasons..."))

    const compSeasonResolver = ReactiveReifiedPromise<{competitionSeason: CompetitionSeason | null}>()
    const updateDefaultDates = async () : Promise<void> => {
      const competitionUID = selectedCompetitionUID.value
      const seasonUID = selectedSeasonUID.value

      if (!competitionUID || !seasonUID) {
        return;
      }

      dateLookupBusy.value = true
      try {
        const compSeason = await (compSeasonResolver
          .run(
            () => xlsxExport_menu_getCompetitionSeason(axiosAuthBackgroundInstance, {competitionUID, seasonUID}),
            {debounce_ms: 100, minRuntime_ms: 200}
          )
        ).getResolvedOrFail()

        const startDate = dayjsOr(compSeason.competitionSeason?.seasonStart)
        const endDate = startDate?.add(compSeason.competitionSeason?.seasonWeeks ?? 0, "weeks")

        if (startDate?.isValid() && endDate?.isValid()) {
          dateFrom.value = startDate.format(DAYJS_FORMAT_HTML_DATE)
          dateTo.value = endDate.format(DAYJS_FORMAT_HTML_DATE)
        }
        else {
          dateFrom.value = ""
          dateTo.value = ""
        }
      }
      finally {
        dateLookupBusy.value = false
      }
    }

    const updateDefaultSeason = () : void => {
      const competitionUID = selectedCompetitionUID.value

      if (!competitionUID) {
        return;
      }

      const seasonUID = arrayFindOrFail(competitions.value, v => v.competitionUID === competitionUID)?.seasonUID

      if (seasons.value.find(v => v.seasonUID === seasonUID)) {
        selectedSeasonUID.value = seasonUID
      }
    }

    onMounted(async () => {
      dateLookupBusy.value = true
      competitions.value = await xlsxExport_menu_getCompetitions(axiosAuthBackgroundInstance)
      seasons.value = await xlsxExport_menu_getSeasons(axiosAuthBackgroundInstance)

      if (competitions.value.length === 0) {
        competitionOptions.value = noAvailableOptions()
      }
      else {
        competitionOptions.value = {disabled: false, options: competitions.value.map(v => ({label: v.competition, value: v.competitionUID}))}
      }

      if (seasons.value.length === 0) {
        seasonOptions.value = noAvailableOptions()
      }
      else {
        seasonOptions.value = {disabled: false, options: seasons.value.map(v => ({label: v.seasonName, value: v.seasonUID}))}
      }

      selectedCompetitionUID.value = forceCheckedIndexedAccess(competitionOptions.value.options, 0)?.value ?? ""
      selectedSeasonUID.value = forceCheckedIndexedAccess(seasonOptions.value.options, 0)?.value ?? ""

      void updateDefaultDates()
    })

    const playerExportExportDisabled = computed(() => !selectedCompetitionUID.value || !selectedSeasonUID.value);
    const playerExportURL = computed(() => {
      return playerExportExportDisabled.value
        ? "javascript:void(0)"
        : `https://${Client.value.instanceConfig.appdomain}/${xlsxExport_player_apiPath({competitionUID: selectedCompetitionUID.value, seasonUID: selectedSeasonUID.value})}`
    })

    const coachTeamAssignmentsExportDisabled = computed(() => !selectedCompetitionUID.value || !selectedSeasonUID.value);
    const coachTeamAssignmentsExportURL = computed(() => {
      return coachTeamAssignmentsExportDisabled.value
        ? "javascript:void(0)"
        : `https://${Client.value.instanceConfig.appdomain}/${xlsxExport_coachTeamAssignments_apiPath({competitionUID: selectedCompetitionUID.value, seasonUID: selectedSeasonUID.value})}`
    })

    const gamesExportDisabled = computed(() => !selectedCompetitionUID.value || !selectedSeasonUID.value || dateLookupBusy.value || !dateFrom.value || !dateTo.value);
    const gamesExportURL = computed(() => {
      return coachTeamAssignmentsExportDisabled.value
        ? "javascript:void(0)"
        : `https://${Client.value.instanceConfig.appdomain}/${xlsxExport_games_apiPath({competitionUID: selectedCompetitionUID.value, seasonUID: selectedSeasonUID.value, dateFrom: dateFrom.value, dateTo: dateTo.value})}`
    })

    return () => {
      return (
        <div style="--fk-padding-input: .5em; --fk-margin-outer: none;">
          <div class="max-w-96 my-4">
            <h1 class="mb-4">Mojo Roster and Schedule Export</h1>
            <p>This utility will produce the <b>player</b>, <b>coach</b>, and <b>game schedule</b> exports for submission to Mojo Sports. Exports will include all divisions for the selected program and season, so if some divisions (e.g. Playground) should not be exported, delete those from the Excel file before submitting to Mojo.</p>
            <p class="mt-4">Once exported, submit the Excel files to Mojo through their <a target="_blank" class="text-blue-600 underline" href="https://form.asana.com/?k=YFBS17cBbrazkaVYIh8kmQ&d=1150136940882755">roster and schedule submission form</a>. This form is also located in your <a target="_blank" class="text-blue-600 underline" href="https://organization.mojo.sport/login">Mojo Organization Portal.</a> </p>
            <p class="mt-4">More information about Mojo is available on the <a target="_blank" class="text-blue-600 underline" href="https://wiki.ayso.org/wiki/MOJO">AYSO Wiki</a>.</p>
            <div class="mt-4">
              <div class="font-medium text-sm">Program</div>
              <FormKit
                type="select"
                disabled={competitionOptions.value.disabled}
                options={competitionOptions.value.options}
                v-model={selectedCompetitionUID.value}
                onChange={async () => {
                  await updateDefaultSeason()
                  await updateDefaultDates()
                }}
                data-test="competitionUID"
              />
            </div>
            <div>
              <div class="font-medium text-sm">Season</div>
              <FormKit
                type="select"
                disabled={seasonOptions.value.disabled}
                options={seasonOptions.value.options}
                v-model={selectedSeasonUID.value}
                onChange={async () => {
                  await updateDefaultDates()
                }}
                data-test="seasonUID"
              />
            </div>
          </div>

          <div class="max-w-96 border-green-800 rounded-md il-box-shadow-2 my-4">
            <div class="text-white p-2 bg-green-800 rounded-t-md">Player Export</div>
            <div class="p-2 flex flex-col items-center justify-center">
              <a href={playerExportURL.value}>
                <Btn2 disabled={playerExportExportDisabled.value} class="my-2 px-2 py-1" data-test="getMojoExport_players">Get</Btn2>
              </a>
            </div>
          </div>

          <div class="max-w-96 border-green-800 rounded-md il-box-shadow-2 my-4">
            <div class="text-white p-2 bg-green-800 rounded-t-md">Coach Export</div>
            <div class="p-2 flex justify-center">
              <a href={coachTeamAssignmentsExportURL.value}>
                <Btn2 disabled={coachTeamAssignmentsExportDisabled.value} class="my-2 px-2 py-1" data-test="getMojoExport_coachTeamAssignments">Get</Btn2>
              </a>
            </div>
          </div>

          <div class="max-w-96 border-green-800 rounded-md il-box-shadow-2 my-4">
            <div class="text-white p-2 bg-green-800 rounded-t-md">Games Export</div>
            <div class="p-2">
              <div class="my-2">
                <div class="font-medium text-sm">Date From</div>
                {dateLookupBusy.value
                  ? <FormKit type="text" disabled={true} placeholder="Loading deafult dates..."/>
                  : <div>
                    <FormKit type="date" v-model={dateFrom.value} validation={[["required"]]} validationMessages={{required: "'Date From' is required"}}/>
                  </div>
                }
              </div>
              <div class="my-2">
                <div class="font-medium text-sm">Date To</div>
                {dateLookupBusy.value
                  ? <FormKit type="text" disabled={true} placeholder="Loading deafult dates..."/>
                  : <div>
                    <FormKit type="date" v-model={dateTo.value} validation={[["required"]]} validationMessages={{required: "'Date To' is required"}}/>
                  </div>
                }
              </div>
              <div class="flex justify-center">
                <a href={gamesExportURL.value}>
                  <Btn2 disabled={gamesExportDisabled.value} class="my-2 px-2 py-1" data-test="getMojoExport_games">Get</Btn2>
                </a>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
})
