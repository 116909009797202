import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances";
import { bestUpcomingOrCurrentCompetitionStartDayOfWeek } from "src/helpers/Competition";
import { DAYJS_FORMAT_HTML_DATE, dayjsOr } from "src/helpers/formatDate";
import { arrayFindOrFail, assertIs, assertNonNull, assertTruthy, forceCheckedIndexedAccess, nextOpaqueVueKey, UiOption, UiOptions, unreachable, useWatchLater, vReqT, weakEq } from "src/helpers/utils";
import { Competition, CompetitionSeason, Division, Guid, Integerlike, Season } from "src/interfaces/InleagueApiV1";
import { Client } from "src/store/Client";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { LocationQueryValue, RouteLocationRaw, RouterLink, useRouter } from "vue-router";
import { Phase1_CreateRounds, Phase1Elem, Phase2_ConfirmRounds, Phase2Elem, Phase3_ConfirmTentativeTeams, Phase3Elem, RoundWrapper } from "./MatchmakerElems";
import { deleteRounds, generateTentativeMatchups, updateRound, getOrCreateRounds, Round, GetOrCreateRoundsResponse, GenerateTentativeMatchupsResponse, saveMatchups, compSeasonDivMenu, Pool } from "src/composables/InleagueApiV1.Matchmaker";
import { k_POOL_ALL } from "../calendar/GameScheduler.shared";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import * as R_GameSchedulerCalendar from "../calendar/R_GameSchedulerCalendar.route";
import dayjs from "dayjs";
import * as R_Matchmaker from "./R_Matchmaker.route"

import { FormKit } from "@formkit/vue";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { mungePhase1QueryParams, mungePhase2QueryParams, mungePhase3QueryParams, Phase1Query, Phase2Query, Phase3Query } from "./R_Matchmaker.route";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";

export default defineComponent({
  setup() {
    const router = useRouter()

    const phase1QueryParams = ref<Phase1Query | null>(null)
    const phase2QueryParams = ref<Phase2Query | null>(null)
    const phase3QueryParams = ref<Phase3Query | null>(null)

    watch(() => router.currentRoute.value.name, () => {
      if (router.currentRoute.value.name === R_Matchmaker.RouteNames.phase1) {
        // no query params here is OK, can be null
        phase1QueryParams.value = mungePhase1QueryParams(router.currentRoute.value)
      }
      else if (router.currentRoute.value.name === R_Matchmaker.RouteNames.phase2) {
        const params = mungePhase2QueryParams(router.currentRoute.value)
        if (params) {
          phase2QueryParams.value = params
        }
        else {
          // no params for phase2 is incorrect -- reroute to phase1
          router.replace({name: R_Matchmaker.RouteNames.phase1})
        }
      }
      else if (router.currentRoute.value.name === R_Matchmaker.RouteNames.phase3) {
        const params = mungePhase3QueryParams(router.currentRoute.value)
        if (params) {
          phase3QueryParams.value = params
        }
        else {
          // no params for phase3 is incorrect -- reroute to phase1
          router.replace({name: R_Matchmaker.RouteNames.phase1})
        }
      }
      else {
        unreachable(`currentRoute.value.name='${router.currentRoute.value.name?.toString() ?? "<<undefined>>"}'`)
      }
    }, {immediate: true});

    return () => {
      return (
        <div style="max-width:2048px;" data-test="R_Matchmaker">
          <h2>Matchmaker</h2>
          {router.currentRoute.value.name === R_Matchmaker.RouteNames.phase1
            ? <Phase1 query={phase1QueryParams.value}/>
            : (router.currentRoute.value.name === R_Matchmaker.RouteNames.phase2 && phase2QueryParams.value)
            ? <Phase2 query={phase2QueryParams.value}/>
            : (router.currentRoute.value.name === R_Matchmaker.RouteNames.phase3 && phase3QueryParams.value)
            ? <Phase3 query={phase3QueryParams.value}/>
            : null
          }
        </div>
      )
    }
  }
})

// TODO: this should probably be driven by a query param rather the current approach of using app state on the jump from phase3--(complete)-->phase1
let justSavedInfo : null | {token: string, location: RouteLocationRaw} = null

const Phase1 = defineComponent({
  props: {
    query: vReqT<Phase1Query | null>()
  },
  setup(props) {
    const router = useRouter()
    const ready = ref(false)
    const justSavedRouteLoc = ref<RouteLocationRaw | null>(null)

    // The form has 2 states,
    // one where we are choosing a comp/season/div, and then the other where a comp/season/div is selected and we have
    // an actual form to fill out. The form contains pools which needs comp/season/div. I guess we could have pool be part of the
    // "outer" aspect of the form, and the form only gets built when it has a full comp/season/div/pool. That's still a 2 phase thing though.
    const form = (() => {
      const formData = ref<Phase1_CreateRounds | null>(null)
      const loading = ref(false)

      // We need to be able to select season/comp/div without having a form,
      // but also then we need to keep the form updated with the selected values if there is a form.
      const entityIdProxy = (key: "seasonUID" | "competitionUID" | "divID") => {
        const data = ref<"" | Guid>("");
        return {
          get value() { return data.value },
          set value(v) {
            data.value = v
            if (formData.value) {
              formData.value[key] = v
            }
          }
        }
      }

      const selectedSeasonUID = entityIdProxy("seasonUID")
      const selectedCompetitionUID = entityIdProxy("competitionUID")
      const selectedDivID = entityIdProxy("divID")

      const doReloadPools = async () : Promise<void> => {
        const competitionUID = selectedCompetitionUID.value
        const divID = selectedDivID.value
        const seasonUID = selectedSeasonUID.value

        if (!competitionUID || !divID || !seasonUID) {
          return
        }

        await menu.doLoadPools({competitionUID, seasonUID, divID})

        if (formData.value) {
          if (!menu.poolOptions.options.find(v => weakEq(v.value, form.formData.value?.poolID as any))) {
            formData.value.poolID = k_POOL_ALL
          }
        }
      }

      const doHandleCompetitionChange = async () : Promise<void> => {
        assertNonNull(menu.competitions)

        await Promise.all([
          menu.doLoadSeasons({competitionUID: selectedCompetitionUID.value}),
          menu.doLoadDivisions({competitionUID: selectedCompetitionUID.value})
        ])

        const selectedCompetition = menu.competitions.find(v => v.competitionUID === selectedCompetitionUID.value)

        selectedSeasonUID.value = menu.seasonOptions.options.find(v => v.value === selectedCompetition?.seasonUID)?.value
          ?? forceCheckedIndexedAccess(menu.seasonOptions.options, 0)?.value
          ?? ""

        selectedDivID.value = menu.divisionOptions.options.find(v => v.value === selectedDivID.value)?.value
          ?? forceCheckedIndexedAccess(menu.divisionOptions.options, 0)?.value
          ?? ""

        await doResetForm()
      }

      /**
       * Reset the form using the current selected comp/season/div values.
       */
      const doResetForm = async () : Promise<void> => {
        const competitionUID = selectedCompetitionUID.value
        const seasonUID = selectedSeasonUID.value
        const divID = selectedDivID.value

        if (!seasonUID || !competitionUID || !divID) {
          formData.value = null
        }
        else {
          try {
            loading.value = true

            // Should always succeed because the form has values from the menu, so lookup via the menu should be OK.
            // n.b. do before any awaits, because suspending could allow code to run that changes what's in comps or seasons
            const competition = menu.getCompetitionOrFail(competitionUID)
            const season = menu.getSeasonOrFail(seasonUID)

            await menu.doLoadPools({seasonUID, competitionUID, divID})


            // Seasons should have been loaded with the appropriate competition for the current selection
            // Also, it is possible that there is no competition season.
            assertTruthy(!season.competitionSeason || season.competitionSeason.competitionUID === competitionUID);

            formData.value = await phase1Data({
              competition,
              season,
              competitionSeason: season.competitionSeason,
              divID,
              poolID: formData.value?.poolID || k_POOL_ALL,
              availablePoolOptions: menu.poolOptions.options
            })
          }
          finally {
            loading.value = false
          }
        }
      }

      return {
        formData,
        selectedSeasonUID,
        selectedDivID,
        selectedCompetitionUID,
        doReloadPools,
        doHandleCompetitionChange,
        doResetForm,
        /**
         * `loading` means "the whole form is being built",
         * rather than "some sub portions of the form are loading" (e.g. pools).
         * There is a difference between "no form" (form is null) and
         * "no form but working on making one..." (form is null and loading is true).
         */
        get loading() { return loading.value },
      }
    })()

    const menu = (() => {
      const competitions = ref<Competition[] | null>(null)
      const competitionOptions = ref<UiOptions>({disabled: true, options: []})

      const seasons = ReactiveReifiedPromise<(Season & {competitionSeason: CompetitionSeason | null})[]>()
      const seasonOptions = ref<UiOptions>({disabled: true, options: []})

      const divisions = ReactiveReifiedPromise<Division[]>()
      const divisionOptions = ref<UiOptions>({disabled: true, options: []})

      const pools = ReactiveReifiedPromise<Pool[]>()
      const poolOptions = ref<UiOptions<"" | "ALL" | Integerlike>>({disabled: true, options: []})

      // it looks bad for options lists to flash super fast from "options -> loading new options ... -> options"
      // so enforce a min wait time
      const minRuntime_ms = 200
      const debounce_ms = 100

      const doLoadCompetitions = async () : Promise<void> => {
        competitions.value = (await compSeasonDivMenu.getCompetitions(axiosAuthBackgroundInstance)).competitions
        if (competitions.value.length === 0) {
          competitionOptions.value = {disabled: true, options: [{label: "No available options", value: "", attrs: {disabled: true}}]}
        }
        else {
          competitionOptions.value = {disabled: false, options: competitions.value.map(v => ({label: v.competition, value: v.competitionUID}))}
        }
      }

      const doLoadSeasons = async (args: {competitionUID: Guid}) : Promise<void> => {
        seasonOptions.value = {disabled: true, options: [{label: "Loading seasons...", value: ""}]}
        const s = await seasons.run(async () => {
          const v = await compSeasonDivMenu.getSeasons(axiosAuthBackgroundInstance, args)
          return v.seasons
        }, {debounce_ms, minRuntime_ms}).getResolvedOrFail()

        if (s.length === 0) {
          seasonOptions.value = {disabled: true, options: [{label: "No available options", value: "", attrs: {disabled: true}}]}
        }
        else {
          seasonOptions.value = {disabled: false, options: s.map(v => ({label: v.seasonName, value: v.seasonUID}))}
        }
      }

      const doLoadDivisions = async (args: {competitionUID: Guid}) : Promise<void> => {
        divisionOptions.value = {disabled: true, options: [{label: "Loading divisions...", value: ""}]}
        const d = await divisions.run(async () => {
          const v = await compSeasonDivMenu.getDivisions(axiosAuthBackgroundInstance, args)
          return v.divisions
        }, {debounce_ms, minRuntime_ms}).getResolvedOrFail()

        if (d.length === 0) {
          divisionOptions.value = {disabled: true, options: [{label: "No available options", value: "", attrs: {disabled: true}}]}
        }
        else {
          divisionOptions.value = {disabled: false, options: d.map(v => ({label: v.division || v.displayName, value: v.divID}))}
        }
      }

      const doLoadPools = async (args: {competitionUID: Guid, seasonUID: Guid, divID: Guid}) : Promise<void> => {
        poolOptions.value = {disabled: true, options: [{label: "Loading pools...", value: ""}]}

        const d = await pools.run(async () => {
          return await compSeasonDivMenu.getPools(axiosAuthBackgroundInstance, args)
        }, {debounce_ms, minRuntime_ms}).getResolvedOrFail()

        poolOptions.value = {
          disabled: false,
          options: [
            {label: "Entire division", value: k_POOL_ALL},
            ...d.map(v => ({label: v.poolName, value: v.poolId.toString() as Integerlike}))
          ]
        }
      }

      const isDisabled = computed(() => {
        return competitionOptions.value.disabled
          || seasonOptions.value.disabled
          || divisionOptions.value.disabled
          || poolOptions.value.disabled
      })

      const getCompetitionOrFail = (competitionUID: Guid) => {
        assertNonNull(competitions.value)
        return arrayFindOrFail(competitions.value, v => v.competitionUID === competitionUID)
      }

      const getSeasonOrFail = (seasonUID: Guid) => {
        assertIs(seasons.underlying.status, "resolved")
        return arrayFindOrFail(seasons.underlying.data, v => v.seasonUID === seasonUID)
      }

      return {
        doLoadCompetitions,
        doLoadSeasons,
        doLoadDivisions,
        doLoadPools,
        get competitions() { return competitions.value },
        get competitionOptions() { return competitionOptions.value },
        get seasonOptions() { return seasonOptions.value },
        get divisionOptions() { return divisionOptions.value },
        get poolOptions() { return poolOptions.value },
        get isDisabled() { return isDisabled.value },
        getCompetitionOrFail,
        getSeasonOrFail,
      }
    })()

    const doCreateRounds = async () => {
      assertNonNull(form.formData.value)

      type A = {[K in keyof Phase2Query]-?: undefined | LocationQueryValue | LocationQueryValue[]} // undefined ignored when dropping optionality, without exactOptionalPropertyTypes
      type B = {[K in keyof A]: A[K] | undefined} // ... without exactOptionalPropertyTypes, need this extra step

      const v : B = {
        competitionUID: form.formData.value.competitionUID,
        seasonUID: form.formData.value.seasonUID,
        divID: form.formData.value.divID,
        poolID: form.formData.value.poolID.toString(),
        includeGamesWithTeams: form.formData.value.includeGamesWithTeams ? "1" : "0",
        startDate: form.formData.value.startDate,
        endDate: form.formData.value.endDate,
        numRoundRobinCycles: form.formData.value.numRoundRobinCycles.toString(),
        roundLengthInDays: form.formData.value.roundLengthInDays.toString(),
      }

      await router.push({name: R_Matchmaker.RouteNames.phase2, query: v})
    }

    const queryParamsWatcher = useWatchLater(() => form.formData.value, async () => {
      // "The type of Phase1Query, as queryparams, but everything is required, except 'justSaved', which is not allowed"
      // TODO: enable exactOptionalPropertyTypes
      type A = {[K in keyof Omit<Phase1Query, "justSaved">]-?: undefined | LocationQueryValue | LocationQueryValue[]} // undefined ignored when dropping optionality, without exactOptionalPropertyTypes
      type B = {[K in keyof A]: A[K] | undefined} // ... without exactOptionalPropertyTypes, need this extra step

      const q : B = {
        seasonUID: form.formData.value?.seasonUID,
        competitionUID: form.formData.value?.competitionUID,
        divID: form.formData.value?.divID,
        includeGamesWithExistingMatchups: form.formData.value
          ? form.formData.value.includeGamesWithTeams ? "1" : "0"
          : undefined,
        startDate: form.formData.value?.startDate,
        endDate: form.formData.value?.endDate,
        numRoundRobinCycles: form.formData.value?.numRoundRobinCycles.toString(),
        roundLengthInDays: form.formData.value?.roundLengthInDays.toString(),
        poolID: form.formData.value?.poolID.toString(),
      }

      await router.replace({...router.currentRoute.value, query: {...router.currentRoute.value.query, ...q}})
    }, {deep: true})

    onMounted(async () => {
      GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        await menu.doLoadCompetitions()

        assertNonNull(menu.competitions)

        {
          // init selected comp/div/season from available menu options
          form.selectedCompetitionUID.value = menu.competitionOptions.options.find(v => v.value === props.query?.competitionUID)?.value
            ?? forceCheckedIndexedAccess(menu.competitionOptions.options, 0)?.value
            ?? ""

          const selectedCompetition = menu.competitions.find(v => v.competitionUID === form.selectedCompetitionUID.value)

          if (form.selectedCompetitionUID.value) {
            await Promise.all([
              menu.doLoadSeasons({competitionUID: form.selectedCompetitionUID.value}),
              menu.doLoadDivisions({competitionUID: form.selectedCompetitionUID.value})
            ])
          }

          form.selectedSeasonUID.value = menu.seasonOptions.options.find(v => v.value === selectedCompetition?.seasonUID)?.value
            ?? forceCheckedIndexedAccess(menu.seasonOptions.options, 0)?.value
            ?? ""

          form.selectedDivID.value = menu.divisionOptions.options.find(v => v.value === props.query?.divID)?.value
            ?? forceCheckedIndexedAccess(menu.divisionOptions.options, 0)?.value
            ?? ""
        }

        if (props.query) {
          // also maybe init the "you just saved this, here's a link to it" link
          justSavedRouteLoc.value = maybeConsumeJustSavedRouteLoc(props.query.justSaved ?? "")
        }

        await form.doResetForm()

        if (props.query) {
          if (form.formData.value) {
            // should get here by virtue of awaiting on resetting the form
            // Init available values from query params if we've got them
            form.formData.value.includeGamesWithTeams = props.query.includeGamesWithExistingMatchups ?? form.formData.value.includeGamesWithTeams
            form.formData.value.startDate = dayjsOr(props.query.startDate)?.format(DAYJS_FORMAT_HTML_DATE) ?? form.formData.value.startDate
            form.formData.value.endDate = dayjsOr(props.query.endDate)?.format(DAYJS_FORMAT_HTML_DATE) ?? form.formData.value.endDate
            form.formData.value.numRoundRobinCycles = props.query.numRoundRobinCycles ?? form.formData.value.numRoundRobinCycles
            form.formData.value.roundLengthInDays = props.query.roundLengthInDays ?? form.formData.value.roundLengthInDays
            form.formData.value.poolID = menu.poolOptions.options.find(v => weakEq(v.value, props.query?.poolID || ""))?.value || k_POOL_ALL;
          }
        }

        queryParamsWatcher.start()

        ready.value = true
      })
    })

    return () => {
      if (!ready.value) {
        return null
      }

      return (
        <div style="max-width:2048px;">
          <div style="--fk-margin-outer:none; --fk-padding-input:.5em;" class="my-4">
            {justSavedRouteLoc.value
              ? <div style="max-width: var(--fk-max-width-input);" class="mb-4">
                  <div class="inline-block rounded-md text-sm border-green-800 w-full" >
                    <div class="p-1 text-white bg-green-800 rounded-t-md flex gap-2 items-center">
                      <FontAwesomeIcon icon={faCheck} class="ml-1"/>
                      <div>Saved</div>
                    </div>
                    <div class="p-2 flex gap-2 items-center border-r border-l border-b rounded-b-md border-green-800">
                      <RouterLink data-test="justSaved" class="il-link" {...{target:"_blank"}} to={justSavedRouteLoc.value}>Show on game scheduler calendar</RouterLink>
                    </div>
                  </div>
                </div>
              : null
            }
            <div class="text-sm font-medium">Program</div>
            <FormKit
              type="select"
              disabled={menu.competitionOptions.disabled}
              options={menu.competitionOptions.options}
              v-model={form.selectedCompetitionUID.value}
              validation="required"
              onInput={async (value: any) => {
                form.selectedCompetitionUID.value = value
                await form.doHandleCompetitionChange()
              }}
            />
            <div class="text-sm font-medium">Season</div>
            <FormKit
              type="select"
              disabled={menu.seasonOptions.disabled}
              options={menu.seasonOptions.options}
              v-model={form.selectedSeasonUID.value}
              validation="required"
              onInput={async (value: any) => {
                form.selectedSeasonUID.value = value
                await form.doReloadPools()
              }}
            />
            <div class="text-sm font-medium">Division</div>
            <FormKit
              type="select"
              disabled={menu.divisionOptions.disabled}
              options={menu.divisionOptions.options}
              v-model={form.selectedDivID.value}
              validation="required"
              onInput={async (value: any) => {
                form.selectedDivID.value = value
                await form.doReloadPools()
              }}
            />
          </div>

          <div>
            {form.loading
              ? "Loading form info..."
              : null
            }
            {!form.loading && form.formData.value
              ? <Phase1Elem
                data={form.formData.value}
                poolOptions={menu.poolOptions}
                onCreateRounds={() => doCreateRounds()}
                disableSubmit={menu.isDisabled}
              />
              : null
            }
          </div>
        </div>
      )
    }
  }
})

const Phase2 = defineComponent({
  props: {
    query: vReqT<Phase2Query>(),
  },
  setup(props) {
    const router = useRouter()
    const state = ref<{raw: GetOrCreateRoundsResponse, data: Phase2_ConfirmRounds} | null>(null)
    const season = ref<Season>()
    const competition = ref<Competition>()
    const division = ref<Division>()
    const pool = ref<Pool>()

    const doDeleteRounds = async () : Promise<void> => {
      try {
        const {seasonUID, competitionUID, divID, poolID} = props.query
        await deleteRounds(axiosInstance, {seasonUID, competitionUID, divID, poolID})

        const q : {[K in keyof Omit<Phase1Query, "justSaved">]-?: LocationQueryValue | LocationQueryValue[]} = {
          seasonUID: props.query.seasonUID,
          competitionUID: props.query.competitionUID,
          divID: props.query.divID,
          includeGamesWithExistingMatchups: props.query.includeGamesWithTeams ? "1" : "0",
          startDate: props.query.startDate,
          endDate: props.query.endDate,
          numRoundRobinCycles: props.query.numRoundRobinCycles.toString(),
          roundLengthInDays: props.query.roundLengthInDays.toString(),
          poolID: props.query.poolID.toString(),
        }

        await router.push({name: R_Matchmaker.RouteNames.phase1, query: {...router.currentRoute.value.query, ...q}})
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const toPhase3 = async () => {
      assertNonNull(state.value)

      const roundIDs = state.value.data.rounds.filter(v => !v.round.excluded).map(v => v.round.roundID)

      if (roundIDs.length === 0) {
        // shouldn't be allowed to get her if this is the case
        return
      }

      const query : {[K in keyof Phase3Query]-?: LocationQueryValue | LocationQueryValue[]} = {
        seasonUID: props.query.seasonUID,
        competitionUID: props.query.competitionUID,
        divID: props.query.divID,
        roundIDs: roundIDs,
        poolID: props.query.poolID.toString(),
        balanceHomeAndAway: state.value.data.balanceHomeAndAway ? "1" : "0",
        byePosition: state.value.data.byePosition,
        includeGamesWithTeams: state.value.data.includeGamesWithExistingMatchups ? "1" : "0",
        startDate: props.query.startDate,
        endDate: props.query.endDate,
        numRoundRobinCycles: props.query.numRoundRobinCycles.toString(),
        roundLengthInDays: props.query.roundLengthInDays.toString(),
      }

      await router.push({name: R_Matchmaker.RouteNames.phase3, query})
    }

    const doSetExcluded = async (args: {roundWrapper: RoundWrapper, value: boolean}) : Promise<void> => {
      const {roundWrapper: r, value} = args
      const saved = r.round.excluded
      try {
        r.uiState.busy = true

        await Promise.all([
          updateRound(axiosAuthBackgroundInstance, {roundID: r.round.roundID, excluded: value}),
          new Promise(r => setTimeout(r, 250)) // "busy" for a minimum of 250ms
        ])

        r.round.excluded = value
      }
      catch (err) {
        r.round.excluded = saved;
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
      finally {
        r.uiState.busy = false
      }
    }

    onMounted(async () => {
      try {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          const xcompetition = await Client.getCompetitionByUidOrFail(props.query.competitionUID)
          const xseason = await Client.getSeasonByUidOrFail(props.query.seasonUID)
          const xdivision = await Client.getDivisionByIdOrFail(props.query.divID)
          const xpool = (await compSeasonDivMenu.getPools(axiosInstance, {
            competitionUID: xcompetition.competitionUID,
            divID: xdivision.divID,
            seasonUID: xseason.seasonUID,
          })).find(v => weakEq(props.query.poolID, v.poolId))

          // assign all at once for coherent view
          competition.value = xcompetition
          season.value = xseason
          division.value = xdivision
          pool.value = xpool

          const r = await getOrCreateRounds(axiosInstance, {
            competitionUID: props.query.competitionUID,
            seasonUID: props.query.seasonUID,
            divID: props.query.divID,
            poolID: props.query.poolID,
            startDateInclusive: props.query.startDate,
            endDateInclusive: props.query.endDate,
            roundLengthInDays: props.query.roundLengthInDays,
            numRoundRobinCycles: props.query.numRoundRobinCycles,
            includeGamesWithTeams: props.query.includeGamesWithTeams,
          });

          state.value = {
            raw: r,
            data: Phase2_ConfirmRounds(r.rounds, r.warning)
          }

          state.value.data.includeGamesWithExistingMatchups = props.query.includeGamesWithTeams
        })
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    })

    return () => {
      return (
        <div>
          <div>{competition.value?.competition}</div>
          <div>{season.value?.seasonName}</div>
          <div>{division.value?.displayName || division.value?.division}</div>
          <div>{pool.value?.poolName || ""}</div>
          {state.value ? <div>Team count: {state.value.raw.teamCountForCompSeasonDivPool}</div> : null}
          {state.value
            ? <Phase2Elem
              data={state.value.data}
              gameSchedulerRoute={gameSchedulerRoute(props.query.competitionUID, props.query.divID, state.value.data.rounds.map(v => v.round))}
              teamCountForCompSeasonDivPool={state.value.raw.teamCountForCompSeasonDivPool}
              onDeleteRounds={async () => await doDeleteRounds()}
              onGenerateMatchups={async () => await toPhase3()}
              onSetExcluded={async (args) => await doSetExcluded(args)}
            />
            : null
          }
        </div>
      )
    }
  }
})

const Phase3 = defineComponent({
  props: {
    query: vReqT<Phase3Query>(),
  },
  setup(props) {
    const router = useRouter()
    const state = ref<{raw: GenerateTentativeMatchupsResponse, data: Phase3_ConfirmTentativeTeams} | null>(null)
    const season = ref<Season>()
    const competition = ref<Competition>()
    const division = ref<Division>()
    const pool = ref<Pool>()

    const doSaveTentativeMatchups = async () : Promise<void> => {
      assertNonNull(state.value)

      try {
        await saveMatchups(axiosInstance, {
          updateByeField: state.value.data.updateByeField,
          each: state.value.data.rounds.flatMap(round => round.round.games.map(game => ({
            gameID: game.gameID,
            homeTeamID: game.home,
            visitorTeamID: game.visitor
          }))),
        });

        const route = gameSchedulerRoute(props.query.competitionUID, props.query.divID, state.value.data.rounds.map(v => v.round), {includeByeField: state.value.data.updateByeField})
        let savedToken : string | undefined = undefined
        if (route) {
          // should always be non-null here
          savedToken = setJustSavedLoc(route)
        }

        const q : {[K in keyof Phase1Query]-?: LocationQueryValue} = {
          seasonUID: props.query.seasonUID,
          competitionUID: props.query.competitionUID,
          divID: props.query.divID,
          includeGamesWithExistingMatchups: props.query.includeGamesWithTeams ? "1" : "0",
          startDate: props.query.startDate,
          endDate: props.query.endDate,
          numRoundRobinCycles: props.query.numRoundRobinCycles.toString(),
          roundLengthInDays: props.query.roundLengthInDays.toString(),
          justSaved: savedToken as string,
          poolID: props.query.poolID.toString(),
        }

        await router.push({name: R_Matchmaker.RouteNames.phase1, query: {...router.currentRoute.value.query, ...q}})
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const cancel = async () : Promise<void> => {
      const q : {[K in keyof Omit<Phase1Query, "justSaved">]-?: LocationQueryValue} = {
        seasonUID: props.query.seasonUID,
        competitionUID: props.query.competitionUID,
        divID: props.query.divID,
        includeGamesWithExistingMatchups: props.query.includeGamesWithTeams ? "1" : "0",
        startDate: props.query.startDate,
        endDate: props.query.endDate,
        numRoundRobinCycles: props.query.numRoundRobinCycles.toString(),
        roundLengthInDays: props.query.roundLengthInDays.toString(),
        poolID: props.query.poolID.toString(),
      }

      await router.push({name: R_Matchmaker.RouteNames.phase1, query: {...router.currentRoute.value.query, ...q}})
    }

    onMounted(async () => {
      try {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          const xcompetition = await Client.getCompetitionByUidOrFail(props.query.competitionUID)
          const xseason = await Client.getSeasonByUidOrFail(props.query.seasonUID)
          const xdivision = await Client.getDivisionByIdOrFail(props.query.divID)
          const xpool = (await compSeasonDivMenu.getPools(axiosInstance, {
            competitionUID: xcompetition.competitionUID,
            divID: xdivision.divID,
            seasonUID: xseason.seasonUID,
          })).find(v => weakEq(props.query.poolID, v.poolId))

          // assign all at once for coherent view
          competition.value = xcompetition
          season.value = xseason
          division.value = xdivision
          pool.value = xpool

          const v = await generateTentativeMatchups(axiosInstance, {
            roundIDs: props.query.roundIDs,
            poolID: props.query.poolID,
            balanceHomeAndAway: props.query.balanceHomeAndAway,
            byePosition: props.query.byePosition,
            includeGamesWithTeams: props.query.includeGamesWithTeams,
          });
          state.value = {
            raw: v,
            data: Phase3_ConfirmTentativeTeams(v)
          }
        })
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    })

    return () => {
      return (
        <div>
          <div>{competition.value?.competition}</div>
          <div>{season.value?.seasonName}</div>
          <div>{division.value?.displayName || division.value?.division}</div>
          <div>{pool.value?.poolName || ""}</div>
          {state.value ? <div>Team count: {state.value.raw.teamCountForCompSeasonDivPool}</div> : null}
          {state.value
            ? <Phase3Elem
              data={state.value.data}
              teamCountForCompSeasonDivPool={state.value.raw.teamCountForCompSeasonDivPool}
              onSaveTentativeMatchups={async () => await doSaveTentativeMatchups()}
              onCancel={async () => await cancel()}
            />
            : null
          }
        </div>
      )
    }
  }
})

/**
 * generate a route location suitable for an "edit rounds" link, based on comp/div/round-games
 * Returns null if list of rounds is empty.
 */
function gameSchedulerRoute(competitionUID: Guid, divID: Guid, rounds: Round[], opts?: {includeByeField?: boolean}) : RouteLocationRaw | null {
  if (rounds.length === 0) {
    return null
  }

  const fieldUIDs = (() => {
    const fieldUIDs = new Set(rounds.flatMap(r => r.games.map(v => v.fieldUID)));

    if (opts?.includeByeField) {
      fieldUIDs.add(Client.value.instanceConfig.byefield)
    }

    return [...fieldUIDs]
  })();

  return R_GameSchedulerCalendar.routeDetailToRouteLocation({
    routeName: "GameSchedulerCalendar.main",
    queryParams: {
      competitionUIDs: [competitionUID],
      divIDs: [divID],
      fieldUIDs,
      dateFrom: dayjs(rounds[0].startDate),
      dateTo: dayjs(rounds[rounds.length - 1].endDate),
    }
  })
}

function setJustSavedLoc(location: RouteLocationRaw) : string {
  justSavedInfo = {token: nextOpaqueVueKey(), location}
  return justSavedInfo.token
}

function maybeConsumeJustSavedRouteLoc(token: string) : RouteLocationRaw | null {
  const obj = justSavedInfo
  justSavedInfo = null
  if (obj?.token === token) {
    return obj.location
  }
  return null
}

function phase1Data(_: {
  competition: Pick<Competition, "competitionUID" | "startDayOfWeek">,
  season: Pick<Season, "seasonUID" | "seasonWeeks">,
  competitionSeason: Pick<CompetitionSeason, "seasonUID" | "competitionUID" | "seasonWeeks"> | null,
  divID: Guid,
  poolID: k_POOL_ALL | Integerlike,
  availablePoolOptions: UiOption<"" | k_POOL_ALL | Integerlike>[],
}) : Phase1_CreateRounds {
  const {competition, season, competitionSeason, divID, poolID, availablePoolOptions} = _

  // sanity check
  assertTruthy(!competitionSeason || season.seasonUID === competitionSeason.seasonUID)
  assertTruthy(!competitionSeason || competition.competitionUID === competitionSeason.competitionUID)

  const startDate = bestUpcomingOrCurrentCompetitionStartDayOfWeek(competition.startDayOfWeek)
  const endDate = startDate.add(competitionSeason?.seasonWeeks || season.seasonWeeks, "weeks")

  return Phase1_CreateRounds({
    competitionUID: competition.competitionUID,
    seasonUID: season.seasonUID,
    divID,
    poolID: availablePoolOptions.find(v => weakEq(v.value, poolID))?.value || k_POOL_ALL,
    startDate: startDate.format(DAYJS_FORMAT_HTML_DATE),
    endDate: endDate.format(DAYJS_FORMAT_HTML_DATE)
  })
}
