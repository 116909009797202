import { exhaustiveCaseGuard, maybePrettifyUSPhoneNumber, parseIntOr, sortBy, sortByDayJS, sortByMany, vReqT } from "src/helpers/utils";
import { computed, defineComponent, watch } from "vue";
import { TeamPlayerCoachSummaryResponse, TeamPlayerCoachSummary, TeamPlayerCoachSummary_Player, getTeamPlayerCoachSummary_PlayerKey } from "src/composables/InleagueApiV1.Teams";
import { PlayerRosterSort } from "./R_TeamSeason.route";
import dayjs from "dayjs";
import { BasicTable, freshSortState, inferColIDs, typedBasicTableProps } from "src/modules/TableUtils";

export const TeamPlayerCoachSummaryElement = defineComponent({
  props: {
    data: vReqT<TeamPlayerCoachSummaryResponse>(),
    sort: vReqT<PlayerRosterSort>(),
  },
  setup(props) {
    return () => {
      return (
        <div>
          {
            props
              .data
              .rosters
              .slice() // really should be a readonly array ... or parent sorts ... but not a big deal here
              .sort(rosterSort)
              .map(row => <RunOne all={props.data} row={row} sort={props.sort}/>)
          }
        </div>
      )
    }
  }
})

const RunOne = defineComponent({
  props: {
    row: vReqT<TeamPlayerCoachSummary>(),
    all: vReqT<TeamPlayerCoachSummaryResponse>(),
    sort: vReqT<PlayerRosterSort>(),
  },
  setup(props) {
    const coachCols = freshCoachColDefs();
    const coachSortState = freshSortState(coachCols);
    const sortedCoachRows = computed(() => props.row.coaches.slice().sort(coachSortState.asSorter()))

    const playerCols = freshPlayerColDefs();
    const playerSortState = freshSortState(playerCols)
    const sortedPlayerAssignments = computed(() => props.row.playerAssignments.slice().sort(playerSortState.asSorter()))
    const sortedPlayerLoans = computed(() => props.row.playerLoans.slice().sort(playerSortState.asSorter()))

    watch(() => props.sort, () => {
      switch (props.sort) {
        case "name":
          coachSortState.reconfigure([{colID: "name", dir: "asc"}])
          playerSortState.reconfigure([{colID: "name", dir: "asc"}])
          return;
        case "uniform":
          playerSortState.reconfigure([{colID: "jersey", dir: "asc"}])
          return;
        default: exhaustiveCaseGuard(props.sort);
      }
    }, {immediate: true})

    return () => {
      return (
        <div class="p-2 shadow-md bg-white rounded-md my-6">
          <div>
            <span class="font-bold">S/A/R</span>
            <span>{/*intentional space*/} </span>
            {props.row.team.instanceConfig.regionSection}/
            {props.row.team.instanceConfig.regionArea}/
            {props.row.team.instanceConfig.region}
            <span>{/*intentional space*/} </span>
            {props.row.team.instanceConfig.regionName}
          </div>
          <div>
            <span class="font-bold">MY</span>
            <span>{/*intentional space*/} </span>
            <span>MY{props.all.season.registrationYear}</span>
          </div>
          <div>
            <span class="font-bold">Division</span>
            <span>{/*intentional space*/} </span>
            <span>{props.row.team.divGender}{props.row.team.divNum}</span>
          </div>
          <div>
            <span class="font-bold">Team name</span>
            <span>{/*intentional space*/} </span>
            <span>{props.row.team.teamName}</span>
          </div>
          <div>
            <span class="font-bold">Player count</span>
            <span>{/*intentional space*/} </span>
            <span>{props.row.playerAssignments.length}</span>
          </div>
          <div>
            <div class="font-bold">Team colors</div>
            <div class="ml-2 pl-2 border-l border-gray-200">
              <div>
                {
                  props.row.team.colorJersey
                    ? <>
                      <span class="font-medium">Jersey</span>
                      <span>{/*intentional space*/} </span>
                      <span>{props.row.team.colorJersey} {props.row.team.colorJerseySolidStripe ? `(${props.row.team.colorJerseySolidStripe})` : ""}</span>
                    </>
                    : null
                }
              </div>
              <div>
                {
                  props.row.team.colorShorts
                    ? <>
                      <span class="font-medium">Shorts</span>
                      <span>{/*intentional space*/} </span>
                      <span>{props.row.team.colorShorts}</span>
                    </>
                    : null
                }
              </div>
              <div>
                {
                  props.row.team.colorSocks
                    ? <>
                      <span class="font-medium">Socks</span>
                      <span>{/*intentional space*/} </span>
                      <span>{props.row.team.colorSocks}</span>
                    </>
                    : null
                }
              </div>
            </div>
          </div>

          <div class="my-2 border-b border-gray-200 border-dashed"/>

          <div>Coaches</div>
          <BasicTable {...typedBasicTableProps({
            colDefs: coachCols,
            sortState: coachSortState,
            rowData: sortedCoachRows.value,
            rowKey: v => v.assignmentID,
            noData: () => <div class="p-4 flex items-center justify-center">No coaches</div>
          })}/>

          <div class="my-2 border-b border-gray-200 border-dashed"/>

          <div>Players</div>
          <BasicTable {...typedBasicTableProps({
            colDefs: freshPlayerColDefs(),
            sortState: playerSortState,
            rowData: sortedPlayerAssignments.value,
            rowKey: v => getTeamPlayerCoachSummary_PlayerKey(v),
            noData: () => <div class="p-4 flex items-center justify-center">No player assignments</div>
          })}/>

          <div class="my-2 border-b border-gray-200 border-dashed"/>

          <div>Player loans</div>
          <BasicTable {...typedBasicTableProps({
            colDefs: freshPlayerColDefs(),
            sortState: playerSortState,
            rowData: sortedPlayerLoans.value,
            rowKey: v => getTeamPlayerCoachSummary_PlayerKey(v),
            noData: () => <div class="p-4 flex items-center justify-center">No player loans</div>
          })}/>
        </div>
      )
    }
  }
})

type Coach = TeamPlayerCoachSummary["coaches"][number];

function headCoaches(coaches: TeamPlayerCoachSummary["coaches"]) {
  return coaches
    .filter(coach => coach.title === "Head Coach" || coach.title === "Co-Coach")
    .sort(coachSort)
}

function asstCoaches(coaches: TeamPlayerCoachSummary["coaches"]) {
  return coaches
    .filter(coach => coach.title === "Assistant")
    .sort(coachSort)
}

function adminCoaches(coaches: TeamPlayerCoachSummary["coaches"]) {
  return coaches
    .filter(coach => coach.title === "Administrator")
    .sort(coachSort)
}

const nameSort = sortByMany<{firstName: string, lastName: string}>(
  sortBy(_ => _.lastName),
  sortBy(_ => _.firstName)
);

const coachTitleSort = sortBy<Coach>(_ => _.title === "Head Coach" ? 0 : _.title === "Co-Coach" ? 1 : 2)
const coachSort = sortByMany<Coach>(
  coachTitleSort,
  nameSort,
)

const rosterSort = sortByMany<TeamPlayerCoachSummary>(
  sortBy(_ => _.team.divNum),
  sortBy(_ => _.team.teamLetter),
  sortBy(_ => _.team.divGender)
)

function freshCoachColDefs() {
  return inferColIDs<Coach>()([
    {
      id: "title",
      label: "Title",
      html: v => v.title,
      sort: coachTitleSort
    },
    {
      id: "name",
      label: "Name",
      html: v => v.firstName + " " + v.lastName,
      sort: nameSort
    },
    {
      id: "aysoID",
      label: "AYSOID",
      html: v => v.stackSID
    },
    {
      id: "phone",
      label: "Phone",
      html: v => maybePrettifyUSPhoneNumber(v.primaryPhone || ""),
    },
    {
      id: "email",
      label: "Email",
      html: v => v.email || "",
      sort: sortBy(_ => _.email || "")
    },
    {
      id: "certs",
      label: "Certification / training",
      html: v => v.volunteerCerts.map(v => v.certificationDesc).join(", ")
    }
  ])
}

function freshPlayerColDefs() {
  return inferColIDs<TeamPlayerCoachSummary_Player>()([
    {
      id: "aysoid",
      label: "AYSOID",
      html: v => v.registration.child.stackSID,
    },
    {
      id: "jersey",
      label: "Jersey",
      html: v => v.uniform,
      sort: sortBy(_ => parseIntOr(_.uniform, -1))
    },
    {
      id: "name",
      label: "Name",
      html: v => v.registration.child.playerFirstName + " " + v.registration.child.playerLastName,
      sort: sortByMany(
        sortBy(_ => _.registration.child.playerLastName),
        sortBy(_ => _.registration.child.playerFirstName)
      )
    },
    {
      id: "phone",
      label: "Phone",
      html: v => maybePrettifyUSPhoneNumber(v.registration.child.parent1Phone || ""),
    },
    {
      id: "email",
      label: "Email",
      html: v => v.registration.child.parent1Email,
    },
    {
      id: "birthdate",
      label: "Birthdate",
      html: v => dayjs(v.registration.child.playerBirthDate).format("MM/DD/YY"),
      sort: sortByDayJS(_ => dayjs(_.registration.child.playerBirthDate))
    },
    {
      id: "age",
      label: "Age",
      html: v => dayjs().diff(dayjs(v.registration.child.playerBirthDate), "years"),
      sort: sortByDayJS(_ => dayjs(_.registration.child.playerBirthDate))
    },
    {
      id: "regdate",
      label: "Registration date",
      html: v => dayjs(v.registration.dateCreated).format("M/D/YYYY"),
      sort: sortByDayJS(_ => dayjs(_.registration.dateCreated))
    }
  ])
}
