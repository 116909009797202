import { assertTruthy, exhaustiveCaseGuard, parseIntOrFail, requireNonNull, vReqT } from "src/helpers/utils"
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1"
import { defineComponent, ref, Ref, shallowRef } from "vue"

/**
 * Performance pitfalls:
 *  - use of getBoundingClientRect forces a style recalc each call; this is slow.
 *    Possible workaround: Consider using an IntersectionObserver to track element sizes?
 * - FontAwesome installs a MutationObserver on doc root for all SVG elements, which causes inserting/mutating SVG elements
 *   on the page to be extremely slow.
 *   see: https://github.com/FortAwesome/Font-Awesome/issues/14368
 *   This looks like it can be disabled via `noAuto` (i.e. `import { noAuto } from "@fortawesome/fontawesome-svg-core";`
 *   But there doesn't seem to an api to turn it back on? Do we need it? Can we disable it globally for the app and be OK?
 */
export const EdgeDrawer = defineComponent({
  props: {
    bracketContainerRef: vReqT<Ref<HTMLElement | null>>(),
    roundSlotElemRefTracker: vReqT<RoundSlotElemRefTracker>(),
    bracket: vReqT<Bracket>(),
  },
  emits: {
    edgeLayoutInfo: (_: Map<BracketRoundSlotID, EdgeLayoutInfo>) => true,
  },
  setup(props, ctx) {
    return () => {
      // hm ... side effects of rendering ...
      // well, as long as we don't write to anything reactive, this should be OK.
      // Ideally, we would lift the "compute edge info" and "render edges" into separate steps.
      // We have some parents who would like to know this info; meaning, they should probably compute it,
      // and then pass it to us and we would use it to draw with; rather than we compute it to draw the edges,
      // and then pass it up when we've figured it out.
      const m = new Map<BracketRoundSlotID, EdgeLayoutInfo>()

      // emit next tick, after `m` has been filled in
      setTimeout(() => ctx.emit("edgeLayoutInfo", m), 0);

      return (() => {
        const result : JSX.Element[] = []
        for (let i = 1; i < props.bracket.bracketRounds.length; i++) {
          const round = props.bracket.bracketRounds[i]

          for (const slot of round.roundSlots) {
            if (slot.type === "game") {
              const edgeLayoutInfo : EdgeLayoutInfo = {centerOfEdgeTo: new Map()}
              m.set(parseIntOrFail(slot.bracketRoundSlotID), edgeLayoutInfo)

              const self = props.roundSlotElemRefTracker.getOrFail(slot)
              const homeRef = slot.priorBracketRoundSlotID_home
                ? props.roundSlotElemRefTracker.maybeGet({bracketRoundSlotID: slot.priorBracketRoundSlotID_home})
                : undefined
              const visitorRef = slot.priorBracketRoundSlotID_visitor
                ? props.roundSlotElemRefTracker.maybeGet({bracketRoundSlotID: slot.priorBracketRoundSlotID_visitor})
                : undefined

              result.push(
                <OneEdgeSet
                  bracketContainerRef={props.bracketContainerRef}
                  homeSource={homeRef ? {bracketRoundSlotID: parseIntOrFail(slot.priorBracketRoundSlotID_home), elemRef: homeRef} : undefined}
                  visitorSource={visitorRef ? {bracketRoundSlotID: parseIntOrFail(slot.priorBracketRoundSlotID_visitor), elemRef: visitorRef} : undefined}
                  sink={self}
                  out_edgeLayoutInfo={edgeLayoutInfo}
                />
              )
            }
            else {
              // no-op
              continue;
            }
          }
        }

        return result
      })()
    }
  }
})

const OneEdgeSet = defineComponent({
  props: {
    bracketContainerRef: vReqT<Ref<HTMLElement | null>>(),
    homeSource: vReqT<undefined | {bracketRoundSlotID: number, elemRef: Ref<HTMLElement | null>}>(),
    visitorSource: vReqT<undefined | {bracketRoundSlotID: number, elemRef: Ref<HTMLElement | null>}>(),
    sink: vReqT<Ref<HTMLElement | null>>(),
    out_edgeLayoutInfo: vReqT<EdgeLayoutInfo>(),
  },
  setup(props) {
    const stroke = "black"
    const strokeWidth = "1"

    // need svg lines to be "in between" pixels for anti-aliasing reasons;
    // a line of width 1 placed "exactly on" a pixel looks blurry.
    // Hm, this seems to depend on stroke width, too. For a 1px stroke, we want it to fall on half-pixels.
    // For a 2px stroke, falling on whole pixels is fine.
    const pixelAdjust = (px: number) => {
      const int_strokeWidth = parseIntOrFail(strokeWidth)
      if (int_strokeWidth % 2 === 0) {
        return Math.round(px)
      }
      else {
        return Math.round(px) + .5
      }
    }

    return () => {
      const bracketContainer = props.bracketContainerRef.value
      const homeRef = props.homeSource?.elemRef.value
      const visitorRef = props.visitorSource?.elemRef.value
      const sink = props.sink?.value

      if (!bracketContainer || !homeRef || !visitorRef || !sink) {
        return null
      }

      const {above, below} = (() => {
        const m1Rect = relativeRect(homeRef, bracketContainer)
        const m2Rect = relativeRect(visitorRef, bracketContainer)
        return {
          above: m1Rect.y < m2Rect.y ? {rect: m1Rect, source: "home" as const} : {rect: m2Rect, source: "visitor" as const},
          below: m1Rect.y < m2Rect.y ? {rect: m2Rect, source: "visitor" as const} : {rect: m1Rect, source: "home" as const},
        }
      })();
      const sinkCoords = relativeRect(sink, bracketContainer)

      const radius = 10
      const r = radius

      const aMidY = pixelAdjust(above.rect.y + (above.rect.height / 2))
      const bMidY = pixelAdjust(below.rect.y + (below.rect.height / 2))
      const midMidY = pixelAdjust((above.rect.bottom + below.rect.top) / 2)

      // xpos start of horizontal line out of {source1, source2}
      const hx1 = pixelAdjust(above.rect.x + above.rect.width + 3)
      // xpos end of horizontal line out of {source1, source2}, adjusted to not include radius of arc at end
      const hx2 = hx1 + 20 - radius
      // xpos end of horizontal line from end of line out of {source1,source2} to sink
      const hx3 = sinkCoords.x - 3

      {
        const sinkRect = relativeRect(sink, bracketContainer)
        const aTextPos = sinkRect.top - 30
        const bTextPos = sinkRect.bottom + 30
        props.out_edgeLayoutInfo.centerOfEdgeTo.set(props.homeSource.bracketRoundSlotID, {x: hx2 + radius , y: above.source === "home" ? aTextPos : bTextPos})
        props.out_edgeLayoutInfo.centerOfEdgeTo.set(props.visitorSource.bracketRoundSlotID, {x: hx2 + radius, y: above.source === "visitor" ? aTextPos : bTextPos})
      }

      return <>
        <path d={`M ${hx1} ${aMidY} H ${hx2} a ${r},${r} 0 0 1, ${r},${r}`} fill="transparent" stroke={stroke} stroke-width={strokeWidth}/>
        <path d={`M ${hx1} ${bMidY} H ${hx2} a ${r},${r} 0 0 0, ${r},-${r}`} fill="transparent" stroke={stroke} stroke-width={strokeWidth}/>
        <path d={`M ${hx2 + r} ${aMidY + r} V ${bMidY - r}`} fill="transparent" stroke={stroke} stroke-width={strokeWidth}/>
        <path d={`M ${hx2 + r} ${midMidY} H ${hx3}`} fill="transparent" stroke={stroke} stroke-width={strokeWidth}/>
      </>
    }
  }
})

export interface Bracket {
  /**
   * PK
   */
  bracketID: Guid,
  bracketRounds: BracketRound[],
  seasonUID: Guid,
  // required?...
  competitionUID: Guid,
  divID: Guid,
}

export interface BracketRound {
  /**
   * PK
   */
  bracketRoundID: number,
  /**
   * FK, belongsTo bracket
   */
  bracketID: Guid,
  ordinal: number,
  name: string,
  roundSlots: BracketRoundSlot[]
}

export type BracketRoundSlot = BracketRoundSlot_Game | BracketRoundSlot_SingleTeam

interface BracketRoundSlot_Base {
  /**
   * PK
   */
  bracketRoundSlotID: Integerlike,
  /**
   * FK, belongs to bracket round
   */
  bracketRoundID: Integerlike,
  type: "game" | "single-team"
}

export interface BracketRoundSlot_Game extends BracketRoundSlot_Base {
  type: "game",
  gameID: "" | Guid,
  game?: unknown,
  priorBracketRoundSlotID_home: "" | Integerlike,
  priorBracketRoundSlotID_visitor: "" | Integerlike,
}

export interface BracketRoundSlot_SingleTeam extends BracketRoundSlot_Base {
  type: "single-team",
  teamID: Guid
  team?: unknown
}

/**
 * tracks DOM refs for each roundSlot element, used to later determine window positions
 * to draw SVG stuff between the elements.
 */
export type RoundSlotElemRefTracker = ReturnType<typeof RoundSlotElemRefTracker>
export function RoundSlotElemRefTracker() {
  type MinBracketRoundSlot = Pick<BracketRoundSlot, "bracketRoundSlotID">
  const bracketRoundDomRefKey = (slot: MinBracketRoundSlot) => slot.bracketRoundSlotID.toString()

  const domRefs = shallowRef(new Map<string, Ref<HTMLElement | null>>())

  const maybeGet = (slot: MinBracketRoundSlot) : undefined | Ref<HTMLElement | null> => {
    const m = domRefs.value
    const k = bracketRoundDomRefKey(slot)
    return m.get(k)
  }

  const getOrFail = (slot: MinBracketRoundSlot) : Ref<HTMLElement | null> => {
    return requireNonNull(maybeGet(slot))
  }

  const reinitFrom = (bracket: Bracket) : void => {
    const m = new Map<string, Ref<HTMLElement | null>>()
    for (const round of bracket.bracketRounds) {
      for (const slot of round.roundSlots) {
        const k = bracketRoundDomRefKey(slot)
        assertTruthy(!m.has(k))
        m.set(k, ref(null))
      }
    }
    domRefs.value = m;
  }

  const add = (slot: MinBracketRoundSlot) : void => {
    const k = bracketRoundDomRefKey(slot)
    const m = domRefs.value
    assertTruthy(!m.has(k))
    m.set(k, ref(null))
  }

  return {
    getOrFail,
    maybeGet,
    reinitFrom,
    add
  }
}

function relativeRect(elem: HTMLElement, relativeToThis: HTMLElement) {
  const b1 = elem.getBoundingClientRect()
  const b2 = relativeToThis.getBoundingClientRect()

  const x = b1.x - b2.x
  const y = b1.y - b2.y

  return {
    x,
    y,
    height: b1.height,
    width: b1.width,
    bottom: y + b1.height,
    top: y
  }
}

export type BracketRoundSlotID = number
export interface EdgeLayoutInfo {
  centerOfEdgeTo: Map<BracketRoundSlotID, {x: number,  y: number}>
}
