import { Axios, AxiosInstance } from "axios";
import { Guid, DateTimelike, Integerlike, Numbool, Datelike, IL_DayOfWeek, SeasonTriple, Division, CoachTitle } from "src/interfaces/InleagueApiV1";
import { GetCompletionsAtPositionOptions } from "typescript";
import { Conflict } from "./InleagueApiV1.GameSchedulerXlsx";

export {
  bulkUpdateGamesAndFieldBlocks,
  clearGameTeamAssignments,
  createFieldBlocks,
  createGames,
  deleteGamesAndFieldBlocks,
  getGamesAndBlocksForGameSchedulerView,
  getNonDependentCrudAndViewOptions,
  getCompDependentViewOptions,
  getCompDependentCrudGameOptions,
  getCompDivSeasonDependentCrudGameOptions,
  updateFieldBlock,
  updateGame,
}

export type {
  CreateFieldBlockRequest,
  CreateGameRequest,
  FieldBlockForGameSchedulerView,
  GameForGameSchedulerView,
  GetNonDependentCrudAndViewOptionsResponse,
  GetCompDependentViewOptionsResponse,
  GetCompDependentCrudGameOptionsResponse,
  GetCompDivSeasonDependentCrudGameOptionsResponse,
  GetGamesAndBlocksForGameSchedulerViewResponse,
  UpdateFieldBlockRequest,
  UpdateGameRequest,
}

interface GameForGameSchedulerView {
  gameID: Guid,
  gameStart: DateTimelike,
  gameEnd: DateTimelike,
  fieldUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  roundID: string, // guid?
  visitorTeamID: "TBD" | Guid,
  visitorTeamDesignation: string,
  visitorTeamName: string,
  homeTeamID: "TBD" | Guid,
  homeTeamDesignation: string,
  homeTeamName: string,
  poolID: "ALL" | Integerlike,
  genderNeutral: Numbool,
  playoff: Numbool,
  pointsCount: Numbool,
  blockFromMatchmaker: Numbool,
  comment: string,
  seasonUID: Guid,
  seasonName: string,
  competition: string,
  division: string,
  gameNum: number,
  fieldName: string,
  coaches: {
    /**
     * TeamID here will be either the outer homeTeamID or visitorTeamID;
     * if home or visitor is TBD there will be no coaches for that particular team
     */
    teamID: Guid,
    seasonUID: Guid, // should always match game season
    firstName: string,
    lastName: string,
    title: CoachTitle
  }[]
}

interface FieldBlockForGameSchedulerView {
  /**
   * Field block PK
   */
  id: Integerlike,
  fieldUID: Guid,
  fieldName: string,
  slotStart: DateTimelike,
  slotEnd: DateTimelike,
  comment: string,
}

interface GetGamesAndBlocksForGameSchedulerViewResponse {
  games: GameForGameSchedulerView[],
  fieldBlocks: FieldBlockForGameSchedulerView[]
}

async function getGamesAndBlocksForGameSchedulerView(
  ax: AxiosInstance, args: {
    competitionUIDs: Guid[],
    divIDs: Guid[],
    fieldUIDs: Guid[],
    dateFromInclusive: DateTimelike,
    dateToInclusive: DateTimelike
  } | {gameIDs: Guid[], fieldBlockIDs: Integerlike[]}
) : Promise<GetGamesAndBlocksForGameSchedulerViewResponse> {
  let params : Record<string, any> = {}

  if ("gameIDs" in args) {
    params = {
      gameIDs: args.gameIDs,
      fieldBlockIDs: args.fieldBlockIDs,
    }
  }
  else {
    params = {
      competitionUIDs: args.competitionUIDs,
      divIDs: args.divIDs,
      fieldUIDs: args.fieldUIDs,
      dateFromInclusive: args.dateFromInclusive,
      dateToInclusive: args.dateToInclusive,
    }
  }

  const response = await ax.get(`v1/gameScheduler/gameSchedulerView/gamesAndBlocks`, {params})
  return response.data.data;
}

/**
 * For "display options", divisions is not currently considered dependent.
 * We can have a set of permissions something like
 *  - comp-a div-a
 *  - comp-b div-b
 * And where a user selects "comp-a" and "div-b", which may or may not yield games based on permission, but they won't have permission to edit them.
 * Currently the ui doesn't surface a hierarchical display options menu.
 *
 * Note that the "actions" (create game, edit game) surfaces dependent options, where available divisions may change based on the selected competition.
 * It is worth repeating here that a competition is the "root" of such a menu hierarchy, and so is never dependent, and so a user's competitions can always be
 * determined from the results here.
 */
interface GetNonDependentCrudAndViewOptionsResponse {
  competitions: {competitionUID: Guid, competition: string, startDayOfWeek: IL_DayOfWeek}[],
  fields: {fieldUID: Guid, fieldID: Integerlike, fieldAbbrev: string, fieldName: string}[],
}

async function getNonDependentCrudAndViewOptions(ax: AxiosInstance) : Promise<GetNonDependentCrudAndViewOptionsResponse> {
  const response = await ax.get(`v1/gameScheduler/gameSchedulerView/options`);
  return response.data.data;
}

interface GetCompDependentViewOptionsResponse {
  divisions: Division[]
}

async function getCompDependentViewOptions(ax: AxiosInstance, args: {competitionUIDs: Guid[]}) : Promise<GetCompDependentViewOptionsResponse> {
  if (args.competitionUIDs.length === 0) {
    // axios won't serialize empty arrays
    return {
      divisions: []
    }
  }

  const response = await ax.get(`v1/gameScheduler/gameSchedulerView/options`, {params: {viewCompetitionUIDs: args.competitionUIDs}});

  return response.data.data;
}

interface GetCompDependentCrudGameOptionsResponse {
  seasons: {seasonUID: Guid, seasonName: string, seasonID: Integerlike}[],
  divisions: Division[],
  competition: {
    /**
     * guaranteed to be an element in the sibling season options list
     */
    currentSeason: SeasonTriple
  }
}

async function getCompDependentCrudGameOptions(ax: AxiosInstance, args: {competitionUID: Guid}) : Promise<GetCompDependentCrudGameOptionsResponse> {
  const {competitionUID} = args
  const response = await ax.get(`v1/gameScheduler/gameSchedulerView/options`, {params: {competitionUID}})
  return response.data.data;
}

interface GetCompDivSeasonDependentCrudGameOptionsResponse {
  teams: {
    teamID: Guid,
    teamDesignation: string,
    teamName: string,
    coaches: {
      teamID: Guid, // should match outer teamID value
      seasonUID: Guid, // should always match the "contextual" season
      firstName: string,
      lastName: string,
      title: CoachTitle
    }[]
  }[],
  pools: {poolName: string, poolID: Integerlike, poolUID: Guid}[],
}

async function getCompDivSeasonDependentCrudGameOptions(ax: AxiosInstance, args: {competitionUID: Guid, divID: Guid, seasonUID: Guid}) : Promise<GetCompDivSeasonDependentCrudGameOptionsResponse> {
  const {competitionUID, divID, seasonUID} = args
  const response = await ax.get(`v1/gameScheduler/gameSchedulerView/options`, {params: {competitionUID, divID, seasonUID}})
  return response.data.data;
}

interface UpdateGameRequest {
  gameID: Guid,
  startDate?: Datelike,
  startHour?: Integerlike,
  startMinute?: Integerlike,
  gameDurationMinutes?: Integerlike,
  homeTeamID?: "TBD" | Guid,
  visitorTeamID?: "TBD" | Guid,
  competitionUID?: Guid,
  divID?: Guid,
  fieldUID?: Guid,
  poolID?: "ALL" | Integerlike,
  genderNeutral?: boolean,
  playoff?: boolean,
  pointsCount?: boolean,
  blockFromMatchmaker?: boolean,
  comment?: string,
  seasonUID?: Guid,
  roundID?: null | Guid,
}

async function updateGame(ax: AxiosInstance, args: UpdateGameRequest) : Promise<GameForGameSchedulerView> {
  const v = await bulkUpdateGamesAndFieldBlocks(ax, {games: [args], fieldBlocks: []});
  return v.games[0]
}

interface UpdateFieldBlockRequest {
  id: Integerlike,
  startDate?: DateTimelike,
  startHour?: Integerlike,
  startMinute?: Integerlike,
  lengthMinutes?: Integerlike,
  fieldUID?: Guid,
  comment?: string,
}

async function updateFieldBlock(ax: AxiosInstance, args: UpdateFieldBlockRequest) : Promise<FieldBlockForGameSchedulerView> {
  const v = await bulkUpdateGamesAndFieldBlocks(ax, {games: [], fieldBlocks: [args]});
  return v.fieldBlocks[0]
}

async function deleteGamesAndFieldBlocks(ax: AxiosInstance, args: {gameIDs: Guid[], fieldBlockIDs: Integerlike[]}) : Promise<void> {
  await ax.delete(`v1/gameScheduler/gameSchedulerView/gamesAndBlocks`, {params: {gameIDs: args.gameIDs, fieldBlockIDs: args.fieldBlockIDs}});
}

async function bulkUpdateGamesAndFieldBlocks(
  ax: AxiosInstance,
  args: {games: UpdateGameRequest[], fieldBlocks: UpdateFieldBlockRequest[]}
) : Promise<{games: GameForGameSchedulerView[], fieldBlocks: FieldBlockForGameSchedulerView[]}> {
  const response = await ax.put(`v1/gameScheduler/gameSchedulerView/gamesAndBlocks`, args);
  return response.data.data
}

/**
 * for game start, provide either
 *  - just (`dateTime`)
 *  - all of (`startDate`, `startHour`, `startMinute`)
 */
interface CreateGameRequest {
  competitionUID: Guid,
  divID: Guid,
  fieldUID: Guid,
  slotCount: Integerlike,
  dateTime?: DateTimelike,
  startDate?: Datelike,
  startHour?: Integerlike,
  startMinute?: Integerlike
  slotGameDurationMinutes: Integerlike,
  repeatWeeks: Integerlike,
  comment: string,
  playoff: boolean,
  pointsCount: boolean,
  genderNeutral: boolean,
  blockFromMatchmaker: boolean,
  poolID: "ALL" | Integerlike,
  scheduleEvenIfBlocked: boolean,
  acknowledgedConflicts: Conflict[],
  tags: string[],
  homeTeamID?: Guid,
  visitorTeamID?: Guid,
}

/**
 * Supports "many requests" for the XLSX feature
 */
async function createGames(ax: AxiosInstance, args: {each: CreateGameRequest[]}) : Promise<GameForGameSchedulerView[]> {
  const response = await ax.post(`v1/gameScheduler/gameSchedulerView/games`, args)
  return response.data.data;
}

/**
 * for game start, provide either
 *  - just (`dateTime`)
 *  - all of (`startDate`, `startHour`, `startMinute`)
 */
interface CreateFieldBlockRequest {
  fieldUID: Guid,
  dateTime?: DateTimelike,
  startDate?: Datelike,
  startHour?: Integerlike,
  startMinute?: Integerlike,
  lengthMinutes: Integerlike,
  repeatWeeks: Integerlike,
  comment: string,
  scheduleEvenIfBlocked: boolean,
}

async function createFieldBlocks(ax: AxiosInstance, args: CreateFieldBlockRequest) : Promise<FieldBlockForGameSchedulerView[]> {
  const response = await ax.post(`v1/gameScheduler/gameSchedulerView/fieldBlocks`, args);
  return response.data.data;
}

async function clearGameTeamAssignments(ax: AxiosInstance, args: {competitionUID: Guid, divID: Guid, dateFromInclusive: Datelike, dateToInclusive: Datelike}) : Promise<void> {
  const response = await ax.delete(`v1/gameScheduler/teamAssignments`, {params: args})
  return response.data.data
}
