import { vOptT } from "src/helpers/utils"
import { defineComponent } from "vue"

export const btn2_defaultEnabledClasses = "bg-green-800 hover:bg-green-700 active:bg-green-500 cursor-pointer"
export const btn2_disabledButLooksEnabledClasses = "bg-green-800"
export const btn2_redEnabledClasses = "bg-red-800 hover:bg-red-700 active:bg-red-500 cursor-pointer"

/**
 * Thin convenience wrapper around \<button\>. Defaults to "type=button".
 * Intended as a replacement for `<t-btn/>`, prefer this over t-btn.
 */
export const Btn2 = defineComponent({
  props: {
    commonClasses: vOptT("text-white font-medium rounded-md text-sm"),
    enabledClasses: vOptT(btn2_defaultEnabledClasses),
    disabledClasses: vOptT("bg-gray-200"),
    transitionClasses: vOptT("transition ease-out duration-150"),
    disabled: vOptT(false),
    type: vOptT<"submit" | "button">("button"),
  },
  emits: {
    click: () => true,
  },
  setup(props, {emit, slots}) {
    return () => {
      return (
        <button
          type={props.type}
          class={[props.commonClasses, props.transitionClasses, props.disabled ? props.disabledClasses : props.enabledClasses]}
          disabled={props.disabled}
          onClick={() => emit("click")}
        >
          {slots.default?.()}
        </button>
      )
    }
  }
})
