import dayjs, { Dayjs } from 'dayjs'

export const dayJSDate = (date: string) => {
  // console.log('DATE: ', dayjs(date).format('M-D-YYYY'))
  return dayjs(date).format('M-D-YYYY')
}

export const dayJSTime = (date: string) => {
  return dayjs(date).format('H:mm a')
}

export const datePickerFormat = (date: dayjs.Dayjs | string | undefined | null) => {
  if (!date) {
    return "";
  }

  const v = dayjs.isDayjs(date) ? date : dayjs(date);
  return v.isValid() ? v.format('YYYY-MM-DD') : "";
}

export const getWeekDay = (date: string) => {
  const weekNum = new Date(date).getDay()
  switch (weekNum) {
    case 0:
      return 'Sunday'
    case 1:
      return 'Monday'
    case 2:
      return 'Tuesday'
    case 3:
      return 'Wednesday'
    case 4:
      return 'Thursday'
    case 5:
      return 'Friday'
    case 6:
      return 'Saturday'
  }
  return null
}
export const getMonth = (date: string) => {
  const weekDate = new Date(date).getMonth()
  switch (weekDate) {
    case 0:
      return 'January'
    case 1:
      return 'February'
    case 2:
      return 'March'
    case 3:
      return 'April'
    case 4:
      return 'May'
    case 5:
      return 'June'
    case 6:
      return 'July'
    case 7:
      return 'August'
    case 8:
      return 'September'
    case 9:
      return 'October'
    case 10:
      return 'November'
    case 11:
      return 'December'
    default:
      return 'Not a valid Date'
  }
}

export const getOrdinalDay = (date: string) => {
  const num = `${new Date(date).getDate()}`
  // console.log('num', num)
  if (num.endsWith('11') || num.endsWith('12') || num.endsWith('13')) {
    return num + 'th'
  } else if (num.endsWith('0')) {
    return num + 'th'
  } else if (num.endsWith('1')) {
    return num + 'st'
  } else if (num.endsWith('2')) {
    return num + 'nd'
  } else if (num.endsWith('3')) {
    return num + 'rd'
  } else {
    return num + 'th'
  }
}

// return time in 12:21pm format
export const formatTime = (date: string) => {
  const realDate = new Date(date)
  let hour = realDate.getHours()
  let minutes = `${realDate.getMinutes()}`
  let period = 'am'
  // console.log(hour)

  if (hour >= 12 && hour < 24) {
    if (hour > 12) hour -= 12
    period = 'pm'
  }

  if (minutes.length < 2) {
    minutes = '0' + minutes
  }

  if (hour === 0) hour += 12

  return `${hour}:${minutes} ${period}`
}

export const addDays = (date: string, numOfDays = 1) => {
  const givenDate = new Date(date)
  const returnDate = new Date(
    givenDate.setDate(givenDate.getDate() + numOfDays)
  )
  return returnDate
}

export const subtractWeeks = (date: string, numOfWeeks = 1) => {
  const givenDate = new Date(date)
  const returnDate = new Date(
    givenDate.getTime() - (7 * numOfWeeks + 1) * 24 * 60 * 60 * 1000
  )
  return returnDate
}

export const addWeeks = (date: string, numOfWeeks = 1) => {
  const givenDate = new Date(date)
  const returnDate = new Date(
    givenDate.getTime() + (7 * numOfWeeks + 1) * 24 * 60 * 60 * 1000
  )
  return returnDate
}

export const subtractMonths = (date: string, numOfMonths = 1) => {
  const givenDate = new Date(date)
  const m = givenDate.getMonth()
  givenDate.setMonth(givenDate.getMonth() - numOfMonths)

  if (givenDate.getMonth() == m) givenDate.setDate(0)
  return givenDate
}

// takes two dates and returns
//Wednesday, January 1st 2020, 12:21 pm  - Thursday, January 2nd 2020, 12:21 pm format
// OR Wednesday, January 1st 2020, 12:21 pm - 1:21 pm format
export const formatDates = (startDate: string, endDate: string) => {
  // console.log(startDate, endDate)
  const startArr = startDate.split(' ')
  const endArr = endDate.split(' ')
  // console.log(startArr, endArr)
  const sDate = new Date(startDate)
  const eDate = new Date(endDate)
  if (
    startArr[0] === endArr[0] &&
    startArr[1] === endArr[1] &&
    startArr[2] === endArr[2]
  ) {
    return `${getWeekDay(startDate)}, ${getMonth(startDate)} ${getOrdinalDay(
      startDate
    )} ${sDate.getFullYear()}, ${formatTime(startDate)} - ${formatTime(
      endDate
    )}`
  } else {
    return `${getWeekDay(startDate)}, ${getMonth(startDate)} ${getOrdinalDay(
      startDate
    )} ${sDate.getFullYear()}, ${formatTime(startDate)} - ${getWeekDay(
      endDate
    )}, ${getMonth(endDate)} ${getOrdinalDay(
      endDate
    )} ${eDate.getFullYear()},${formatTime(endDate)}`
  }
}

// returns date in Wednesday, January 1st 2020, 12:21 pm format
export const formatDate = (date: string) => {
  return `${getWeekDay(date)}, ${getMonth(date)} ${getOrdinalDay(
    date
  )}, ${new Date(date).getFullYear()}, ${formatTime(date)}`
}

// returns date in Jan 1st 20, 12:21pm format
export const formatDateShort = (date: string) => {
  return `${getMonth(date).slice(0, 3)}. ${getOrdinalDay(date)} ${(
    `${new Date(date).getFullYear()}`
  ).slice(2)}, ${formatTime(date)}`
}

// returns date in Jan 1st 20, 12:21pm format
export const formatDateOnlyShort = (date: string) => {
  return `${getMonth(date).slice(0, 3)} ${new Date(date).getDate()}, ${
    `${new Date(date).getFullYear()}`
  }`
}

// returns January 1st, 2020
export const formatDateWithoutTime = (date: string) => {
	const fixedDate = new Date(date)
  fixedDate.setHours(0)
  fixedDate.setMinutes(0)
  fixedDate.setSeconds(0)
  const UTCDate = fixedDate.toUTCString()
  const dateObj = new Date(UTCDate)
  // console.log(
  //   'formatDateWithoutTime',
  //   fixedDate,
  //   fixedDate.toUTCString(),
  //   date,
  //   fixedDate.getMonth(),
  //   getOrdinalDay(fixedDate.toUTCString()),
  //   fixedDate.getFullYear()

  // )
  return `${getMonth(UTCDate)} ${getOrdinalDay(UTCDate)}, ${dateObj.getFullYear()}`
}

// return 1/1/20 format
export const formatDateAsNums = (date: string) => {
  // console.log('formatDateAsNums', date)
  const trueDate = new Date(date)
  return `${trueDate.getMonth() + 1}/${trueDate.getDate()}/${(
    `${trueDate.getFullYear()}`
  ).slice(2)}`
}

export const formatDateWithDashes = (date: string) => {
  // console.log('date', date)
  const trueDate = new Date(date)
  // console.log('trueDate', trueDate)
  // console.log('what is returned', trueDate.getMonth() + 1, trueDate.getDate(), trueDate.getFullYear() )
  return `${trueDate.getMonth() + 1}-${trueDate.getDate()}-${
    `${trueDate.getFullYear()}`
  }`
}

// returns boolean if two dates are on the same day
export const sameDay = (firstDate: string, secondDate: string) => {
  const fDate = new Date(firstDate)
  const sDate = new Date(secondDate)
  return (
    fDate.getFullYear() === sDate.getFullYear() &&
    fDate.getMonth() == sDate.getMonth() &&
    fDate.getDate() === sDate.getDate()
  )
}

export const sameMinute = (firstDate: string, secondDate: string) => {
  const fDate = new Date(firstDate)
  const sDate = new Date(secondDate)
  // console.log('dates', fDate, sDate)
  // console.log('years', fDate.getFullYear() === sDate.getFullYear())
  // console.log('months', fDate.getMonth() == sDate.getMonth())
  // console.log('date', fDate.getDate() === sDate.getDate())
  // console.log('hours', fDate.getHours() === sDate.getHours())
  // console.log('minutes', Math.abs(fDate.getMinutes() - sDate.getMinutes()) < 2)

  return (
    fDate.getFullYear() === sDate.getFullYear() &&
    fDate.getMonth() == sDate.getMonth() &&
    fDate.getDate() === sDate.getDate() &&
    fDate.getHours() === sDate.getHours() &&
    Math.abs(fDate.getMinutes() - sDate.getMinutes()) < 2
  )
}

export const currentAYSOYear = () => {
  //TESTING DATES
  const todaysDate = new Date()
  const cutOffDate = new Date(todaysDate.getFullYear(), 7, 1)
  let currentAYSOYear = 0
  if (todaysDate < cutOffDate) {
    currentAYSOYear = todaysDate.getFullYear() - 1
  } else {
    currentAYSOYear = todaysDate.getFullYear()
  }
  // console.log('TESTING DATES')
  // console.log(todaysDate, cutOffDate, currentAYSOYear)
  return currentAYSOYear
}

// returns the current age of a person based off their birthdate
export const calculateAge = (date: string) => {
  const today = new Date()
  const birthDate = new Date(date)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--
  return age
}

// Returns the next date after d, that import {  } from "module"; the day of the week specified by dow
export const nextDay = (d: Date, dow: number) => {
  // console.log('nextDay', d, dow)
  d.setDate(d.getDate() + ((dow + (7 - d.getDay())) % 7))
  // console.log('dddd', d)
  return d
}

export const createWeekOptions = (
  seasonStart: Date,
  dow: number,
  weeks: number,
  displayStart?: Date
) => {
  // console.log('createWeekOptions', seasonStart, dow, weeks)
  // Javascript starts its dates with Mon = 1, whereas the API starts its dates with Sun = 1. This line handles the discrepancy
  dow = dow - 1 === 0 ? 7 : dow - 1
  let startDay = nextDay(new Date(seasonStart), dow)
  // console.log('after start day', startDay)
  // if display start not provided, start display at todays date
  displayStart = displayStart ? displayStart : new Date()

  // console.log('startDay', startDay, 'displayStart', displayStart)

  // if (startDay < displayStart) {
  //   alert('Error: Season is in the past!')
  // }

  const displayWeeks: { [key: string]: string } = {}
  // console.log('before for loop :)', startDay, displayStart, weeks)
  for (let i = 0; i < weeks; i++) {
    // console.log('START-DAY', startDay, 'DISPLAY-Start', displayStart)
    if (startDay.setHours(0,0,0,0) >= displayStart.setHours(0,0,0,0)) {
      // console.log('in if statement')
      displayWeeks[
        startDay.toString()
      ] = formatDateOnlyShort(startDay.toString()).toString()
    }
    startDay = addDays(startDay.toString(), 7)
  }
  // console.log('displayWeeks', displayWeeks)
  return displayWeeks
}

/**
 * Formats a date for an input element
 *
 * If the date is an empty string, returns an empty string
 */
export const formatForDateInput = (date: string) => {
  if(date.length>0){
  const dateArray = date.split('-')
  const dateObj = new Date(date)
  const month = dateObj.getMonth() + 1 //padStart(2, 0)}`
  const day = dateObj.getDate()
  // console.log('formatDate returns: ', `${dateObj.getFullYear().toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`, 'incoming: ', date, 'dateObj: ', dateObj)
  return `${dateObj.getFullYear().toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
  } else {
    return ''
  }
}

/**
 * assign the time portion of `rhs` to a copy of `lhs`
 */
export function setTimeParts(lhs: Dayjs, rhs: Dayjs) : Dayjs;
/**
 * assign the time described by `parts` to the time portion of a copy of `lhs`
 */
export function setTimeParts(lhs: Dayjs, parts: [hr24: number, minute: number, second: number, millisecond: number]) : Dayjs;
export function setTimeParts(lhs: Dayjs, rhs: Dayjs | [hr24: number, minute: number, second: number, millisecond: number]) {
  const partNames = ["hour", "minute", "second", "millisecond"] as const;
  let working = lhs;
  if (Array.isArray(rhs)) {
    for (let i = 0; i < partNames.length; ++i) {
      const partName = partNames[i];
      working = working.set(partName, rhs[i]);
    }
  }
  else {
    for (let i = 0; i < partNames.length; ++i) {
      const partName = partNames[i];
      working = working.set(partName, rhs[partName]());
    }
  }
  return working;
}

/**
 * like `December 21, 2022 12:55:44`
 *
 * This is generally the format InleagueApiV1 endpoints will use for sending date fields to the client.
 * This format should parse correctly without any additional coaxing of dayjs, e.g.
 * ```
 * dayjs(dayjs().format(defaultCfFormatString)).isValid() === true
 * ```
 */
export const defaultDayjsCfFormatString = "MMMM DD, YYYY HH:mm:ss";

/**
 * Format some datelike as per the format string,
 * but if the datelike is unparseable, revert to some fallback, which by default is the unformatted datelike itself.
 */
export function dayjsFormatOr(datelike: string | undefined | null, formatString: string, fallback = (datelike ?? "")) : string {
  if (!datelike) {
    return fallback;
  }
  const v = dayjs(datelike);
  if (v.isValid()) {
    return v.format(formatString);
  }
  else {
    return fallback;
  }
}

/**
 * Return a dayjs object if the date was parseable, otherwise undefined
 */
export function dayjsOr(datelike_or_datetimelike: any) : Dayjs | undefined {
  if (!datelike_or_datetimelike) {
    // `dayjs(undefined)` is same as `dayjs()`
    return undefined
  }
  const v = dayjs(datelike_or_datetimelike);
  return v.isValid() ? v : undefined;
}

/**
 * ex. "2023-08-07"
 */
export const DAYJS_FORMAT_HTML_DATE = "YYYY-MM-DD";

/**
 * ex. "2023-08-07T13:46"
 */
export const DAYJS_FORMAT_HTML_DATETIME = "YYYY-MM-DDTHH:mm"

/**
 * api "localtime" is a zoneless datetime string.
 * Most dates on the server are zoneless, with an implicit "league" timezone.
 * So "3pm" on the client is "3pm" on the server, no matter the difference
 * between client timezone and server timezone.
 */
export const DAYJS_FORMAT_IL_API_LOCALDATETIME = "YYYY-MM-DD HH:mm"
export const DAYJS_FORMAT_IL_API_LOCALDATE = "YYYY-MM-DD"

/**
 * ex. "Thu January 1, 1970 12:00 am"
 *
 * There might be a few more common format strings:
 *  - If we're showing a bunch of dates, like on a schedule, we will usually say '5/4/24' or 'Sat 5/4/24'
 *  - If we're only showing one or two dates, or for any reason have the real estate to be more explicit, we'll do 'Sat May 5, 2024'
 */
export const DAYJS_FORMAT_IL_FULL_1 = "ddd MMMM D, YYYY h:mm a"
