<template lang="pug">
div
  //- Off-canvas menu for mobile
  div(class='md:hidden')
    .h-screen.fixed.inset-0.flex.z-40(v-if='openMobile')
      //-
        Off-canvas menu overlay, show/hide based on off-canvas menu state.
        Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
      transition(
        enter-active-class='transition-opacity ease-linear duration-300',
        enter-class='opacity-0',
        enter-to-class='opacity-100',
        leave-active-class='transition-opacity ease-linear duration-300',
        leave-class='opacity-100',
        leave-to-class='opacity-0'
      )
        .fixed.inset-0(@click='openMobile = false')
          .absolute.inset-0.bg-gray-600.opacity-75
      //-
        Off-canvas menu- show/hide based on off-canvas menu state.
        Entering: "transition ease-in-out duration-300 transform"
        From: "-translate-x-full"
        To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
        From: "translate-x-0"
        To: "-translate-x-full"
      transition(
        enter-active-class='transition ease-in-out duration-300 transform',
        enter-class='-translate-x-full',
        enter-to-class='translate-x-0',
        leave-active-class='transition ease-in-out duration-300 transform',
        leave-class='translate-x-0',
        leave-to-class='-translate-x-full'
      )
        //- I AM THE DASHBOARD
        .relative.flex-1.flex.flex-col.max-w-xs.w-full.pt-5.pb-4.bg-green-900(
          data-cy='mobile-sidebar'
        )
          //- I AM THE CLOSE BUTTON
          .absolute.top-0.right-0.-mr-14.p-1
            button.fix-notch-top.flex.items-center.justify-center.h-12.w-12.rounded-full(
              @click='openMobile = !openMobile',
              class='focus:outline-none focus:bg-gray-600',
              aria-label='Close sidebar',
              data-cy='close-sidebar'
            )
              svg.h-6.w-6.text-white(
                stroke='currentColor',
                fill='none',
                viewbox='0 0 24 24'
              )
                path(
                  stroke-linecap='round',
                  stroke-linejoin='round',
                  stroke-width='2',
                  d='M6 18L18 6M6 6l12 12'
                )
          //- I AM THE WORKFLOW TITLE/LOGO
          .mt-5.flex-1.h-0.align-center.overflow-y-auto.text-center.text-3xl.text-white
            .text-center.text-3xl.text-white(@click='goHome')
              img(:src='clientTheme.banner', data-cy='leagueBanner')
            Links
  //- Static sidebar for desktop
  .hidden(class='md:flex md:flex-shrink-0')
    .flex.flex-col.w-64.bg-green-900.h-screen.fixed.z-40
      .text-center.text-3xl.text-white(@click='goHome')
        img(:src='clientTheme.banner', data-cy='leagueBanner')
      .py-2.h-0.flex-1.flex.flex-col.overflow-y-auto
        Links
</template>

<script lang='ts'>
import { RouteRecordRaw } from 'vue-router'
import { useRouter } from 'vue-router'
import routes from 'src/router/routes'
import authService from 'src/helpers/authService'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { defineComponent, watch, computed, ref, Ref } from 'vue'
import Links from 'src/components/Navigational/Links.vue'
import { UserData } from 'src/interfaces/Store/user'

import { System } from 'src/store/System'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'

export default defineComponent({
  name: 'Sidebar',
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Links
  },
  setup() {
    // Data

    const router = useRouter()
    const openMobile = ref(false)
    const userRoutes = ref([]) as Ref<RouteRecordRaw[]>

    const getRoles = computed(() => {
      return User.value.roles
    })

    const isLoggedIn = computed(() => {
      return User.isLoggedIn
    })

    const isImpersonating = computed(() => {
      return (User.value.userData as UserData).isImpersonating
    })

    const clientTheme = computed(() => {
      return Client.value.clientTheme
    })

    const openSidebar = computed(() => {
      return System.value.openSidebar
    })

    const isMobile = computed(() => {
      return System.value.isMobile
    })

    //
    // is there any similarity we can share between this and Links.vue:createLinks?
    // seems like the logic is mostly the same; if it is very different it might help to clarify why
    //
    const createLinks = () => {
      const displayRoutes: RouteRecordRaw[] = []
      for (const key in routes) {
        const auth = routes[key]?.meta?.auth
        if (key === 'home') {
          // Needed because default '/' route must come last but home comes first
          displayRoutes.unshift(routes[key])
        } else if (isLoggedIn.value && routes[key].meta?.requiresNoAuth) {
          // Skip: Only logged out users should get this nav item
        } else if (!isLoggedIn.value && !routes[key].meta?.requiresAuth) {
          displayRoutes.push(routes[key])
        } else if (isLoggedIn.value && auth?.length === 0) {
          displayRoutes.push(routes[key])
        } else if (
          auth && auth !== "DEFER-TO-BEFORE-ENTER" && authService(getRoles.value as unknown as string[], ...auth)
        ) {
          displayRoutes.push(routes[key])
        }
      }
      return displayRoutes
    }
    userRoutes.value = createLinks()

    const goHome = async () => {
      openMobile.value = !openMobile.value
      if(isMobile.value) {
        await router.push({name: 'mobile-landing'})
      } else {
        await router.push({name: 'home'})
      }
    }

    // onCreated

    watch(openSidebar, bool => {
      // console.log('openSidebar changed', bool)
      openMobile.value = bool
    })

    watch(openMobile, (bool: boolean) => {
      System.directCommit_setOpenSidebar(bool)
    })

    // onLoginChanged
    watch([isLoggedIn, isImpersonating], () => {
      userRoutes.value = createLinks()
    })

    return {
      isMobile,
      openMobile,
      clientTheme,
      userRoutes,
      getRoles,
      isLoggedIn,
      goHome
    }
  },
})
</script>




