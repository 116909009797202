<template lang="pug">
div
  //-
  //- There can be a single top-level modal set from anywhere in the application.
  //- It's not "route based", it just sits on top of any route.
  //-
  Modal(
    v-bind="globalTopLevelModalState.props"
    v-on="globalTopLevelModalState.emitsHandlers"
  )
    template(v-slot:title)
      component(
        v-if="globalTopLevelModalState.slots.title"
        :is="globalTopLevelModalState.slots.title"
      )
    template(v-slot:content)
      component(
        v-if="globalTopLevelModalState.slots.content"
        :is="globalTopLevelModalState.slots.content"
      )
  .w-full.h-full.fixed.block.top-0.left-0.bg-white.bg-opacity-75(
    style='z-index: 88888',
    v-if='loading'
  )
    template(v-if="customGlobalSpinner")
      component(:is="customGlobalSpinner")
    template(v-else)
      span.block.relative.flex.justify-center.items-center.h-full
        TLoader

  //-
  //- we route to the actual /login route now, in response to this bool flipping to true,
  //- so this is not necessary ... right?
  //- No -- this is necessary for the `app/` route in the not-logged-in-case
  //-
  LoginModalPage(v-if='showLoginModal')

  //- 100vh here is so that the "main container" is always at least the height of the screen
  //- it can of course be larger for content that must scroll
  .flex.bg-grey-100(style="min-height:100vh;")
    Sidebar
    //- iOS Forward/Back Nav Bar
    Navbar(v-if='devicePlatform === "ios"')
    //- Router view
    .flex.flex-col.w-0.flex-1.layout-body(
      class='md:pl-64',
      :data-cy='"route_" + $route.name'
      :style="routerViewRootStyles"
    )
      main.flex-1.py-15(
        class='focus:outline-none il-new-stacking-context',
        :style="kludgyMainContainerStyles"
        tabindex='0'
      )
        Header(class="sticky top-0 left-0" style="z-index:1;")
        div(:class="[routeEntryContainerLayoutStrategy, 'il-new-stacking-context']")
          Progress(v-if='processing')
          router-view(v-if='!hideRouterView' v-slot="{Component}")
            KeepAlive(:include="['PlayerLookupPage','VolunteerLookupPage']")
              component(:is='Component')
</template>

<script lang="ts">
import Sidebar from 'src/components/Navigational/Sidebar.vue'
import Header from 'src/components/Navigational/Header.vue'
import LoginModalPage from 'src/components/User/LoginModal.vue'
import Progress from 'src/components/Navigational/Progress.vue'
import Navbar from 'src/components/Mobile/Navigational/navbar.vue'
import TLoader from 'src/components/UserInterface/t-loader.vue'
import { Plugins } from '@capacitor/core'
import { useRoute, useRouter } from 'vue-router'


const { Device } = Plugins

import { defineComponent, computed, ComputedRef, ref, watch, CSSProperties } from 'vue'
import { RouteWithInleagueMetadata, routeAuthPredicateRunner } from 'src/router/routes'

import { Modal } from "src/components/UserInterface/Modal"
import * as EventuallyPinia from "src/store/EventuallyPinia"
import { getLoginModalDismissed } from "src/store/Misc"
import { exhaustiveCaseGuard, useWindowSize } from 'src/helpers/utils'
import { System } from 'src/store/System'
import { User } from 'src/store/User'

export default defineComponent({
  name: 'MainLayout',
  components: {
    LoginModalPage,
    Sidebar,
    Header,
    Progress,
    Navbar,
    TLoader,
    Modal,
  },
  setup() {
    const route = useRoute() as RouteWithInleagueMetadata
    const router = useRouter()
    const error = ref(false)

    const routeEntryContainerLayoutStrategy = computed(() => {
      if (!route.meta.containerLayoutStrategy) {
        // default when not specified
        return "il-router-entry-container-constrained"
      }

      if (route.meta.containerLayoutStrategy === "unconstrained") {
        return "il-router-entry-container-unconstrained"
      }
      else if (route.meta.containerLayoutStrategy === "constrained") {
        return "il-router-entry-container-constrained"
      }
      else {
        exhaustiveCaseGuard(route.meta.containerLayoutStrategy)
      }
    })

    const windowSize = useWindowSize()
    const routerViewRootStyles = computed<CSSProperties>(() => {
      if (route.meta.useRouterViewRootAsWindowlikeScrollContainer) {
        return {
          overflow: "auto",
          height: `${windowSize.height}px`
        }
      }
      else {
        return {}
      }
    })

    const kludgyMainContainerStyles = computed<CSSProperties>(() => {
      if (route.meta.useRouterViewRootAsWindowlikeScrollContainer) {
        return {
          ...route.meta.kludgyMainContainerStyles,
          // pure magic, it seems; without this, the horizontal scrollbar extends to the left and underneath of the sidebar
          overflow: "auto",
        }
      }
      else {
        return route.meta.kludgyMainContainerStyles || {}
      }
    })

    const globalTopLevelModalState = computed(() => EventuallyPinia.globalTopLevelModalState.state );

    const loading: ComputedRef = computed(() => {
      return System.value.loading
        || EventuallyPinia.GlobalInteractionBlockingRequestsInFlight.count > 0
    })

    const devicePlatform: ComputedRef = computed(() => {
      return System.value.devicePlatform
    })

    const isLoggedIn = computed(() => {
      return User.isLoggedIn
    })

    //
    // manual watchers and result holders to deal with async auth predicate runner
    //
    const asyncAuthResultHolder = ref(false);
    const rerunAuthCheck = async () : Promise</*side-effecting only*/ void> => {
      asyncAuthResultHolder.value = await routeAuthPredicateRunner(route.meta);
    }
    watch(() => route, rerunAuthCheck, {deep: true});
    watch(() => User.isLoggedIn, rerunAuthCheck);

    const showLoginModal = computed(() => {
      return !isLoggedIn.value && !asyncAuthResultHolder.value && !getLoginModalDismissed();
    })

    watch(() => showLoginModal.value, async () => {
      // some event (at time of this writing: receipt of a 401 HTTP request in an axios request where the "default" handler triggers an update here in response to a 401)
      // has caused us to want to show the login modal. We want to go to the login route, and then if the user is able to log back in, go back to where we were.
      if (showLoginModal.value) {
        System.setRedirectOnLogin({type: "vue-router-route", value: {...router.currentRoute.value}})
        await router.push({path: "/login"});
      }
    })

    const hideRouterView = computed(() => {
      // FIXME: route name as enum
      return showLoginModal.value && route.name?.toString() !== "login"
    });

    const processing = computed(() => {
      return (
        System.value.paymentProcessing ||
        System.value.refundProcessing
      )
    })

    return {
      hideRouterView,
      error,
      showLoginModal,
      isLoggedIn,
      processing,
      loading,
      devicePlatform,
      globalTopLevelModalState,
      routeEntryContainerLayoutStrategy,
      kludgyMainContainerStyles,
      routerViewRootStyles,
      customGlobalSpinner: computed(() => EventuallyPinia.GlobalInteractionBlockingRequestsInFlight.customGlobalSpinner),
    }
  },
})
</script>
