import { FormKit } from "@formkit/vue";
import { CreateProvisionalCertOption, VolunteerCertEditorCert } from "src/composables/InleagueApiV1.User";
import { dayjsFormatOr } from "src/helpers/formatDate";
import { Reflike, UiOption, sortBy, sortByDayJS, vReqT } from "src/helpers/utils";
import { Integerlike } from "src/interfaces/InleagueApiV1";
import { BasicTable, ColDef, freshSortState, typedBasicTableProps } from "src/modules/TableUtils";
import { computed, defineComponent } from "vue";
import { authZ_editVolunteerCerts } from "./R_UserEditor.route";

export const UserCertsImpl = defineComponent({
  name: "UserCertsResolved",
  props: {
    userData: vReqT<{firstName: string, lastName: string, stackSID: string, stackRecordKey: string}>(),
    certs: vReqT<VolunteerCertEditorCert[]>(),
    createProvisionalCertOptions: vReqT<UiOption<CreateProvisionalCertOption | null>[]>(),
    mut_selectedCreateProvisionalCertOption: vReqT<Reflike<CreateProvisionalCertOption | null>>(),
    doCreateProvisionalCert: vReqT<() => Promise<void>>(),
    mut_selectedDeletes: vReqT<Reflike<Record<Integerlike, boolean>>>(),
    doDeleteProvisionalCerts: vReqT<() => Promise<void>>(),
    doResyncCerts: vReqT<() => Promise<void>>(),
  },
  setup(props) {
    const deleteTargets = computed(() => {
      return Object
        .entries(props.mut_selectedDeletes.value satisfies Record<string, boolean>)
        .filter(([_, selected]) => selected)
        .map(([vCertID]) => vCertID as Integerlike)
    });

    const colDefs = computed(() => {
      if (authZ_editVolunteerCerts()) {
        props.mut_selectedDeletes.value = {}
        props.certs.forEach(cert => { props.mut_selectedDeletes.value[cert.vCertID] = false })
        return freshColDefs({withDeleteCol: true, selectedDeletes: props.mut_selectedDeletes.value})
      }
      else {
        return freshColDefs({withDeleteCol: false});
      }
    });

    const sortState = freshSortState(colDefs.value)
    const sortedRows = computed(() => sortState.sort([...props.certs]))

    return () => {
      return (
        <div data-test="UserCerts">
          {
            props.userData.stackRecordKey
              ? (
                <>
                  <div class="w-full p-2 shadow-md rounded-md border-slate-100 bg-white">
                    <h2 class="mb-2">Volunteer Certification History - {props.userData.firstName} {props.userData.lastName} {props.userData.stackSID ? `(AYSO ID: ${props.userData.stackSID})` : ""}</h2>
                    <div class="w-full overflow-x-auto">
                      <BasicTable {...typedBasicTableProps({
                        colDefs: colDefs.value,
                        rowData: sortedRows.value,
                        sortState: sortState,
                        rowKey: v => v.vCertID.toString(),
                        rowAttrs: v => ({"data-test": `vCertID=${v.vCertID}`})
                      })}/>
                    </div>
                    <div class="flex flex-col sm:flex-row items-start gap-2 my-2">
                      {
                        authZ_editVolunteerCerts()
                          ? (
                            <>
                              <t-btn
                                type="button"
                                margin={false}
                                disable={deleteTargets.value.length === 0}
                                class={deleteTargets.value.length === 0 ? "bg-gray-200" : ""}
                                onClick={props.doDeleteProvisionalCerts}
                                data-test="deleteSelectedProvisionalCertsButton"
                              >Delete selected</t-btn>
                              <t-btn
                                type="button"
                                margin={false}
                                onClick={props.doResyncCerts}
                                data-test="resync"
                              >Resync</t-btn>
                              <div>
                                Volunteer certification data is synchronized in real-time with Affinity,
                                the AYSO National Association Management Platform. The <strong>Resync</strong> function is only needed to resolve issues with duplicate or missing certifications when duplicate records are merged on the AYSO side. It will clear the local, inLeague list and reset it to match Affinity.
                              </div>
                            </>
                          )


                          : null
                      }
                    </div>
                  </div>

                  <div>
                    {
                      authZ_editVolunteerCerts()
                        ? (
                          <div class="my-4 p-2 border-slate-100 shadow-md rounded-md bg-white">
                            <h3>Add a Provisional Certification</h3>

                            <div class="border-b border-dashed border-gray-300 my-2"/>
                            {
                              props.userData.stackRecordKey
                                ? (
                                  <div style="--fk-margin-outer: 0;">
                                    <div>
                                      You may add or remove certifications directly to a user record with <strong>provisional</strong> status.
                                      <ul>
                                        <li>Provisional certifications exist only in inLeague and are not sent to AYSO.</li>
                                      <li> Provisional certifications will not update the 'highest coaching certificate' or 'highest referee grade' fields on the volunteer record.</li>
                                      </ul>
                                    </div>
                                    <div class="mt-2">
                                      <FormKit
                                        type="select" options={props.createProvisionalCertOptions} v-model={props.mut_selectedCreateProvisionalCertOption.value}
                                        data-test="createProvisionalCertSelect"
                                      />
                                    </div>
                                    <div class="mt-2">
                                      <t-btn
                                        type="button"
                                        margin={false}
                                        onClick={() => props.doCreateProvisionalCert()}
                                        disable={!props.mut_selectedCreateProvisionalCertOption}
                                        class={!props.mut_selectedCreateProvisionalCertOption ? "bg-gray-200" : ""}
                                        data-test="createProvisionalCertSubmit"
                                      >Submit</t-btn>
                                    </div>
                                  </div>
                                )
                                : (
                                  <div>Provisional certifications may only be added directly to users who have registered as volunteers with AYSO and have an AYSO ID assigned by the Affinity platform.</div>
                                )
                            }
                          </div>
                        )
                        : null
                    }
                  </div>
                </>
              )
              : <div>This user does not have a stack record key and so does not have any certs.</div>
          }
        </div>
      )
    }
  }
})

enum ColID {
  delete = 1, category, cert, certDate, provisional, addedBy,
}

export type UserCertTableColDef = ColDef<VolunteerCertEditorCert, ColID>

export function freshColDefs(
  config:
    | {withDeleteCol: false}
    | {withDeleteCol: true, selectedDeletes: Record</*VCertID*/string, boolean>}
) : UserCertTableColDef[] {
  let colDefs : UserCertTableColDef[] = [
    {
      id: ColID.delete,
      label: "Delete",
      html: v => {
        if (!config.withDeleteCol || !v.provisional) {
          return "";
        }
        return (
          <div class="flex items-center justify-start pl-1" style="--fk-margin-decorator:none; --fk-margin-outer:none;">
            <FormKit type="group">
              <FormKit type="checkbox" v-model={config.selectedDeletes[v.vCertID]} data-test="deleteCertCheckbox"/>
            </FormKit>
          </div>
        )
      },
    },
    {
      id: ColID.category,
      label: "Category",
      html: v => v.category,
      sort: sortBy(_ => _.category)
    },
    {
      id: ColID.cert,
      label: "Certification",
      html: v => v.certificationDesc,
      sort: sortBy(_ => _.certificationDesc)
    },
    {
      id: ColID.certDate,
      label: "Certification Date",
      html: v => dayjsFormatOr(v.certDate, "YYYY-M-DD"),
      sort: sortByDayJS(_ => _.certDate)
    },
    {
      id: ColID.provisional,
      label: "Provisional",
      html: v => v.provisional ? "Yes" : "No",
    },
    {
      id: ColID.addedBy,
      label: "Added by",
      html: v => {
        if (v.provisional) {
          return v.addedByUser
            ? `${v.addedByUser.firstName} ${v.addedByUser.lastName}`
            : ""
        }
        else {
          return "AYSO Nat'l"
        }
      }
    }
  ]

  if (!config.withDeleteCol) {
    colDefs = colDefs.filter(v => v.id !== ColID.delete)
  }

  return colDefs;
}
