<!---
  99% copy-pasted from npm package `vue3-quill`
  a) no big reason this should be an external dependency (it just wraps quill in a vue component)
  b) Vite doesn't seem to be able to deal with "prebundling" of node_module dependencies which themselves import .vue files
  (would need to transform it using vue-loader and then esbuild would bundle it or something?)
--->
<template>
  <section ref="editor"></section>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { onMounted, ref, watch, onUnmounted, onBeforeUnmount } from 'vue'
import { htmlEditButton } from 'quill-html-edit-button'

import Quill from 'quill'

Quill.register('modules/htmlEditButton', htmlEditButton)

/**
 * https://quilljs.com/playground/#class-vs-inline-style
 * https://github.com/quilljs/quill/issues/3277
 *
 * quill will emit css classes instead of styles, which will break emails.
 *
 * The following purports to be a solution, but doesn't really seem to jive with our manual use of toolbars,
 * for example, the combination of `Quill.register(SizeStyle, true)` and explicit toolbar size options
 * renders the font size drop down a no-op -- it's still offered, but does nothing.
 *
 * In places where we use Quill for user-provided email, we can:
 *  - figure out how to convince quill to always emit style attrs, instead of class attrs
 *    (because the classes will not be available in the email)
 *  - inline the classes (there's quill css files int the node_modules dir somewhere) into the emails
 *  - only offer toolbar features for things that are easy enough to plop into an email; like H{1,2,3,4,5} tags,
 *    text color (which quill seems to emit literal style attrs for by default), <ul> and <ol>
 *
 */
// var DirectionAttribute = Quill.import('attributors/attribute/direction');
// Quill.register(DirectionAttribute, true);
// var AlignClass = Quill.import('attributors/class/align');
// Quill.register(AlignClass, true);
// var BackgroundClass = Quill.import('attributors/class/background');
// Quill.register(BackgroundClass, true);
// var ColorClass = Quill.import('attributors/class/color');
// Quill.register(ColorClass, true);
// var DirectionClass = Quill.import('attributors/class/direction');
// Quill.register(DirectionClass, true);
// var FontClass = Quill.import('attributors/class/font');
// Quill.register(FontClass, true);
// var SizeClass = Quill.import('attributors/class/size');
// Quill.register(SizeClass, true);
// var AlignStyle = Quill.import('attributors/style/align');
// Quill.register(AlignStyle, true);
// var BackgroundStyle = Quill.import('attributors/style/background');
// Quill.register(BackgroundStyle, true);
// var ColorStyle = Quill.import('attributors/style/color');
// Quill.register(ColorStyle, true);
// var DirectionStyle = Quill.import('attributors/style/direction');
// Quill.register(DirectionStyle, true);
// var FontStyle = Quill.import('attributors/style/font');
// Quill.register(FontStyle, true);
// var SizeStyle = Quill.import('attributors/style/size');
// Quill.register(SizeStyle, true);

/**
 * Return a fresh copy every time, because there are places that will write into the result.
 */
function defaultOptions() {
  return {
    theme: 'snow',
    boundary: document.body,
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['clean'],
        ['link', 'image', 'video']
      ]
    },
    placeholder: 'Insert content here ...',
    readOnly: false
  }
}

export default {
  name: 'quill-editor',
  props: {
    content: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * these get merged w/ default
     */
    options: {
      type: Object,
      required: false,
      default: () => ({})
    },
    /**
     * if present, this is used on its own (without being merged with the "default" options, as in the `options` prop above)
     */
    forceOptions: {
      type: Object,
      required: false
    }
  },
  emits: ['ready', 'change', 'input', 'blur', 'focus', 'update:value'],
  setup(props, context) {
    const state = {
      editorOption: {},
      quill: null
    }

    let _content = ''

    watch(
      () => props.value,
      val => {
        if (state.quill) {
          if (val && val !== _content) {
            _content = val
            state.quill.pasteHTML(val)
          } else if (!val) {
            state.quill.setText('')
          }
        }
      }
    )

    watch(
      () => props.content,
      val => {
        if (state.quill) {
          if (val && val !== _content) {
            _content = val
            state.quill.pasteHTML(val)
          } else if (!val) {
            state.quill.setText('')
          }
        }
      }
    )

    watch(
      () => props.disabled,
      val => {
        if (state.quill) {
          state.quill.enable(!val)
        }
      }
    )

    const editor = ref(null)

    const mergeOptions = (def, custom) => {
      for (const key in custom) {
        if (!def[key] || key !== 'modules') {
          def[key] = custom[key]
        } else {
          mergeOptions(def[key], custom[key])
        }
      }
      return def
    }

    const initialize = () => {
      if (editor.value) {
        // Options
        state.editorOption = props.forceOptions ?? mergeOptions(defaultOptions(), props.options)
        state.editorOption.readOnly = props.disabled ? true : false
        // Instance
        state.quill = new Quill(editor.value, state.editorOption)
        // console.log('intilized')

        // Set editor content
        if (props.value) {
          // "disable" quill temporarily during the paste operation, otherwise it steals focus and sets page scrollY pos
          // required by https://github.com/quilljs/quill/issues/2156
          // inspired by https://github.com/quilljs/quill/issues/2156#issuecomment-619530283
          state.quill.disable();
          state.quill.pasteHTML(props.value)
          state.quill.enable();
        }

        // Mark model as touched if editor lost focus
        state.quill.on('selection-change', range => {
          if (!range) {
            context.emit('blur', state.quill)
          } else {
            context.emit('focus', state.quill)
          }
        })
        // Update model if text changes
        state.quill.on('text-change', () => {
          // diabled editor after content initialized
          if (props.disabled) {
            state.quill.enable(false)
          }
          let html = editor.value.children[0].innerHTML
          const quill = state.quill
          const text = state.quill.getText()
          if (html === '<p><br></p>') html = ''
          _content = html
          context.emit('update:value', _content)
          context.emit('change', { html, text, quill })
        })

        // Emit ready event
        context.emit('ready', state.quill)
      }
    }

    onBeforeUnmount(() => {
      const editorToolbar = editor.value.previousSibling
      if (editorToolbar && editorToolbar.className.indexOf('ql-toolbar') > -1) {
        editorToolbar.parentNode.removeChild(editorToolbar)
      }
    })

    onMounted(() => {
      initialize()
    })

    onUnmounted(() => {
      state.quill = null
    })

    return { editor }
  }
}
</script>
