import { FormKit } from "@formkit/vue"
import type { AxiosInstance } from "axios"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { UiOption, vReqT } from "src/helpers/utils"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { defineComponent, ref } from "vue"

export type TournamentTeamExpandedForConfigurator = iltypes.WithDefinite<iltournament.TournamentTeamFromListTournamentTeamsEndpoint, "admin" | "playerLinks" | "officials">

export async function getTournamentTeamExpandedForConfigurator(axios: AxiosInstance, tournamentTeamID: iltypes.Integerlike) : Promise<TournamentTeamExpandedForConfigurator> {
  return await iltournament
    .getTournamentTeam(
      axios, {
        tournamentTeamID: tournamentTeamID,
        expand: ["admin", "playerLinks", "officials"]
    }) as TournamentTeamExpandedForConfigurator
}

export interface TextWithMaxLen {
  text: string,
  readonly maxLen: number
}

export function TextWithMaxLen(initialText: string, maxLen: number) : TextWithMaxLen {
  const text = ref(initialText)
  return {
    get text() { return text.value },
    set text(s) { text.value = s },
    get maxLen() { return maxLen },
  }
}

export interface RefMutablesFormData {
  comments: TextWithMaxLen,
  maxAR: "" | iltypes.Integerlike,
  maxCR: "" | iltypes.Integerlike,
}

export interface CoachMutablesFormData {
  comments: TextWithMaxLen,
}

export const MAX_TOURNTEAM_OFFICIAL_COMMENTS_LEN = 3000;

//
// This is the agreed upon sanctioned list used to build "highest{Cr,Ar}Options".
// It's not clear if we can pull this from somewhere (list of div links for the tournmaent maybe?)
//
const __magicHardCodedHighestCrArOptions = [8,10,12,14,19];

// chief ref
export const highestCrOptions = ((() => {
  const result : UiOption[] = [{label: "N/A", value: ""}]
  for (const i of __magicHardCodedHighestCrArOptions) {
    result.push({label: `U${i}`, value: `${i}`})
  }
  return result;
})())

// asst. ref
export const highestArOptions = ((() => {
  const result : UiOption[] = [{label: "N/A", value: ""}]
  for (const i of __magicHardCodedHighestCrArOptions) {
    result.push({label: `U${i}`, value: `${i}`})
  }
  return result;
})())

export const RefMutablesForm = defineComponent({
  props: {
    highestCrOptions: vReqT<UiOption[]>(),
    highestArOptions: vReqT<UiOption[]>(),
    refForm: vReqT<RefMutablesFormData>(),
  },
  setup(props) {
    return () => {
      return (
        <div class="flex flex-col" style="--fk-margin-outer:none;">
          <div class="text-sm font-medium my-1">Highest CR</div>
          <FormKit type="select" data-test="highestCR" name="Highest CR" options={props.highestCrOptions} v-model={props.refForm.maxCR}/>

          <div class="text-sm font-medium my-1">Highest AR</div>
          <FormKit type="select" data-test="highestAR" name="Highest AR" options={props.highestArOptions} v-model={props.refForm.maxAR}/>

          <div>
            <div class="text-sm font-medium">Comments</div>
            <textarea v-model={props.refForm.comments.text} class="w-full min-h-[10em]"/>
          </div>
          <div class="ml-auto mb-2 text-xs">
            {props.refForm.comments.text.length}/{props.refForm.comments.maxLen}
          </div>
        </div>
      )
    }
  }
})
