<template lang="pug">
div
  .flex.justify-between
    .font-medium.flex.flex-col.justify-center.text-lg Transaction # {{ transNum }}
      FormKitForm#form(
        type='form',
        ref='trnsFrm',
        :schema='schma',
        label='Submit',
        v-if='Object.keys(transactions).length && Object.keys(teams).length'
      )
      FormKitSchema(
        :schema='schma',
        :data='form',
      )
      t-btn(
        label='Delete',
        @click='deleteTransaction',
        data-cy='deleteTransaction'
      )
    template
      .flex.justify-end.w-full
        .font-medium.text-red-600(label='Remove', @click='deleteTransaction') Delete
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  PropType,
  computed,
  watch,
} from 'vue'
import { TransactionForm, TransactionType } from 'src/interfaces/score'
import { axiosInstance } from 'src/boot/axios'
import { createSelect } from 'src/helpers/schemaService'
import { useRoute } from 'vue-router'

import { Transaction } from 'src/interfaces/score'
import { HomeTeam, Team, VisitorTeam } from 'src/composables/InleagueApiV1.Game'

import { __FIXME__UNSAFE_CAST } from 'src/helpers/utils'
import { Games } from 'src/store/Games'

export default defineComponent({
  props: {
    // @fixme -- is this supposed to be `TransactionType[]` ?
    compTransactions: {
      type: Object as PropType<TransactionType>,
      default: () => ({}),
    },
    transaction: { type: Object as PropType<Transaction>, required: true },
    homeTeam: { type: Object as PropType<Team>, default: () => ({}) },
    visitorTeam: { type: Object as PropType<Team>, default: () => ({}) },
    transNum: { type: Number },
  },
  setup(props, { emit }) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const teams = ref({})
    const transactions = ref({}) as Ref<{
      [key: string]: string
    }>
    const homeTeamPlayers = ref({}) as Ref<{ [key: string]: string }>
    const visitorTeamPlayers = ref({}) as Ref<{ [key: string]: string }>
    const $route = useRoute()
    const frmChanged = ref(false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const validation = ref({}) as any
    const trnsFrm = ref({}) as Ref<TransactionForm>


    const transactionSchema = ref({})



    const submitScore = computed(() => {
      return Games.value.submitScore
    })

    const schma = computed(() => {
      return [
        {
          $formkit: 'select',
          model: 'teamID',
          name: 'teamID',
          label: 'Team*',
          options: teams.value,
          'data-cy': 'teamID',
        },
        {
          $formkit: 'select',
          model: 'transactionType',
          name: 'transactionType',
          label: 'Transaction*',
          options: transactions.value,
          'data-cy': 'transactionType',
        },
        {
          $formkit: 'select',
          model: 'registrationID',
          name: 'registrationID',
          label: 'Player (optional)',
          options: homeTeamPlayers.value,
          disabled: homeTeamPlayers.value.length === '0',
          placeholder:
            Object.keys(homeTeamPlayers.value).length === 0
              ? '(No Players Assigned)'
              : '-- Select a Player --',
          condition: (model: TransactionForm) =>
            model.teamID === props.homeTeam.ID,
          'data-cy': 'registrationID',
        },
        {
          $formkit: 'select',
          model: 'registrationID',
          name: 'registrationID',
          label: 'Player (optional)',
          options: visitorTeamPlayers.value,
          disabled: visitorTeamPlayers.value.length === '0',
          placeholder:
            Object.keys(visitorTeamPlayers.value).length === 0
              ? '(No Players Assigned)'
              : '-- Select a Player --',
          condition: (model: TransactionForm) =>
            model.teamID === props.visitorTeam.ID,
          'data-cy': 'registrationID',
        },
        {
          $formkit: 'text',
          model: 'comment',
          name: 'comment',
          label: 'Transaction Comments',
          'data-cy': 'comment',
        },
      ]
    })

    // Lifecycle Hooks

    // methods
    const fillForm = () => {
      const frm: TransactionForm = {
        transactionID: props.transaction.transactionID
          ? props.transaction.transactionID
          : '',
        teamID: props.transaction.teamID,
        transactionType: props.transaction.transactionType
          ? props.transaction.transactionType
          : '',
        registrationID: props.transaction.registrationID,
        comment: props.transaction.comment,
      }
      trnsFrm.value = frm
    }

    const createTeams = () => {
      teams.value = {
        [props.homeTeam.ID]: props.homeTeam.fullName,
        [props.visitorTeam.ID ? props.visitorTeam.ID : 'TBD']: props.visitorTeam
          .fullName
          ? props.visitorTeam.fullName
          : 'TBD',
      }
    }

    const createPlayers = () => {
      if (Object.keys(props.homeTeam).length) {
        homeTeamPlayers.value = createSelect(
          props.homeTeam.players ? props.homeTeam.players : [],
          'registrationID',
          'playerFirstName',
          'playerLastName'
        )
      }
      if (Object.keys(props.visitorTeam).length) {
        visitorTeamPlayers.value = createSelect(
          props.visitorTeam.players ? props.visitorTeam.players : [],
          'registrationID',
          'playerFirstName',
          'playerLastName'
        )
      }
    }

    const createTransactionOptions = () => {
      transactions.value = createSelect(
        __FIXME__UNSAFE_CAST<TransactionType[]>(props.compTransactions),
        'typeID',
        'label'
      )
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const deleteTransaction = async () => {
      if (props.transaction.transactionID) {
        try {
          await axiosInstance.delete(
            `https://inleague.localtest.me/api/v1/scoreModifier/${props.transaction.transactionID}`
          )
        } catch (err) {
          // console.error(err)
        }
      }
      emit('deleteTrans', props.transNum)
    }

    watch(submitScore, async (sending: boolean) => {
      // created() {
      let verified = true
      if (frmChanged.value) {
        if (sending && Object.keys(trnsFrm.value).length) {
          if (props.transaction.transactionID) await deleteTransaction()
          if (validation.value.hasErrors) {
            verified = false
          } else {
            const options: TransactionForm = {
              teamID: trnsFrm.value.teamID,
              transactionType: trnsFrm.value.transactionType,
              comment: trnsFrm.value.comment,
            }

            trnsFrm.value.registrationID
              ? (options['registrationID'] = trnsFrm.value.registrationID)
              : ''
            trnsFrm.value.transactionID
              ? (options['transactionID'] = trnsFrm.value.transactionID)
              : ''

            try {
              await axiosInstance
                .post(
                  `/v1/game/${$route.params.id as string}/scoreModifier`,
                  options
                )
                .then(resp => {
                  Games.directCommit_setSubmitScore(false)
                })
            } catch (err) {
              // console.error(err)
            }
          }
        }
      }
      Games.directCommit_setSubmissionVerified(verified)
    })

    watch(
      trnsFrm,
      (newVal, oldVal) => {
        // If the previous value had keys (i.e. hasn't just been initiated):
        if (Object.keys(oldVal).length) {
          frmChanged.value = true
        }
      },
      { deep: true }
    )

    onMounted(() => {
      createTeams()
      createTransactionOptions()
      transactionSchema.value = schma.value
      createPlayers()
      fillForm()
    })

    return {
      trnsFrm,
      teams,
      transactions,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      validation,
      fillForm,
      createTeams,
      createPlayers,
      createTransactionOptions,
      homeTeamPlayers,
      visitorTeamPlayers,
      deleteTransaction,
      transactionSchema,
      frmChanged,
      schma,
    }
  },
})
</script>
