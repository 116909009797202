import dayjs from "dayjs";
import authService from "src/helpers/authService";
import { User } from "src/store/User";

/**
 * Access to this page is by "reflike user" or "any user with a valid risk status" (for a specific ref-scheduler notion of "valid risk status").
 * A non-reflike user having a valid risk status will only be offered competitions which are "limitRefAccess=0", which may potentially filter
 * the list of available comps for that user to an empty list.
 */
export function hasSomeRoutePermission() : boolean {
  return currentUserIsReflikeUser() || currentUserHasValidRiskStatus()
}

export function currentUserIsReflikeUser() : boolean {
  return authService(User.userData?.roles ?? [], "webmaster", "refScheduler", "compManager", "ref");
}

export function currentUserHasValidRiskStatus() {
  const riskStatus = User.userData?.RiskStatus.toLowerCase() || ""
  const riskStatusExpires = dayjs(User.userData?.RiskStatusExpiration)
  const hasValidRiskStatus = (riskStatus === "green" || riskStatus === "blue") && dayjs().isBefore(riskStatusExpires)
  return hasValidRiskStatus
}
