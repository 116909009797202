import { FormKit } from "@formkit/vue";
import { vReqT, DeepConst, exhaustiveCaseGuard } from "src/helpers/utils";
import { defineComponent, ref } from "vue";

import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import * as iltypes from "src/interfaces/InleagueApiV1"

export const CandidateDisplay = defineComponent({
  props: {
    candidateInfo: vReqT<DeepConst<iltournament.PlayerCandidate>>(),
  },
  emits: {
    doAddPlayerToRoster: (_: DeepConst<iltournament.PlayerCandidate>, _form: EditOnePlayerMutables, _childID?: iltypes.Guid) => true,
  },
  setup(props, {emit}) {
    const form = ref<EditOnePlayerMutables>({uniform: ""})

    /**
     * supply a `childID` in the "localTeam" case
     */
    const doAddPlayerToRoster = (childID?: iltypes.Guid) => {
      emit("doAddPlayerToRoster", props.candidateInfo, form.value, childID);
    }

    return () => (
      <div data-test="CandidateDisplay">
        <Worker/>
      </div>
    );

    function Worker() {
      if (props.candidateInfo.detail.type === "foreignTeam") {
        return (
          <>
            <div>Player name: {props.candidateInfo.playerFirstName} {props.candidateInfo.playerLastName} ({props.candidateInfo.stackSID})</div>
            <div>
              <FormKit type="text" v-model={form.value.uniform} label="Uniform"/>
            </div>
            <div data-test={`childID=NONE`}>
              {
                props.candidateInfo.detail.candidacy.candidate
                  ? (
                    <div>
                      {
                        props.candidateInfo.detail.candidacy.warning
                          ? (
                            <div data-test="warning">
                              <div class="text-sm underline">Warning:</div>
                              <div class="pl-2 text-sm">{props.candidateInfo.detail.candidacy.warning}</div>
                              <div class="text-sm">Your permissions allow you to override this warning.</div>
                            </div>
                          )
                          : null
                      }
                      <div class="my-2">
                        <t-btn data-test="commitToRoster" onClick={() => doAddPlayerToRoster()} margin={false}>Add to team roster</t-btn>
                      </div>
                    </div>
                  )
                  : <div class="text-red-600" data-test="error">{props.candidateInfo.detail.candidacy.error}</div>
              }
            </div>
          </>
        )
      }
      else if (props.candidateInfo.detail.type === "localTeam") {
        // basically exactly the same as "foreign team" except we may have multiple candidates
        return (
          <>
            <div>Player name: {props.candidateInfo.playerFirstName} {props.candidateInfo.playerLastName} ({props.candidateInfo.stackSID})</div>
            <div>
              <FormKit type="text" v-model={form.value.uniform} label="Uniform"/>
            </div>
            <div>
              {
                props.candidateInfo.detail.inLeagueCandidates.length > 1
                  ? <div class="my-3 flex justify-center">Note: More than 1 inLeague player matched this lookup. Please choose one.</div>
                  : null
              }

              <div style="display:grid; grid-template-columns: repeat(auto-fit, minmax(16em, .5fr)); gap:.675em;" class="mx-2 my-4">
                {
                  props.candidateInfo.detail.inLeagueCandidates.map(candidacy => {
                    return (
                      <div class="bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5 p-2" data-test={`childID=${candidacy.childID}`}>
                        {
                          candidacy.candidate ? (
                            <>
                              <div>{props.candidateInfo.playerFirstName} {props.candidateInfo.playerLastName}</div>
                              {
                                candidacy.warning
                                  ? (
                                    <div data-test="warning">
                                      <div class="text-sm underline">Warning:</div>
                                      <div class="pl-2 text-sm">{candidacy.warning}</div>
                                      <div class="text-sm">Your permissions allow you to override this warning.</div>
                                    </div>
                                  )
                                  : null
                              }
                              <div class="my-2">
                                <t-btn data-test="commitToRoster" onClick={() => doAddPlayerToRoster(candidacy.childID)} margin={false}>Add to team roster</t-btn>
                              </div>
                            </>
                          )
                          : (
                            <div data-test="error">
                              <div>{props.candidateInfo.playerFirstName} {props.candidateInfo.playerLastName}</div>
                              <div class="text-red-600">{candidacy.error}</div>
                            </div>
                          )
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </>
        )
      }
      else {
        exhaustiveCaseGuard(props.candidateInfo.detail)
      }
    }
  }
})

export interface EditOnePlayerMutables {
  uniform: string
}
