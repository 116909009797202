import { UserData } from 'src/interfaces/Store/user'

export const notSignedInUser = () : UserData => ({
  DOB: '',
  RiskStatus: '',
  RiskStatusExpiration: "",
  impersonatingUserEmail: '',
  stackUserSID: '',
  stackUserID: '',
  middleName: '',
  appDomain: 'demo.inleague.io',
  isDirector: 0,
  isDataReader: 0,
  isPlayerAdmin: 0,
  isCoach: 0,
  competitionsMemento: [],
  isEventAdmin: 0,
  isVolunteerAdmin: 0,
  isWebmaster: 0,
  isCupDirector: 0,
  isBirthCertAdmin: 0,
  belongingChildrenIDsAreSet: false,
  belongingUserIDsAreSet: false,
  impersonatingUserID: '',
  belongingUserIDs: [],
  belongingChildrenIDs: [],
  needsDailyCOVIDWaiver: false,
  VolunteerGracePeriod: '',
  familyIDs: [],
  lastCheckedSharedQueries: '',
  unauthorizedMenuItems: [],
  isAreaStaff: 0,
  isParent: 0,
  authorizedCategories: [],
  region: 0,
  isInvisible: 0,
  refAuth: {},
  roles: [],
  rcRegion: '',
  enableBeta: 0,
  chiefRefsMemento: [],
  isAreaDirector: 0,
  userID: '',
  isImpersonating: false,
  isPracticeScheduler: 0,
  isRefScheduler: 0,
  activePlayerRegistrations: [],
  isRef: 0,
  lastName: '',
  isInleague: 0,
  isScholarshipAdmin: 0,
  isEmailAdmin: 0,
  isRegistrar: 0,
  playerAssignmentsMemento: [],
  volunteerACoach: false,
  AYSOID: '',
  volunteerRef: false,
  authorizedMenuItems: [],
  rolesRemovedByVMP: false,
  isCampDirector: 0,
  isBoard: 0,
  lastCheckedMenu: '',
  divisionsMemento: [],
  inleague_csrfToken: 'notValid',
  isArea: 0,
  isTreasurer: 0,
  compManagerMode: false,
  queryIDsSharedToRole: [],
  firstName: '',
  isEventReporter: 0,
  isUpperDivision: false,
  otherUserAuth: [],
  email: '',
  isOptingOut: false,
  hasEmailManagerLink: false,
  lasteAYSOYear: '',
  clientID: '',
  isRC: 0,
  volunteerCoach: false,
  checkedRefAuth: false,
  checkedMenuItems: [],
  userNum: 0,
  isGameScheduler: 0,
  hasChild: false,
  coachAssignmentsMemento: [],
  gender: '',
  frontendThemeVariant: '',
})
