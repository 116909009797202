import { arrayDeleteAt, arrayFindIndexOrFail, assertIs, assertTruthy, exhaustiveCaseGuard, max, parseIntOr, parseIntOrFail, rangeExc, rangeInc, requireNonNull, UiOption, unreachable, useWindowSize, weakEq } from "src/helpers/utils";
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { computed, defineComponent, onMounted, Ref, ref, shallowRef, Teleport } from "vue";
import { Bracket, BracketRound, BracketRoundSlot, BracketRoundSlotID, EdgeDrawer, EdgeLayoutInfo, RoundSlotElemRefTracker } from "./fooImpl";
import { nilGuid } from "src/composables/InleagueApiV1";
import { Btn2 } from "src/components/UserInterface/Btn2";
import { FormKit } from "@formkit/vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { DAYJS_FORMAT_HTML_DATE } from "src/helpers/formatDate";
import dayjs from "dayjs";

export default defineComponent({
  setup() {
    const windowSize = useWindowSize()
    const svgRedrawKey = computed(() => `{x=${windowSize.width}, y=${windowSize.height}}`)

    const bracketContainerRef = ref<HTMLElement | null>(null);
    const cssUnits_svgWidth = () => {
      if (bracketContainerRef.value) {
        return bracketContainerRef.value.scrollWidth + "px";
      }
      else {
        // fallback, but not as good as exact for when the bracket container would cause scrolling,
        // where 100% will mean "visible container width" rather than
        return "100%";
      }
    }

    const __dev__games = [
      {gameID: nilGuid + "/1", gameNum: 1, gameDate: dayjs().add(0, "days").format(DAYJS_FORMAT_HTML_DATE), home: "Team1", visitor: "Team2"},
      {gameID: nilGuid + "/2", gameNum: 2, gameDate: dayjs().add(0, "days").format(DAYJS_FORMAT_HTML_DATE), home: "Team3", visitor: "Team4"},
      {gameID: nilGuid + "/3", gameNum: 3, gameDate: dayjs().add(0, "days").format(DAYJS_FORMAT_HTML_DATE), home: "Team5", visitor: "Team6"},
      {gameID: nilGuid + "/4", gameNum: 4, gameDate: dayjs().add(0, "days").format(DAYJS_FORMAT_HTML_DATE), home: "Team7", visitor: "Team8"},
      {gameID: nilGuid + "/5", gameNum: 5, gameDate: dayjs().add(0, "days").format(DAYJS_FORMAT_HTML_DATE), home: "", visitor: ""},
      {gameID: nilGuid + "/6", gameNum: 6, gameDate: dayjs().add(0, "days").format(DAYJS_FORMAT_HTML_DATE), home: "", visitor: ""},
      {gameID: nilGuid + "/7", gameNum: 7, gameDate: dayjs().add(0, "days").format(DAYJS_FORMAT_HTML_DATE), home: "", visitor: ""},
    ];

    const uiDataByRoundSlot = ref(new Map<BracketRoundSlotID, {selectedGameID: Guid}>())

    const roundsBySelectedGameIDs = computed(() => {
      return new Map([...uiDataByRoundSlot.value.entries()].map(v => [v[1].selectedGameID, v[0]]))
    })

    const gameOptions = (slot: BracketRoundSlot) => {
      const result : UiOption[] = [{label: `None`, value: ""}]
      for (const game of __dev__games) {
        const disabled = roundsBySelectedGameIDs.value.has(game.gameID) && uiDataByRoundSlot.value.get(parseIntOrFail(slot.bracketRoundSlotID))?.selectedGameID !== game.gameID
        result.push({label: `Game ${game.gameNum} (${game.gameDate})`, value: game.gameID, attrs: {disabled}})
      }
      return result;
    }

    const bracket = ref<Bracket | null>(null)

    const rounds = ref(3)
    const roundSlotElemRefTracker = RoundSlotElemRefTracker()

    const roundCountOptions : UiOption[] = rangeInc(1,8).map(v => ({label: v.toString(), value: v.toString()}))
    const selectedRoundCount = ref<Integerlike>(3)

    const newBracket = (roundCount_: Integerlike) => {
      const roundCount = parseIntOrFail(roundCount_)

      const freshBracket : Bracket = {
        bracketID: nilGuid,
        seasonUID: nilGuid,
        competitionUID: nilGuid,
        divID: nilGuid,
        bracketRounds: []
      }

      for (let i = 0; i < roundCount; i++) {
        const isFirstRound = i === 0
        const n = roundCount - 1 - i
        const slotCount = 2 ** n

        const round : BracketRound = {
          bracketRoundID: nextEphemeralBracketRoundID(),
          bracketID: nilGuid,
          ordinal: 0,
          name: `Round ${i + 1}`,
          roundSlots: []
        }

        for (let roundSlotIdx = 0; roundSlotIdx < slotCount; roundSlotIdx++) {
          round.roundSlots.push({
            bracketRoundSlotID: nextEphemeralRoundRoundSlotID(),
            bracketRoundID: round.bracketRoundID,
            type: "game",
            gameID: "",
            priorBracketRoundSlotID_home: isFirstRound
              ? ""
              : freshBracket.bracketRounds[i-1].roundSlots[(2*roundSlotIdx) + 0].bracketRoundSlotID,
            priorBracketRoundSlotID_visitor: isFirstRound
              ? ""
              : freshBracket.bracketRounds[i-1].roundSlots[(2*roundSlotIdx) + 1].bracketRoundSlotID,
          })
        }

        freshBracket.bracketRounds.push(round)
      }

      bracket.value = freshBracket
      roundSlotElemRefTracker.reinitFrom(bracket.value)

      uiDataByRoundSlot.value = new Map()
      for (const round of freshBracket.bracketRounds) {
        for (const slot of round.roundSlots) {
          if (slot.type === "game") {
            uiDataByRoundSlot.value.set(parseIntOrFail(slot.bracketRoundSlotID), {selectedGameID: slot.gameID})
          }
          else {
            unreachable("unhandled slot type")
          }
        }
      }
    }

    const bracketDepth = computed(() => bracket?.value?.bracketRounds.length ?? 0)
    const bracketWidth = computed(() => {
      return bracketDepth.value === 0 ? 0 : 2 ** (bracketDepth.value - 1)
    })

    const slotRows = computed(() => (bracketWidth.value * 2) - 1)
    const roundCols = computed(() => bracketDepth.value)

    onMounted(() => {
      newBracket(3)
    })

    const edgeLayoutInfo = shallowRef(new Map<BracketRoundSlotID, EdgeLayoutInfo>)

    return () => {
      return (
        <div style="--fk-padding-input:.5em; --fk-bg-input:white;">
          <Teleport to="body">
            <div class="p-2 bg-yellow-400 text-sm rounded-md mb-4 fixed top-0 left-0 flex items-center gap-2 w-1/2" style="z-index:9999">
              <FontAwesomeIcon icon={faTriangleExclamation}/>
              <span>brackets prototype // not connected to any database</span>
            </div>
          </Teleport>

          <div class="shadow-md rounded-md py-2 inline-block bg-white min-w-64 p-2">
            <FormKit type="select" options={roundCountOptions} v-model={selectedRoundCount.value} label="Rounds"/>
            <Btn2 class="px-2 py-1" onClick={() => newBracket(selectedRoundCount.value)}>New bracket</Btn2>
          </div>
          {bracket.value
            ? <div class="my-2 il-new-stacking-context">
                <Btn2 class="my-2 px-2 py-1" onClick={() => alert("from flights, pools, manually?")}>Seed {bracket.value.bracketRounds[0]?.name || "Round 1"} from ...</Btn2>
                <svg style={`position:absolute; width:${cssUnits_svgWidth()}; height:100%; z-index:0;`} key={svgRedrawKey.value}>
                  {/* <Foo bracketContainerRef={bracketContainerRef} matchupDomPair={matchupRefs.value[0]}/>
                  <Foo bracketContainerRef={bracketContainerRef} matchupDomPair={matchupRefs.value[1]}/>
                  <Foo bracketContainerRef={bracketContainerRef} matchupDomPair={matchupRefs.value[2]}/> */}
                  <EdgeDrawer
                    bracketContainerRef={bracketContainerRef}
                    roundSlotElemRefTracker={roundSlotElemRefTracker}
                    bracket={bracket.value}
                    onEdgeLayoutInfo={(m) => {
                      edgeLayoutInfo.value = m
                    }}
                  />
                </svg>
                <div
                  ref={bracketContainerRef}
                  style={`grid-gap:2em 4em; display:grid; grid-template-columns: repeat(${roundCols.value}, 20em); grid-template-rows: repeat(${slotRows.value + 1}, auto); grid-auto-flow:column; position:relative; z-index:1;`}>
                  {bracket.value.bracketRounds.flatMap((bracketRound, roundIdx) => {
                    const leadingPadding = roundIdx === 0 ? 0 : ((2 ** roundIdx)-1)
                    const interNodePadding = 2 ** (roundIdx+1) - 1
                    const trailingPadding = leadingPadding
                    return <>
                      {/*column header*/}
                      <div class="p-1">
                        <FormKit type="text" v-model={bracketRound.name} class="w-full"/>
                      </div>

                      {/*grid padding to offset the first element of each successive column*/}
                      <>{rangeExc(0, leadingPadding).map(() => <div>&nbsp;</div>)}</>

                      {bracketRound.roundSlots.map((roundSlot, slotIdx, a) => {
                        const isLast = slotIdx === a.length - 1
                        if (roundSlot.type === "game") {
                          return <>
                            <div class="bg-white border border-black rounded-md" ref={roundSlotElemRefTracker.getOrFail(roundSlot)}>
                              <div class="p-1 bg-green-800 text-white">
                                <div class="text-xs">bracketRound={roundSlot.bracketRoundID} bracketSlot={roundSlot.bracketRoundSlotID}</div>
                              </div>
                              <div class="p-1">
                                <div>some info here</div>
                                <FormKit label="Game" type="select" options={gameOptions(roundSlot)} v-model={requireNonNull(uiDataByRoundSlot.value.get(parseIntOrFail(roundSlot.bracketRoundSlotID))).selectedGameID}/>
                                <div class="text-xs">(what games are we sourcing from? Any from season/comp/div/date-range? searchable, find any?)</div>
                              </div>
                            </div>
                            <>{rangeExc(0, isLast ? trailingPadding : interNodePadding).map(() => <div>&nbsp;</div>)}</>
                          </>
                        }
                        else {
                          return <div>unimplemented round slot type '{roundSlot.type}'</div>
                        }
                      })}
                    </>
                  })}
                  {bracket.value.bracketRounds.flatMap((bracketRound, roundIdx) => {
                    return bracketRound.roundSlots.map((roundSlot, slotIdx) => {
                      if (roundSlot.type !== "game") {
                        return null;
                      }

                      const edgeInfo = edgeLayoutInfo.value.get(parseIntOrFail(roundSlot.bracketRoundSlotID))
                      if (!edgeInfo) {
                        return null;
                      }

                      const homeEdge = edgeInfo.centerOfEdgeTo.get(parseIntOr(roundSlot.priorBracketRoundSlotID_home, Infinity))
                      const visitorEdge = edgeInfo.centerOfEdgeTo.get(parseIntOr(roundSlot.priorBracketRoundSlotID_visitor, Infinity))

                      return <>
                        {homeEdge
                          ? <Btn2 onClick={() => swapHomeVisitor(roundSlot)} class="ml-2 px-2 py-1" style={`transform: translateY(-50%); position:absolute; top: ${homeEdge.y}px; left:${homeEdge.x}px;`}>H</Btn2>
                          : null
                        }
                        {visitorEdge
                          ? <Btn2 onClick={() => swapHomeVisitor(roundSlot)} class="ml-2 px-2 py-1 rounded-md bg-gray-200" style={`transform: translateY(-50%); position:absolute; top: ${visitorEdge.y}px; left:${visitorEdge.x}px;`}>V</Btn2>
                          : null
                        }
                      </>
                    })
                  })}
                </div>
              </div>
              : null
            }
        </div>
      )
    }
  }
})

function swapHomeVisitor(bracketRoundSlot: BracketRoundSlot) {
  assertIs(bracketRoundSlot.type, "game");
  const temp = bracketRoundSlot.priorBracketRoundSlotID_home
  bracketRoundSlot.priorBracketRoundSlotID_home = bracketRoundSlot.priorBracketRoundSlotID_visitor
  bracketRoundSlot.priorBracketRoundSlotID_visitor = temp
}

const nextEphemeralBracketRoundID = (() => {
  let v = -1;
  return () => v--
})();

const nextEphemeralRoundRoundSlotID = (() => {
  let v = -1;
  return () => v--
})()
