import { FormKit, FormKitMessages } from "@formkit/vue";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { axiosInstance } from "src/boot/AxiosInstances";
import { PropType, computed, defineComponent, getCurrentInstance, reactive, ref, watch } from "vue";

import { SoccerBall } from "../SVGs";
import { AutoModal, DefaultModalController } from "src/components/UserInterface/Modal";

import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { DeepConst, FK_nodeRef, exhaustiveCaseGuard, useIziToast, vReqT } from "src/helpers/utils";
import { Client } from "src/store/Client";
import { TournTeamPlayerID } from "src/composables/InleagueApiV1.Tournament";
import { CandidateDisplay, EditOnePlayerMutables } from "./TournamentTeamConfigurator.players.impl";

export const PlayerRoster = defineComponent({
  name: "PlayerRoster",
  props: {
    tournamentTeamID: {
      required: true,
      type: [String,Number] as any as PropType<iltypes.Integerlike>
    },
    mut_existingPlayerLinks: {
      required: true,
      type: Array as PropType<iltournament.TournTeamPlayerLink[]>
    }
  },
  setup(props) {
    const iziToast = useIziToast();

    const form = ref({stackSID: "", lastName: ""})

    /**
     * represents a lookup result from the backend, where we also allow some things to be edited prior to the
     * user persisting the candidate to the team's player roster
     */
    interface AugmentedCandidate {
      fromApi: DeepConst<iltournament.PlayerCandidate>,
      formData: EditOnePlayerMutables
    }

    const mostRecentCandidateSearchResult = ref<
      | "no-search-yet"
      | "nothing-found"
      | AugmentedCandidate
    >("no-search-yet")

    const doCandidacyLookupByStackSid = async () : Promise<void> => {
      try {
        // TODO: axiosInstance that does not error toast on 404
        const playerCandidate = await iltournament.getPlayerCandidateByStackSID(
          axiosInstance, {
            tournamentTeamID: props.tournamentTeamID,
            stackSID: form.value.stackSID,
            lastName: form.value.lastName
        });

        if (playerCandidate) {
          mostRecentCandidateSearchResult.value = {fromApi: playerCandidate, formData: {uniform: ""}}
        }
        else {
          mostRecentCandidateSearchResult.value = "nothing-found";
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doAddPlayerToRoster = async (candidate: DeepConst<iltournament.PlayerCandidate>, form: EditOnePlayerMutables, childID?: iltypes.Guid) : Promise<void> => {
      try {
        const fresh = await iltournament.addTournamentTeamPlayerByStackSID(axiosInstance, {
          tournamentTeamID: props.tournamentTeamID,
          stackSID: candidate.stackSID,
          lastName: candidate.playerLastName,
          uniform: form.uniform,
          // intent to be explicit here that we can forward `undefined`,
          // which should be the case when adding a "foreign" player who will have no associated inLeague child record.
          childID: childID ?? undefined
        });
        props.mut_existingPlayerLinks.unshift(fresh); // new to front
        mostRecentCandidateSearchResult.value = "no-search-yet"; // "zero out" the current search, we assume here we've just "consumed" it
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const doUpdatePlayer = async (playerID: TournTeamPlayerID, uniform: string) : Promise<void> => {
      try {
        await iltournament.updateTournTeamPlayer(axiosInstance, {tournamentTeamID: props.tournamentTeamID, playerID, uniform});
        const localTarget = props.mut_existingPlayerLinks.find(v => v.type === playerID.type && v.typeID === playerID.typeID);
        if (localTarget) {
          //
          // @store-candidate
          //
          if (localTarget.type === "areaPlayer") {
            localTarget.areaPlayer.uniform = uniform;
          }
          else if (localTarget.type === "playerAssignment") {
            localTarget.playerAssignment.uniform = uniform;
          }
          else {
            exhaustiveCaseGuard(localTarget)
          }
        }
        else {
          // hm, couldn't find it, shouldn't happen; not fatal, but local cache is now out of sync with user expectations
        }

        await iziToast.success({message: "Changes saved."})
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const doRemovePlayerFromRoster = async (playerID: TournTeamPlayerID) : Promise<void> => {
      try {
        await iltournament.removeAreaPlayerFromForeignTournamentTeamByStackSID(axiosInstance, {tournamentTeamID: props.tournamentTeamID, playerID});
        const idx = props.mut_existingPlayerLinks.findIndex(v => v.type === playerID.type && v.typeID === playerID.typeID);
        if (idx === -1) {
          return;
        }

        // @store-candidate
        props.mut_existingPlayerLinks.splice(idx, 1);
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const deletePlayerConfirmationModalController = reactive((() => {
      const busy = ref(false);

      const doDelete = async (id: TournTeamPlayerID) => {
        try {
          try {
            busy.value = true;
            await doRemovePlayerFromRoster(id)
          }
          finally {
            busy.value = false;
          }
          deletePlayerConfirmationModalController.close()
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      };

      const doCancel = () => deletePlayerConfirmationModalController.close()

      // don't allow the modal to be closed while we're busy
      const onCloseCB = (doClose : () => void) : void => {
        if (busy.value) {
          return;
        }
        else {
          doClose();
        }
      }

      return DefaultModalController<iltournament.TournTeamPlayerLink>({
        title: () => (
          <>
            <div>Remove player confirmation</div>
            <div class="my-1 border-b border-slate-200"/>
          </>
        ),
        content: areaPlayer => {
          if (!areaPlayer) {
            return null;
          }
          const underlying = areaPlayer.type === "areaPlayer" ? areaPlayer.areaPlayer : areaPlayer.playerAssignment;
          return (
            <>
              <div>Remove {underlying.firstName} {underlying.lastName}?</div>
              <div class="flex gap-4 mt-4">
                <t-btn data-test="yes" type="button" onClick={() => doDelete(areaPlayer)}>Yes</t-btn>
                <t-btn data-test="no" type="button" onClick={() => doCancel()} color="red">No</t-btn>
              </div>
              {
                busy.value
                  ? (
                    <div style="position:absolute; top:0; left:0; width:100%; height: 100%;">
                      <div class="w-full h-full" style="background-color: rgba(255,255,255,0.5)">&nbsp;</div>
                      <div style="position: absolute; top: 0; left: 0; margin-left:4px; margin-top:4px">
                        <SoccerBall color={Client.value.clientTheme.color} width=".2in" height=".2in" timeForOneRotation="1.25s"/>
                      </div>
                    </div>
                  )
                  : null
              }
            </>
          )
        }
      }, {onCloseCB})
    })())

    const FK_stackSID = FK_nodeRef();
    const FK_lastName = FK_nodeRef();

    return () => (
      <div data-test="PlayerRoster">
        <AutoModal data-test="ConfirmDeleteModal" controller={deletePlayerConfirmationModalController}/>
        <div>
          <FormKit type="form" actions={false} onSubmit={doCandidacyLookupByStackSid} ref={FK_stackSID}>
            <FormKit  type="text" v-model={form.value.stackSID} label="AYSOID" validation={[["required"]]} data-test="stack-lookup/stackSID"/>
            <FormKit  type="text" v-model={form.value.lastName} label="Last name" validation={[["required"]]} data-test="stack-lookup/lastName"/>
            <t-btn data-test="stack-lookup/submit" margin={false} type="submit">Find player by AYSO ID</t-btn>
            <span class="hidden">
              <FormKitMessages node={FK_stackSID.value?.node} />
              <FormKitMessages node={FK_lastName.value?.node} />
            </span>
          </FormKit>
          <div>
            {
              mostRecentCandidateSearchResult.value === "no-search-yet"
                ? null
                : mostRecentCandidateSearchResult.value === "nothing-found"
                ? (
                  <>
                    <div class="border-b border-slate-200 my-2"/>
                    <div>No results.</div>
                  </>
                )
                : (
                  <>
                    <div class="border-b border-slate-200 my-2"/>
                    <CandidateDisplay
                      candidateInfo={mostRecentCandidateSearchResult.value.fromApi}
                      onDoAddPlayerToRoster={doAddPlayerToRoster}
                    />
                  </>
                )
            }
          </div>
          <div class="border-b border-gray-200 my-2"></div>
        </div>
        <ExistingPlayersListing/>
      </div>
    )

    function ExistingPlayersListing() : JSX.Element {
      const Separator = () => <div class="mt-1 border-b border-slate-200"/>
      return (
        <div>
          <div class="font-medium">Current players</div>
          {
            props.mut_existingPlayerLinks.length === 0
              ? <div class="flex flex-col items-center justify-center p-2 text-sm">
                  <div>No current players</div>
                  <div>Add players by a lookup against AYSO IDs above</div>
                </div>
              : null
          }
          <div class="my-2">
            {
              props.mut_existingPlayerLinks.map((areaPlayer, idx, a) => {
                const isLast = idx === a.length - 1;
                const bgColor = idx % 2 ? "bg-gray-100" : ""
                return (
                  <div class={`${bgColor} pt-1`}>
                    <div class="flex" data-test={`stackSID=${areaPlayer.aysoID}`}>
                      <EditOnePlayer
                        playerLink={areaPlayer} key={`${areaPlayer.type}/${areaPlayer.typeID}`}
                        onRequestUpdate={({uniform}) => doUpdatePlayer(areaPlayer, uniform)}
                        onRequestDelete={() => deletePlayerConfirmationModalController.open(areaPlayer)}
                      />
                    </div>
                    { isLast ? null : <Separator/> }
                  </div>
                )
              })
            }
          </div>
        </div>
      )
    }
  }
})

const EditOnePlayer = defineComponent({
  props: {
    playerLink: vReqT<iltournament.TournTeamPlayerLink>(),
  },
  emits: {
    requestUpdate: (_: EditOnePlayerMutables) => true,
    requestDelete: () => true,
  },
  setup(props, {emit}) {
    /**
     * The appropriate underlying entity out, based on tag.
     * They share enough properties that this is useful.
     */
    const entity = computed(() => {
      if (props.playerLink.type === "areaPlayer") {
        return props.playerLink.areaPlayer;
      }
      else if (props.playerLink.type === "playerAssignment") {
        return props.playerLink.playerAssignment;
      }
      else {
        exhaustiveCaseGuard(props.playerLink);
      }
    })

    const makeCopyOfPlayerMutables = () : EditOnePlayerMutables => {
      return {
        uniform: entity.value.uniform
      }
    }

    const mutables = ref(makeCopyOfPlayerMutables());
    watch(() => props.playerLink, () => { mutables.value = makeCopyOfPlayerMutables() }, {deep: true})

    const requestUpdate = () => {
      emit("requestUpdate", mutables.value);
    }

    const requestDelete = () => {
      emit("requestDelete");
    }

    return () => (
      <div class="flex w-full gap-2">
        <div>
          <div>{entity.value.firstName} {entity.value.lastName}</div>
          <div>AysoID: {props.playerLink.aysoID}</div>
        </div>
        <div class="ml-2">
          <FormKit outer-class="$reset" type="text" label="Uniform" v-model={mutables.value.uniform}/>
        </div>
        <div class="ml-auto">
          <div class="flex flex-col items-start justify-start gap-2">
            <t-btn data-test="saveChanges" margin={false} onClick={() => requestUpdate()} type="button"><span class="text-xs">Save changes</span></t-btn>
            <t-btn data-test="remove" margin={false} onClick={() => requestDelete()} type="button"><span class="text-xs">Remove</span></t-btn>
          </div>
        </div>
      </div>
    )
  }
})
