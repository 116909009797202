import { AllScoresComponentState } from "src/components/Score/AllScores.ilx";
import { GameI } from "src/interfaces/Store/game";
import { ref } from "vue";
import { Game } from "src/interfaces/InleagueApiV1"

export type UpdateGameArgs = Pick<Game, "gameID"> & Partial<Game>;

export const Games = (() => {
  const state = ref(freshState());

  const setAllScoresComponentState = (payload: AllScoresComponentState) => {
    state.value.allScoresComponentState = payload;
  }

  /**
   * Update a game, given its gameID, and some portion of game object which should be updated.
   * For example, `putGame({gameID: ID, gameStart: ..., genderNeutral: ...})` updates
   * the game identified by gameID, by updating its gameStart and genderNeutral fields.
   *
   * Note this is not ActiveRecord; these changes are local to the client store only, they are not
   * persisted to the database.
   */
  const updateGame = (payload: UpdateGameArgs) => {
    if (state.value.allScoresComponentState?.gamesListing) {
      const targetIdx = state.value.allScoresComponentState.gamesListing.findIndex(game => game.gameID === payload.gameID);
      if (targetIdx !== -1) {
        const target = state.value.allScoresComponentState.gamesListing[targetIdx];
        state.value.allScoresComponentState.gamesListing[targetIdx] = {...target, ...payload};
      }
    }
  }

  const directCommit_setCompetitionSearch = (competitionSearch: string) => {
    state.value.competitionSearch = competitionSearch
  }

  const directCommit_setDivisionSearch = (divisionSearch: string) => {
    state.value.divisionSearch = divisionSearch
  }

  const directCommit_setDateRangeSearch = (dateRangeSearch: string) => {
    state.value.dateRangeSearch = dateRangeSearch
  }

  const directCommit_setSubmissionVerified = (submissionVerified: boolean) => {
    state.value.submissionVerified = submissionVerified
  }

  const directCommit_setSubmitScore = (submitScore: boolean) => {
    state.value.submitScore = submitScore
  }

  const directCommit_setAllScoresComponentState = (payload: AllScoresComponentState) => {
    state.value.allScoresComponentState = payload;
  }

  return {
    get value() { return state.value },
    setAllScoresComponentState,
    updateGame,
    directCommit_setCompetitionSearch,
    directCommit_setDivisionSearch,
    directCommit_setDateRangeSearch,
    directCommit_setSubmissionVerified,
    directCommit_setSubmitScore,
    directCommit_setAllScoresComponentState,
  }
})()

function freshState(): GameI {
  return {
    competitionSearch: '',
    divisionSearch: '',
    dateRangeSearch: '',
    submissionVerified: false,
    submitScore: false,
    allScoresComponentState: null,
  }
}
