<template lang="pug">
div.mt-4
  .flex.flex-col
    .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
      .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
        .shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
          table.min-w-full.divide-y.divide-gray-200
            thead.bg-gray-50
              tr
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200(colspan="4")
                  | Registration Data - {{ playerDetails.seasonName}}
            .grid.grid-cols-3.divide-y.divide-gray-200(v-if="Object.keys(playerDetails).length>0" class="lg:grid-cols-6")
              td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                | Division:
              td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                div {{ divName }}
              td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                | Registration Status:
              td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                div {{ registrationStatus }}
              td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                | Height:
              td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                .w-40.flex.items-end(v-if="coreQuestionEditPermissionMap['playerHeight']")
                  FormKit(
                    :noMargins='true',
                    v-model='playerDetails.playerHeight',
                    name='playerHeight',
                    validations='required',
                    innerClass='bg-white'
                  )
                  div.ml-2 in.
                div(v-else) {{ playerDetails.playerHeight }} {{ playerDetails.playerHeight ? 'in.' : '' }}
              td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                | Weight:
              td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                div(v-if="coreQuestionEditPermissionMap['playerWeight']")
                  .w-40.flex.items-end
                    FormKit(
                      :noMargins='true',
                      v-model='playerDetails.playerWeight',
                      name='playerWeight',
                      validations='required',
                      innerClass='bg-white'
                    )
                    .ml-2 lbs.
                div(v-else) {{ playerDetails.playerWeight }} {{ playerDetails.playerWeight ? 'lbs.' : '' }}
              td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                | School:
              td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                div(v-if="coreQuestionEditPermissionMap['playerSchool']")
                  FormKit(
                    :noMargins='true',
                    v-model='playerDetails.playerSchool',
                    name='playerSchool',
                    validations='required',
                    innerClass='bg-white'
                  )
                div(v-else) {{ playerDetails.playerSchool }}
              td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                | Grade:
              td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                div(v-if="coreQuestionEditPermissionMap['grade']")
                  FormKit(
                    :noMargins='true',
                    v-model='playerDetails.grade',
                    name='grade',
                    validations='required',
                    type="select",
                    :options='grades',
                    innerClass='bg-white'
                  )
                div(v-else) {{ playerDetails.grade }}
              td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                | Player Email:
              td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                div(v-if="coreQuestionEditPermissionMap['playerEmail']")
                  FormKit(
                    :noMargins='true',
                    v-model='playerDetails.playerEmail',
                    name='playerEmail',
                    validations='required',
                    innerClass='bg-white'
                  )
                div(v-else)
                  | {{ playerDetails.playerEmail }}
              td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                | Player Cell Phone:
              td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                div(v-if="coreQuestionEditPermissionMap['playerCellPhone']")
                  FormKit(
                    :noMargins='true',
                    v-model='playerDetails.playerCellPhone',
                    name='playerCellPhone',
                    validations='required',
                    innerClass='bg-white'
                  )
                div(v-else)
                  | {{ playerDetails.playerCellPhone }}
              td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                | Co-Ed:
              td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                div.custom(v-if="coreQuestionEditPermissionMap['coed']")
                  FormKit(
                    :noMargins='true',
                    v-model='playerDetails.coed',
                    name='coed',
                    validations='required'
                    type="radio"
                    row=true
                    :options="{0:'No', 1: 'Yes'}",
                    optionsClass='flex',
                    innerClass="ml-6",
                  )
                div(v-else) {{ playerDetails.coed ? 'Yes' : 'No' }}
              td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right
                | Submitter Comments:
              td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2
                div(v-if="granularEditPermissions['registrationData/submitterComments']")
                  FormKit(
                    :noMargins='true',
                    v-model='playerDetails.comments',
                    name='comments',
                    validations='required',
                    innerClass="bg-white"
                  )
                div(v-else) {{ playerDetails.comments }}
              td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right(v-if="granularEditPermissions['registrationData/leagueComment']")
                | League Comments:
              td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2(v-if="granularEditPermissions['registrationData/leagueComment']")
                FormKit(
                  type="textarea"
                  :noMargins='true',
                  v-model='playerDetails.leagueComment',
                  name='leagueComment',
                  innerClass='bg-white'
                )
              td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right(v-if='authService(userRoles, "Registrar")')
                | Registrar Comments:
              td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2(v-if='authService(userRoles, "Registrar")')
                div(v-if="hasSomeSuperEditPermission")
                  FormKit(
                    type="textarea"
                    :noMargins='true',
                    v-model='playerDetails.registrarOnly',
                    name='registrarOnly',
                    innerClass='bg-white'
                  )
                div(v-else) {{ playerDetails.leagueComment }}
              td.px-6.py-4.whitespace-wrap.text-sm.font-medium.text-gray-900.text-right(v-if='instanceConfig.regnopractice')
                | No Practice Day:
              td.px-6.py-4.whitespace-wrap.text-sm.text-gray-500.bg-stone-200.col-span-2(v-if='instanceConfig.regnopractice')
                div(v-if="coreQuestionEditPermissionMap['noPracticeDay']")
                  FormKit(
                    :noMargins='true',
                    v-model='playerDetails.noPracticeDay',
                    name='noPracticeDay',
                    type='select',
                    :options='[{"value":"","label":"All days OK"},{"value":"Monday","label":"Monday"},{"value":"Tuesday","label":"Tuesday"},{"value":"Wednesday","label":"Wednesday"},{"value":"Thursday","label":"Thursday"},{"value":"Friday","label":"Friday"}]',
                    innerClass='bg-white'
                  )
                div(v-else) {{ playerDetails.noPracticeDay }}
MedicalInformation.mt-4(v-model:playerDetails="playerDetails" :coreQuestionEditPermissionMap="coreQuestionEditPermissionMap")
EmergencyContact.mt-4(v-model:playerDetails="playerDetails" :coreQuestionEditPermissionMap="coreQuestionEditPermissionMap")
CustomQuestions.mt-4(
  v-model:playerDetails="playerDetails"
  :hasSomeSuperEditPermission='hasSomeSuperEditPermission'
  :granularEditPermissions='granularEditPermissions'
  :lockedDueToSomeSeasonCompetitionLockDate="lockedDueToSomeSeasonCompetitionLockDate"
  @updateCustomQuestions='(val) => $emit("updateCustomQuestions", val)'
)
ProgramRegistrations.mt-4(v-model:playerDetails="playerDetails" :hasSomeSuperEditPermission="hasSomeSuperEditPermission" v-if="playerDetails.competitions.length>0")
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, PropType, computed, watch } from 'vue'
import { axiosInstance } from 'src/boot/axios'

import authService from 'src/helpers/authService'
import { UserData } from 'src/interfaces/Store/user'
import { PlayerDetailsI } from 'src/interfaces/Store/registration'
import MedicalInformation from 'src/components/PlayerEditor/RegistrationData/MedicalInformation.vue'
import EmergencyContact from 'src/components/PlayerEditor/RegistrationData/EmergencyContact.vue'
import CustomQuestions from 'src/components/PlayerEditor/RegistrationData/CustomQuestions.vue'
import ProgramRegistrations from 'src/components/PlayerEditor/RegistrationData/ProgramRegistrations.vue'
import { getAggregateRegistrationStatusUiString } from './RegistrationUtils'
import * as ilapi from "src/composables/InleagueApiV1"

import { User } from 'src/store/User'
import { Client } from 'src/store/Client'
import { vReqT } from 'src/helpers/utils'
import { wellKnownRegistrationGrades } from 'src/composables/registration'

export default defineComponent({
  name: "RegistrationData",
  components: {
    MedicalInformation,
    EmergencyContact,
    CustomQuestions,
    ProgramRegistrations,
  },
  props: {
    playerDetails: {
      required: true,
      type: Object as PropType<PlayerDetailsI>
    },
    coreQuestionEditPermissionMap: {
      required: true,
      type: Object as PropType<Record<string, boolean>>
    },
    /**
     * @deprectated use granularEditPermissions
     */
    hasSomeSuperEditPermission: {
      required: true,
      type: Boolean
    },
    granularEditPermissions: vReqT<Record<string, boolean>>(),
    lockedDueToSomeSeasonCompetitionLockDate: {
      required: true,
      type: Boolean
    }
  },
  inheritAttrs: false,
  emits: ['updateCoreQuestionAnswers', 'updateCustomQuestions'],
  setup(props, context) {
    const grades = ref(wellKnownRegistrationGrades())
    const registrationStatus = computed(() => getAggregateRegistrationStatusUiString(props.playerDetails.competitions));
    const divName = ref('')
    const isFamilyMember = ref(false)



    const userRoles = (User.value.userData as UserData).roles

    const instanceConfig = computed(()=> {
      return Client.value.instanceConfig
    })

    const getDivName = async (ID: string) => {
      try {
        const division = await Client.getDivisionByID(ID)
        return division?.displayName ?? ""
      } catch {
        return ''
      }
    }

    const setFamilyMember = async () => {
      const children = await ilapi.getMyChildren(axiosInstance);
      for (let i = 0; i < children.length; i++) {
        if (children[i].childID === props.playerDetails.childID) {
          isFamilyMember.value = true
        }
      }
    }

    onMounted(async() => {
      await setFamilyMember()
      divName.value = await getDivName(props.playerDetails.divID)
    })

    watch(props.playerDetails, (v) => {
      context.emit("updateCoreQuestionAnswers", v);
    })

    return {
      grades,
      authService,
      userRoles,
      registrationStatus,
      divName,
      instanceConfig,
    }
  },
})
</script>
