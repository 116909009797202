import type { PropType, ExtractPropTypes } from "vue";
import type { RouteLocationRaw } from "vue-router"
import type { Guid } from "src/interfaces/InleagueApiV1"
import { exhaustiveCaseGuard, type ExtractEmitsHandlers } from "src/helpers/utils";

/**
 * route types declare route parameterizations
 * i.e. a route takes a param named "familyID", or "userID", or etc.
 * these types are always strings (Guid is an alias for string)
 * These types should be directly passable as `params` to a RouteLocationRaw
 */
type R_Default = undefined; // "no params"
interface R_SingularFamily {
  singularFamilyID: Guid
}
interface R_User {
  userID: Guid
}
interface R_Child {
  childID: Guid
}

/**
 * prop types wrap route types and make exhaustive case matching easier at use sites
 * These are the types the component will receive
 */
interface P_Base {
  name: RouteName
}
interface P_Default extends P_Base {
  name: RouteName.default
}
interface P_SingularFamily extends P_Base, R_SingularFamily {
  name: RouteName.singularFamily
}
interface P_User extends P_Base, R_User {
  name: RouteName.user
}
interface P_Child extends P_Base, R_Child {
  name: RouteName.child,
}

/**
 * TypeLookup is just a map (string -> type)
 */
interface TypeLookup {
  routeTypes: R_Default | R_SingularFamily | R_User | R_Child
  propTypes: P_Default | P_SingularFamily | P_User | P_Child
}

export const propsDef = {
  // wrapper because it's tough (impossible?) to type tagged unions at the "top-prop-level"
  wrapper: {
    required: true,
    type: Object as PropType<TypeLookup["propTypes"]>
  }
} as const

export const emitsDef = {
  // emits nothing
} as const

export type Props = ExtractPropTypes<typeof propsDef>;
export type Emits = ExtractEmitsHandlers<typeof emitsDef>;

export enum RouteName {
  default = "family-profile",
  singularFamily = "family-profile-singular-family",
  user = "family-profile-user",
  child = "family-profile-child"
}

/**
 * A vue-router RouteLocationRaw constrained to some particular set of path params
 */
type ConstrainedRouteLocationRaw<PathParams> = RouteLocationRaw & {params: PathParams}

/**
 * Convert some props into a RouteLocationRaw
 */
export function asRouteLocationRaw(args: Props["wrapper"]) : ConstrainedRouteLocationRaw<TypeLookup["routeTypes"]> {
    const params = (() => {
      switch (args.name) {
        case RouteName.default: return undefined;
        case RouteName.singularFamily: return {singularFamilyID: args.singularFamilyID};
        case RouteName.child: return {childID: args.childID};
        case RouteName.user: return {userID: args.userID};
        default: exhaustiveCaseGuard(args);
      }
    })();

    return {
      name: args.name,
      params: params
    }
}
