import type { AxiosInstance } from "axios";
import { exhaustiveCaseGuard } from "src/helpers/utils";
import { Competition, CompetitionSeason, Datelike, Division, Game, Guid, Integerlike, Numbool, Season, Team, WithDefinite } from "src/interfaces/InleagueApiV1";
import { rpc } from "./Rpc";

export interface Pool {
  /**
   * PK
   */
  poolUID: Guid,
  /**
   * AK -- (poolID, clientID)
   */
  poolId: Integerlike,
  poolName: string,
  competitionUID: Guid,
  divID: Guid,
  seasonUID: Guid,
}

export interface GetPoolsArgs {
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
}

/**
 * Similar to "getOrCreate" rounds, but requires less information because it does not need to create rounds if there are none currently existing.
 */
export interface ListRoundsArgs {
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  poolID: "ALL" | Integerlike,
}

export interface Round {
  roundID: Guid,
  /**
   * Why can this be null?
   */
  roundNum: Integerlike | "",
  startDate: Datelike,
  endDate: Datelike,
  excluded: Numbool,
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  poolID: "" | Integerlike,
  games: (Game & WithDefinite<Game, "field" | "homeTeam" | "visitorTeam">)[]
}

interface UpdateRoundArgs {
  roundID: Guid,
  excluded?: boolean,
}

export interface DeleteRoundsArgs {
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  poolID: "ALL" | Integerlike
}

export interface GenerateMatchupsArgs {
  roundIDs: Guid[],
  poolID: "ALL" | Integerlike,
  balanceHomeAndAway: boolean,
  byePosition: "first" | "last",
  includeGamesWithTeams: boolean,
}

export interface SaveMatchupsArgs {
  updateByeField: boolean,
  each: {
    gameID: Guid,
    homeTeamID: Guid,
    visitorTeamID: Guid,
  }[]
}

/**
 * Similar to "listRounds", but will create rounds for the (comp, season, div, pool) if none exist.
 */
export interface GetOrCreateRoundsArgs {
  competitionUID: Guid,
  seasonUID: Guid,
  divID: Guid,
  poolID: "ALL" | Integerlike,
  startDateInclusive: Datelike,
  endDateInclusive: Datelike,
  roundLengthInDays: Integerlike,
  numRoundRobinCycles: Integerlike,
  includeGamesWithTeams: boolean,
}
export interface GetOrCreateRoundsResponse {
  rounds: Round[],
  /**
   * does not include potential bye team
   */
  teamCountForCompSeasonDivPool: number,
  /**
   * Empty string if no warning, otherwise, a warning to the effect of "not enough games or teams or whatever",
   * note that rounds may still be created even if there is a warning. Rounds are ephemeral and can be deleted and generated again.
   */
  warning: string,
}

export interface GenerateTentativeMatchupsResponse {
  rounds: (
    & Round
    & {leftovers: {home: Team, visitor: Team}[]}
  )[],
  /**
   * comp/season/div is implied by the rounds, which all must be for the same comp/season/div
   */
  teamCountForCompSeasonDivPool: number,
}

interface CreateRoundArgs {
  competitionUID: Guid,
  seasonUID: Guid,
  divID: Guid,
  poolID: "ALL" | Integerlike
}

export const listRounds = rpc<ListRoundsArgs, Round[]>("get", "v1/matchmaker/listRounds")
export const deleteRounds = rpc<DeleteRoundsArgs, void>("delete", "v1/matchmaker/deleteRounds")
export const updateRound = rpc<UpdateRoundArgs, void>("put", "v1/matchmaker/updateRound")
export const createRound = rpc<CreateRoundArgs, Round>("post", "v1/matchmaker/createRound")
export const getOrCreateRounds = rpc<GetOrCreateRoundsArgs, GetOrCreateRoundsResponse>("post", "v1/matchmaker/getOrCreateRounds")
export const generateTentativeMatchups = rpc<GenerateMatchupsArgs, GenerateTentativeMatchupsResponse>("post", "v1/matchmaker/generateTentativeMatchups")
export const compSeasonDivMenu = {
  getCompetitions: rpc<void, {competitions: Competition[]}>("get", "v1/matchmaker/compSeasonDivMenu_competitions"),
  getSeasons: rpc<{competitionUID: Guid}, {seasons: (Season & {competitionSeason: CompetitionSeason | null})[]}>("get", "v1/matchmaker/compSeasonDivMenu_seasons"),
  getDivisions: rpc<{competitionUID: Guid}, {divisions: Division[]}>("get", "v1/matchmaker/compSeasonDivMenu_divisions"),
  getPools: rpc<GetPoolsArgs, Pool[]>("get", "v1/matchmaker/compSeasonDivMenu_pools"),
} as const

export const saveMatchups = rpc<SaveMatchupsArgs, void>("post", "v1/matchmaker/saveMatchups")
