import { FormKit } from "@formkit/vue";
import { axiosInstance, getGlobalApiURL } from "src/boot/AxiosInstances";
import { defineComponent, ref } from "vue";
import { formkitSingleFileFormRef } from "../../UserInterface/Modal.photoUpload.common";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { FormAugmentedDryRunResult, createGamesDryRunFromXlsx, xlsxDownloadURLs } from "src/composables/InleagueApiV1.GameSchedulerXlsx";
import { checkFileSnapshotConsistency, downloadFromObjectURL, exhaustiveCaseGuard, fileSnapshotInconsistencyWarning, useIziToast } from "src/helpers/utils";
import { CommittedResults, DryRunResults } from "./GameScheduler.impl"
import { Client } from 'src/store/Client'
import { buildLegacyLink } from "src/boot/auth";
import type { FormKitNode } from "@formkit/core"
import { SoccerBall } from "../../SVGs";
import { CreateGameRequest, createGames, GameForGameSchedulerView } from "src/composables/InleagueApiV1.GameScheduler";

export default defineComponent({
  setup() {
    const fkFileRef = formkitSingleFileFormRef()
    const fileUploadInputRef = ref<null | {node: FormKitNode}>(null);
    const iziToast = useIziToast();

    type Data =
      | {mode: "pending"}
      | {mode: "dry-run", data: FormAugmentedDryRunResult[]}
      | {mode: "complete", data: GameForGameSchedulerView[]}

    const data = ref<Readonly<Data>>({mode: "pending"});

    const doSubmitDryRun = async () => {
      const file = fkFileRef.file.value;
      if (!file) {
        throw Error("form should have prevented this")
      }

      if (!await checkFileSnapshotConsistency(file)) {
        // this is a better error than not performing this check and getting a cryptic network failure because FromData can't read it's source file.
        iziToast.warning({message: fileSnapshotInconsistencyWarning})
        data.value = {mode: "pending"}
        fileUploadInputRef.value?.node.reset();
        return;
      }

      try {
        const results = await createGamesDryRunFromXlsx(axiosInstance, {file});
        data.value = {
          mode: "dry-run",
          data: results.map(v => ({...v, conflictAck: v.conflicts.length === 0 ? "ignore-and-create" : null}))
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const doSubmitActual = async () => {
      if (data.value.mode !== "dry-run") {
        throw Error("illegal state");
      }

      const submittable : CreateGameRequest[] = data.value.data
        .filter(v => {
          if (!v.conflictAck) {
            throw Error("illegal state (form should have prevented this)")
          }
          return v.conflictAck === "ignore-and-create";
        })
        .map(v => {
          if (v.perExpandedRequest.slotCount !== 1 || v.perExpandedRequest.repeatWeeks !== 0) {
            // sanity check -- expanded requests have their slotCount and repeatWeeks reduced to their respective
            // "don't repeat this" value, because each (potentially expandable) request has __already been__ expanded.
            throw Error("unexpected perExpandedRequest values")
          }
          return {
            competitionUID: v.perExpandedRequest.competitionUID,
            divID: v.perExpandedRequest.divID,
            fieldUID: v.perExpandedRequest.fieldUID,
            slotCount: v.perExpandedRequest.slotCount,
            dateTime: v.perExpandedRequest.dateTime,
            slotGameDurationMinutes: v.perExpandedRequest.slotGameDurationMinutes,
            repeatWeeks: v.perExpandedRequest.repeatWeeks,
            comment: v.perExpandedRequest.comment,
            playoff: v.perExpandedRequest.playoff,
            pointsCount: v.perExpandedRequest.pointsCount,
            genderNeutral: v.perExpandedRequest.genderNeutral,
            blockFromMatchmaker: v.perExpandedRequest.blockFromMatchmaker,
            poolID: v.perExpandedRequest.poolID,
            scheduleEvenIfBlocked: false,
            acknowledgedConflicts: v.conflicts,
            tags: v.perExpandedRequest.tags,
            homeTeamID: v.perExpandedRequest.homeTeamID || undefined,
            visitorTeamID: v.perExpandedRequest.visitorTeamID || undefined,
          }
        })

      try {
        const created = await createGames(axiosInstance, {each: submittable});
        data.value = {
          mode: "complete",
          data: created
        }

        const gameStr = created.length === 1 ? "game" : "games";

        iziToast.success({message: `Created ${created.length} ${gameStr}`})
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const downloadIsBusy = ref(false);
    const doDownload = async () : Promise<void> => {
      try {
        try {
          downloadIsBusy.value = true;
          const bytes = (await axiosInstance.get(xlsxDownloadURLs.xlsxCreateGamesTemplateUrl(getGlobalApiURL()), {responseType: "blob"})).data;
          await downloadFromObjectURL(bytes, "GameScheduleTemplate.xlsx")
        }
        finally {
          downloadIsBusy.value = false;
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    return () => {
      return (
        <div data-test="R_GameScheduler">
          <h2>Game Schedule Upload - Create Games</h2>
          <div class="mt-4">
            Using the template below, you can upload a game schedule with or without team match-ups. inLeague will read the uploaded spreadsheet and preview the games it intends to create, highlighting any potential field space conflicts.
          </div>
          <div>
            If team match-ups are not provided, they can be assigned later via the matchmaker, the game scheduler, or the match-ups upload tool.
          </div>
          <ul class="list-disc ml-10 mt-2 mb-2 underline">
            <li><a href={buildLegacyLink(Client.value.instanceConfig.appdomain, '/gameScheduler', '')} target="_blank">Game Scheduler Tool (inLeague Classic)</a></li>
            <li><a href="https://gitlab.inleague.io/content/guides-and-documents/-/wikis/Game-Scheduling-Overview" target="_blank">Game Scheduling Documentation</a></li>
          </ul>
          <div>
            <t-btn type="button" margin={false} disable={downloadIsBusy.value} class={`my-2 ${downloadIsBusy.value ? 'bg-gray-200' : ''}`} onClick={() => doDownload()}>
              {
                downloadIsBusy.value
                  ? <div class="flex items-center gap-2 text-black">
                    <SoccerBall color="black" width="1em" height="1em"/>
                    <span>Downloading...</span>
                  </div>
                  : "Download blank Excel (xlsx) template"
              }
            </t-btn>
          </div>

          <div class="border-b my-2"/>

          <div>
            <div>
              Upload populated template (XLSX) file here:
            </div>
            <FormKit type="form" actions={false} onSubmit={doSubmitDryRun}>
              <FormKit type="file" accept=".xlsx" v-model={fkFileRef.fk_file} ref={fileUploadInputRef} data-test="xlsxUpload"/>
              <t-btn
                margin={false}
                disable={!fkFileRef.file.value}
                class={!fkFileRef.file.value ? "bg-gray-200" : ""}
                data-test="xlsxUploadSubmit"
                type="submit">Submit (preview and check for conflicts)
              </t-btn>
            </FormKit>
          </div>
          {
            data.value.mode === "pending"
              ? null
              : data.value.mode === "dry-run"
              ? (
                <FormKit type="form" actions={false} onSubmit={doSubmitActual}>
                  <div class="my-4">
                    <DryRunResults dryRunResults={data.value.data}/>
                  </div>
                  <t-btn type="submit" data-test="doSubmitActual">Submit</t-btn>
                </FormKit>
              )
              : data.value.mode === "complete"
              ? (
                <div class="my-4">
                  <div>Created</div>
                  <CommittedResults committedResults={data.value.data}/>
                </div>
              )
              : exhaustiveCaseGuard(data.value)
          }
        </div>
      )
    }
  }
})
