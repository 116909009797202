import * as iltypes from 'src/interfaces/InleagueApiV1'
import type { AxiosInstance } from 'axios'
import { parseFloatOr, parseIntOr, requireNonNull, CheckedOmit } from 'src/helpers/utils'
import * as Child from "./InLeagueApiV1.Child"
import * as TeamChooser from "./InleagueApiV1.TeamChooserMenu"
import { DateTimelike, Guid, Integerlike, Numbool } from 'src/interfaces/InleagueApiV1'

// This could be an enum, but we're gonna see how well we can do with "manual" enums.
// Seems the only thing we might be missing is the free bidirectional mapping (key<->value).
//  - and enums offer more informative tooltips (as in, the difference between "1|2|3|4|5" and "EventQuestionType")
// Note that key case is important here (i.e. all lowercase), it tracks how the backend will serialize the values.
export const EventQuestionType = {
  checkbox: 1,
  radio: 2,
  text: 3,
  select: 4,
  textarea: 5,
  1: "checkbox",
  2: "radio",
  3: "text",
  4: "select",
  5: "textarea"
} as const;


/** intended to be a typemap only, there shouldn't ever be actual values having this type  */
export interface EventQuestionType {
  int: Extract<(typeof EventQuestionType)[keyof typeof EventQuestionType], number>,
  string: Extract<keyof typeof EventQuestionType, string>
}

export function mapQuestionTypeIntToString(v: EventQuestionType["int"]) : EventQuestionType["string"] {
  return EventQuestionType[v];
}

export function mapQuestionTypeStringToInt(v: EventQuestionType["string"]) : EventQuestionType["int"] {
  return EventQuestionType[v];
}

export const SignupAllowanceType = {
  closed: 0, // n.b. this is falsy if used in bool position
  usersOnly: 1,
  playersOnly: 2,
  usersAndPlayers: 3
} as const;

export interface SignupAllowanceType {
  int: (typeof SignupAllowanceType)[keyof typeof SignupAllowanceType]
  string: keyof SignupAllowanceType
}

/**
 * We expect that the case here matches exactly what the server provides
 */
export const EventCategoryType = {
  General: "General",
  Referees: "Referees",
  Coaches: "Coaches",
  Tryouts: "Tryouts",
  Training: "Training",
  Board: "Board",
} as const;
export type EventCategoryType = keyof typeof EventCategoryType;

export interface EventQuestionOption {
	id: iltypes.Guid,
	optionText: string,
	optionValue: string,
  /**
   * todo: should be `null | number` (it does come over the wire as `cfnull | integerlike` though, we need to transform it)
   */
	order: iltypes.cfnull | iltypes.Integerlike,
	questionID: iltypes.Guid,
}

type EventQuestion_RawApi = Omit<EventQuestion, "sortOrder"> & {
  isRequired: iltypes.Numbool,
  sortOrder: iltypes.cfnull | iltypes.Integerlike,
}

export interface EventQuestion {
  clientID: iltypes.Guid,
  isRequired: boolean,
  label: string,
  questionID: iltypes.Guid,
  /**
   * expandable
   */
  questionOptions?: EventQuestionOption[],
  shortLabel: string,
  /**
   * a sort order is not required; all questions with absent sortOrders should be ordered
   * "as a group" before all those questions with a specified sort order. The order of the
   * elements within the "unsorted group" is unspecified.
   */
  sortOrder: null | number,
  type: EventQuestionType["string"]
  /**
   * expandable
   * list of events with which this question is associated
   *
   * todo: right now, this comes across the wire as an Event; we should munge it appropriately;
   * need to hoist the munger out of `getEvent` (and make it so it doesn't assume the incoming raw data is exactly
   * a GetEventResponse but rather just a base event)
   *
   */
  events?: Pick<Event, "eventID" | "eventName">[]
}

export interface Event {
  address: string,
  /**
   * list of seasonIDs (not seasonUIDs) this event is restricted to (i.e. a user must parent a player associated with one of these seasons to be eligible)
   * an empty list means "no such restriction"
   */
  adultLimitSeasons: iltypes.Integerlike[],
  /**
   * Exactly same information as `adultLimitSeasons` but using the GUID keys for seasons
   * This is only available from some endpoints.
   */
  adultLimitSeasonUIDs?: Guid[],
  allDay: boolean,
  allowSignups: SignupAllowanceType["int"],
  clientID: iltypes.Guid,
  comments: string,
  competitionUID: iltypes.Guid,
  contactEmail: string,
  contactName: string,
  contactPhone: string,
  couponLinkCount: number,
  /**
   * divisions this event is restricted to (e.g. an entity being signed up must be in some way associated with this division)
   * An empty list means "no such restriction"
   */
  divisions: iltypes.DivisionID[],
  emailText: string,
  eventCategory: EventCategoryType,
  eventEnd: null | iltypes.Datelike,
  eventID: iltypes.Guid,
  /**
   * This is either:
   *  - case venueID non-null --> a view into the linked venueID's venueName,
   *  - case venueID null     --> the literal `eventLoc` value for freeform eventlocation text
   *
   * To check if eventLoc is "freeform", check if venueID is truthy
   */
  eventLoc: string,
  eventName: string,
  eventStart: null | iltypes.Datelike,
  feePlayer: null | number,
  feeUser: null | number,
  gatewayID: null | iltypes.Guid,
  /**
   * google calendar ID, if any
   */
  gCalID: null | string,
  isCancelled: boolean,
  maxCount: null | iltypes.Integerlike,
  notificationEmails: string,
  /**
   * list of seasonIDs (not seasonUIDs) this event is restricted to (e.g. a player must be associated with one of these seasons)
   * an empty list means "no such restriction"
   */
  playerLimitSeasons: iltypes.Integerlike[],
  /**
   * Optional, depends on endpoint.
   * Represents exactly the same information as playerLimitSeasons, but in the form of seasonUIDs rather than seasonIDs.
   */
  playerLimitSeasonUIDs?: iltypes.Guid[],
  questions: EventQuestion[],
  regionSponsor: string,
  /**
   * expandable
   */
  rosterLayoutDetail?: EventRosterLayoutDetail
  /**
   * pefer to use `seasonUID` whenever possible
   */
  seasonID: iltypes.Integerlike,
  seasonName: string,
  seasonUID: iltypes.Guid,
  /**
   * expandable
   */
  signups?: EventSignup[],
  signedUpCount: iltypes.Integerlike,
  stripe_price_child: string,
  stripe_price_child_amt: null | number,
  stripe_price_user: string,
  stripe_price_user_amt: null | number,
  stripe_sync_date: null | iltypes.Datelike,
  teamID: null | iltypes.Guid,
  venueCity: string,
  venueID: iltypes.Guid,
  venueName: string,
  venueState: string,
  venueStreet: string,
  venueZip: string,
}

type BooleanToNumbool<T> = boolean extends T ? (Exclude<T, boolean> | iltypes.Numbool) : T;
type NullToCfNull<T> = null extends T ? (Exclude<T, null> | iltypes.cfnull) : T;
// e.g. {foo: null | number, bar: null|boolean} -> {foo: cfnull | number, bar: cfnull|numbool};
// n.b. doesn't work recursively (some raw shapes are adhoc omit/exclude kinda things rather than direct recursive applications of AsRawApiShape)
// this just covers common cases. There's alot of adhoc things we can't reliably transform here.
type AsRawApiShape<Obj> = {[P in keyof Obj]: NullToCfNull<BooleanToNumbool<Obj[P]>>}

/**
 * raw Event object as sent from API
 * There are some transformations we'd like to apply prior to returning to the application layer
 */
type Event_RawApi = CheckedOmit<AsRawApiShape<Event>, "playerLimitSeasons" | "adultLimitSeasons" | "allowSignups"> & {
  allowSignups: iltypes.Integerlike,
  /**
   * comma delimited list of seasonIDs (not seasonUIDs!)
   */
  playerLimitSeasons: string
  /**
   * comma delimited list of seasonIDs (not seasonUIDs!)
   */
  adultLimitSeasons: string
}

/**
 * quasi Event object suitable for submission to a create/update endpoint.
 *
 * Not quite a super/sub type of an actual `Event` object.
 *
 * Many things are the same, some "array of objects" from Event become "array of IDs of those objects".
 * cfnullish things are explicitly "empty-string or T", where T is often an alias for "string",
 * so ultimately we just have type `string` in those positions. Some things are mutually exclusive
 * with other things; this is tough to express, and ends up with a big union of similar variants, so
 * we don't express it.
 *
 * Notably, eventID is omitted; this is expected to be provided out-of-band,
 * to support both "make new" and "update existing" use cases.
 *
 * `clientID` is also intentionally omitted, since it is never user writeable.
 *
 * `seasonID` is omitted in favor of exclusively using seasonUID.
 */
export interface SubmittableEvent {
  /**
   * list of seasonIDs
   */
  adultLimitSeasons: iltypes.Integerlike[],
  allDay: iltypes.Numbool,
  allowSignups: iltypes.Integerlike<SignupAllowanceType["int"]>,
  comments: string,
  competitionUID: string,
  contactEmail: string,
  contactName: string,
  contactPhone: string,
  /**
   * restrict this event to users (players? both? only one?) who are in these divisions
   */
  divisions: iltypes.DivisionID[],
  emailText: string,
  eventCategory: EventCategoryType,
  /**
   * only allowed if `eventStart` is non-nullish
   */
  eventEnd?: iltypes.Datelike,
  /**
   * mutually exclusive w/ `venueID`
   */
  eventLoc?: string,
  eventName: string,
  eventStart: "" | iltypes.Datelike,
  /**
   * `undefined` means "do not touch this field on the backend, during either a create or update request"
   * `""` means "set to nothing"
   * numeric value is "set to that value"
   */
  feePlayer: undefined | "" | iltypes.Numeric,
  /**
   * Types have same meaning as `feePlayer`
   * @see feePlayer
   */
  feeUser: undefined | "" | iltypes.Numeric,
  gatewayID: iltypes.Guid,
  gCalID: string,
  maxCount: "" | iltypes.Integerlike,
  notificationEmails: string,
  /**
   * list of seasonIDs
   */
  playerLimitSeasons: iltypes.Integerlike[],
  /**
   * array of questionIDs
   */
  questions: iltypes.Guid[],
  regionSponsor: string,
  seasonUID: iltypes.Guid,
  teamID: iltypes.Guid | "",
  /**
   * mutually exclusive with `eventLoc`
   */
  venueID?: iltypes.Guid,
}

export interface EventVenue {
  city: string,
  clientID: iltypes.Guid,
  comments: string,
  isActive: boolean,
  name: string,
  state: string,
  street: string,
  venueID: iltypes.Guid,
  zip: string,
}

export async function listEventVenues(axios: AxiosInstance) : Promise<EventVenue[]> {
  const response = await axios.get(`v1/eventVenues`);
  return response.data.data.map(mungeEventVenue);

  function mungeEventVenue(raw: AsRawApiShape<EventVenue>) : EventVenue {
    return {
      city: raw.city,
      clientID: raw.clientID,
      comments: raw.comments,
      isActive: !!raw.isActive,
      name: raw.name,
      state: raw.state,
      street: raw.street,
      venueID: raw.venueID,
      zip: raw.zip,
    }
  }
}

/**
 * support structure, expandable on an Event, which provides misc. additional data
 * required to draw the Roster table
 *
 * For an event with an expanded "signups" and expanded "rosterLayoutDetail",
 * the set of available users and children here is expected to fully cover all users/children of the event's
 * expanded signups.
 */
export interface EventRosterLayoutDetail {
  /**
   * We get an adhoc partial user here
   * We need elevated permission to see roster layouts, so we can conceptually pick fields out of an admin view of a user
   */
  users: {[userID: iltypes.Guid]: Pick<iltypes.User_Privileged, "firstName" | "lastName" | "email" | "AYSOID" | "primaryPhone">},
  children: {[childID: iltypes.Guid]: iltypes.WithDefinite<iltypes.Child, "parent2Email" | "parent2Phone" | "parent2FirstName" | "parent2LastName">
  },
  childrenComputedDetail: {
    /**
     * NOTE: perf considerations currently dictate that we do not calculate speculative division assignments here,
     * because the current approach times out running against large lists of signups.
     * So, if speculative=true, the div list will be empty.
     */
    [childID: iltypes.Guid]: Child.CalculatedAgeAndDivisionInfo
  },
  /**
   * The contract is that if an answer listing is present here for some signup,
   * then it contains every question for this event, even if there is no answer for that question
   * for the particular signup (the `answer` property will contain some sentinel value in that case, currently '<<no answer>>'
   * which is ambiguous with an actual answer of '<<no answer>>' but good enough for now)
   */
  questionAnswers: {
    [eventSignupID: iltypes.Guid]:
      | undefined // no-unchecked
      | {
        eventID: iltypes.Guid,
        eventSignupID: iltypes.Guid,
        entityType: "child" | "user",
        entityID: iltypes.Guid,
        questionID: iltypes.Guid,
        shortLabel: string,
        answer: string,
      }[]
  }
}

/**
 * An Event object, with auto-expanded things we from the default get endpoint ("questions.questionOptions")
 */
export type GetEventResult = iltypes.WithDefinite<Event, "playerLimitSeasonUIDs" | "adultLimitSeasonUIDs"> & {questions: iltypes.WithDefinite<EventQuestion, "questionOptions">[]};

export type EventExpandable = "signups" | "rosterLayoutDetail"

export async function getEvent(axios: AxiosInstance, eventID: iltypes.Guid, expand?: EventExpandable[]) : Promise<GetEventResult> {
  const rawData : Event_RawApi = await (async () => {
    const params : Record<string, any> = {};
    if (expand?.length) {
      params.expand = expand.join(",");
    }
    const response = await axios.get(`v1/event/${eventID}`, {params});
    return response.data.data;
  })();

  const listToArray = (v: string) => v === "" ? [] : v.split(",");

  const result : GetEventResult = {
    address: rawData.address,
    // list of seasonIDs, not seasonUIDs
    adultLimitSeasons: listToArray(rawData.adultLimitSeasons) as iltypes.Integerlike[],
    adultLimitSeasonUIDs: requireNonNull(rawData.adultLimitSeasonUIDs),
    allDay: !!rawData.allDay,
    allowSignups: (() => {
      // coerce raw data to "definitely numbers", e.g. we do not support `"3"`, only `3`
      if (rawData.allowSignups /*not strict*/ == SignupAllowanceType.closed) {
        return SignupAllowanceType.closed;
      }
      else if (rawData.allowSignups /*not strict*/ == SignupAllowanceType.playersOnly) {
        return SignupAllowanceType.playersOnly;
      }
      else if (rawData.allowSignups /*not strict*/ == SignupAllowanceType.usersAndPlayers) {
        return SignupAllowanceType.usersAndPlayers;
      }
      else if (rawData.allowSignups /*not strict*/ == SignupAllowanceType.usersOnly) {
        return SignupAllowanceType.usersOnly;
      }
      else {
        // might want to throw here in dev, shouldn't be reachable.
        // throw "unreachable";
        return SignupAllowanceType.closed;
      }
    })(),
    clientID: rawData.clientID,
    comments: rawData.comments,
    competitionUID: rawData.competitionUID,
    contactEmail: rawData.contactEmail,
    contactName: rawData.contactName,
    contactPhone: rawData.contactPhone,
    couponLinkCount: rawData.couponLinkCount,
    divisions: rawData.divisions,
    emailText: rawData.emailText,
    eventCategory: rawData.eventCategory,
    eventEnd: iltypes.cfNullGetOr(rawData.eventEnd, null),
    eventID: rawData.eventID,
    eventLoc: rawData.eventLoc,
    eventName: rawData.eventName,
    eventStart: iltypes.cfNullGetOr(rawData.eventStart, null),
    feePlayer: parseFloatOr(rawData.feePlayer, null),
    feeUser: parseFloatOr(rawData.feeUser, null),
    gatewayID: iltypes.cfNullGetOr(rawData.gatewayID, null),
    gCalID: iltypes.cfNullGetOr(rawData.gCalID, null),
    isCancelled: !!rawData.isCancelled,
    maxCount: parseIntOr(rawData.maxCount, null),
    notificationEmails: rawData.notificationEmails,
    /**
     * list of seasonIDs (not seasonUIDs)
     */
    playerLimitSeasons: listToArray(rawData.playerLimitSeasons) as iltypes.Integerlike[],
    /**
     * List of seasonUIDs (represents same info as playerLimitSeasons but in seasonUID form).
     */
    playerLimitSeasonUIDs: requireNonNull(rawData.playerLimitSeasonUIDs),
    questions: (rawData.questions as EventQuestion_RawApi[]).map(mungeEventQuestion) as iltypes.WithDefinite<EventQuestion, "questionOptions">[],
    regionSponsor: rawData.regionSponsor,
    seasonID: rawData.seasonID,
    seasonName: rawData.seasonName,
    seasonUID: rawData.seasonUID,
    signedUpCount: rawData.signedUpCount,
    stripe_price_child: rawData.stripe_price_child,
    stripe_price_child_amt: iltypes.cfNullGetOr(rawData.stripe_price_child_amt, null),
    stripe_price_user: rawData.stripe_price_user,
    stripe_price_user_amt: iltypes.cfNullGetOr(rawData.stripe_price_user_amt, null),
    stripe_sync_date: iltypes.cfNullGetOr(rawData.stripe_sync_date, null),
    teamID: iltypes.cfNullGetOr(rawData.teamID, null),
    venueCity: rawData.venueCity,
    venueID: rawData.venueID,
    venueName: rawData.venueName,
    venueState: rawData.venueState,
    venueStreet: rawData.venueStreet,
    venueZip: rawData.venueZip,
  };

  //
  // expandables
  //
  if (rawData.playerLimitSeasonUIDs) {
    result.playerLimitSeasonUIDs = rawData.playerLimitSeasonUIDs;
  }
  if (rawData.signups) {
    result.signups = (rawData.signups as AsRawApiShape<EventSignup>[]).map(mungeEventSignup);
  }
  if (rawData.rosterLayoutDetail) {
    // no munge? seems to come across the wire exactly as expected.
    result.rosterLayoutDetail = rawData.rosterLayoutDetail;
  }

  return result;
}

/**
 * @deprecated
 */
export async function listEvents(
  axios: AxiosInstance,
  args?: {
    endingOnOrAfter?: iltypes.Datelike,
    divID?: iltypes.Guid,
    venueID?: iltypes.Guid
  }
) : Promise<Event[]> {
  const response = await axios.get(`v1/events`, {params: args});
  return response.data.data;
}

// very similiar to `listEvents`? one difference is the backend applies a "is visible to this user" filter here.
export async function findEligibleEventsForUser(axios: AxiosInstance, args: {
  userID: iltypes.Guid,
  teamEventsOnly?: boolean,
  endingOnOrAfter?: iltypes.Datelike,
  divID?: iltypes.Guid,
  venueID?: iltypes.Guid,
  hasSomeSignupWithUserOrPlayerName?: string
  eventName?: string,
}) : Promise<Event[]> {
  const {userID, ...params} = args;
  const response = await axios.get(`v1/events/user/${userID}`, {params});

  // TODO: munge response
  return response.data.data;
}

export async function createEvent(axios: AxiosInstance, args: {data: SubmittableEvent}) : Promise<Partial<Event> & {__fixme__needsMunge: true}> {
  const response = await axios.post(`v1/event/`, args.data);
  return response.data.data;
}

export async function updateEvent(axios: AxiosInstance, args: {eventID: iltypes.Guid, data: SubmittableEvent}) : Promise<Partial<Event> & {__fixme__needsMunge: true}> {
  const response = await axios.post(`v1/event/${args.eventID}`, args.data);
  return response.data.data;
}

export async function deleteEvent(axios: AxiosInstance, eventID: iltypes.Guid) : Promise<void> {
  await axios.delete(`v1/event/${eventID}`);
}

export interface EventFormOptions {
  eventQuestions: EventQuestion[],
  eventVenues: EventVenue[],
  seasonOptions: iltypes.Season[],
  competitionOptions: {competitionID: number, competitionUID: iltypes.Guid, competition: string}[],
  limitToParticularSeasonOptions: {
    byFamilyHavingPlayerRegisteredIn: iltypes.Season[],
    byPlayerIsRegisteredIn: iltypes.Season[]
  },
  limitToParticularDivisionOptions: {
    byPlayerIsInDivision: iltypes.Division[]
  },
  paymentGateways: iltypes.PaymentGateway[],
  /**
   * Either there is no current connection to google (probably a missing or expired oauth token)
   * or there is and we get some calendar info
   */
  googleCalendars:
    | {connected: false}
    | {connected: true, calendars: {summary: string, id: string}[]}
}

/**
 * accepts an optional eventID to retrieve options that are specifically relevant to an existing eventID for edit purposes
 */
export async function getEventFormOptions(axios: AxiosInstance, eventID?: iltypes.Guid) : Promise<EventFormOptions> {
  const params : Record<string, any> = {}
  if (eventID) {
    params.eventID = eventID;
  }
  const response = await axios.get(`v1/event/eventFormOptions`, {params});
  return response.data.data;
}

function mungeEventQuestion(raw: EventQuestion_RawApi) : EventQuestion {
  const sortOrder = (() => {
    const maybeInt = parseInt(raw.sortOrder as string);
    return isNaN(maybeInt) ? null : maybeInt;
  })();

  const ret : EventQuestion = {
    clientID: raw.clientID,
    isRequired: !!raw.isRequired,
    label: raw.label,
    questionID: raw.questionID,
    shortLabel: raw.shortLabel,
    sortOrder: sortOrder,
    type: raw.type
  }

  // warn: expandables are all optional,
  // so if we forget to do some here, we won't get a type error
  if (raw.questionOptions) {
    // probably need to munge each question option, but the only transform we currently
    // might want to apply is `"" -> null` so it's not too big a deal
    ret.questionOptions = raw.questionOptions.sort((l,r) => {
      const xl = parseIntOr(l.order, -2);
      const xr = parseIntOr(r.order, -1);
      return xl < xr ? -1 : 1;
    })
  }

  if (raw.events) {
    // currently does not need any post processing
    ret.events = raw.events.map(raw => ({eventID: raw.eventID, eventName: raw.eventName}));
  }

  return ret;
}

export type EventQuestionExpandable = "questionOptions" | "events"

/**
 * always auto-expands "questionOptions"
 */
export async function listEventQuestions(axios: AxiosInstance, expand?: EventQuestionExpandable[]) : Promise<iltypes.WithDefinite<EventQuestion, "questionOptions">[]> {
  const params : Record<string, any> = {};
  if (expand?.length) {
    params.expand = expand.join(",");
  }
  const response : EventQuestion_RawApi[] = (await axios.get(`v1/eventQuestions`, {params})).data.data;
  return response.map(mungeEventQuestion) as any;
}

export async function getEventQuestion(
  axios: AxiosInstance,
  questionID: iltypes.Guid
) : Promise<iltypes.WithDefinite<EventQuestion, "questionOptions">> {
  const rawData : EventQuestion_RawApi = (await axios.get(`v1/eventQuestion/${questionID}`)).data.data;
  return mungeEventQuestion(rawData) as any;
}

export type CreateUpdateQuestionOptionArgs = Pick<EventQuestionOption, "optionText" | "optionValue">
export type CreateEventQuestionArgs =
  & CheckedOmit<EventQuestion, "questionOptions" | "clientID" | "questionID">
  & {questionOptions: CreateUpdateQuestionOptionArgs[]};

export interface RevisedSortOrder {
  questionID: iltypes.Guid,
  sortOrder: number
}

export async function createEventQuestion(
  axios: AxiosInstance,
  args: CreateEventQuestionArgs
) : Promise<{revisedSortOrders: null | RevisedSortOrder[], eventQuestion: iltypes.WithDefinite<EventQuestion, "questionOptions">}> {
  const rawData = (await axios.post(`v1/eventQuestion`, args)).data.data;
  return {
    eventQuestion: mungeEventQuestion(rawData.eventQuestion) as any,
    revisedSortOrders: rawData.revisedSortOrders,
  }
}

export type UpdateEventQuestionArgs =
  & CheckedOmit<EventQuestion, "questionOptions" | "clientID">
  & {questionOptions: CreateUpdateQuestionOptionArgs[]};

export async function updateEventQuestion(
  axios: AxiosInstance,
  args: UpdateEventQuestionArgs
) : Promise<{revisedSortOrders: null | RevisedSortOrder[], eventQuestion: iltypes.WithDefinite<EventQuestion, "questionOptions">}> {
  const {questionID, ...formData} = args;
  const rawData = (await axios.put(`v1/eventQuestion/${questionID}`, formData)).data.data;
  return {
    eventQuestion: mungeEventQuestion(rawData.eventQuestion) as any,
    revisedSortOrders: rawData.revisedSortOrders
  }
}

export async function deleteEventQuestion(axios: AxiosInstance, questionID: iltypes.Guid) : Promise<{revisedSortOrders: null | RevisedSortOrder[]}> {
  const response = await axios.delete(`v1/eventQuestion/${questionID}`);
  return response.data.data;
}

export async function nudgeEventQuestion(axios: AxiosInstance, questionID: iltypes.Guid, dir: "up" | "down") : Promise<RevisedSortOrder[]>{
  const response = await axios.post(`v1/eventQuestion/${questionID}/nudge`, {dir});
  return response.data.data;
}

/**
 * only gets event signups for the current user's family, presumably we could support different filters but we don't need to right now
 * to get all the event signups for a particular event (and assuming registrar permission), use getEvent with the "signup" expandable
 */
export async function getEventSignups(axios: AxiosInstance, args: {eventID: iltypes.Guid, narrowTo: "currentUserFamily"}) : Promise<iltypes.WithDefinite<EventSignup, "questionAnswers" | "computed_feeAfterVisibleDiscounts">[]> {
  const rawListing : AsRawApiShape<EventSignup>[] = (await axios.get(`v1/event/${args.eventID}/signups`)).data.data;
  return rawListing.map(mungeEventSignup) as any; // can't quite push the withDefinite part through here
}

export async function getEventSignup(axios: AxiosInstance, args: {eventSignupID: iltypes.Guid}) : Promise<EventSignup> {
  const raw : AsRawApiShape<EventSignup> = (await axios.get(`v1/eventSignup/${args.eventSignupID}`)).data.data;
  return mungeEventSignup(raw);
}

/**
 * An event signup for a either player or a user
 */
export interface EventSignup {
  addedByAdmin: boolean,
  canBeMoved: boolean,
  canceled: boolean,
  childFirstName: string,
  childID: iltypes.Guid,
  childLastName: string,
  clientID: iltypes.Guid,
  comments: string,
  /**
   * not serialized from all endpoints
   * If present, should be a number in range [0, <actual fee>]
   */
  computed_feeAfterVisibleDiscounts?: number,
  couponID: string,
  /**
   * synthetic frontend property, pulled from the appropriate "childID" / "userID" field
   */
  entityID: iltypes.Guid,
  eventID: iltypes.Guid,
  eventName: string,
  eventSignupID: iltypes.Guid,
  /**
   * synthetic frontend property, pulled from the appropriate "feePlayer" / "feeUser" field
   */
  fee: null | number,
  feePlayer: null | number,
  feeUser: null | number,
  /**
   * If we have an associated invoice, this will be non-null
   */
  invoiceInstanceID: null | iltypes.Integerlike,
  /**
   * non-nullish indicates that this is a "legacy" event signup, that is not participating in the new invoice system.
   * If non-nullish, `invoiceInstanceID` must be null (and vice-versa)
   */
  invoiceNo: string,
  /**
   * associated invoice line item ID, if any
   * todo -- null ?
   */
  lineItemID: string,
  paid: boolean,
  // depending on endpoint (!)
  // ("do signup" endpoint seems to return questionAnswers, the "get signups" returns the {user,child} variants )
  // we get either {questionAnswers} | {questionAnswersUser} | {questionAnswersChild}
  // however, we will unify this to exactly {questionAnswers}
  questionAnswers?: EventQuestionAnswer[],
  /** @deprecated use questionAnswers */
  questionAnswersUser?: EventQuestionAnswer[],
  /** @deprecated use questionAnswers*/
  questionAnswersChild?: EventQuestionAnswer[],
  seasonUID: iltypes.Guid,
  signedUpBy: iltypes.UserID,
  signupDate: iltypes.Datelike,
  stripe_price_child: string,
  stripe_price_user: string,
  submitterEmail: string,
  userFirstName: string,
  userID: string,
  userLastName: string,
}

function mungeEventSignup(raw: AsRawApiShape<EventSignup>) : EventSignup {
  return {
    addedByAdmin: !!raw.addedByAdmin,
    canBeMoved: !!raw.canBeMoved,
    canceled: !!raw.canceled,
    childFirstName: raw.childFirstName,
    childID: raw.childID,
    childLastName: raw.childLastName,
    clientID: raw.clientID,
    comments: raw.comments,
    computed_feeAfterVisibleDiscounts: raw.computed_feeAfterVisibleDiscounts ?? undefined,
    couponID: raw.couponID,
    entityID: raw.childID || raw.userID,
    eventID: raw.eventID,
    eventName: raw.eventName,
    eventSignupID: raw.eventSignupID,
    fee: parseFloatOr(raw.childID ? raw.feePlayer : raw.feeUser, null),
    feePlayer: parseFloatOr(raw.feePlayer, null),
    feeUser: parseFloatOr(raw.feeUser, null),
    invoiceInstanceID: iltypes.cfNullGetOr(raw.invoiceInstanceID, null),
    invoiceNo: raw.invoiceNo,
    lineItemID: raw.lineItemID,
    paid: !!raw.paid,
    questionAnswers: (() => {
      // Really explicit that it could be undefined here.
      // The "raw api shape" really is possibly undefined.
      // This IIFE is what assigns it so that the outside world can see it as always definitely EventQuestionAnswer[].

      // sometimes `questionAnswers` is received; it takes precedence
      // otherwise, if the signup is a child signup, try to use the child answers
      // otherwise, if the signup is a user signup, try to user the user answers
      // otherwise, an empty list (but this case should not be reached)
      return (raw.questionAnswers as EventQuestionAnswer[] | undefined)?.map(v => mungeEventQuestionAnswer(v as AsRawApiShape<EventQuestionAnswer>))
        || (raw.childID && raw.questionAnswersChild?.map(v => mungeEventQuestionAnswer(v as AsRawApiShape<EventQuestionAnswer>)))
        || (raw.userID && raw.questionAnswersUser?.map(v => mungeEventQuestionAnswer(v as AsRawApiShape<EventQuestionAnswer>)))
        || [];
    })(),
    seasonUID: raw.seasonUID,
    signedUpBy: raw.signedUpBy,
    signupDate: raw.signupDate,
    stripe_price_child: raw.stripe_price_child,
    stripe_price_user: raw.stripe_price_user,
    submitterEmail: raw.submitterEmail,
    userFirstName: raw.userFirstName,
    userID: raw.userID,
    userLastName: raw.userLastName,
  }
}

export interface EventQuestionAnswer {
  /** The answer value */
  answer: string,
  /** Unique ID of the child signing up for a player signup. Empty for an adult signup */
  childID: iltypes.Guid | null,
  /** Unique ID of the question */
  clientID: iltypes.Guid,
  /** Unique ID of the event to which the question was assigned */
  eventID: iltypes.Guid,
  /** Unique ID of this answer */
  id: iltypes.Guid,
  /** Associated question */
  question: EventQuestion,
  /** Unique ID of the question */
  questionID: iltypes.Guid,
  /** Unique ID of the user signing up. Empty for a player signup */
  userID: iltypes.Guid | null,
}

function mungeEventQuestionAnswer(raw: AsRawApiShape<EventQuestionAnswer>) : EventQuestionAnswer {
  return {
    /** The answer value */
    answer: raw.answer,
    /** Unique ID of the child signing up for a player signup. Empty for an adult signup */
    childID: iltypes.cfNullGetOr(raw.childID, null),
    /** Unique ID of the question */
    clientID: raw.clientID,
    /** Unique ID of the event to which the question was assigned */
    eventID: raw.eventID,
    /** Unique ID of this answer */
    id: raw.id,
    /** Associated question */
    question: mungeEventQuestion(raw.question as EventQuestion_RawApi),
    /** Unique ID of the question */
    questionID: raw.questionID,
    /** Unique ID of the user signing up. Empty for a player signup */
    userID: iltypes.cfNullGetOr(raw.userID, null)
  }
}

/**
 * holds question answers for a particular entity for a particular event
 * Represents only one type of entity at a time; either a user, or a child
 */
interface SignupRequestBase {
  //
  // three discriminants
  //
  childID?: string
  userID?: string
  isChild?: boolean

  /**
   * This represents answers to custom questions,
   * should be `undefined` if there are no answers
   */
  customQuestions: undefined | {[questionID: iltypes.Guid]: string | boolean | number}
  comments: string,
  /**
   * todo: clarify what this is (seems we always set it to true?)
   */
  isAttending: boolean
}

export interface ChildEntitySignupRequest extends SignupRequestBase {
  childID: string,
  isChild: true
}

export interface UserEntitySignupRequest extends SignupRequestBase {
  userID: string,
  isChild: false
}

export type SignupRequest = ChildEntitySignupRequest | UserEntitySignupRequest

/**
 * @param args.forceActivate if supplied must be `true`, otherwise defaults to false. Requires admin permission. Will forceActivate the target event signups.
 */
export async function signup(axios: AxiosInstance, args: {eventID: iltypes.Guid, signupRequests: SignupRequest[], forceActivate?: true}) : Promise<iltypes.WithDefinite<EventSignup, "questionAnswers" | "computed_feeAfterVisibleDiscounts">[]> {
  const payload = {signups: args.signupRequests, forceActivate: args.forceActivate};
  const response = await axios.post(`v1/event/${args.eventID}/signups`, payload);
  const rawSignups : any[] = response.data.data;
  return rawSignups.map(mungeEventSignup) as iltypes.WithDefinite<EventSignup, "questionAnswers" | "computed_feeAfterVisibleDiscounts">[];
}

export async function updateSignup(axios: AxiosInstance, args: {eventSignupID: iltypes.Guid, comments?: string}) : Promise<void> {
  const {eventSignupID, ...payload} = args;
  await axios.put(`v1/eventSignup/${eventSignupID}`, payload);
}

/**
 * cancel some user or child's event signup
 * The event signup must not already be paid. To cancel a paid signup, consider issuing a refund.
 */
export async function cancelEventSignup(
  axios: AxiosInstance,
  args: {eventID: iltypes.Guid} & ({userID: iltypes.Guid} | {childID: iltypes.Guid})) : Promise<void> {
  const {eventID, ...params} = args;
  await axios.delete(`v1/event/${args.eventID}/signup`, {params});
}

/**
 * The names of roles a user might have, who is allowed to participate in writing into an Event via an EventForm.
 * These role names are somewhat ad hoc and aren't guaranteed to map to "actual" role names,
 * although there is a conceptual and handwavey connection to actual role names.
 */
export type EventFormUserType = "Registrar" | "Coach" | "EventContact"

export async function getEventFormTeamOptions(
  axios: AxiosInstance,
  args: {
    eventID: /*undefined if for new event without ID*/ undefined | iltypes.Guid,
    seasonUID: iltypes.Guid,
    eventFormUserType: EventFormUserType
  }
) : Promise<{[teamID: iltypes.Guid]: TeamChooser.TeamItem}> {
  const response = await axios.get(`v1/event/eventFormTeamOptions`, {params: args});
  return response.data.data;
}

export async function moveEventSignup(axios: AxiosInstance, args: {eventSignupID: iltypes.Guid, moveToThisEventID: iltypes.Guid, comments: string}) : Promise<void> {
  await axios.post(`v1/eventSignup/${args.eventSignupID}/move`, {moveToThisEventID: args.moveToThisEventID, comments: args.comments});
}

export interface EventSigninSheetViewData {
  eventName: string,
  eventStart: DateTimelike,
  eventEnd: DateTimelike,
  eventLoc: string,
  allDay: Numbool,
  venue: null | {
    name: string,
    street: string,
    city: string,
    state: string,
    zip: string,
    comments: string,
  },
  signups: EventSigninSheetViewData_Signup[]
}

export type EventSigninSheetViewData_Signup = EventSigninSheetViewData_ChildSignup | EventSigninSheetViewData_UserSignup

interface EventSigninSheetViewData_SignupBase {
  type: "child" | "user"
  signupDate: DateTimelike,
}

export interface EventSigninSheetViewData_ChildSignup extends EventSigninSheetViewData_SignupBase {
  type: "child"
  child: {
    childID: Guid,
    stackSID: string,
    playerFirstName: string,
    playerLastName: string,
    instanceConfig: {
      region: Integerlike,
    },
    parent1: {
      primaryPhone: string,
      email: string
    }
  },
}
export interface EventSigninSheetViewData_UserSignup extends EventSigninSheetViewData_SignupBase {
  type: "user"
  user: {
    ID: Guid,
    stackSID: string,
    firstName: string,
    lastName: string,
    region: Integerlike,
    primaryPhone: string,
    email: string
  }
}

export async function getEventSigninSheetViewData(axios: AxiosInstance, args: {eventID: Guid}) : Promise<EventSigninSheetViewData> {
  const response = await axios.get(`v1/event/${args.eventID}/signinSheet`)
  return response.data.data;
}
