import { computed, defineComponent, ref } from "vue"
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import dayjs, { Dayjs } from "dayjs"
import { X, SoccerBall } from "src/components/SVGs"
import { ilDraggable, vueDirective_ilDraggable } from "src/modules/ilDraggable"
import { vReqT, exhaustiveCaseGuard, gatherByKey_manyPerKey, sortBy, assertNonNull, requireNonNull, assertTruthy } from "src/helpers/utils"
import { Guid } from "src/interfaces/InleagueApiV1"
import { Client } from "src/store/Client"
import { CalendarElementStyle } from "./CalendarElementStyle"
import { LayoutNodeRoot, LayoutNode } from "./CalendarLayout"
import { GameLayoutTreeStore } from "./GameLayoutTreeStore"
import { authZ_canDragOrResizeNode, authZ_canEditNodeViaOverlay, coachBlurbForTeamName, GameCalendarUiElement, isEffectivelyAllDay, teamDesignationAndMaybeName } from "./GameScheduler.shared"

export {
  CalendarElementMover,
  CalendarElementVerticalResizer,
  // vite HMR not as nice exporting this way (triggers full caller reload)
  //CalendarGridElement,
}

export const CalendarGridElement = defineComponent({
  props: {
    date: vReqT<string>(),
    fieldUID: vReqT<Guid>(),
    // might be good to have separate elements, one for the "root", and one for the rest
    layoutNode: vReqT<LayoutNodeRoot<GameCalendarUiElement> | LayoutNode<GameCalendarUiElement>>(),
    px_containerHeight: vReqT<number>(),
    px_containerWidth: vReqT<number>(),
    px_xOffset: vReqT<number>(),
    startHour24Inc: vReqT<number>(),
    // endHour24 "inclusive", i.e. if it is 8, then we show 8, meaning we show all the way 8:59:59
    // so `(endHour24 - zeroHour24) + 1` is the "full span" of hours we expect to be showing
    endHour24Inc: vReqT<number>(),
    px_heightPerHour: vReqT<number>(),
    px_elemWidth: vReqT<number>(),
    px_cellBorderAndGridlineThickness: vReqT<number>(),
    elemVerticalResizer: vReqT<CalendarElementVerticalResizer>(),
    elemMover: vReqT<CalendarElementMover>(),
    z: vReqT<number>(),
    /**
     * The single global "this node is being dragged around", or null if no drag is in progress.
     */
    moveeNode: vReqT<LayoutNode<GameCalendarUiElement> | null>(),
    gridSlicesPerHour: vReqT<number>(),
    getCalendarElementStyles: vReqT<(_: GameCalendarUiElement) => CalendarElementStyle>(),
    gamesTree: vReqT<GameLayoutTreeStore>(),
    authZ: vReqT<{canCrudGames: boolean, canCrudFieldBlocks: boolean}>(),
    isInBulkSelectMode: vReqT<boolean>(),
  },
  directives: {
    ilDraggable: vueDirective_ilDraggable
  },
  emits: {
    showEditorPane: (_: {layoutNode: LayoutNode<GameCalendarUiElement>, domElement: HTMLElement}) => true,
    showConfirmDeleteModal: (_: LayoutNode<GameCalendarUiElement>) => true,
    toggleBulkSelect: (_: LayoutNode<GameCalendarUiElement>) => true,
  },
  setup(props, {emit}) {
    const endSameDayAsStart = computed(() => {
      if (!props.layoutNode.parent) {
        return dayjs() // dummy, shouldn't ever be used
      }
      return props.layoutNode.data.uiState.time.start.hour(props.endHour24Inc + 1)
    })

    const px_verticalSize = computed(() => {
      if (!props.layoutNode.parent) {
        return 0
      }

      const endClampedToStartDay = props.layoutNode.data.uiState.time.end.unix() > endSameDayAsStart.value.unix()
        ? endSameDayAsStart.value
        : props.layoutNode.data.uiState.time.end

      const startHourClamped = Math.max(props.layoutNode.data.uiState.time.start.hour(), props.startHour24Inc)

      const diffSeconds = endClampedToStartDay.unix() - props.layoutNode.data.uiState.time.start.hour(startHourClamped).unix()
      const diffHours = diffSeconds / 3600
      const hoursSpan = (props.endHour24Inc - props.startHour24Inc) + 1

      // offset of -1 to not cover the gridline itself
      return Math.round(props.px_containerHeight * (diffHours / hoursSpan)) - props.px_cellBorderAndGridlineThickness - 1;
    })

    const y_offset = computed(() => {
      if (!props.layoutNode.parent) {
        return 0
      }

      const startHourClamped = Math.max(props.layoutNode.data.uiState.time.start.hour(), props.startHour24Inc)
      const borderAdjust = startHourClamped - props.startHour24Inc
      const mins = (startHourClamped * 60) + props.layoutNode.data.uiState.time.start.minute()

      return Math.round(borderAdjust + ((mins / 60) - props.startHour24Inc) * props.px_heightPerHour)
    })

    const deltaPx2NewTime = (originalTime: {start: Dayjs, end: Dayjs}, deltaPx: number, adjusting: "start" | "end") : {start: Dayjs, end: Dayjs} => {
      const snapMinutes = 60 / props.gridSlicesPerHour
      const snapSeconds = snapMinutes * 60
      const hourSpan = (props.endHour24Inc - props.startHour24Inc) + 1
      const pxPerHour = props.px_containerHeight / hourSpan
      const pxPerMinute = pxPerHour / 60
      const deltaMinutes = deltaPx / pxPerMinute

      if (adjusting === "start") {
        const newUnix = originalTime.start.add(deltaMinutes, "minutes").unix()
        const snappedUnix = Math.round(newUnix / snapSeconds) * snapSeconds;
        const adjustedStart = dayjs(snappedUnix * 1000)

        const minStartTime = originalTime.start.hour(props.startHour24Inc).minute(0).second(0)
        const maxEndTime = originalTime.end.subtract(snapMinutes, "minutes")
        return {
          start: adjustedStart.isAfter(maxEndTime) ? maxEndTime : adjustedStart.isBefore(minStartTime) ? minStartTime : adjustedStart,
          end: originalTime.end
        }
      }
      else if (adjusting === "end") {
        const newUnix = originalTime.end.add(deltaMinutes, "minutes").unix()
        const snappedUnix = Math.round(newUnix / snapSeconds) * snapSeconds;
        const adjustedEnd = dayjs(snappedUnix * 1000)

        const minStartTime = originalTime.start.add(snapMinutes, "minutes")
        const maxEndTime = originalTime.end.hour(props.endHour24Inc + 1).minute(0)
        return {
          start: originalTime.start,
          end: adjustedEnd.isBefore(minStartTime) ? minStartTime : adjustedEnd.isAfter(maxEndTime) ? maxEndTime : adjustedEnd
        }
      }
      else {
        exhaustiveCaseGuard(adjusting)
      }
    }

    const effectiveZIndex = computed(() => {
      return !props.layoutNode.parent ? 0
        : props.layoutNode.data.uiState.isBeingVerticallyResized ? 999
        : props.layoutNode.data.uiState.dragState === "drag-handle" ? 999
        : props.layoutNode.data.uiState.isModalOrOverlayFocus ? 999
        : props.layoutNode.data.uiState.dragState === "stationary-drag-source" ? 99
        : props.z;
    })

    /**
     * Child nodes, grouped by start-time, where the groups themselves are sorted by startTime asc.
     * Items having the same start time should share the same y offsets. We assume the provided
     * list is already sorted in ascending and so the resulting per-group (i.e. 2nd level) lists
     * will also remain sorted.
     * Investigate: shouldn't we (can we?), uh, receive a data structure from `props` already like this?
     */
    const childNodesGroupedByStartTime = computed<LayoutNode<GameCalendarUiElement>[][]>(() => {
      const nodesPerStartTime = gatherByKey_manyPerKey(
        props.layoutNode.children,
        node => {
          return (node.data.uiState.time.start.hour() * 60) + node.data.uiState.time.start.minute()
        }
      );

      return [...nodesPerStartTime.entries()]
        .sort(sortBy(_ => /*startTime*/ _[0]),)
        .map(_ => /*nodes per startTime*/_[1])
    })

    const canDragOrResize = computed(() => {
      return !props.layoutNode.parent
        ? false
        : authZ_canDragOrResizeNode(props.layoutNode, props.gamesTree.authZByCompDiv) && !props.layoutNode.data.uiState.isBulkSelected
    })

    const elementStyle = computed(() => props.layoutNode.parent ? props.getCalendarElementStyles(props.layoutNode.data) : null)

    /**
     * Size of each of the top and bottom regions contained inside a layed-out element,
     * where the mouse will act as a vertical resizer.
     */
    const px_verticalResizeGutter = 8;

    const elemRef = ref<HTMLElement | null>(null)

    return () => {
      return (
        <>
          {
            // moveeNode gets special treatment, because it is "exotree" (not contained as a member of props.layoutNode)
            props.moveeNode
              ? <CalendarGridElement
                layoutNode={props.moveeNode}
                px_containerHeight={props.px_containerHeight}
                px_xOffset={0}
                startHour24Inc={props.startHour24Inc}
                endHour24Inc={props.endHour24Inc}
                px_heightPerHour={props.px_heightPerHour}
                px_containerWidth={props.px_containerWidth}
                px_elemWidth={props.px_containerWidth - props.px_cellBorderAndGridlineThickness}
                px_cellBorderAndGridlineThickness={props.px_cellBorderAndGridlineThickness}
                elemVerticalResizer={props.elemVerticalResizer}
                elemMover={props.elemMover}
                z={props.z+1}
                moveeNode={null}
                date={props.date}
                fieldUID={props.fieldUID}
                gridSlicesPerHour={props.gridSlicesPerHour}
                getCalendarElementStyles={props.getCalendarElementStyles}
                gamesTree={props.gamesTree}
                authZ={props.authZ}
                isInBulkSelectMode={props.isInBulkSelectMode}
              />
              : null
          }
          {
            //
            // This is "the element"
            // @grep the element theElement the main thing theMainThing
            //
            props.layoutNode.parent // i.e. "not the root element"
              ? (
                <div
                  ref={elemRef}
                  data-test={props.layoutNode.data.type === "game"
                      ? `calendarElement/gameID=${props.layoutNode.data.data.gameID}`
                      : `calendarElement/fieldBlockID=${props.layoutNode.data.data.id}`}
                  style={{
                    //padding: `0 .25em`,
                    position: `absolute`,
                    top: `${y_offset.value}px`,
                    height: `${px_verticalSize.value}px`,
                    width: props.layoutNode.data.uiState.isBeingVerticallyResized ? "100%" : `${props.px_elemWidth}px`,
                    left: props.layoutNode.data.uiState.isBeingVerticallyResized ? 0 : `${props.px_xOffset}px`,
                    overflow: "hidden",
                    // It would probably be better to just straight up build
                    // the DOM in the correct zOrder, but explicitly setting z is good enough for now
                    zIndex: effectiveZIndex.value,
                    cursor: props.layoutNode.data.uiState.isBeingVerticallyResized ? "row-resize" : undefined,
                    ...elementStyle.value?.body,
                    ...(props.layoutNode.data.uiState.isBulkSelected ? {backgroundColor: "rgb(106, 163, 255)", color: "black"} : undefined),
                    // don't soak up pointer events if an element is being moved; we want drag events to go to the grid
                    // (uh ... shouldn't they do so naturally? we're not stopPropagating things right?)
                    pointerEvents: `${props.elemMover.isMoving ? "none" : "auto"}`
                  }}
                  class={[
                    "text-sm",
                    "border border-white rounded-md",
                    "overflow",
                    props.layoutNode.data.uiState.isModalOrOverlayFocus
                      || props.layoutNode.data.uiState.isBulkSelected ? "outline outline-black outline-dashed outline-3" : "",
                  ]}
                >
                  {/*new stacking context _within_ absolute pos parent*/}
                  <div class="flex flex-col h-full relative" style="z-index:0;">
                    <div class="p-1 flex" style={elementStyle.value?.title}>
                      <div>
                        {(() => {
                          const t = `${props.layoutNode.data.uiState.time.start.format("h:mm a")} - ${props.layoutNode.data.uiState.time.end.format("h:mm a")}`
                          if (props.layoutNode.data.type === "fieldBlock") {
                            return `Blocked Time - ${t}`
                          }
                          else {
                            return `${props.layoutNode.data.data.division} - ${t}`
                          }
                        })()}
                      </div>
                      {(props.layoutNode.data.type === "game" && props.authZ.canCrudGames) || (props.layoutNode.data.type === "fieldBlock" && props.authZ.canCrudFieldBlocks)
                        ? (
                          <button style="z-index:1;" type="button" class="ml-auto" onClick={() => {
                            assertNonNull(props.layoutNode.parent, "outer flow type remains valid here")
                            emit("showConfirmDeleteModal", props.layoutNode)
                          }}>
                            <div class="hover:bg-[rgba(0,0,0,.125)] active:bg-[rgba(0,0,0,.25)] rounded-md flex items-center"
                              style="width:1.25em; height:1.25em; padding:.125em; box-sizing:content-box;"
                            >
                              <X penColor={elementStyle.value?.title.color} width="1.25em" height="1.125em"/>
                            </div>
                          </button>
                        )
                        : null
                      }
                    </div>
                    <div class="p-1">
                      {props.layoutNode.data.uiState.isSaving
                        ? <div class="flex items-center gap-2" style="z-index:1;">
                          <SoccerBall key={`isSaving/${props.layoutNode.data.__vueKey}`} color={Client.value.clientTheme.color} width="1.5em" height="1.5em"/>
                          <span>Saving</span>
                        </div>
                        : null
                      }
                      {props.layoutNode.data.uiState.isOpeningEditPane
                        ? <div class="flex items-center gap-2" style="z-index:1;">
                          <SoccerBall key={`isSaving/${props.layoutNode.data.__vueKey}`} color={Client.value.clientTheme.color} width="1.5em" height="1.5em"/>
                          <span>Loading details...</span>
                        </div>
                        : null
                      }
                      {props.layoutNode.data.type === "game"
                        ? <>
                          {(() => {
                            const data = props.layoutNode.data.data;
                            return <>
                              <div class="text-xs">Game {props.layoutNode.data.data.gameNum}</div>
                              <div>
                                {data.homeTeamID === "TBD"
                                  ? "TBD"
                                  : <span>
                                      <span>{teamDesignationAndMaybeName({teamDesignation: data.homeTeamDesignation, teamName: data.homeTeamName})}</span>
                                      <span class="text-xs"> ({coachBlurbForTeamName(data.coaches.filter(v => v.teamID === data.homeTeamID)) || "No current coaches"})</span>
                                  </span>
                                }
                              </div>
                              <div>
                                vs. {data.visitorTeamID === "TBD"
                                  ? "TBD"
                                  : <span>
                                      <span>{teamDesignationAndMaybeName({teamDesignation: data.visitorTeamDesignation, teamName: data.visitorTeamName})}</span>
                                      <span class="text-xs"> ({coachBlurbForTeamName(data.coaches.filter(v => v.teamID === data.visitorTeamID)) || "No current coaches"})</span>
                                  </span>
                                }
                              </div>
                            </>
                          })()}
                        </>
                        : null
                      }
                      {props.layoutNode.data.uiState.noBulkSelect
                        ? <>
                          <div class="text-xs">{props.layoutNode.data.uiState.noBulkSelect.msg}</div>
                          <div class="absolute top-0 left-0 w-full h-full bg-white opacity-50" style="z-index:1"></div>
                        </>
                        : null
                      }
                      <div class="mt-auto">
                        {props.layoutNode.data.type === "game" && props.layoutNode.data.data.blockFromMatchmaker
                          ? <span class="text-lg"><FontAwesomeIcon icon={faEyeSlash}/></span>
                          : null
                        }
                      </div>
                    </div>
                    {/*adjust gameStart by dragging top*/}
                    <div
                      onClick={evt => {
                        // don't bubble into a click on the parent
                        evt.stopImmediatePropagation()
                      }}
                      onMousedown={evt => {
                        if (!canDragOrResize.value) {
                          return;
                        }

                        assertNonNull(props.layoutNode.parent, "always remains true from outer flow type")

                        evt.preventDefault() // stop dragging mouse from selecting text
                        evt.stopImmediatePropagation()
                        props.elemVerticalResizer.startResizingGameVertically({
                          startPageY: evt.pageY,
                          node: props.layoutNode,
                          viewport: {
                            startHour24Inc: props.startHour24Inc,
                            endHour24Inc: props.endHour24Inc,
                          },
                          which: "start",
                          date: props.date,
                          field: props.fieldUID,
                          deltaPx2NewTime: deltaPx2NewTime,
                        })
                      }}
                      style={`position:absolute; top:0; left: 0; width: 100%; height:${px_verticalResizeGutter}px; ${canDragOrResize.value ? "cursor:row-resize;" : ""}`}>
                    </div>

                    {/*draggable/clickable area*/}
                    <div
                      data-test="primaryInteractor"
                      v-ilDraggable={!canDragOrResize.value ? null : {
                        dragHandleJsxFunc: () => null,
                        onDragStart: (_, evt) => {
                          assertNonNull(props.layoutNode.parent, "outer flow type remains valid here")
                          if (props.elemMover.isMoving) {
                            return false;
                          }

                          evt.stopPropagation()

                          const {offsetX, offsetY} = evt

                          setTimeout(() => {
                            // run this next tick, otherwise sync dom mutations end up immediately firing "dragend"
                            if (!props.layoutNode.parent) {
                              // super unlikely here, but could happen if the timeout took longer than expected
                              return
                            }
                            props.elemMover.startMovingGame(props.date, props.fieldUID, props.layoutNode, offsetX, offsetY)
                          }, 0)

                          return true
                        },
                        onLeaveOrEnd: () => {
                          props.elemMover.tryReset()
                        }
                      } satisfies ilDraggable}
                      onClick={evt => {
                        assertNonNull(props.layoutNode.parent, "outer flow type remains valid here")
                        if (!authZ_canEditNodeViaOverlay(props.layoutNode, props.gamesTree.authZByCompDiv)) {
                          return;
                        }

                        if (props.isInBulkSelectMode) {
                          emit("toggleBulkSelect", props.layoutNode)
                          return;
                        }

                        if (props.layoutNode.data.uiState.isSaving) {
                          // already busy doing something else
                          return;
                        }
                        emit("showEditorPane", {layoutNode: props.layoutNode, domElement: requireNonNull(elemRef.value)})
                      }}
                      class={[
                        // TODO: we have problems setting the cursor globally when it would otherwise make sense to do so
                        // (e.g. when a node is being vertically resized, set the cursor globally to row-resize).
                        // Instead these values always override anything set from parent elements (as css tends to do).
                        // So anyway, we need to do a little dance here to try to get the right one to show up.
                        props.layoutNode.data.uiState.dragState === "drag-handle" ? "cursor-move"
                        : props.layoutNode.data.uiState.isBeingVerticallyResized ? undefined
                        : authZ_canEditNodeViaOverlay(props.layoutNode, props.gamesTree.authZByCompDiv) ? "cursor-pointer"
                        : undefined
                      ]}
                      style={`position:absolute; top: ${px_verticalResizeGutter}px; left: 0; bottom: ${px_verticalResizeGutter}px; width: 100%;`}>
                    </div>

                    {/*adjust gameEnd by dragging bottom*/}
                    <div
                      onClick={evt => {
                        // don't bubble into a click on the parent
                        evt.stopImmediatePropagation()
                      }}
                      onMousedown={evt => {
                        if (!canDragOrResize.value) {
                          return;
                        }
                        assertNonNull(props.layoutNode.parent, "outer flow type remains valid here")

                        evt.preventDefault() // stop dragging mouse from selecting text
                        evt.stopImmediatePropagation()

                        props.elemVerticalResizer.startResizingGameVertically({
                          startPageY: evt.pageY,
                          node: props.layoutNode,
                          viewport: {
                            startHour24Inc: props.startHour24Inc,
                            endHour24Inc: props.endHour24Inc,
                          },
                          which: "end",
                          date: props.date,
                          field: props.fieldUID,
                          deltaPx2NewTime: deltaPx2NewTime,
                        })
                      }}
                      style={`position:absolute; bottom:0; left: 0; width: 100%; height:${px_verticalResizeGutter}px; ${canDragOrResize.value ? "cursor:row-resize;" : ""}`}></div>

                    {/*stationary drag source indicator*/}
                    {
                      props.layoutNode.data.uiState.dragState === "stationary-drag-source"
                        // google calendar sets the opacity of the drag source element to ~50% or so; but their layout is more conducive to this (elements are stacked but the things underneath don't take up 100%)
                        ? <div style="position:absolute; top: 0; left: 0; width: 100%; height:100%; background-color:rgba(0,0,0,.35);"></div>
                        : null
                    }

                    {/* isSaving=true overlay */}
                    {props.layoutNode.data.uiState.isSaving
                      ? <div class="absolute top-0 left-0 h-full w-full bg-white opacity-30"></div>
                      : null
                    }
                  </div>
                </div>
              )
              : null
          }
          {
            childNodesGroupedByStartTime
              .value
              .flatMap(nodes => {
                const isRoot = !props.layoutNode.parent
                const offsetX = props.px_xOffset;
                const width = props.px_elemWidth;
                const freshOffset = isRoot ? 0 : 48
                const remaining = width - offsetX - freshOffset;
                const allocate_x_perElement = remaining / nodes.length;
                return nodes.map((node, i) => {
                  return (
                    <CalendarGridElement
                      key={node.data.__vueKey}
                      layoutNode={node}
                      px_containerHeight={props.px_containerHeight}
                      px_xOffset={props.px_xOffset + freshOffset + (allocate_x_perElement * i)}
                      px_cellBorderAndGridlineThickness={props.px_cellBorderAndGridlineThickness}
                      startHour24Inc={props.startHour24Inc}
                      endHour24Inc={props.endHour24Inc}
                      px_heightPerHour={props.px_heightPerHour}
                      px_containerWidth={props.px_containerWidth}
                      px_elemWidth={props.px_elemWidth - freshOffset - (allocate_x_perElement * i)}
                      gamesTree={props.gamesTree}
                      elemVerticalResizer={props.elemVerticalResizer}
                      elemMover={props.elemMover}
                      z={props.z+1}
                      date={props.date}
                      fieldUID={props.fieldUID}
                      moveeNode={null}
                      gridSlicesPerHour={props.gridSlicesPerHour}
                      getCalendarElementStyles={props.getCalendarElementStyles}
                      onShowEditorPane={args => emit("showEditorPane", args)}
                      onShowConfirmDeleteModal={args => emit("showConfirmDeleteModal", args)}
                      authZ={props.authZ}
                      isInBulkSelectMode={props.isInBulkSelectMode}
                      onToggleBulkSelect={node => emit("toggleBulkSelect", node)}
                  />)
                });
              })
          }
        </>
      )
    }
  }
})


function CalendarElementVerticalResizer() {
  let onResizeCommitted : undefined | ((_: {layoutNode: LayoutNode<GameCalendarUiElement>, date: string, field: string, preMutationGameDate: {start: Dayjs, end: Dayjs}}) => boolean | Promise<boolean>)
  interface State {
    savedGlobalCursorStyle : string,
    node: LayoutNode<GameCalendarUiElement>,
    preMutationGameDate: {start: Dayjs, end: Dayjs}
    viewport: {
      startHour24Inc: number,
      endHour24Inc: number,
    }
    startPageY: number
    convertDeltaPxToNewTime: (originalTime: {start: Dayjs, end: Dayjs}, px: number, which: "start" | "end") => {start: Dayjs, end: Dayjs};
    /**
     * Are we adjusting the start time, or the end time
     */
    which: "start" | "end"
    date: string,
    field: string,
    isAsyncCommitting: boolean,
  }

  const state = ref<null | State>(null)

  const clearResizeHandlers = () => {
    window.removeEventListener("mouseup", onMouseUpButtonRelease, {capture: true})
    window.removeEventListener("mousemove", onMouseMove, {capture: true})
    window.removeEventListener("keyup", onEsc, {capture: true})
  }

  const installResizeHandlers = () => {
    window.addEventListener("mouseup", onMouseUpButtonRelease, {capture: true})
    window.addEventListener("mousemove", onMouseMove, {capture: true})
    window.addEventListener("keyup", onEsc, {capture: true})
  }

  const onEsc = (evt: KeyboardEvent) => {
    if (evt.key === "Escape") {
      evt.preventDefault()
      evt.stopPropagation()
      clearResizeHandlers()
      cancelResize()
    }
  }

  const onMouseUpButtonRelease = (evt: MouseEvent) => {
    assertNonNull(state.value)

    evt.preventDefault()
    evt.stopImmediatePropagation()

    clearResizeHandlers()
    void tryCommitResize()
  }

  const cancelResize = () => {
    assertNonNull(state.value)

    clearResizeHandlers()

    state.value.node.data.uiState.isBeingVerticallyResized = false

    state.value.node.data.uiState.time = {
      start: state.value.preMutationGameDate.start,
      end: state.value.preMutationGameDate.end,
      isEffectivelyAllDay: isEffectivelyAllDay(state.value.preMutationGameDate.start.unix(), state.value.preMutationGameDate.end.unix())
    }

    document.body.style.cursor = state.value.savedGlobalCursorStyle

    state.value = null
  }

  const tryCommitResize = async () => {
    assertNonNull(state.value)
    state.value.isAsyncCommitting = true

    try {
      if (onResizeCommitted) {
        let ok : boolean
        try {
          ok = await onResizeCommitted({layoutNode: state.value.node, date: state.value.date, field: state.value.field, preMutationGameDate: {...state.value.preMutationGameDate}})
        }
        catch {
          ok = false
        }
        if (ok) {
          commit()
        }
        else {
          cancelResize()
        }
      }
      else {
        commit()
      }
    }
    finally {
      assertTruthy(!state.value, "state cleared out on all paths");
    }

    function commit() {
      assertNonNull(state.value)
      clearResizeHandlers()
      document.body.style.cursor = state.value.savedGlobalCursorStyle
      state.value.node.data.uiState.isBeingVerticallyResized = false
      state.value = null
    }
  }

  const onMouseMove = (evt: MouseEvent) => {
    assertNonNull(state.value)

    evt.preventDefault()
    evt.stopPropagation()

    const minStart = state.value.preMutationGameDate.start.hour(state.value.viewport.startHour24Inc)
    // yes, use "start" to get "same date" even in cases of bleed into next day like onto "day+1 @ 12am"
    const maxEnd = state.value.preMutationGameDate.start.hour(state.value.viewport.endHour24Inc + 1)

    const snappedPreMutationGameDate = {
      start: state.value.preMutationGameDate.start.isBefore(minStart) ? minStart : state.value.preMutationGameDate.start,
      end: state.value.preMutationGameDate.end.isAfter(maxEnd) ? maxEnd : state.value.preMutationGameDate.end,
    }
    const adjusted = state.value.convertDeltaPxToNewTime(snappedPreMutationGameDate, evt.pageY - state.value.startPageY, state.value.which)

    if (state.value.which === "start") {
      state.value.node.data.uiState.time = {
        start: adjusted.start,
        end: state.value.node.data.uiState.time.end,
        isEffectivelyAllDay: isEffectivelyAllDay(adjusted.start.unix(), state.value.node.data.uiState.time.end.unix())
      }
    }
    else {
      state.value.node.data.uiState.time = {
        start: state.value.node.data.uiState.time.start,
        end: adjusted.end,
        isEffectivelyAllDay: isEffectivelyAllDay(state.value.node.data.uiState.time.start.unix(), adjusted.end.unix())
      }
    }
  }

  return {
    /**
     * Callback for _after_ resize is committed.
     * Provides an opportunity for us to resort whichever list owns the resized game.
     * If the callback returns false, the resize is canceled (the resized object returns to its prior size)
     */
    onResizeCommitted: (f: (_: {layoutNode: LayoutNode<GameCalendarUiElement>, date: string, field: string, preMutationGameDate: {start: Dayjs, end: Dayjs}}) => boolean | Promise<boolean>) => {
      onResizeCommitted = f
    },
    startResizingGameVertically(args: {
      startPageY: number,
      node: LayoutNode<GameCalendarUiElement>,
      viewport: {
        startHour24Inc: number,
        endHour24Inc: number,
      }
      deltaPx2NewTime: (originalTime: {start: Dayjs, end: Dayjs}, px: number, which: "start" | "end") => {start: Dayjs, end: Dayjs},
      which: "start" | "end",
      date: string,
      field: string
    }) {
      if (state.value) {
        // probably waiting on some other resize to complete asynchronously
        return;
      }

      state.value = {
        savedGlobalCursorStyle: document.body.style.cursor,
        node: args.node,
        convertDeltaPxToNewTime: args.deltaPx2NewTime,
        startPageY: args.startPageY,
        preMutationGameDate: {
          start: args.node.data.uiState.time.start,
          end: args.node.data.uiState.time.end
        },
        viewport: args.viewport,
        which: args.which,
        date: args.date,
        field: args.field,
        isAsyncCommitting: false,
      }

      args.node.data.uiState.isBeingVerticallyResized = true
      document.body.style.cursor = "row-resize"

      installResizeHandlers()
    }
  }
}
type CalendarElementVerticalResizer = ReturnType<typeof CalendarElementVerticalResizer>

function CalendarElementMover() {
  interface State {
    /**
     * The freshNode is the one that gets dragged.
     * It starts life as a copy of the sourceNode
     */
    readonly freshNode: LayoutNode<GameCalendarUiElement>,
    /**
     * The sourceNode remains in place during a drag operation,
     * to show "this is where the drag originated".
     */
    readonly sourceNode: LayoutNode<GameCalendarUiElement>,
    readonly grabOffsetX: number,
    readonly grabOffsetY: number,
    /**
     * This updates as drags move left/right across dates/fields
     */
    currentDate: string,
    /**
     * This updates as drags move left/right across dates/fields
     */
    currentFieldUID: string,
    isAsyncCommitting: boolean,
    /**
     * The date of the drag started from (stored denormalized, should match what is in `sourceNode`)
     */
    readonly initialDate: string,
    /**
     * The field the drag started from (stored denormalized, should match what is in `sourceNode`)
     */
    readonly initialFieldUID: string,
  }

  const state = ref<null | State>(null)

  const clearMoveHandlers = () => {
    window.removeEventListener("keyup", onEsc, {capture: true})
  }

  const installMoveHandlers = () => {
    window.addEventListener("keyup", onEsc, {capture: true})
  }

  const onEsc = (evt: KeyboardEvent) => {
    if (evt.key === "Escape") {
      evt.preventDefault()
      evt.stopPropagation()
      clearMoveHandlers()
      tryReset()
    }
  }

  const reset = () => {
    assertNonNull(state.value)
    clearMoveHandlers()
    state.value.freshNode.data.uiState.dragState = null
    state.value.sourceNode.data.uiState.dragState = null
    state.value = null
  }

  const tryReset = () => {
    if (!state.value || state.value.isAsyncCommitting) {
      return
    }
    reset()
  }

  return {
    get isMoving() {
      return state.value !== null;
    },
    startMovingGame: (date: string, fieldUID: Guid, sourceNode: LayoutNode<GameCalendarUiElement>, grabOffsetX: number, grabOffsetY: number) => {
      if (state.value) {
        // Don't allow to start drags if another drag is not complete
        // This is intended to help some async logic where a drop might not complete unless some HTTP requests complete.
        return;
      }

      const freshNode : LayoutNode<GameCalendarUiElement> = {
        ...sourceNode,
        children: [],
        data: {
          ...sourceNode.data,
          uiState: {
            ...sourceNode.data.uiState,
            dragState: "drag-handle"
          }
        }
      };

      state.value = {
        freshNode: freshNode,
        sourceNode: sourceNode,
        grabOffsetX: grabOffsetX,
        grabOffsetY: grabOffsetY,
        currentDate: date,
        currentFieldUID: fieldUID,
        initialDate: date,
        initialFieldUID: fieldUID,
        isAsyncCommitting: false,
      }

      sourceNode.data.uiState.dragState = "stationary-drag-source"

      // TODO: this doesn't actually work.
      // We'd like to say "set the cursor to the 'move' cursor, and disregard any other element's cursor style"
      document.body.style.cursor = "move !important";

      installMoveHandlers()
    },
    updateDateFieldOwner(args: {date: string, field: string}) : void {
      assertNonNull(state.value)
      state.value.currentDate = args.date
      state.value.currentFieldUID = args.field
    },
    maybeGetSourceNode: () : LayoutNode<GameCalendarUiElement> | null => {
      return state.value?.sourceNode || null
    },
    maybeGetMovee: (args: {date: string, fieldUID: string}) : LayoutNode<GameCalendarUiElement> | null => {
      if (state.value?.currentDate === args.date && state.value.currentFieldUID === args.fieldUID) {
        return state.value.freshNode
      }
      return null;
    },
    get grabOffsetY() {
      return requireNonNull(state.value).grabOffsetY
    },
    get currentDate() {
      return requireNonNull(state.value).currentDate
    },
    get currentFieldUID() {
      return requireNonNull(state.value).currentFieldUID
    },
    get initialDate() {
      return requireNonNull(state.value).initialDate
    },
    get initialFieldUID() {
      return requireNonNull(state.value).initialFieldUID
    },
    tryReset,
    withIsAsyncCompleting: async <T,>(f: () => Promise<T>) : Promise<T> => {
      assertNonNull(state.value)
      try {
        state.value.isAsyncCommitting = true
        return await f()
      }
      finally {
        state.value.isAsyncCommitting = false
      }
    }
  }
}
type CalendarElementMover = ReturnType<typeof CalendarElementMover>
