import { exhaustiveCaseGuard, parseIntOr, sortBy, sortByMany, useSimplePrintIsolator, vReqT } from "src/helpers/utils";
import { defineComponent, ref } from "vue";
import { Client } from "src/store/Client"
import { PictureDayRoster, PictureDayRosterResponse } from "src/composables/InleagueApiV1.Teams";
import { PlayerRosterSort } from "./R_TeamSeason.route";
import dayjs from "dayjs";
import { faPrint } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export const PictureDayRosterElement = defineComponent({
  props: {
    data: vReqT<PictureDayRosterResponse>(),
    sort: vReqT<PlayerRosterSort>(),
  },
  setup(props) {
    const printRoot = ref<HTMLElement | null>(null);
    useSimplePrintIsolator(printRoot, "portrait");

    return () => {
      return (
        <div>
          <div class="flex justify-center mb-2">
            <t-btn margin={false} onClick={() => window.print()}>
              <div class="flex gap-2 items-center">
                <FontAwesomeIcon icon={faPrint}/>
                Print
              </div>
            </t-btn>
          </div>
          <div ref={printRoot}>
            <div class="mb-4 text-center">
              <h3 class="m-auto">AYSO Region {Client.value.instanceConfig.region}</h3>
              <h3 class="m-auto">Picture Day Roster - {dayjs().format("YYYY")}</h3>
              <h4 class="m-auto">{props.data.season.seasonName}</h4>
            </div>
            {
              props
                .data
                .rosters
                .slice() // really should be a readonly array ... or parent sorts ... but not a big deal here
                .sort(rosterSort)
                .map(v => {
                  return (
                    <table
                      key={v.team.teamID}
                      style="max-width: 1024px; border: 1px solid black; border-collapse: collapse;"
                      class="m-auto mb-4 bg-white"
                    >
                      <tr>
                        <td class="p-1 border">Division</td>
                        <td class="p-1 border bold">{v.team.division}</td>
                        <td class="p-1 border">Team ID</td>
                        <td class="p-1 border bold">{v.team.team}</td>
                      </tr>
                      <tr>
                        <td class="p-1 border">Team Name</td>
                        <td class="p-1 border bold" colspan="3">{v.team.teamName}</td>
                      </tr>
                      {
                        headCoaches(v.coaches).map(coach => {
                          return <tr key={coach.assignmentID}>
                            <td class="border p-1">{coach.title}</td>
                            {
                              coach.email || coach.primaryPhone
                                ? (
                                  <>
                                    <td class="p-1 border bold">{coach.firstName} {coach.lastName}</td>
                                    <td class="p-1 border" colspan="2">
                                      <div>{coach.email}</div>
                                      <div class="px-1 border-b"/>
                                      <div>{coach.primaryPhone}</div>
                                    </td>
                                  </>
                                )
                                : <td class="p-1 bold" colspan="3">{coach.firstName} {coach.lastName}</td>
                            }
                          </tr>
                        })
                      }
                      {
                        asstCoaches(v.coaches).map(coach => {
                          return <tr key={coach.assignmentID}>
                            <td class="p-1 border">{coach.title}</td>
                            <td class="p-1 border bold" colspan="3">{coach.firstName} {coach.lastName}</td>
                          </tr>
                        })
                      }
                      {
                        adminCoaches(v.coaches).map(coach => {
                          return <tr key={coach.assignmentID}>
                            <td class="border p-1">{coach.title}</td>
                            {
                              coach.email || coach.primaryPhone
                                ? (
                                  <>
                                    <td class="p-1 border bold">{coach.firstName} {coach.lastName}</td>
                                    <td class="p-1 border " colspan="2">
                                      <div>{coach.email}</div>
                                      <div class="px-1 border-b"/>
                                      <div>{coach.primaryPhone}</div>
                                    </td>
                                  </>
                                )
                                : <td class="p-1 bold" colspan="3">{coach.firstName} {coach.lastName}</td>
                            }
                          </tr>
                        })
                      }
                      <tr>
                        <th class="p-1 border" align="left">Uniform #</th>
                        <th class="p-1 border" align="left" colspan="3">Player Name</th>
                      </tr>
                      {
                        v
                          .playerAssignments
                          .slice()
                          .sort(playerAssignmentSort(props.sort))
                          .map(player => {
                            return <tr key={player.assignmentID}>
                              <td class="p-1 border">{player.uniform}</td>
                              <td class="p-1 border bold" colspan="3">{player.firstName} {player.lastName}</td>
                            </tr>
                          })
                      }
                    </table>
                  )
                })
            }
          </div>
        </div>
      )
    }
  }
})

function headCoaches(coaches: PictureDayRoster["coaches"]) {
  return coaches
    .filter(coach => coach.title === "Head Coach" || coach.title === "Co-Coach")
    .sort(coachSort)
}

function asstCoaches(coaches: PictureDayRoster["coaches"]) {
  return coaches
    .filter(coach => coach.title === "Assistant")
    .sort(coachSort)
}

function adminCoaches(coaches: PictureDayRoster["coaches"]) {
  return coaches
    .filter(coach => coach.title === "Administrator")
    .sort(coachSort)
}

const nameSort = sortByMany<{firstName: string, lastName: string}>(
  sortBy(_ => _.lastName),
  sortBy(_ => _.firstName)
);

type Coach = PictureDayRoster["coaches"][number];
const coachSort = sortByMany<Coach>(
  sortBy(_ => _.title === "Head Coach" ? 0 : _.title === "Co-Coach" ? 1 : 2),
  nameSort,
)

const playerAssignmentSort = (rosterSort: PlayerRosterSort) => {
  if (rosterSort === "name") {
    return nameSort
  }
  else if (rosterSort === "uniform") {
    type PlayerAssignment = PictureDayRoster["playerAssignments"][number]
    return sortByMany<PlayerAssignment>(
      sortBy(_ => parseIntOr(_.uniform, -1)),
      nameSort
    );
  }
  else {
    exhaustiveCaseGuard(rosterSort);
  }
}

const rosterSort = sortByMany<PictureDayRoster>(
  sortBy(_ => _.team.divNum),
  sortBy(_ => _.team.teamLetter),
  sortBy(_ => _.team.divGender)
)
