<template lang="pug">
Teleport(to="body")
  .modal-wrap.z-50.fixed.inset-x-0.pb-6(
    class='sm-w:overflow-y-scroll sm-w:top-0 sm-w:bottom-0 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center'
    data-test="LoginModal"
  )
    .fixed.inset-0.transition-opacity
      .absolute.inset-0.bg-gray-600.opacity-75(class='sm-w:opacity-100 sm-w:bg-white')
    .bg-white.pb-4.container.transform.transition-all.w-full.max-w-screen-md.bottom-0.left-0.flex.flex-col(
      data-cy='loginModalPage',
      class='md:shadow-xl sm-w:overflow-y-scroll sm-w:top-0 sm-w:bottom-0 md:rounded-lg sm:pb-0 md:pb-4 md:overflow-hidden md:max-w-sm md:bottom-auto md:left-auto md:h-auto sm:flex-row md:flex-col',
      role='dialog',
      aria-modal='true',
      aria-labelledby='modal-headline'
    )
      .fix-notch-top.bg-green-900.flex.flex-col.items-center.relative(
        class='sm:w-2/3 md:w-full sm:justify-between sm:h-full'
      )
        div(
          class="transition-colors p-1 absolute top-0 right-0 cursor-pointer hover:bg-[rgba(0,0,0,.25)] active:bg-[rgba(0,0,0,.5)] p-1 m-1 flex justify-center items-center"
          style="border-radius:50%; width:1.5em; height:1.5em;"
          @click="dismissModal"
        )
          X(color="white" penColor="white")
        img.w-full(:src='clientTheme.banner', alt='inLeague icon')
        .block
          .w-auto.inline-block
            h4.ml-9.align-bottom.leading-9.text-white.w-full.font-medium.text-sm.mt-5.h-full(
              class='sm:text-xs md:font-medium'
            )
              | {{ instanceConfig.regionname }}
            img.mb-5.-mt-6.h-full(
              class='sm:mb-1 md:mb-5',
              :src='publicPath + "/img/inleague_logo_white.svg"',
              alt='inLeague icon',
              width=100
            )
      //-
      //- mfa init state is conceptually a pair of (login state, route location)
      //-
      template(v-if="isInMfaInitFlow")
        div(class="p-3")
          div
            div Two Factor Authentication: Setup
            div(class="text-xs") Your league requires 2FA for administrative users.
            div(class="border-b border-slate-300 mb-2")
          template(v-if="!mfaInitFlowIsComplete")
            MfaInitOptions(
              v-if="route.name === 'mfa-init.options'"
              v-on="mfaInitOptionsHandlers"
              :state="User.loginState"
            )
            MfaInitSMS(
              v-if="route.name === 'mfa-init.sms'"
              v-on="mfaInitSuccessHandlers"
              :state="User.loginState"
            )
            MfaInitTOTP(
              v-if="route.name === 'mfa-init.totp'"
              v-on="mfaInitSuccessHandlers"
              :state="User.loginState"
            )
          template(v-else)
            //- if init flow is complete, we should be on the init flow complete route.
            //- But assigning to these separate values is not atomic, so we have a pair (login state, router state)
            //- that can very briefly become incoherent
            div(
              v-if="route.name === 'mfa-init.complete'"
              class="flex items-center justify-center"
            )
              | You're all set!
      template(v-else-if="isInMfaChallengeFlow")
        div(class="p-3")
          MfaChallengeOptions(
            v-if="route.name === 'mfa-challenge.options'"
            v-on="mfaChallengeOptionsHandlers"
            :state="User.loginState"
          )
          MfaChallengeSMS(
            v-if="route.name === 'mfa-challenge.sms'"
            v-on="mfaChallengeSuccessHandlers"
            :state="User.loginState"
          )
          MfaChallengeTOTP(
            v-if="route.name === 'mfa-challenge.totp'"
            v-on="mfaChallengeSuccessHandlers"
            :state="User.loginState"
          )
      template(v-else-if="!User.isLoggedIn")
        #errors
        FormKit(class='sm:mx-auto sm:w-full sm:max-w-md', :actions='false', type='form' @submit='login')
          .bg-green-00.py-3.px-3.text-gray-600(class='sm:px-10')
            FormKit(
              label='Email Address',
              v-model='username',
              name='username',
              type='email',
              validations='required|email',
              input-class='text-black form-input block w-full',
              data-test='username',
              autocomplete="username"
            )
            FormKit(
              label='Password',
              type='password',
              v-model='password',
              name='password',
              validations='required|min:5,length',
              input-class='text-black form-input block w-full',
              data-test='password',
              ref='pwd'
              autocomplete="current-password"
            )
            .flex.justify-between
              .text-sm.leading-5.flex.flex-row.mt-3
                router-link.flex.flex-grow.font-medium.text-green-600.transition.ease-in-out.duration-150(
                  class='hover:text-green-500 focus:outline-none focus:underline',
                  :to='{ name: "forgot-password" }'
                ) Forgot Password?
              .text-sm.leading-5.flex.space-between.mt-3
                //- {{ @ disabled as per iOS app review  @ }}
                //- router-link.font-medium.text-green-600.transition.ease-in-out.duration-150(
                //-   class='hover:text-green-500 focus:outline-none focus:underline',
                //-   :to='{ name: "mobile-select-league" }',
                //-   v-if='isMobile'
                //- ) Create Account
                router-link.font-medium.text-green-600.transition.ease-in-out.duration-150(
                  class='hover:text-green-500 focus:outline-none focus:underline',
                  :to='{ name: "welcome" }',
                  v-if='!isMobile'
                )
                  //- on mobile, we don't necessarily know a specific target league here, so we can't show this
                  span(data-test="create-account") Create Account

            .flex.justify-center.text-sm.cursor-pointer.font-medium.text-green-600.transition.ease-in-out.duration-150.w-full.mt-4(
              class='hover:text-green-500 focus:outline-none focus:underline',
              :class='{ "justify-between": hasMultipleLeagues }'
            )
              .w-28.h-12.inline-flex.items-center.text-center.px-4.py-2.border.border-transparent.text-sm.leading-5.font-medium.rounded-md.text-white.transition.ease-in-out.duration-150.bg-green-700(
                class='focus:outline-none',
                :class='[hasMultipleLeagues ? "flex" : "hidden"]',
                @click='clearLeague'
              ) Switch League
              TBtn(
                type='submit',
                data-test='do-login',
                label='Login',
                width='w-28',
                :margin='false'
              )
            div.flex.justify-center.mt-4
              SigninWithGoogle()
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  computed,
  inject,
  ref,
  Ref,
  watch,
  getCurrentInstance,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { maybeParseJSON } from 'src/helpers/utils'
import { axiosNoAuthInstance, updateApiUrl } from 'src/boot/axios'
import { exhaustiveCaseGuard, useIziToast } from 'src/helpers/utils'

import { mfaInitRouteNameByMfaType, mfaChallengeRouteNameByMfaType, beginMfaInitJourney } from "src/components/User/Mfa.route"
import { MfaInitOptions, MfaInitSMS_Public, MfaInitTOTP_Public, type MfaInitOptionsEmits, type MfaInitSuccessEmits } from "src/components/User/MfaInit"
import { MfaChallengeSuccessEmits, MfaChallengeOptionsEmits, MfaChallengeOptions, MfaChallengeSMS, MfaChallengeTOTP } from "src/components/User/MfaChallenge"
import { SigninWithGoogle } from "src/components/Oauth"

import * as ilauth from "src/composables/InleagueApiV1.Authenticate"
import { getLogger } from 'src/modules/LoggerService'
import { PublicLogWriter } from 'src/modules/Loggers'
import { RouterHistoryTracker } from 'src/store/EventuallyPinia.RouterHistoryTracker'
import { setLoginModalDismissed } from "src/store/Misc"
import { X } from "src/components/SVGs"
import { System } from 'src/store/System'
import { AuthenticateResult, User } from 'src/store/User'
import { Client } from 'src/store/Client'

export default defineComponent({
  components: {
    SigninWithGoogle,
    MfaInitOptions, MfaInitSMS: MfaInitSMS_Public, MfaInitTOTP: MfaInitTOTP_Public,
    MfaChallengeOptions, MfaChallengeSMS, MfaChallengeTOTP,
    X
  },
  name: 'LoginModal',
  setup() {
    const publicPath = computed<string>(() => Client.value.publicPath);

    const route = useRoute()
    const router = useRouter()
    const localInstance = getCurrentInstance()
    const hasMultipleLeagues = ref(false)
    const username = ref('')
    const password = ref('')
    const location = ref({}) as Ref<GeolocationPosition>
    const pwd = ref(null)
    const iziToast = useIziToast();

    const isInMfaInitFlow = computed(() => {
      const state = User.loginState.state;
      switch (state) {
        case "mfa-init-flow":
          // fallthrough
        case "mfa-init-flow-complete":
          return true;
        case "logged-in":
          // fallthrough
        case "logged-out":
          // fallthrough
        case "mfa-challenge-flow":
          // fallthrough
        case "oauth-ack-multiple-leagues":
          return false;
        default: return exhaustiveCaseGuard(state)
      };
    })

    const mfaInitFlowIsComplete = computed(() => User.loginState.state === "mfa-init-flow-complete")

    const isInMfaChallengeFlow = computed(() => {
      const state = User.loginState.state;
      switch (state) {
        case "mfa-challenge-flow":
          return true;
        case "mfa-init-flow":
          // fallthrough
        case "mfa-init-flow-complete":
          // fallthrough
        case "logged-in":
          // fallthrough
        case "logged-out":
          // fallthrough
        case "oauth-ack-multiple-leagues":
          return false;
        default: return exhaustiveCaseGuard(state)
      };
    })

    const dev_TOTP_DEV_GUARD__IS_DEV_CONTEXT = Client.value.instanceConfig.clientid === "E2CE1A48-50A0-4C54-A754-C9DCE2F86902";
    const dev_totp_initFlow = ref(false);
    const dev_totp_challengeFlow = ref(false);

    const isMobile = computed(() => {
      return System.value.isMobile
    })

    const modalClass = computed(() => {
      if (isMobile.value) return 'absolute bottom-0 left-0 h-screen'
      else return 'rounded-lg overflow-hidden'
    })

    const leagueSelected = computed(() => {
      return System.value.clientLeague
    })

    const clientTheme = computed(() => {
      return Client.value.clientTheme
    })

    const isLoggedIn = computed(() => {
      return User.isLoggedIn;
    })

    const tBtnClasses = computed(() => {
      return System.value.tBtnClasses
    })

    const instanceConfig = computed(() => {
      return Client.value.instanceConfig
    })

    const saveUserDetails = () => {
      localStorage.setItem('username', JSON.stringify(username.value))
      User.directCommit_setUserEmail(username.value)
      User.directCommit_setPwd(password.value)
    }

    //
    // does what it says on the tin, but probably the server shouldn't send such an item if we don't want it?
    // Or we could filter it in the js api layer?
    //
    // Operates in place and returns the mutated item.
    //
    // This may be related to apple/ios app app review logins? Something like "only let them access the demo account"?
    //
    const FIXME_dropNonInleagueItemWhereClientIdIsExactlyDemoUnlessThereIsOnlyOneLeague_inPlace = (leagues: ilauth.LeagueDomainDetails[]) => {
      const DEMO_CLIENT_ID = "E2CE1A48-50A0-4C54-A754-C9DCE2F86902";
      for (let i = 0; i < leagues.length; i++) {
        if (
          leagues[i].clientID === DEMO_CLIENT_ID &&
          leagues[i].isInleague != 1 &&
          // don't drop the only league ... is this expected to happen?
          // what if there's only one league, and we wanted to filter it away? Now we have the league we wanted to filter away,
          // but kept it, because it's the only one? Huh? So, non-inleague demo users with accounts only for demo make it through
          // this filter. But, non-inleague demo users with 2 accounts only see their non-demo account ... ?
          leagues.length > 1
        ) {
          leagues = leagues.splice(i, 1)
          break
        }
      }
      return leagues
    }

    const handleLoginResult_multi = async (availableLeagues: ilauth.LeagueDomainDetails[]) => {
      // under what circumstances is this true?
      if (route.query.redirect && route.query.redirect !== '/') {
        System.setRedirectOnLogin({type: "vue-router-route", value: route.query.redirect as string})
      }

      // why is this necessary?
      //
      // aug/9/2023 -- it's not clear what this is doing, or that it's necessary.
      // some kind of shortcut for dev? do we need this at all? if we hear complaints that we've commented this out,
      // add those complaints here for documentation. Otherwise, delete this in a few months.
      //
      // const leagues = FIXME_dropNonInleagueItemWhereClientIdIsExactlyDemoUnlessThereIsOnlyOneLeague_inPlace(availableLeagues)

      const leagues = availableLeagues;

      if (leagues.length > 1) {
        //
        // there is more than 1 league to choose from, we need to offer a selection
        //
        await router.push({ path: 'mobile-select-league-login' })
      } else {
        //
        // There is, after filtering away some leagues as per the FIXME above, exactly 1 selectable league
        // we can update our api base URL, and then retry to login
        //
        await updateApiUrl(`https://${leagues[0].appDomain}/api`)

        const leagueDetails = await System.selectLeague(
          leagues[0].appDomain
        )

        await Client.customizeLeagueDisplay(leagueDetails)

        try {
          //
          // here, our api url has been updated so that login will pick a particular league-centric endpoint
          //
          const result = await User.loginWeb(axiosNoAuthInstance, {
            username: username.value,
            password: password.value,
            leagueSelected: true,
          })

          // this is circular, and could get into an infinite loop; but we know we don't have a "multi" response here that
          // would trigger another call to this function
          handleLoginResult(result);
        } catch (err) {
          // toast it
          iziToast.error({message: "Sorry, something went wrong."});

          // log it
          void getLogger(PublicLogWriter).log("warning", "/LoginModal", {
            error: err,
            vueRouterHistory: RouterHistoryTracker
              .getHistory()
              .map(routeLocation => routeLocation.fullPath),
          });
        }
      }
    }

    const login = async () : Promise<void> => {
      saveUserDetails()

      const savedLeague = maybeParseJSON(
        localStorage.getItem('savedLeague') ?? ""
      )

      if (savedLeague) {
        await updateApiUrl(`https://${savedLeague.appDomain}/api`)
        await Client.customizeLeagueDisplay(savedLeague)
      }

      await loginWorker();
    }

    const loginWorker = async () : Promise<void> => {
      const loginResult = await User.doAuthenticate({
        username: username.value,
        password: password.value,
        // "league is selected" if either
        //  - we're NOT on mobile device build (as in, we are in a browser on a particular league domain)
        //  - clientURL is truthy, meaning we could be on mobile but focused on a particular league
        leagueSelected: !isMobile.value || !!System.value.clientUrl,
      })
      await handleLoginResult(loginResult);
    }

    const handleLoginResult_single = async (authResponse: ilauth.AuthenticateResponse) : Promise<void> => {
      switch (authResponse.status) {
        case "complete": {
          await finalizeLogin(authResponse);
          return;
        }
        case "needs-mfa-init": {
          await beginMfaInitJourney(router, authResponse)
          return;
        }
        case "needs-mfa-challenge": {
          User.loginState = {
            state: "mfa-challenge-flow",
            userID: authResponse.userID,
            token: authResponse.token,
            mfaDetails: authResponse.mfaDetails
          };

          if (authResponse.mfaDetails.enrolledMfaTypes.length === 1) {
            await router.push(mfaChallengeRouteNameByMfaType(authResponse.mfaDetails.enrolledMfaTypes[0]))
          }
          else {
            await router.push(mfaChallengeRouteNameByMfaType("CHOOSE!"))
          }
          return;
        }
        default: exhaustiveCaseGuard(authResponse);
      }
    }

    const handleLoginResult = async (loginResult: AuthenticateResult) : Promise<void> => {
      if (loginResult.ok) {
        if (loginResult.type === "single") {
          await handleLoginResult_single(loginResult.data);
          return;
        }
        else if (loginResult.type === "multi") {
          await handleLoginResult_multi(loginResult.data)
          return;
        }
        else {
          exhaustiveCaseGuard(loginResult);
        }
      }
      else {
        iziToast.error({ message: loginResult.msg, target: '#errors', timeout: false })
        return;
      }
    }

    const clearLeague = async () => {
      localStorage.setItem('savedLeague', JSON.stringify(''))
      await Client.customizeLeagueDisplay({
        appDomain: '',
        regionName: '',
        clientID: '',
      })
      await updateApiUrl(`https://api.inleague.io`)
      await login()
    }

    watch(username, val => {
      localStorage.setItem('savedLeague', JSON.stringify(''))
    })

    watch([username, password], () => {
      iziToast.destroy()
    })

    onMounted(async () => {
      await router.isReady()

      {
        // this is a bit underspecified when it comes to its interaction with the url hash used for mfa/oauth
        // probably the hash will "win" (any redirectURLs specified via the hash will be dealt with later and stomp the result
        // of checking for the onLoginURL). However, we don't expect there to be too much overlap between cases where we'd use the
        // onLoginSuccessURL and where we use the fragment. We might want to unify the approaches at some point.
        const onLoginURL = route.query.onLoginURL
        if (typeof onLoginURL === "string") {
          System.setRedirectOnLogin({type: "url", value: onLoginURL})
        }
      }

      const savedLeague = maybeParseJSON(
        localStorage.getItem('savedLeague') ?? ""
      )
      hasMultipleLeagues.value = !!savedLeague
      if (savedLeague)
        await Client.customizeLeagueDisplay(savedLeague)

      if (route.hash) {
        // n.b. this isn't security related it's only a marker indicating we should try the implicit 3rd party login endpoint.
        // If we have a fragment here, it should be a base64 encoded json object saying what to do and how
        const base64 = route.hash.slice(1); // drop leading hash char

        // clean up url bar, maybe this is not necessary
        await router.replace({...router.currentRoute, hash: undefined})

        const str = atob(base64);
        const obj : ilauth.LoginURLFragment = JSON.parse(str);

        if (obj.what === "oauth-login") {
          try {
            System.setLoading(true);
            const loginResult = await User.doAuthenticate("use-implied-3rd-party-oauth-flow", obj.assertion);
            await handleLoginResult(loginResult);
          }
          catch (e) {
            // some failure, not much else can we do here; maybe log it?
            await router.push({path: "/login"})
          }
          finally {
            System.setLoading(false);
          }
          return;
        }
        else if (obj.what === "mfa-from-elsewhere") {
          await handleLoginResult_single(obj.data);
          return;
        }
        else if (obj.what === "multiple-leagues") {
          if (obj.isInThirdPartyOauthAckFlow) {
            User.loginState = {state: "oauth-ack-multiple-leagues", email: obj.email, availableLeagues: obj.availableLeagues};
          }
          await handleLoginResult_multi(obj.availableLeagues);
          return;
        }
        else {
          exhaustiveCaseGuard(obj);
        }
      }

      const savedUsername = maybeParseJSON(
        localStorage.getItem('username') ?? ""
      )

      if (typeof route.query.email === "string") {
        username.value = route.query.email;
      }
      else if (savedUsername) {
        username.value = savedUsername
      } else if (User.value.userEmail) {
        username.value = User.value.userEmail
      }

      if (User.value.loggedOutErrorDisplayed) {
        localInstance?.appContext.config.globalProperties.$toast.error({
          message:
            'Logged out due to inactivity. Please log back in to continue this request.',
          timeout: false,
          target: '#errors',
        })
      }
    })

    const finalizeLogin = async (authData: ilauth.AuthenticateResponse_Complete) : Promise<void> => {
      await User.loginUser(authData);

      User.handleOnLoginAction(authData)

      //
      // Do we have some place to go after login?
      //

      if (System.getRedirectOnLogin()) {
        await System.performAndConsumeLoginRedirect(router)
        return;
      }

      //
      // There's no requested place to go after login, go to the default.
      //
      if (isMobile.value) {
        await router.push({ name: 'mobile-landing' })
      }
      else {
        //
        // if we get here, we're on desktop, and we don't need to change the current route
        // misc. side effects have proven to the rest of the app we're logged in and should result
        // in this component being unmounted
        //
        // so just flow off the end
        //
      }
    }

    const mfaInitOptionsHandlers : MfaInitOptionsEmits = {
      selected: async mfaType => {
        await router.push(mfaInitRouteNameByMfaType(mfaType))
      }
    }

    const mfaChallengeOptionsHandlers : MfaChallengeOptionsEmits = {
      selected: async mfaType => {
        await router.push(mfaChallengeRouteNameByMfaType(mfaType))
      }
    }

    const mfaInitSuccessHandlers : MfaInitSuccessEmits = {
      success: async authData => {
        // subtle behavior here -- await push route, then change login state (which is expected to change the output modal based on pair (routename, login state))
        // if we do it the other way around (i.e. change login state, await push route) then the "current modal" remains until the route push await resolves,
        // but the "current modal"'s is not intended to work with state other than exactly "mfa-int-flow".
        //
        // HOWEVER -- the init-flow-complete route is guarded on the user being in the init-flow-complete login state. So, this design suffers from offering 2 bad options:
        //  - (1) await push route, then update login state; this requires the init-flow-complete route accept the active 'init-flow' state
        //  - (2) update login state, await push route; this requires that we guard mounting "init flow modal"(s) appropriately
        //
        // Option 2 seems less error prone (avoiding option 1's allowance of entering a route in the wrong state), so we'll do that
        //
        // Ideally these could be an atomic op?
        //
        User.loginState = {state: "mfa-init-flow-complete"};
        await router.push(mfaInitRouteNameByMfaType("COMPLETE!"));

        // Slight race here, we should probably store the callback handle in the store and can clear it
        // if somehow we log out before this finishes.
        setTimeout(async () => await finalizeLogin(authData), 2000);
      }
    }

    const mfaChallengeSuccessHandlers : MfaChallengeSuccessEmits = {
      success: async authData => {
        await finalizeLogin(authData);
      }
    }

    return {
      publicPath,
      modalClass,
      leagueSelected,
      username,
      password,
      location,
      login,
      clientTheme,
      isMobile,
      pwd,
      hasMultipleLeagues,
      clearLeague,
      tBtnClasses,
      instanceConfig,
      dev_TOTP_DEV_GUARD__IS_DEV_CONTEXT,
      dev_totp_initFlow,
      dev_totp_challengeFlow,
      User,
      mfaInitSuccessHandlers,
      mfaInitOptionsHandlers,
      mfaChallengeOptionsHandlers,
      mfaChallengeSuccessHandlers,
      route,
      isInMfaInitFlow,
      isInMfaChallengeFlow,
      mfaInitFlowIsComplete,
      dismissModal: () => {
        setLoginModalDismissed(true);
      },
    }
  },
})
</script>
