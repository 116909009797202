import { assertTruthy } from "src/helpers/utils";

export function teamCountAdjustedWithMaybeByeTeam(teamCountNoBye: number) {
  const needsBye = (teamCountNoBye % 2) === 1
  return teamCountNoBye + (needsBye ? 1 : 0)
}

export function getRoundRobinInfo({teamCount}: {teamCount: number}) {
  if ((teamCount % 2) == 1) {
    throw Error("teamCount must be even");
  }

  const gamesPerRound = teamCount / 2;
  const gamesPerRoundRobinCycle = (teamCount * (teamCount - 1)) / 2;

  const roundsPerRoundRobinCycle = gamesPerRoundRobinCycle / gamesPerRound
  assertTruthy(roundsPerRoundRobinCycle === (teamCount - 1)) // reduced form of above

  /**
  * @roundNum "zero-based" -- should start at 0
  * results are zero-based (e.g. zero is the first cycle)
  */
  const roundRobinCycleNum = (roundNum: number) => {
    return Math.floor(roundNum / roundsPerRoundRobinCycle);
  }

  /**
   * given some roundNum, which relative roundNum is it within its owning round robin cycle?
   * This assumes we have some series of densly packed roundNums (e.g. 0,1,2,3,4,5...)
   * representing 1-or-more round robin cycles.
   *
   * @roundNum "zero-based" -- should start at 0
   * results are zero-based
   */
  const relativeRoundNumFromSeriesAbsoluteRoundNum = (roundNum: number) => {
    return (roundNum % roundsPerRoundRobinCycle)
  }

  return {
    gamesPerRound,
    gamesPerRoundRobinCycle,
    roundsPerRoundRobinCycle,
    roundRobinCycleNum,
    relativeRoundNumFromSeriesAbsoluteRoundNum
  }
}
