import { GetTeamPoolForEditTeamPoolViewResponse } from "src/composables/InleagueApiV1.Teams"
import { SetEx, nextOpaqueVueKey } from "src/helpers/utils"
import { Guid } from "src/interfaces/InleagueApiV1"

export interface ResolvedSelection {
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  poolData: PoolData
}

export interface NewPoolForm {
  renderKey: string,
  name: string,
}

export function NewPoolForm() : NewPoolForm {
  return {
    renderKey: nextOpaqueVueKey(),
    name: ""
  }
}

export interface PoolData {
  apiData: GetTeamPoolForEditTeamPoolViewResponse,
  selectedAddTheseTeamIDs: SetEx<Guid>,
  selectedRemoveTheseTeamIDs: {[poolUID: Guid]: undefined | SetEx<Guid>}
  createdThisSessionPoolUIDs: Guid[],
}
