import { FormKit } from "@formkit/vue";
import { RoutePropsVariant } from "src/RouteTemplate.route";
import { PaneController, Pane } from "src/components/Team/Pane";
import { PlayerRosterSort } from "src/components/Team/R_TeamSeason.route";
import { TeamChooserMenu } from "src/composables/InleagueApiV1.TeamChooser2";
import { vReqT, exhaustiveCaseGuard, UiOption, useWindowSize, setIsSame, Reflike, vOptT, assertNonNull } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";
import { CSSProperties, Ref, Transition, TransitionProps, computed, defineComponent, ref, watch } from "vue";
import { BasicTeamChooserSelectionManager, getTeamChooserMenuNode } from "./TeamChooserUtils";

/**
 * Typically we want to show a long list of teams, in pane, absolutely positioned in some root container.
 * The container should grow to contain the list to make it scrollable. This provides the refs and watchers
 * required to support this.
 */
export function useTeamSelectionButtonsPaneSizeWatcher() {
  const openAbsolutePosPanes = ref<readonly HTMLElement[]>([]);
  const rootRef = ref<HTMLElement | null>(null);

  const unwatch = watch (() => openAbsolutePosPanes.value, () => {
    if (!rootRef.value) {
      return;
    }

    if (openAbsolutePosPanes.value.length === 0) {
      rootRef.value.style.height = "";
    }
    else {
      rootRef.value.style.height = Math.max(rootRef.value.scrollHeight, rootRef.value.clientHeight) + "px";
    }
  })

  return {
    openAbsolutePosPanes,
    rootRef,
    unwatch
  }
}

export const TeamSelectionButtons = defineComponent({
  props: {
    teamSelectMode: vReqT<"single" | "multi">(),
    menu: vReqT<TeamChooserMenu>(),
    selectionManager: vReqT<BasicTeamChooserSelectionManager>(),
    levels: vReqT<{season?: boolean, competition?: boolean, division?: boolean, team?: boolean, sort?: boolean}>(),
    // TODO: levels.sort + mut_sort --> mut_sort should only required if levels.sort is true
    mut_sort: vOptT<Reflike<PlayerRosterSort>>(),
    mut_openAbsolutePosPanes: vReqT<Ref<readonly HTMLElement[]>>(),
  },
  setup(props, ctx) {
    const paneInteractorButtonStyles : CSSProperties = {
      width: "8rem"
    }
    const paneStyles : CSSProperties = {
      zIndex: "99",
      maxHeight: "40rem",
      top: "4px",
      left: "calc(100% + 1em)",
      overflowY: "auto",
    }

    const isInMultiTeamSelectMode = computed<boolean>(() => {
      return props.teamSelectMode === "multi"
    });

    const sortOptions : UiOption<PlayerRosterSort>[] = [
      {label: "Uniform", value: "uniform"},
      {label: "Name", value: "name"}
    ];

    const partialSelection = computed(() => {
      return {
        seasonUID: props.selectionManager.selectedSeasonUID.value,
        competitionUID: props.selectionManager.selectedCompetitionUID.value,
        divID: props.selectionManager.selectedDivID.value,
      }
    });

    const seasonName = computed(() => {
      const {seasonUID} = partialSelection.value
      return seasonUID
        ? getTeamChooserMenuNode(props.menu, "season", {seasonUID})?.name ?? null
        : null
    })

    const competitionName = computed(() => {
      const {seasonUID, competitionUID} = partialSelection.value
      return seasonUID && competitionUID
        ? getTeamChooserMenuNode(props.menu, "competition", {seasonUID, competitionUID})?.name ?? null
        : null
    })

    const divName = computed(() => {
      const {seasonUID, competitionUID, divID} = partialSelection.value
      return seasonUID && competitionUID && divID
        ? getTeamChooserMenuNode(props.menu, "division", {seasonUID, competitionUID, divID})?.name ?? null
        : null
    })

    const underPaneTextTransition : TransitionProps = {
      enterActiveClass: "transition duration-100 ease-out",
      enterFromClass: "opacity-0",
      enterToClass: "opacity-100",
      leaveActiveClass: "transition duration-100 ease-in",
      leaveFromClass: "opacity-100",
      leaveToClass: "opacity-0",
    }

    const windowSize = useWindowSize();

    /**
     * The initial intent of <Pane> was that the controllers were scoped;
     * but we ended up needing access to them from "here" (i.e. the Pane-owning context),
     * so we grab access to them after they become available
     */
    const paneControllers = {
      season: {
        controller: null as null | PaneController,
        element: ref<HTMLElement | null>(null),
      },
      competition: {
        controller: null as null | PaneController,
        element: ref<HTMLElement | null>(null),
      },
      division: {
        controller: null as null | PaneController,
        element: ref<HTMLElement | null>(null),
      },
      team: {
        controller: null as null | PaneController,
        element: ref<HTMLElement | null>(null),
      }
    }

    // This should be "atomic", meaning we get one watch event
    // per user input, TO INCLUDE possible changes to selections in response
    // to user input (e.g reconstraining input based on a new selection). So
    // a user input + some programmatic changes in response to that user input, should
    // be 1 event, rather than multiple.
    watch(() => [
      props.selectionManager.selectedSeasonUID.value,
      props.selectionManager.selectedCompetitionUID.value,
      props.selectionManager.selectedDivID.value,
      props.selectionManager.selectedTeamIDs.value,
    ], () => {
      paneControllers.season.controller?.close();
      paneControllers.competition.controller?.close();
      paneControllers.division.controller?.close();
      paneControllers.team.controller?.close();

      if (!props.selectionManager.selectedCompetitionUID.value) {
        paneControllers.competition.controller?.open()
        return
      }

      if (!props.selectionManager.selectedDivID.value) {
        paneControllers.division.controller?.open()
        return
      }

      if (!props.selectionManager.selectedTeamIDs?.value?.length) {
        paneControllers.team.controller?.open()
        return
      }
    })

    watch(() => [
      paneControllers.season.element.value,
      paneControllers.competition.element.value,
      paneControllers.division.element.value,
      paneControllers.team.element.value,
    ], () => {
      const open : HTMLElement[] = []

      maybePush(paneControllers.season.element.value);
      maybePush(paneControllers.competition.element.value);
      maybePush(paneControllers.division.element.value);
      maybePush(paneControllers.team.element.value);

      props.mut_openAbsolutePosPanes.value = open;

      function maybePush(e: HTMLElement | null) {
        if (e) {
          open.push(e);
        }
      }
    })

    const maybeWithNilOption = (otherOpts: UiOption[], args?: {forMultiSelect: boolean}) : UiOption[]=> {
      if (otherOpts.length === 0) {
        // typically shouldn't happen here,
        // though it could if, after pruning incomplete paths, we end up with no seasons
        return [{label: "No options", value: "", attrs: {disabled: true}}]
      }
      else if (otherOpts.length === 1) {
        // only one option, it should be OK to only offer it, because it
        // will have been auto-selected
        return otherOpts
      }
      else {
        if (args?.forMultiSelect) {
          // in the multi select case we still don't need the nil option
          return otherOpts
        }
        else {
          // with more than one option, the user must choose;
          // and the <select> needs a visible "nothing selected" option
          return [{label: "", value: ""}, ...otherOpts]
        }
      }
    }

    return () => {
      if (windowSize.width <= 640) {
        return (
          <div class="mt-4">
            {
              props.levels.season
                ? <FormKit type="select" options={maybeWithNilOption(props.selectionManager.seasonOptions.value)} v-model={props.selectionManager.selectedSeasonUID.value} label="Season"/>
                : null
            }
            {
              props.levels.competition
                ? <FormKit type="select" options={maybeWithNilOption(props.selectionManager.competitionOptions.value)} v-model={props.selectionManager.selectedCompetitionUID.value} label="Program"/>
                : null
            }
            {
              props.levels.division
                ? <FormKit type="select" options={maybeWithNilOption(props.selectionManager.divisionOptions.value)} v-model={props.selectionManager.selectedDivID.value} label="Division"/>
                : null
            }
            {
              props.levels.team
                ? (
                  <FormKit type="select"
                    {...{
                      multiple: isInMultiTeamSelectMode.value ? true : undefined,
                      size: isInMultiTeamSelectMode.value ? 5 : undefined
                    }}
                    options={maybeWithNilOption(props.selectionManager.teamOptions.value, {forMultiSelect: isInMultiTeamSelectMode.value})}
                    v-model={props.selectionManager.selectedTeamIDs.value}
                    label={isInMultiTeamSelectMode.value ? "Teams" : "Team"
                  }/>
                )
                : null
            }
            {
              props.levels.sort && props.mut_sort
                ? <FormKit type="radio" options={sortOptions} v-model={props.mut_sort.value} label="Sort" options-class="flex gap-4"/>
                : null
            }
          </div>
        )
      }

      return (
        <div style="--fk-border:none; --fk-margin-outer:none; --fk-padding-fieldset:none;">
          {
            props.levels.season
              ? (
                <div class="my-1">
                  <Pane element={(args) => {
                    paneControllers.season.controller = args;
                    return (
                      <div class="inline-block">
                        <div class="flex gap-2 items-center">
                          <div class="relative">
                            <t-btn style={paneInteractorButtonStyles} margin={false} onClick={() => args.toggle()}>
                              <div class="text-left">Select&nbsp;Season</div>
                            </t-btn>
                            <Transition {...args.defaultTransitionProps}>
                              {
                                args.isOpen.value
                                  ? (
                                    <div
                                      ref={paneControllers.season.element}
                                      style="z-index:99; left: calc(100% + 1em);"
                                      v-onClickOutside={() => { args.close() }}
                                      class="absolute top-0 whitespace-nowrap bg-white text-black px-4 py-2 border border-slate-100 shadow-lg rounded-md">
                                      <div class="text-sm mb-2">Available seasons</div>
                                      <FormKit type="radio" options={props.selectionManager.seasonOptions.value} v-model={props.selectionManager.selectedSeasonUID.value}/>
                                    </div>
                                  )
                                  : null
                              }
                            </Transition>
                          </div>
                          {/*don't put text underneath the pane*/}
                          <Transition {...underPaneTextTransition}>
                            {args.isOpen.value ? null : <div>{seasonName.value}</div>}
                          </Transition>
                        </div>
                      </div>
                    )
                  }}/>
                </div>
              )
              : null
          }
          {
            props.levels.competition
              ? (
                <div class="my-1">
                  <Pane element={(args) => {
                    const disabled = !props.selectionManager.selectedSeasonUID.value
                    const btnClasses = disabled ? "bg-gray-200" : ""
                    paneControllers.competition.controller = args;
                    return (
                      <div class="inline-block">
                        <div class="flex gap-2 items-center">
                          <div class="relative">
                            <t-btn style={paneInteractorButtonStyles} margin={false}  disable={disabled} class={btnClasses} onClick={() => args.toggle()}>
                              <div class="text-left">Select&nbsp;Program</div>
                            </t-btn>
                            <Transition {...args.defaultTransitionProps}>
                              {
                                args.isOpen.value
                                  ? (
                                    <div
                                      ref={paneControllers.competition.element}
                                      style={paneStyles}
                                      v-onClickOutside={() => { args.close() }}
                                      class="absolute top-0 whitespace-nowrap bg-white text-black px-4 py-2 border border-slate-100 shadow-lg rounded-md">
                                      <div class="text-sm mb-2">Available programs</div>
                                      <FormKit type="radio" options={props.selectionManager.competitionOptions.value} v-model={props.selectionManager.selectedCompetitionUID.value}/>
                                    </div>
                                  )
                                  : null
                              }
                            </Transition>
                          </div>
                          <div class={args.isOpen.value ? "invisible" : ""}>
                            {competitionName.value}
                            <div>
                              {ctx.slots.competition?.()}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }}/>
                </div>
              )
              : null
          }
          {
            props.levels.division
              ? (
                <div class="my-1">
                  <Pane element={(args) => {
                    const disabled = !(props.selectionManager.selectedSeasonUID.value && props.selectionManager.selectedCompetitionUID.value)
                    const btnClasses = disabled ? "bg-gray-200" : ""
                    paneControllers.division.controller = args;
                    return (
                      <div class="inline-block">
                        <div class="flex items-center gap-2">
                          <div class="relative">
                            <t-btn style={paneInteractorButtonStyles} margin={false}  disable={disabled} class={btnClasses} onClick={() => args.toggle()}>
                              <div class="text-left">Select&nbsp;Division</div>
                            </t-btn>
                            <Transition {...args.defaultTransitionProps}>
                              {
                                args.isOpen.value
                                  ? (
                                    <div
                                      ref={paneControllers.division.element}
                                      style={paneStyles}
                                      v-onClickOutside={() => { args.close() }}
                                      class="absolute whitespace-nowrap bg-white text-black px-4 py-2 border border-slate-100 shadow-lg rounded-md"
                                    >
                                      <div class="text-sm mb-2">Available divisions</div>
                                      <FormKit type="radio" options={props.selectionManager.divisionOptions.value} v-model={props.selectionManager.selectedDivID.value}/>
                                    </div>
                                  )
                                  : null
                              }
                            </Transition>
                          </div>
                          {/*don't put text underneath the pane*/}
                          <Transition {...underPaneTextTransition}>
                            {args.isOpen.value ? null : <div>{divName.value}</div>}
                          </Transition>
                        </div>
                      </div>
                    )
                  }}/>
                </div>
              )
              : null
          }
          {
            props.levels.team
              ? (
                <div class="my-1">
                  <PaneTeamSelector
                    menu={props.menu}
                    isInMultiTeamSelectMode={isInMultiTeamSelectMode.value}
                    selectionManager={props.selectionManager}
                    paneInteractorButtonStyles={paneInteractorButtonStyles}
                    paneStyles={paneStyles}
                    underPaneTextTransition={underPaneTextTransition}
                    doCommitSelection={(teamIDs) => props.selectionManager.selectedTeamIDs.value = teamIDs}
                    rebindPaneController={(paneController) => paneControllers.team.controller = paneController}
                    mut_paneElementRef={paneControllers.team.element}
                  />
                </div>
              )
              : null
          }
          {
            props.levels.sort && props.mut_sort
              ? (
                <div class="my-1">
                  <Pane element={(args) => {
                    if (!props.mut_sort) { // re-narrow in capture, shouldn't ever fail
                      throw "illegal state";
                    }

                    return (
                      <div class="inline-block">
                        <div class="flex items-center gap-2">
                          <div class="relative">
                            <t-btn style={paneInteractorButtonStyles} margin={false} onClick={() => args.toggle()}>
                              <div class="text-left">Sort order</div>
                            </t-btn>
                            <Transition {...args.defaultTransitionProps}>
                              {
                                args.isOpen.value
                                  ? (
                                    <div
                                    style={paneStyles}
                                      v-onClickOutside={() => { args.close() }}
                                      class="absolute whitespace-nowrap bg-white text-black px-4 py-2 border border-slate-100 shadow-lg rounded-md"
                                    >
                                      <FormKit type="radio" options={sortOptions} v-model={props.mut_sort.value}/>
                                    </div>
                                  )
                                  : null
                              }
                            </Transition>
                          </div>
                          {/*don't put text underneath the pane*/}
                          <Transition {...underPaneTextTransition}>
                            {
                            args.isOpen.value
                              ? null
                              : <div>{
                                props.mut_sort.value === "name"
                                  ? "Name"
                                  : props.mut_sort.value === "uniform"
                                  ? "Uniform"
                                  : exhaustiveCaseGuard(props.mut_sort.value)
                                }
                              </div>
                            }
                          </Transition>
                        </div>
                      </div>
                    )
                  }}/>
                </div>
              )
              : null
          }
        </div>
      )
    }
  }
})

/**
 * This is a separate stateful component because occasionally we want to not commit changes to the selected teamID
 * until the user clicks "OK, commit this selection".
 */
const PaneTeamSelector = defineComponent({
  props: {
    menu: vReqT<TeamChooserMenu>(),
    isInMultiTeamSelectMode: vReqT<boolean>(),
    selectionManager: vReqT<BasicTeamChooserSelectionManager>(),
    paneInteractorButtonStyles: vReqT<CSSProperties>(),
    paneStyles: vReqT<CSSProperties>(),
    underPaneTextTransition: vReqT<TransitionProps>(),
    doCommitSelection: vReqT<(_: Guid[]) => void>(),
    rebindPaneController: vReqT<(_: PaneController) => void>(),
    mut_paneElementRef: vReqT<Ref<HTMLElement | null>>(),
  },
  setup(props) {
    const copyCurrentCommittedSelection = () => [...props.selectionManager.selectedTeamIDs.value ?? []]

    const localSelectedTeamIDs = ref(copyCurrentCommittedSelection())

    // We generally want to know if the selected values are the same, rather than if the arrays are object-wise identical.
    const localIsDifferentThanCommitted = () : boolean => !setIsSame(new Set(props.selectionManager.selectedTeamIDs.value), new Set(localSelectedTeamIDs.value));

    const maybeCommit = () : void => {
      if (localIsDifferentThanCommitted()) {
        props.doCommitSelection(localSelectedTeamIDs.value)
      }
    }

    watch(() => localSelectedTeamIDs.value, () => {
      if (props.isInMultiTeamSelectMode) {
        // multi select mode never "auto commits"
        return;
      }

      if (localIsDifferentThanCommitted()) {
        // local value changed, and we're in single-select mode; we can immediately commit changes
        props.doCommitSelection(localSelectedTeamIDs.value);
      }
    })

    watch(() => props.selectionManager.selectedTeamIDs.value, () => {
      if (localIsDifferentThanCommitted()) {
        localSelectedTeamIDs.value = copyCurrentCommittedSelection();
      }
    });

    const teamNames = computed<string[]>(() => {
      const seasonUID = props.selectionManager.selectedSeasonUID.value
      const competitionUID = props.selectionManager.selectedCompetitionUID.value
      const divID = props.selectionManager.selectedDivID.value
      if (!seasonUID || !competitionUID || !divID) {
        return [];
      }

      const divMenuNode = getTeamChooserMenuNode(props.menu, "division", {seasonUID, competitionUID, divID})
      if (!divMenuNode) {
        return [];
      }

      return props
        .selectionManager
        .selectedTeamIDs
        .value
        ?.map(teamID => divMenuNode.orderedChildren.get(teamID)?.name)
        .filter((v) : v is string => !!v) ?? []
    })

    return () => {
      return (
        <Pane
          onClose={() => {
            localSelectedTeamIDs.value = copyCurrentCommittedSelection()
          }}
          element={(args) => {
            props.rebindPaneController(args);
            const disabled = !(props.selectionManager.selectedSeasonUID.value && props.selectionManager.selectedCompetitionUID.value && props.selectionManager.selectedDivID.value)
            const btnClasses = disabled ? "bg-gray-200" : ""

            const {type, label} = (() => {
              if (props.isInMultiTeamSelectMode) {
                return {
                  type: "checkbox",
                  label: <span>Select&nbsp;Teams</span>
                } as const
              }
              else {
                return {
                  type: "radio",
                  label: <span>Select&nbsp;Team</span>
                } as const
              }
            })();
            return (
              <div class="inline-block">
                <div class="flex gap-2 items-center">
                  <div class="relative">
                    <t-btn style={props.paneInteractorButtonStyles} margin={false}  disable={disabled} class={btnClasses} onClick={() => args.toggle()}>
                      <div class="text-left">{label}</div>
                    </t-btn>
                    <Transition {...args.defaultTransitionProps}>
                      {
                        args.isOpen.value
                          ? (
                            <div
                              ref={props.mut_paneElementRef}
                              style={props.paneStyles}
                              v-onClickOutside={() => { args.close() }}
                              class="absolute top-0 whitespace-nowrap bg-white text-black border border-slate-100 shadow-lg rounded-md"
                            >
                              <div class="px-4 py-2">
                                <div class="text-sm mb-2">Available teams</div>
                                <FormKit type={type} options={props.selectionManager.teamOptions.value} v-model={localSelectedTeamIDs.value}/>
                              </div>
                              {
                                props.isInMultiTeamSelectMode
                                  ? (
                                    <div style="position:sticky; width:100%; bottom:0; background-color:white;" class="px-3 py-1 text-xs border-t border-slate-200">
                                      <div class="flex items-center gap-2">
                                        <t-btn
                                          style="padding: .333rem .5rem;"
                                          class={!localSelectedTeamIDs.value.length ? `bg-gray-200` : ``}
                                          disable={!localSelectedTeamIDs.value.length}
                                          margin={false}
                                          onClick={() => {
                                            maybeCommit();
                                            args.close()
                                          }}
                                        >OK</t-btn>
                                        <span style={{visibility: localSelectedTeamIDs.value.length ? "hidden" : undefined}}>Select at least 1</span>
                                      </div>
                                    </div>
                                  )
                                  : null
                              }

                            </div>
                          )
                          : null
                      }
                    </Transition>
                  </div>
                  {/*don't put text underneath the pane*/}
                  <Transition {...props.underPaneTextTransition}>
                    {
                      args.isOpen.value
                        ? null
                        : teamNames.value.length <= 3
                        ? <div>{teamNames.value?.join(", ")}</div>
                        : (
                          <div>
                            <div>{teamNames.value.slice(0,3).join(", ")}</div>
                            <div class="text-xs">...and {teamNames.value.length - 3} more</div>
                          </div>
                        )
                    }
                  </Transition>
                </div>
              </div>
            )
         }}/>
      );
    }
  }
})
