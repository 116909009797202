import { FormKit } from "@formkit/vue";
import { PropType, computed, defineComponent, onMounted, reactive, ref, toRef, watch } from "vue";

import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"

import { AxiosErrorWrapper, axiosInstance } from "src/boot/axios";
import { UiOption, exhaustiveCaseGuard, flowCapture, parseFloatOrFail, parseIntOrFail, vReqT } from "src/helpers/utils";

import { RouteLocationRaw, RouterLink, useRouter } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { CreatingTeam, propsDef, routeDetailToRouteLocation, RouteNames } from "./R_TournamentTeamCreate.route"
import * as R_Self from "./R_TournamentTeamCreate.route"

import {QuestionAnswerMap, TournamentTeamRegistrationQuestionsForm, formAnswersToApiSubmittable} from "./TournamentTeam.questionsForm"

import { tournamentTeamStore } from "./Store/TournTeamStore";
import { states } from "src/helpers/states";
import { RefereeRoster } from "./TournamentTeamConfigurator.referees";
import { TournamentTeamExpandedForConfigurator, getTournamentTeamExpandedForConfigurator } from "./TournamentTeamConfigurator.shared";
import ContentChunkDisplay from "src/components/Admin/ContentChunks/ContentChunkDisplay"
import { InvoiceLastStatus_t } from "src/composables/InleagueApiV1.Payments"
import * as ilapi from "src/composables/InleagueApiV1"
import { LastStatus_t } from "src/interfaces/Store/checkout";
import { CoachRoster } from "./TournamentTeamConfigurator.coaches";
import { System } from "src/store/System";
import { User } from "src/store/User";
import { Client } from "src/store/Client";
import * as R_TournamentTeamConfigurator from "./R_TournamentTeamConfigurator.route";
import { RegionDef } from "src/composables/InleagueApiV1.Public";
import { Public } from "src/store/Public";
import { CheckoutStore } from "src/store/CheckoutStore";

import * as R_Checkout from 'src/components/Payment/pages/R_Checkout.route'

// There was some difference between the two types, but both are now the same.
// If this sticks we can unify them.
interface FormBase {
  type: "foreign" | "local"
}

interface CreateForeignTournamentTeamForm extends FormBase {
  type: "foreign",
  readonly tournamentID: iltypes.Integerlike,
  readonly divID: iltypes.Guid,
  teamCity: string,
  teamName: string,
  teamState: string,
  region: "" | iltypes.Integerlike,
}

interface CreateLocalTournamentTeamForm extends FormBase {
  type: "local",
  readonly tournamentID: "" | iltypes.Integerlike,
  readonly divID: iltypes.Guid,
  teamCity: string,
  teamName: string,
  teamState: string,
  region: "" | iltypes.Integerlike
}

type CreateTournamentTeamForm = CreateLocalTournamentTeamForm | CreateForeignTournamentTeamForm

export function freshCreateTeamForm(type: "foreign", instanceConfig: iltypes.InstanceConfig, tournamentID: iltypes.Integerlike, divID: iltypes.Guid, userRegion: "" | iltypes.Integerlike) : CreateForeignTournamentTeamForm;
export function freshCreateTeamForm(type: "local", instanceConfig: iltypes.InstanceConfig, tournamentID: iltypes.Integerlike, divID: iltypes.Guid, userRegion: "" | iltypes.Integerlike) : CreateLocalTournamentTeamForm;
export function freshCreateTeamForm(type: "foreign" | "local", instanceConfig: iltypes.InstanceConfig, tournamentID: iltypes.Integerlike, divID: iltypes.Guid, userRegion: "" | iltypes.Integerlike) : CreateTournamentTeamForm {
  if (type === "foreign") {
    return {
      type: "foreign",
      tournamentID: tournamentID,
      divID: divID,
      teamCity: "",
      teamName: "",
      teamState: "",
      region: userRegion!, // specified as per the selected overload
    }
  }
  else {
    return {
      type: "local",
      tournamentID: tournamentID,
      divID: divID,
      teamCity: instanceConfig.leaguecity, // hm, will a local user ever change this?
      teamName: "",
      teamState: instanceConfig.leaguestate,
      region: userRegion,
    }
  }
}

function teamFormToSubmittable(form: CreateTournamentTeamForm) : iltournament.CreateTournamentTeamArgs {
  if (form.tournamentID === "") {
    throw "form should have required this and assigned it an integer-like value"
  }

  if (form.region === "") {
    throw "form should have required this and assigned it an integer-like value"
  }

  if (form.type === "foreign") {
    return {
      type: "foreign",
      tournamentID: form.tournamentID,
      divID: form.divID,
      teamCity: form.teamCity,
      teamName: form.teamName,
      teamState: form.teamState,
      int_region: form.region,
    }
  }
  else if (form.type === "local") {
    return {
      type: "local",
      tournamentID: form.tournamentID,
      divID: form.divID,
      teamCity: form.teamCity,
      teamName: form.teamName,
      teamState: form.teamState,
      int_region: form.region,
    }
  }
  else {
    exhaustiveCaseGuard(form);
  }
}

const COMMON_TOURNAMENT_TEAM_SELECTOR_BUTTONLIKE_CLASSES = "rounded-md cursor-pointer p-2 my-4 border shadow-sm hover:shadow-md transition-all active:bg-gray-200/10"
const COMMON_TOURNAMENT_TEAM_SELECTOR_INACTIVE_BUTTONLIKE_CLASSES = "rounded-md p-2 my-4 border shadow-sm"

const CreateTournamentTeam_SeasonChooser = defineComponent({
  emits: {
    didChoose: (_: {seasonUID: iltypes.Guid, wasAutoSelectedSingleOption: boolean}) => true
  },
  setup(_, {emit}) {
    const ready = ref(false);
    const seasons = ref<readonly iltypes.Season[]>([])

    onMounted(async () => {
      seasons.value = await tournamentTeamStore.getSeasonsOrFail();

      if (seasons.value.length === 1) {
        emit("didChoose", {seasonUID: seasons.value[0].seasonUID, wasAutoSelectedSingleOption: true})
        return;
      }
      else {
        ready.value = true;
      }
    })

    return () => {
      if (!ready.value) {
        return null;
      }
      return (
        <div class="max-w-lg">
          {
            seasons.value.length === 0
              ? <div class="my-2">Tournament registration is not open at this time.</div>
              : (
                seasons.value.map(season => {
                  return (
                    <div
                      class={COMMON_TOURNAMENT_TEAM_SELECTOR_BUTTONLIKE_CLASSES}
                      onClick={() => emit("didChoose", {seasonUID: season.seasonUID, wasAutoSelectedSingleOption: seasons.value.length === 1})}
                    >
                      {season.seasonName}
                    </div>
                  )
                })
              )
          }
        </div>
      )
    }
  }
})

const CreateTournamentTeam_CompetitionChooser = defineComponent({
  props: {
    seasonUID: {
      required: true,
      type: String as PropType<iltypes.Guid>
    }
  },
  emits: {
    didChoose: (_: {competitionUID: iltypes.Guid, tournamentID: iltypes.Integerlike, wasAutoSelectedSingleOption: boolean}) => true,
  },
  setup(props, {emit}) {
    const ready = ref(false);
    const competitions = ref<readonly iltypes.Competition[]>([])

    const handleDidChoose = async (competitionUID: iltypes.Guid, wasAutoSelectedSingleOption = false) : Promise<void> => {
      const sanityCheck = competitions.value.find(comp => comp.competitionUID === competitionUID)
      if (!sanityCheck) {
        throw Error(`didChoose: selected a competition that was not in the local list of options?`);
      }

      const tournament = await tournamentTeamStore.getTournamentOrFail({
        seasonUID: props.seasonUID,
        competitionUID
      });
      emit("didChoose", {competitionUID, tournamentID: tournament.tournamentID, wasAutoSelectedSingleOption})
    }

    onMounted(async () => {
      competitions.value = await tournamentTeamStore.getCompetitions(props.seasonUID);

      if (competitions.value.length === 1) {
        await handleDidChoose(competitions.value[0].competitionUID, true);
        return;
      }
      else {
        ready.value = true;
      }
    })

    return () => {
      if (!ready.value) {
        return null;
      }
      return (
        <div>
          {
            competitions.value.length === 0
              ? <div>No available programs</div>
              : (
                competitions.value.map(competition => {
                  return (
                    <div
                      class={COMMON_TOURNAMENT_TEAM_SELECTOR_BUTTONLIKE_CLASSES}
                      onClick={() => handleDidChoose(competition.competitionUID)}
                    >
                      {competition.competition} / {competition.competitionUID}
                    </div>
                  )
                })
              )
          }
        </div>
      )
    }
  }
})

const CreateTournamentTeam_PreChooseDiv = defineComponent({
  props: {
    detail: {
      required: true,
      type: null as any as PropType<R_Self.InfoBanner_PreChooseDiv>
    }
  },
  emits: {
    next: () => true
  },
  setup(props, {emit}) {


    type Awaitables =
      | {ready: false}
      | {
        ready: true,
        tournament: iltournament.Tournament,
        seasonName: string,
        competitionName: string,
      }

    const awaitables = ref<Awaitables>({ready: false})

    const textWithInterpolations = computed(() => {
      return awaitables.value.ready
        ? awaitables.value.tournament.html_tournTeamReg_splash_A
          .replaceAll("{seasonName}", awaitables.value.seasonName)
          .replaceAll("{competitionName}", awaitables.value.competitionName)
        : ""
    });

    onMounted(async () => {
      const tournament = await tournamentTeamStore.getTournamentOrFail({tournamentID: props.detail.tournamentID});
      const seasonName = (await Client.getSeasonByUID(props.detail.seasonUID))?.seasonName ?? "undefined-season";
      const competitionName = (await Client.getCompetitionByUID(props.detail.competitionUID))?.competition ?? "undefined-program";

      if (isEmptyStringOrHtmlContainingOnlyEmptyElements(tournament.html_tournTeamReg_splash_A)) {
        emit("next")
      }
      else {
        awaitables.value = {
          ready: true,
          tournament,
          seasonName,
          competitionName
        }
      }
    })

    return () => {
      if (awaitables.value.ready === false) {
        return null;
      }

      return (
        <div>
          <h1>Tournament team registration - {awaitables.value.seasonName} {awaitables.value.competitionName}</h1>
          <div class="my-2" v-html={textWithInterpolations.value}/>
          <t-btn type="button" onClick={() => emit("next")}>Next</t-btn>
        </div>
      )
    }
  }
})

const CreateTournamentTeam_DivisionChooser = defineComponent({
  props: {
    tournamentID: {
      required: true,
      type: null as any as PropType<iltypes.Integerlike>
    }
  },
  emits: {
    didChoose: (_: {divID: iltypes.Guid, wasAutoSelectedSingleOption: boolean}) => true
  },
  setup(props, {emit}) {
    const ready = ref(false);
    const divisions = ref<iltypes.Division[]>([])

    const handleDidChoose = async (divID: iltypes.Guid, wasAutoSelectedSingleOption = false) : Promise<void> => {
      const sanityCheck = divisions.value.find(div => div.divID === divID)
      if (!sanityCheck) {
        throw Error(`didChoose: selected a division that was not in the local list of options?`);
      }
      emit("didChoose", {divID: divID, wasAutoSelectedSingleOption})
    }

    onMounted(async () => {
      divisions.value = await iltournament.getCreateTournamentTeamCandidateDivisions(axiosInstance, {tournamentID: props.tournamentID});

      if (divisions.value.length === 1) {
        await handleDidChoose(divisions.value[0].divID, true);
        return;
      }
      else {
        ready.value = true;
      }
    })

    return () => {
      if (!ready.value) {
        return null;
      }
      return (
        <div>
          {
            divisions.value.length === 0
              ? <div>This tournament does not currently have any divisions open for registration.</div>
              : (
                divisions.value.map(division => {
                  return (
                    <div
                      class={COMMON_TOURNAMENT_TEAM_SELECTOR_BUTTONLIKE_CLASSES}
                      onClick={() => handleDidChoose(division.divID)}
                    >
                      {division.displayName || division.division}
                    </div>
                  )
                })
              )
          }
        </div>
      )
    }
  }
})

export const CreateTournamentTeam_TeamForm_Impl = defineComponent({
  name: "CreateTournamentTeamFormImpl",
  props: {
    season: {
      required: true,
      type: null as any as PropType<Pick<iltypes.Season, "seasonName">>,
    },
    competition: {
      required: true,
      type: null as any as PropType<Pick<iltypes.Competition, "competition">>,
    },
    division: {
      required: true,
      type: null as any as PropType<Pick<iltypes.Division, "divID" | "division" | "displayName">>,
    },
    tournament: {
      required: true,
      type: null as any as PropType<iltournament.Tournament>,
    },
    availableRegions: {
      required: true,
      type: null as any as PropType<RegionDef[]>,
    },
    mut_form: {
      required: true,
      type: null as any as PropType<CreateTournamentTeamForm>
    },
    // to support a "preview" mode that might not want a submit button
    withSubmitButton: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  emits: {
    submit: () => true,
    chooseExisting: (_: any) => true,
  },
  setup(props, {emit}) {


    const submit = () => {
      emit("submit");
    }

    const regionOptions = computed<UiOption[]>(() => {
      return Public.buildStandardRegionOptions(props.availableRegions, {includeNilOption: true})
    })

    const statesOptions : UiOption[] = (() => {
      const result : UiOption[] = [{value: "", label: ""}]
      states.forEach(stateAbbrev => result.push({label: stateAbbrev, value: stateAbbrev}));
      return result;
    })()

    return () => (
      <div class="max-w-lg" data-test="CreateTournamentTeamFormImpl">
        <div>Season: {props.season.seasonName}</div>
        <div>Program: {props.competition.competition}</div>
        <div>Division: {props.division.displayName || props.division}</div>
        <FormKit type="form" actions={false} onSubmit={submit}>
          <FormKit type="text" label="Team name" v-model={props.mut_form.teamName} validation={[["required"]]} data-test="teamName"/>
          <FormKit type="text" label="Team city" v-model={props.mut_form.teamCity} validation={[["required"]]} data-test="teamCity"/>
          <FormKit type="select" label="Team state" options={statesOptions} v-model={props.mut_form.teamState} validation={[["required"]]} data-test="teamState"/>
          <FormKit type="select" label="Region" options={regionOptions.value} v-model={props.mut_form.region} data-test="teamRegion"/>
          {
            props.withSubmitButton
              ? <t-btn type="submit" margin={false}>Submit</t-btn>
              : null
          }
        </FormKit>
      </div>
    )
  }
})

const CreateTournamentTeam_TeamForm = defineComponent({
  props: {
    detail: {
      required: true,
      type: Object as PropType<CreatingTeam>
    }
  },
  emits: {
    createdOrSelectedTournamentTeam: (_: {tournamentTeamID: iltypes.Integerlike}) => true,
  },
  setup(props, {emit}) {


    type LocalState =
      | {ready: false}
      | {
        ready: true,
        season: iltypes.Season,
        competition: iltypes.Competition,
        tournament: iltournament.Tournament,
        division: iltypes.Division,
        availableRegions: RegionDef[],
        mut_form: CreateTournamentTeamForm
      }

    const state = ref<LocalState>({ready: false})

    const userLocalityType = computed<"local" | "foreign">(() => {
      if (typeof User.value.userData === "string") {
        throw Error("Not logged in?"); // should be impossible
      }
      const userRegion = parseIntOrFail(User.value.userData.region)
      const clientRegion = parseIntOrFail(Client.value.instanceConfig.region);
      return userRegion === clientRegion ? "local" : "foreign"
    })

    const handleSubmit = async () : Promise<void> => {
      if (!state.value.ready) {
        throw Error("state.value.ready should be true here");
      }
      const freshID = await tournamentTeamStore.createTournamentTeam(teamFormToSubmittable(state.value.mut_form));
      emit("createdOrSelectedTournamentTeam", freshID);
    }

    const handleChooseExisting = (tournTeam: iltournament.TournamentTeam) => {
      switch (tournTeam.status) {
        case "PENDING": emit("createdOrSelectedTournamentTeam", {tournamentTeamID: tournTeam.tournamentTeamID})
        default: throw Error("not implemented / should go to a mangement view?")
      }
    }

    onMounted(async () => {
      const season = await tournamentTeamStore.getSeasonOrFail(props.detail.seasonUID)
      const competition = await tournamentTeamStore.getCompetitionOrFail({seasonUID: props.detail.seasonUID, competitionUID: props.detail.competitionUID})
      const tournament = await tournamentTeamStore.getTournamentOrFail({seasonUID: props.detail.seasonUID, competitionUID: props.detail.competitionUID})
      const division = await tournamentTeamStore.getDivisionOrFail({tournamentID: props.detail.tournamentID, divID: props.detail.divID})
      const availableRegions = await Public.getMasterRegionList()

      const form = (() => {
        if (userLocalityType.value === "local") {
          const userData = typeof User.value.userData === "object" ? User.value.userData : null;
          return freshCreateTeamForm("local", Client.value.instanceConfig, tournament.tournamentID, division.divID, userData?.region ?? "")
        }
        else if (userLocalityType.value === "foreign") {
          const userData = typeof User.value.userData === "object" ? User.value.userData : null;
          return freshCreateTeamForm("foreign", Client.value.instanceConfig, tournament.tournamentID, division.divID, userData?.region ?? "");
        }
        else {
          exhaustiveCaseGuard(userLocalityType.value);
        }
      })();

      state.value = {
        ready: true,
        season,
        competition,
        tournament,
        division,
        availableRegions,
        mut_form: form,
      };
    })

    return () => {
      if (!state.value.ready) {
        return null;
      }
      return <CreateTournamentTeam_TeamForm_Impl {...state.value} onSubmit={handleSubmit} onChooseExisting={tournTeam => handleChooseExisting(tournTeam)}/>
    }
  }
})

const CreateTournamentTeam_QuestionsForm = defineComponent({
  name: "QuestionsForm",
  props: {
    tournamentTeamID: {
      required: true,
      type: [Number, String] as any as PropType<iltypes.Integerlike>
    },
    submitLabel: vReqT<string>(),
  },
  emits: {
    complete: () => true,
  },
  setup(props, {emit}) {
    type LocalState =
      | {ready: false}
      | {
        ready: true,
        pageItems: iltournament.TournTeamRegPageItem[]
        existingAnswers: iltournament.TournamentRegistrationAnswer[],
      }

    const state = ref<LocalState>({ready:false})

    const handleSubmit = async (answerMap: QuestionAnswerMap) : Promise<void> => {
      try {
        await iltournament.submitTournamentTeamRegistrationAnswers(
          axiosInstance, {
            tournamentTeamID: props.tournamentTeamID,
            answers: formAnswersToApiSubmittable(answerMap)
          });
        emit("complete")
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    onMounted(async () => {
      const pageItems = await iltournament.getTournamentTeamRegistrationPageItems(axiosInstance, {tournamentTeamID: props.tournamentTeamID})
      const existingAnswers = await iltournament.getTournamentTeamRegistrationAnswers(axiosInstance, {tournamentTeamID: props.tournamentTeamID});

      if (pageItems.length === 0) {
        emit("complete");
        return;
      }

      state.value = {
        ready: true,
        pageItems,
        existingAnswers
      }
    })

    return () => state.value.ready
      ? <TournamentTeamRegistrationQuestionsForm
        pageItems={state.value.pageItems}
        existingAnswers={state.value.existingAnswers}
        onSubmit={answerMap => handleSubmit(answerMap)}
        submitLabel={props.submitLabel}
      />
      : null
  }
})

const CreateTournamentTeam_CoachForm_name = `CreateTournamentTeam_CoachForm`
const CreateTournamentTeam_CoachForm = defineComponent({
  name: CreateTournamentTeam_CoachForm_name,
  props: {
    tournamentTeamID: {
      required: true,
      type: null as any as PropType<iltypes.Integerlike>
    }
  },
  emits: {
    complete: (_: TournamentTeamExpandedForConfigurator) => true,
  },
  setup(props, {emit}) {
    type LocalState =
      | {readonly ready: false}
      | {readonly ready: true, readonly data: TournamentTeamExpandedForConfigurator}

    const state = ref<LocalState>({ready:false})

    watch(() => props.tournamentTeamID, async () => {
      state.value = {ready: false}
      const data = await getTournamentTeamExpandedForConfigurator(axiosInstance, props.tournamentTeamID);
      state.value = {
        ready: true,
        data
      }
    }, {immediate: true});

    const persistedCoachCount = computed(() => {
      return state.value.ready
        ? state.value.data.officials.filter(v => v.type === iltournament.TournamentTeamOfficialType.HEAD_COACH).length
        : 0
    })

    // just a static value, we don't pull this from a tournament record or etc. at this time.
    const MIN_HEADCOUNT_COUNT = 1;

    return () => {
      if (!state.value.ready) {
        return null;
      }
      return (
        <div data-test={CreateTournamentTeam_CoachForm_name}>
          {
            ((tournTeam: TournamentTeamExpandedForConfigurator) => (
              <>
                <CoachRoster
                  tournamentTeam={state.value.data}
                  mut_existingOfficials={state.value.data.officials}
                />
                <t-btn type="button" data-test="submit" onClick={() => emit("complete", tournTeam)} disable={persistedCoachCount.value < MIN_HEADCOUNT_COUNT} class={`${persistedCoachCount.value < MIN_HEADCOUNT_COUNT ? 'bg-gray-200' : ''}`}>
                  Next
                </t-btn>
                {
                  persistedCoachCount.value === 0
                    ? <div class="text-xs text-red-600">This tournament requires at least {MIN_HEADCOUNT_COUNT} {MIN_HEADCOUNT_COUNT === 1 ? "head coach" : "head coaches"} prior to submission. You can always add more later.</div>
                    : null
                }
              </>
            ))(/*flowcapture*/state.value.data)
          }
        </div>
      )
    }
  }
})

/**
 * Currently this supports the case where a team must have at least one referee before advancing to
 * payment. So we prevent submit here until there is at least one assigned referee.
 */
const CreateTournamentTeam_RefereeForm = defineComponent({
  props: {
    tournamentTeamID: {
      required: true,
      type: null as any as PropType<iltypes.Integerlike>
    }
  },
  emits: {
    complete: (_: TournamentTeamExpandedForConfigurator) => true,
  },
  setup(props, {emit}) {
    type LocalState =
      | {readonly ready: false}
      | {readonly ready: true, readonly data: TournamentTeamExpandedForConfigurator}

    const state = ref<LocalState>({ready:false})

    watch(() => props.tournamentTeamID, async () => {
      state.value = {ready: false}
      const data = await getTournamentTeamExpandedForConfigurator(axiosInstance, props.tournamentTeamID);
      state.value = {
        ready: true,
        data
      }
    }, {immediate: true});

    const persistedRefereeCount = computed(() => {
      return state.value.ready
        ? state.value.data.officials.filter(v => v.type === iltournament.TournamentTeamOfficialType.REFEREE).length
        : 0
    })

    return () => {
      if (!state.value.ready) {
        return null;
      }
      return (
        <div>
          {
            ((tournTeam: TournamentTeamExpandedForConfigurator) => (
              <>
                <RefereeRoster
                  targetBinding={{
                    type: "tournamentTeam",
                    tournamentTeamID: state.value.data.tournamentTeamID
                  }}
                  tournTeamOrUndefinedIfUnaffiliatedOrMultiple={state.value.data}
                  mut_existingOfficials={state.value.data.officials}
                />
                <t-btn onClick={() => emit("complete", tournTeam)} disable={persistedRefereeCount.value < tournTeam.minRequiredTournTeamRefCount} class={`${persistedRefereeCount.value === 0 ? 'bg-gray-200' : ''}`}>
                  Next
                </t-btn>
                {
                  persistedRefereeCount.value === 0
                    ? <div class="text-xs text-red-600">This tournament requires at least {tournTeam.minRequiredTournTeamRefCount} referees prior to submission</div>
                    : null
                }
              </>
            ))(/*flowcapture*/state.value.data)
          }
        </div>
      )
    }
  }
})

const CreateTournamentTeam_Complete = defineComponent({
  props: {
    detail: {
      required: true,
      type: null as any as PropType<R_Self.Complete>
    },
    nextLabel: {
      required: true,
      type: String
    }
  },
  emits: {
    next: () => true
  },
  setup(props, {emit}) {


    type MountAwaitables =
      | {ready: false}
      | {
        ready: true,
        tournament: iltournament.Tournament,
        seasonName: string,
        competitionName: string,
      }

    //
    // when we're done polling the backend, the invoice may or may not be fully paid
    // generally, it probably will be fully paid (or fully rejected);
    // we poll for ~10 seconds and the webhooks from stripe come in pretty fast
    //
    type AsyncPaymentAwaitables_Polling = {pollingBackendForCompletion: true}
    type AsyncPaymentAwaitables_Done = {
      pollingBackendForCompletion: false,
      // do we ever not have an invoice?
      invoice: "no-associated-invoice" | iltypes.Invoice
    }
    type AsyncPaymentAwaitables =
      | AsyncPaymentAwaitables_Polling
      | AsyncPaymentAwaitables_Done

    const invoiceStatusToUiString = (invoice: iltypes.Invoice) => {
      switch (invoice.lastStatus) {
        case "In flight":
          return "Payment is still processing. Please check back soon.";
        case "Payment rejected":
          // hm, payment page shouldn't send us here if the payment was declined. A user could probably manually nav here though.
          return "Payment was declined.";
        case "Paid and Processed":
          // invoice is definitely defined here by virtue of having read a lastStatus value out of it
          if (parseFloatOrFail(invoice?.lineItemSum) <= 0.01) {
            return "Zero-fee tournament team registration has been submitted for approval."
          }
          else {
            return "Payment succeeded."
          }
        case "":
          // fallthrough
        case "Created":
          return "Invoice has not been paid yet"
        case "Deleted":
          // fallthrough
        case "Refunded":
          // fallthough
        case "Voided":
          // fallthrough
        case "paid-out-of-band":
          return ""; // shouldn't happen
        default: exhaustiveCaseGuard(invoice.lastStatus);
      }
    }

    const mountAwaitables = ref<MountAwaitables>({ready: false})

    // we init this to true;
    // it might not be true, in the case of arriving here after a "zero fee" registration, or a user says they intend to pay by check
    // Such cases should be handled by a subsequent immediate watch on System.value.paymentProcessing, which should be false in those cases.
    // We cannot initialize to false, because there are async things that need to be resolved in that case.
    const paymentAwaitables = ref<AsyncPaymentAwaitables>({pollingBackendForCompletion: true})

    const completionBlurb = computed(() => {
      const v = mountAwaitables.value.ready
        ? mountAwaitables.value.tournament.html_tournTeamReg_complete
          .replaceAll("{seasonName}", mountAwaitables.value.seasonName)
          .replaceAll("{competitionName}", mountAwaitables.value.competitionName)
        : ""

      return isEmptyStringOrHtmlContainingOnlyEmptyElements(v)
        ? "Tournament team registration complete."
        : v;
    });

    // backend gets polled for async completion of payment processing attempt.
    // After some time (~10s, see store details), this will flip to false
    // (or we mounted with it false, because there was no fee to pay)
    watch(
      () => System.value.paymentProcessing,
      async (paymentProcessing) : Promise<void> => {
        if (paymentProcessing) {
          return;
        }

        const tournamentTeam = await tournamentTeamStore.getTournamentTeamOrFail(props.detail.tournamentTeamID)

        if (tournamentTeam.invoiceInstanceID_registration) {
          const invoice = await CheckoutStore.getInvoice({invoiceID: tournamentTeam.invoiceInstanceID_registration.toString(), expand: true});

          paymentAwaitables.value = {
            pollingBackendForCompletion: false,
            invoice: invoice,
          }
        }
        else {
          // when do we expect to not have an invoice, this is an error case right?
          paymentAwaitables.value = {
            pollingBackendForCompletion: false,
            invoice: "no-associated-invoice"
          }
        }
      },
      { immediate: true }
    )

    onMounted(async () => {
      const tournamentTeam = await tournamentTeamStore.getTournamentTeamOrFail(props.detail.tournamentTeamID);
      const tournament = await tournamentTeamStore.getTournamentOrFail(tournamentTeam)
      const seasonName = (await Client.getCompetitionByUID(tournament.competitionUID))?.competition ?? "undefined-season"
      const competitionName = (await Client.getSeasonByUID(tournament.seasonUID))?.seasonName ?? "undefined-season"

      mountAwaitables.value = {
        ready: true,
        tournament,
        seasonName,
        competitionName,
      }
    })

    return () => {
      if (mountAwaitables.value.ready === false) {
        return null;
      }

      return (
        <div data-test="CreateTournamentTeam_Complete">
          {
            paymentAwaitables.value.pollingBackendForCompletion
              ? <div>Payment processing...</div>
              : <DetailOnPollingComplete {...paymentAwaitables.value}/>
          }
        </div>
      )
    }

    function DetailOnPollingComplete({invoice}: AsyncPaymentAwaitables_Done) {
      if (invoice === "no-associated-invoice") {
        // do we ever expect to hit this
        throw Error("unexpected no-associated-invoice")
      }
      return (
        <div>
          {
            props.detail.query?.byCheck
              ? <div>Please contact the league's tournament representative to submit your regional check payment.</div>
              : null
          }
          {
            (invoice.lastStatus === "Paid and Processed" || props.detail.query?.byCheck)
              ? (
                <div>
                  <div class="my-2">{invoiceStatusToUiString(invoice)}</div>
                  <div class="my-2" v-html={completionBlurb.value}/>
                  <div class="flex gap-2">
                    <RouterLink to={{path: "/"}}>
                      <t-btn margin={false}>Home</t-btn>
                    </RouterLink>
                    <RouterLink to={R_TournamentTeamConfigurator.routeDetailToRouteLocation({tournamentTeamID: props.detail.tournamentTeamID})}>
                      <t-btn margin={false}>Configure team</t-btn>
                    </RouterLink>
                  </div>
                </div>
              )
              : (
                <div>
                  <div class="my-2">{invoiceStatusToUiString(invoice)}</div>
                  <div class="flex gap-2">
                    <RouterLink to={{path: "/"}}>
                      <t-btn margin={false}>Home</t-btn>
                    </RouterLink>
                  </div>
                </div>
              )
          }
        </div>
      )
    }
  }
})

export const CreateTournamentTeamLoggedIn = defineComponent({
  name: "CreateTournamentTeamLoggedIn",
  components: {
    ContentChunkDisplay,
  },
  props: propsDef,
  setup(props) {
    const router = useRouter();

    function Go() : JSX.Element {
      switch (props.detail.name) {
        case RouteNames.ChooseSeason: {
          return (
            <>
              <h2>
                <FontAwesomeIcon icon={["fas", "search"]}/>
                <span class="ml-2">Select the tournament for which you would like to submit a team registration.</span>
              </h2>
              <CreateTournamentTeam_SeasonChooser
                onDidChoose={evt => pushOrReplace(evt, routeDetailToRouteLocation({
                  name: RouteNames.ChooseCompetition,
                  seasonUID: evt.seasonUID
                }))}
              />
            </>
          )
        }
        case RouteNames.ChooseCompetition: {
          const {seasonUID} = flowCapture(props.detail);
          return (
            <>
              <h2>
                <FontAwesomeIcon icon={["fas", "search"]}/>
                <span class="ml-2">Tournament Selection</span>
              </h2>
              <CreateTournamentTeam_CompetitionChooser
                seasonUID={seasonUID}
                onDidChoose={evt => pushOrReplace(evt, routeDetailToRouteLocation({
                  name: RouteNames.InfoBanner_PreChooseDiv,
                  seasonUID: seasonUID,
                  competitionUID: evt.competitionUID,
                  tournamentID: evt.tournamentID
                }))}
              />
            </>
          )
        }
        case RouteNames.InfoBanner_PreChooseDiv: {
          const nextRoute = routeDetailToRouteLocation({
            // same props
            ...props.detail,
            // except for route name
            name: RouteNames.ChooseDivision
          })
          return <CreateTournamentTeam_PreChooseDiv detail={props.detail} onNext={() => router.push(nextRoute)}/>
        }
        case RouteNames.ChooseDivision: {
          const {seasonUID, competitionUID, tournamentID} = flowCapture(props.detail);
          return (
            <>
              <h2>
                <FontAwesomeIcon icon={["fas", "search"]}/>
                <span class="ml-2">Select the division of the team you would like to submit:</span>
              </h2>
              <CreateTournamentTeam_DivisionChooser
                tournamentID={tournamentID}
                onDidChoose={evt => pushOrReplace(evt, routeDetailToRouteLocation({
                  name: RouteNames.CreateTeam,
                  seasonUID,
                  competitionUID: competitionUID,
                  tournamentID: tournamentID,
                  divID: evt.divID
                }))}
              />
            </>
          )
        }
        case RouteNames.CreateTeam: {
          return (
            <>
              <h2>
                <FontAwesomeIcon icon={["fas", "clipboard-list"]}/>
                <span class="ml-2">Register a Team</span>
              </h2>
              <CreateTournamentTeam_TeamForm
                detail={props.detail}
                onCreatedOrSelectedTournamentTeam={evt => router.push(routeDetailToRouteLocation({
                  name: RouteNames.Questions,
                  tournamentTeamID: evt.tournamentTeamID
                }))}
              />
            </>
          )
        }
        case RouteNames.Questions: {
          const {tournamentTeamID} = flowCapture(props.detail);

          const handleOnComplete = async () => {
            await router.push(routeDetailToRouteLocation({name: RouteNames.Coaches, tournamentTeamID}));
          }

          return (
            <>
              <h2>
                <FontAwesomeIcon icon={["fas", "clipboard-list"]}/>
                <span class="ml-2">Registration Form</span>
              </h2>
              <CreateTournamentTeam_QuestionsForm
                tournamentTeamID={props.detail.tournamentTeamID}
                onComplete={handleOnComplete}
                submitLabel="Next"
              />
            </>
          )
        }
        case RouteNames.Coaches: {
          const {tournamentTeamID} = flowCapture(props.detail);

          const handleOnComplete = async () => {
            const tournamentTeam = await tournamentTeamStore.getTournamentTeamOrFail(tournamentTeamID)
            const tournament = await tournamentTeamStore.getTournamentOrFail(tournamentTeam)

            if (tournament.requireRefsOnSubmission) {
              const nextRoute = routeDetailToRouteLocation({name: RouteNames.Referees, tournamentTeamID});
              await router.push(nextRoute)
            }
            else {
              await getOrCreateInvoiceAndPushToCheckout(tournamentTeamID)
            }
          }

          return (
            <>
              <h2>
                <FontAwesomeIcon icon={["fas", "clipboard-list"]}/>
                <span class="ml-2">Tournament Team Coaches</span>
              </h2>
              <CreateTournamentTeam_CoachForm
                tournamentTeamID={props.detail.tournamentTeamID}
                onComplete={handleOnComplete}
              />
            </>
          )
        }
        case RouteNames.Referees: {
          const {tournamentTeamID} = flowCapture(props.detail);

          const handleOnComplete = async () => {
            await getOrCreateInvoiceAndPushToCheckout(tournamentTeamID)
          }

          return (
            <>
              <h2>
                <FontAwesomeIcon icon={["fas", "clipboard-list"]}/>
                <span class="ml-2">Referees</span>
              </h2>
              <CreateTournamentTeam_RefereeForm
                tournamentTeamID={props.detail.tournamentTeamID}
                onComplete={handleOnComplete}
              />
            </>
          )
        }
        case RouteNames.Complete: {
          const {tournamentTeamID} = flowCapture(props.detail);
          return (
            <>
              <h2>
                <FontAwesomeIcon icon={["fas", "clipboard-list"]}/>
                <span class="ml-2">Tournament team registration complete</span>
              </h2>
              <CreateTournamentTeam_Complete
                detail={props.detail} onNext={() => getOrCreateInvoiceAndPushToCheckout(tournamentTeamID)}
                nextLabel="To invoicing"
              />
            </>
          )
        }
        default: exhaustiveCaseGuard(props.detail);
      }

      /**
       * We usually want to "silently" choose the only option in cases where there is only one available season, or competition, or etc.
       */
      function pushOrReplace(e: {wasAutoSelectedSingleOption: boolean}, route: RouteLocationRaw) : void {
        const routerFunc = e.wasAutoSelectedSingleOption ? router.replace : router.push;
        routerFunc(route);
      }

      async function getOrCreateInvoiceAndPushToCheckout(tournamentTeamID: iltypes.Integerlike) {
        const invoice = await tournamentTeamStore.getOrCreateTournamentTeamInvoices(tournamentTeamID);

        const needsToPayReg : boolean = parseFloatOrFail(invoice.registrationInvoice.lineItemSum) >= 0.01;
        const needsPaymentInfoForHold : boolean = parseFloatOrFail(invoice.holdPaymentInvoice.lineItemSum) >= 0.01;

        // 0
        if (!needsToPayReg && !needsPaymentInfoForHold) {
          // "pay" here is really just "activate as if you had paid" because the invoice is effectively free
          // whould checkout page have auto-done this for us ... ? For zero fee compregs I think it offers "click here to confirm",
          // but it seems like skipping that here is reasonable.
          await ilapi.payInvoice(axiosInstance, {invoiceID: invoice.registrationInvoice.instanceID, idempotencyKey: ilapi.nilGuid, paymentMethodID: "0", discardCard: true});
          //
          // Who is responsible for this -- should tournteam store invoke the invoice store, and then update itself? or invoice store should pay the invoice, and then update tournteam store?
          // note we don't have an invoice store at the moment, so it's sort of moot.
          // This problem also exists on the checkout page, in cases where there is a fee.
          // It's not too painful here for the moment because there isn't much we expect to need to update. But "transitive updates" could quickly get out of hand.
          //
          (await tournamentTeamStore.getTournamentTeamOrFail(tournamentTeamID)).status = "PAID_AWAITING_APPROVAL";
          await router.push(R_Self.routeDetailToRouteLocation({name: R_Self.RouteNames.Complete, tournamentTeamID}));
        }
        // 1
        else if (!needsToPayReg && needsPaymentInfoForHold) {
          toCheckoutPage()
        }
        // 2
        else if (needsToPayReg && !needsPaymentInfoForHold) {
          toCheckoutPage();
        }
        // 3
        else if (needsToPayReg && needsPaymentInfoForHold) {
          toCheckoutPage();
        }
        // no other possible cases
        else {
          throw Error("unreachable");
        }

        function toCheckoutPage() {
          router.push(R_Checkout.routeDetailToRouteLocation({
            invoiceInstanceIDs: [invoice.registrationInvoice.instanceID]
          }))
        }
      }
    }

    return () => (
      <div data-test="TournamentTeamCreate.loggedIn">
        <Go/>
      </div>
    )
  }
})

/**
 * not sure how robust this is against deeply nested HTML,
 * but the quill thing that generates this html can leave behind things like `<h1></h1>` and etc.
 * which we want to consider "empty" even if the string isn't exactly `""`
 */
function isEmptyStringOrHtmlContainingOnlyEmptyElements(s: string) {
  const div = document.createElement("div");
  div.innerHTML = s;
  return div.innerText.trim() === "";
}
