import axios from "axios";
import { defineComponent, getCurrentInstance, onMounted, PropType, ref } from "vue"

import * as ilauth from "src/composables/InleagueApiV1.Authenticate"
import { axiosBackgroundInstance, AxiosErrorWrapper, axiosNoAuthInstance, FALLBACK_ERROR_MESSAGE } from "src/boot/axios";

import { exhaustiveCaseGuard, ExtractEmitsHandlers, useIziToast } from "src/helpers/utils";

import { Login_MfaChallengeFlow } from "src/store/User";

import { mfaTypeDisplaySort } from "./Mfa.route";
import { maskedLastTwoSmsPhoneNumber, SorryBadPhoneNumber, withGlobalSpinner } from "./MfaCommon";

import { SoccerBall } from "../SVGs";
import { useRouter } from "vue-router";
import { Client } from "src/store/Client";

const mfaChallengeSuccessEmitsDef = {
  success: (_: ilauth.AuthenticateResponse_Complete) => true
} as const;
export type MfaChallengeSuccessEmits = ExtractEmitsHandlers<typeof mfaChallengeSuccessEmitsDef>;

export const MfaChallengeSMS = defineComponent({
  name: "MfaChallengeSMS",
  props: {
    state: {
      required: true,
      type: Object as PropType<Login_MfaChallengeFlow>
    }
  },
  emits: mfaChallengeSuccessEmitsDef,
  setup(props, {emit}) {
    const router = useRouter();
    const code = ref("");
    const rememberThisDevice = ref(false);
    const iziToast = useIziToast();
    const errorMessage = ref<string | null>(null);

    type Mode =
      | "sorry-bad-phone-number"
      | "prompt-to-generate-code"
      | "generating-code"
      | "generating-code-error"
      | "awaiting-code-input"
      | "submitting-code"
      | "done"

    const mode = ref<Mode>(
      props.state.mfaDetails.smsPhoneNumber.ok
        ? "prompt-to-generate-code"
        : "sorry-bad-phone-number"
    );

    const generateSmsChallengeCode = async () => {
      mode.value = "generating-code";
      errorMessage.value = null;
      try {
        const result = await ilauth.public_.generateSmsChallengeCode(axiosBackgroundInstance, {userID: props.state.userID, token: props.state.token});
        if (result.ok) {
          mode.value = "awaiting-code-input"
        }
        else {
          if (result.detail === "invalid-phone-number") {
            // shouldn't happen for a user who's enrolled in sms right? ...
            mode.value = "sorry-bad-phone-number"
            errorMessage.value = null;
          }
          else if (result.detail === "unknown") {
            mode.value = "prompt-to-generate-code"
            errorMessage.value = "Something went wrong generating your code. Please try again."
          }
          else {
            exhaustiveCaseGuard(result.detail);
          }
        }
      }
      catch (err) {
        mode.value = "generating-code-error";
        errorMessage.value = "Something went wrong sending your code."
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const tryWithCode = async () => {
      try {
        mode.value = "submitting-code"
        errorMessage.value = null;
        const response = await ilauth.public_.authenticateWithSMS(axiosBackgroundInstance, {userID: props.state.userID, token: props.state.token, code: code.value, rememberThisDevice: rememberThisDevice.value});
        mode.value = "done"
        emit("success", response);
      }
      catch (e: any) {
        mode.value = "awaiting-code-input"
        if (axios.isAxiosError(e)) {
          if (e.response?.status !== undefined && Math.floor(e.response.status / 100) === 5) {
            iziToast.error({message: FALLBACK_ERROR_MESSAGE})
            // probably should log this, we'd like to know about it
          }
          else {
            // we could probably be clearer
            errorMessage.value = "Invalid code.";
          }
        }
        else {
          throw e;
        }
      }

    }

    return () => (
      <div data-test="MfaChallengeSMS">
        <div>SMS 2FA</div>
        <div class="mb-2 border-b border-slate-300" />
        {
          (() => {
            switch (mode.value) {
              //
              // the "sorry-bad-phone-number" case shouldn't ever happen in an MFA challenge (as opposed to an init) because the user has positively setup a valid phone number.
              // But maybe we can fall into this in some unexpected way.
              //
              case "sorry-bad-phone-number": return (
                <SorryBadPhoneNumber phoneNumber={maskedLastTwoSmsPhoneNumber(props.state.mfaDetails.smsPhoneNumber)}/>
              )
              case "prompt-to-generate-code": return (
                <div class="flex flex-col justify-center items-center">
                  <div class="text-red-700">{errorMessage.value}</div>
                  <t-btn margin={false} class="mt-1" type="button" data-test="generate-code" onClick={() => generateSmsChallengeCode()}>Send code to {maskedLastTwoSmsPhoneNumber(props.state.mfaDetails.smsPhoneNumber)}</t-btn>
                  <div class="text-xs mt-2">Standard messaging and data rates may apply.</div>
                </div>
              )
              case "generating-code": return (
                <>
                  <div class="flex items-center justify-center">
                    Sending code to {maskedLastTwoSmsPhoneNumber(props.state.mfaDetails.smsPhoneNumber)}
                  </div>
                  <div class="flex items-center justify-center my-3">
                    <SoccerBall color={Client.value.clientTheme.color} width=".375in" height=".375in" timeForOneRotation="1.5s"/>
                  </div>
                </>
              )
              case "generating-code-error": return (
                <div class="flex flex-col items-center justify-center">
                  <div>Something went wrong generating your code.</div>
                  <t-btn class="mt-2" margin={false} onClick={() => generateSmsChallengeCode()}>Try again</t-btn>
                  <t-btn class="mt-2" margin={false} onClick={() => router.go(-1)}>Go back</t-btn>
                </div>
              )
              case "awaiting-code-input":
                // fallthrough
              case "submitting-code":
                // fallthrough
              case "done": return (
                <>
                  <div class="flex flex-col justify-center items-start">
                    <div>Type in the code you received:</div>
                    <form class="w-full flex flex-row gap-2 flex-wrap" onSubmit={e => {e.preventDefault(); tryWithCode()}}>
                      <input class="grow" type="text" data-test="code" v-model={code.value}/>
                      <t-btn
                        margin={false}
                        data-test="submit"
                        type="submit"
                        class={`${mode.value === "submitting-code" ? "bg-gray-300" : ""}`}
                        disable={mode.value === "submitting-code"}
                      >Ok</t-btn>
                    </form>
                    <div class="flex items-center mt-1">
                      <input type="checkbox" v-model={rememberThisDevice.value} data-test="rememberThisDevice"/>
                      <span class="text-sm ml-1">Remember this device for 30 days</span>
                    </div>
                    <div class="text-red-700">{errorMessage.value}</div>
                  </div>
                  <div>
                    {
                      mode.value === "submitting-code"
                        ? (
                          <div class="flex items-center justify-center my-4">
                            <SoccerBall color={Client.value.clientTheme.color} width=".375in" height=".375in" timeForOneRotation="1.5s"/>
                          </div>
                        )
                        : null
                    }
                  </div>
                </>
              )
              default: exhaustiveCaseGuard(mode.value);
            }
          })()
        }
      </div>
    )
  }
});

export const MfaChallengeTOTP = defineComponent({
  name: "MfaChallengeTOTP",
  props: {
    state: {
      required: true,
      type: Object as PropType<Login_MfaChallengeFlow>
    }
  },
  emits: mfaChallengeSuccessEmitsDef,
  setup(props, {emit}) {

    const passcode = ref("");
    const rememberThisDevice = ref(false);
    const errorMessage = ref("");
    const iziToast = useIziToast();

    type UiState = "awaiting-input" | "submitting" | "done";
    const state = ref<UiState>("awaiting-input")

    const tryWithCode = async () => {
      try {
        state.value = "submitting";
        const response = await ilauth.public_.authenticateWithTOTP(axiosBackgroundInstance, {userID: props.state.userID, token: props.state.token, passcode: passcode.value, rememberThisDevice: rememberThisDevice.value});
        state.value = "done";
        emit("success", response);
      }
      catch (e: any) {
        state.value = "awaiting-input";
        if (axios.isAxiosError(e)) {
          if (e.response?.status !== undefined && Math.floor(e.response.status / 100) === 5) {
            iziToast.error({message: FALLBACK_ERROR_MESSAGE})
            // probably should log this, we'd like to know about it
          }
          else {
            // we could probably be clearer
            errorMessage.value = "Invalid code.";
          }
        }
        else {
          throw e;
        }
      }
    }

    return () => (
      <div data-test="MfaChallengeTOTP">
        <div>Authenticator 2FA</div>
        <div class="mb-2 border-b border-slate-300" />
        <form onSubmit={(e) => { e.preventDefault(); tryWithCode(); }}>
          <div class="flex flex-col items-start justify-start text-sm" data-test="FreshTotpImpl">
            <div>Enter passcode generated on your authenticator app:</div>
            <div class="mb-2 border-b border-slate-300" />
            <div class="flex gap-1 flex-wrap">
              <input type="text" onInput={() => {errorMessage.value = "";}} v-model={passcode.value} data-test="passcode"/>
              <t-btn type="submit" margin={false} data-test="submit">Submit</t-btn>
            </div>
            <div class="flex items-center mt-1">
              <input type="checkbox" v-model={rememberThisDevice.value} data-test="rememberThisDevice"/>
              <span class="text-sm ml-1">Remember this device for 30 days</span>
            </div>
            <div class="text-sm font-normal text-red-700">{errorMessage.value}</div>
          </div>
          {
            state.value === "submitting"
              ? (
                <div class="flex items-center justify-center my-4">
                  <SoccerBall color={Client.value.clientTheme.color} width=".375in" height=".375in" timeForOneRotation="1.5s"/>
                </div>
              )
              : null
          }
        </form>
      </div>
    )
  }
})

const mfaChallengeOptionsEmitsDef = {
  selected: (_: ilauth.MfaType) => true
} as const;
export type MfaChallengeOptionsEmits = ExtractEmitsHandlers<typeof mfaChallengeOptionsEmitsDef>;

export const MfaChallengeOptions = defineComponent({
  name: "MfaChallengeOptions",
  props: {
    state: {
      required: true,
      type: Object as PropType<Login_MfaChallengeFlow>
    }
  },
  emits: mfaChallengeOptionsEmitsDef,
  setup(props, {emit}) {
    return () => (
      <div style="display: grid; grid-template-columns: repeat(auto-fit, 1fr); gap:.25em;" data-test="MfaChallengeOptions">
        <div>There's a few MFA options you're enrolled in. Pick the one you'd like to use this time around.</div>
        {
          props.state.mfaDetails.enrolledMfaTypes.sort(mfaTypeDisplaySort).map((mfaType) : JSX.Element => {
            const doSelect = () => emit("selected", mfaType);
            switch (mfaType) {
              case "SMS": {
                return (<div class="shadow-sm border border-slate-200 p-2 flex flex-col items-center justify-center">
                  <t-btn data-test="sms" type="button" style="margin-top:4px;" margin={false} onClick={() => doSelect()}>Text message (SMS)</t-btn>
                  <div class="text-xs mt-2">Standard messaging and data rates may apply.</div>
                </div>)
              }
              case "TOTP": {
                return (<div class="shadow-sm border border-slate-200 p-2 flex items-start justify-center">
                  <t-btn data-test="sms" type="button" style="align-self:start; margin-top:4px;" margin={false} onClick={() => doSelect()}>Authenticator app</t-btn>
                </div>)
              }
            }
          })
        }
      </div>
    )
  }
})
