import { CoachDetail, GameTeamDetail } from "src/composables/InleagueApiV1.Game";
import { defineComponent } from "vue";
import { AugmentedGamelikeForSchedule } from "./page/schedules.ilx";

import * as ilapi from "src/composables/InleagueApiV1"
import { exhaustiveCaseGuard, sortBy, sortByMany, vReqT } from "src/helpers/utils";

type GameScheduleInfoEx = AugmentedGamelikeForSchedule<ilapi.GameScheduleInfo>;

export const MultiGameCoachInfo = defineComponent({
  props: {
    games: vReqT<GameScheduleInfoEx[]>(),
  },
  setup(props) {
    return () => {
      return (
        <div style="display:grid; grid-template-columns: repeat(auto-fit, minmax(16em, 1fr)); gap:1em;">
          {
            uniqueTeams(props.games)
              .map(([team, coaches]) => (
                <div class="bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5 p-2">
                  <div class="font-medium text-sm underline">{team.team || team.teamName}</div>
                  <div>
                    {
                      coaches.map(coach => {
                        if (coach.email) {
                          return <div>{headOrAsstCoach(coach)} <a href={`mailto:${coach.email}`} class="il-link">{coach.firstname} {coach.lastname}</a></div>
                        }
                        else {
                          return <div>{headOrAsstCoach(coach)} {coach.firstname} {coach.lastname}</div>
                        }
                      })
                    }
                  </div>
                </div>
              ))
          }
        </div>
      )
    }

    function headOrAsstCoach(coach: MinCoachDetail) {
      switch (coach.title) {
        case "Head Coach":
          return "Head Coach"
        case "Co-Coach":
          return "Co-Coach"
        case "Assistant":
          return "Asst. Coach";
        case "Administrator":
          throw Error(`expected administrators to have been filtered away`);
        default: exhaustiveCaseGuard(coach.title);
      }
    }
  }
})

type MinCoachDetail = Pick<CoachDetail, "firstname" | "lastname" | "title" | "email">
type MinGameTeamDetail = Pick<GameTeamDetail, "team" | "teamName">

/**
 * There can be duplicates across games, for games sharing home or visitor teams. This keeps only the unique as per teamID.
 * Coaches are the same per team, not per game.
 */
function uniqueTeams(gamesEx: GameScheduleInfoEx[]) {
  //
  // e.g. 2 games both have the same homeTeam, which is natural and reasonable; we don't want _the same_ coach list (for that same team) being pushed twice
  //
  const seenTeamIDs = new Set<string>()

  const coachFilter = (c: MinCoachDetail) => c.title === "Head Coach" || c.title === "Co-Coach" || c.title === "Assistant";
  const coachPosSort = sortBy<MinCoachDetail>(_ => _.title === "Head Coach" ? 0 : _.title === "Co-Coach" ? 1 : 2)
  const coachNameSort = sortByMany<MinCoachDetail>(sortBy(_ => _.lastname), sortBy(_ => _.firstname))
  const coachSort = sortByMany(coachPosSort, coachNameSort);

  const result : [MinGameTeamDetail, MinCoachDetail[]][] = []

  for (const gameEx of gamesEx) {
    if (gameEx.homeTeam) {
      const teamID = gameEx.home;
      if (!seenTeamIDs.has(teamID)) {
        seenTeamIDs.add(teamID);

        const coaches = [
          ...gameEx.homeCoaches.filter(coachFilter),
          ...(gameEx.areaCoachDetail?.homeAreaCoaches.filter(coachFilter) ?? [])
        ]

        if (coaches.length > 0) {
          result.push([gameEx.homeTeam, coaches.sort(coachSort)])
        }
      }
    }

    if (gameEx.visitorTeam) {
      const teamID = gameEx.visitor;
      if (!seenTeamIDs.has(teamID)) {
        seenTeamIDs.add(teamID);

        const coaches = [
          ...gameEx.visitorCoaches.filter(coachFilter),
          ...(gameEx.areaCoachDetail?.visitorAreaCoaches.filter(coachFilter) ?? [])
        ];

        if (coaches.length > 0) {
          result.push([gameEx.visitorTeam, coaches.sort(coachSort)])
        }
      }
    }
  };

  // this is reasonable for common team descriptor lists like ['B 9-A', 'B 9-B', 'B 9-C']
  // which can be sorted lexically
  return result.sort(sortBy(_ => _[0].team));
}
