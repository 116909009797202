import { AreaCoachDetail_lowercase, AreaCoachGameData, CoachDetail, GameTeamDetail, getAreaCoachesForGames, RefInfo } from "src/composables/InleagueApiV1.Game";
import { ExtractPropTypes, PropType } from "vue";
import { RouterLink } from "vue-router";
import { buildLegacyLink } from "src/boot/auth";
import * as iltypes from "src/interfaces/InleagueApiV1"
import dayjs from "dayjs";
import authService from "src/helpers/authService";

import { assertNonNull, flowCapture, parseIntOr, parseIntOrFail, sortBy, sortByMany } from "src/helpers/utils";

import * as ilapi from "src/composables/InleagueApiV1"
import { dayjsFormatOr } from "src/helpers/formatDate";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { Client } from "src/store/Client";
import { AxiosInstance } from "axios";
import { Guid, Integerlike, UserID } from "src/interfaces/InleagueApiV1";

export function propsDef() {
  return {
    // optional
    competitionID: {
      type: null as any as PropType<iltypes.Integerlike>,
      required: false
    },
    // optional, but part of a path param wherein it cannot be provided without competitionID being provided
    divID: {
      type: String as PropType<iltypes.Guid>,
      required: false
    }
  }
}

export type Props = ExtractPropTypes<ReturnType<typeof propsDef>>;

/**
 * also see views/gameSchedules/index.cfm
 */
export function computeDivisionStandingsURL(
  props: {
    appDomain: string,
    // caller probably wants to pass a list of gamelikes, but:
    // - we are focused on a singular competition
    // - we are focused on a singular division
    // - the focused competition implies a singular season
    // - so, we have a (comp, season, div, gamelike[]), where all `gamelike` are bound to some (comp, season, div)
    // - therefore:
    //   - `hasSomePoolForCompSeasonDivision` are here all the same, for all gamelikes
    //   - `season_*` member properties for all gamelikes here are the same for all gamelikes
    // So we accept a singular "tokenGamelike" which is good enough and represents all the game's (comp, season, div),
    // until some time where games are explicitly bound to seasons other than their [competition].[currentSeasonUID]
    gamelike: GamelikeForSchedule,
    invokingUserRoles: string[]
  }
) : string | null {
  const isAvailableToAllUsers = props.gamelike.competition_useScores;
  const hasSomeElevatedAuthz = authService(props.invokingUserRoles, "DD","DDReporter","ChiefRef","playerAdmin","registrar","webmaster","inLeague");

  if (!isAvailableToAllUsers && !hasSomeElevatedAuthz) {
    return null;
  }

  const cfm = props.gamelike.hasSomePoolForCompSeasonDivision ? "rankings-pool.cfm" : "rankings.cfm";
  const startDate = dayjs(props.gamelike.competition_season_seasonStart).format("YYYY-MM-DD")
  // legacy code computes endDate by "last game date for some competition", this is a rough surrogate
  const endDate = dayjs(props.gamelike.competition_season_seasonStart).add(props.gamelike.competition_season_seasonWeeks, "weeks").format("YYYY-MM-DD");
  const urlParams = [
    `startDate=${startDate}`,
    `endDate=${endDate}`,
    `competition=${props.gamelike.competition_competitionID}`, // ID, not UID
    `division=${props.gamelike.division}` // division, not divID
  ]
  // TODO: Division standings do not require a login and so do not need main/in - replace with a regular link until we move division standings to the 20th anniversary app
 /* const targetURL = buildLegacyLink(
    props.appDomain,
    `/scoring/${cfm}?${urlParams.join("&")}`,
    "",
  )*/
  const targetURL = 'https://' + props.appDomain + `/scoring/${cfm}?${urlParams.join("&")}`;
  return targetURL;
}

export type GamelikeForSchedule = ilapi.LoggedInUserGame | ilapi.GameScheduleInfo;
export type AugmentedGamelikeForSchedule<T extends GamelikeForSchedule = GamelikeForSchedule> = T & {
  shouldDisplayScoreInfo: boolean,
  /**
   * Null means "area coaches aren't applicable here", whereas the non-null case ("area coaches are applicable here") can still be empty
   */
  areaCoachDetail: null | AreaCoachGameData<AreaCoachDetail_lowercase>
}

export function augmentGamelikesForSchedule<T extends GamelikeForSchedule>(gamelikes: T[], areaCoaches: null | AreaCoachGameData<AreaCoachDetail_lowercase>[]) : AugmentedGamelikeForSchedule<T>[] {
  return gamelikes.map((gamelike) : AugmentedGamelikeForSchedule<T> => {
    return {
      ...gamelike,
      shouldDisplayScoreInfo: (() => {
        const hideScores = !!gamelike.competition_hideScores;
        const hasSomeScore = !isNaN(parseInt(gamelike.homeGoals as any));
        return hasSomeScore && !hideScores;
      })(),
      areaCoachDetail: areaCoaches?.find(v => v.gameID === gamelike.gameID) ?? null
    }
  })
}

// there are many representations of "no {home,visitor}Team" across the app,
// but it doesn't really matter which one a caller supplies, so long as the object case is the same
export type GetAreaCoaches_MinGamelike = {
  gameID: Guid,
  homeTeam?: undefined | null | "" | {
    region: Integerlike
  },
  visitorTeam?: undefined | null | "" | {
    region: Integerlike
  },
}

/**
 * Lookup area coaches for those games "maybe having area teams" (which currently is "does the game have at least one 'foreign' team")
 */
export async function getAreaCoaches(axios: AxiosInstance, games: GetAreaCoaches_MinGamelike[]) {
  const gameOwningClient = {
    clientID: Client.value.instanceConfig.clientid,
    region: parseIntOrFail(Client.value.instanceConfig.region)
  } as const;

  const gamesMaybeHavingAreaCoaches = games.filter(game => maybeHasAreaCoaches(game));

  if (gamesMaybeHavingAreaCoaches.length === 0) {
    return null;
  }
  else {
    return await getAreaCoachesForGames(axios, {gameIDs: gamesMaybeHavingAreaCoaches.map(game => game.gameID)});
  }

  function maybeHasAreaCoaches(game: GetAreaCoaches_MinGamelike) : boolean {
    // not parseIntOrFail because it is possible for a game to be "TBD" for one or both of the teams
    const homeTeamRegion = typeof game.homeTeam === "object" ? parseIntOr(game.homeTeam?.region, null) : null;
    const visitorTeamRegion = typeof game.visitorTeam === "object" ? parseIntOr(game.visitorTeam?.region, null) : null;

    const homeTeamIsForeign = homeTeamRegion !== null && homeTeamRegion !== gameOwningClient.region;
    const visitorTeamIsForeign = visitorTeamRegion !== null && visitorTeamRegion !== gameOwningClient.region;

    return homeTeamIsForeign || visitorTeamIsForeign;
  }
}


export interface ColDef {
  [key: string]: any,
  field: (game: AugmentedGamelikeForSchedule) => string | ((forwardedProps?: {class?: string, style?: string}) => JSX.Element)
}

/**
 * a column def's evaluted field may return a string or another function that will return JSX
 * This does the right thing with either type of result.
 */
export function JsxQuasarColumnRenderShim(props: {fieldResult: string | ((forwardedProps?: Record<string, any>) => JSX.Element)}) : JSX.Element {
  if (typeof props.fieldResult === "string") {
    return <span>{props.fieldResult}</span>
  }
  else {
    const forwardedProps : {class?: string, style?: string} = {}
    if ("class" in props) {
      forwardedProps.class = props.class as string;
    }
    if ("style" in props) {
      forwardedProps.style = props.style as string;
    }
    return props.fieldResult(forwardedProps);
  }
}

function teamWithMaybeTeamName(game: GamelikeForSchedule, which: "home" | "visitor", gameTeamDetail: GameTeamDetail, coachInfo: readonly CoachDetail[], areaCoachInfo: readonly AreaCoachDetail_lowercase[]) : JSX.Element {
  const coachAndAreaCoaches = [...coachInfo, ...areaCoachInfo]

  const headAndCoCoachParenthetical = (() => {
    if (coachAndAreaCoaches.length === 0) {
      return "";
    }

    const blurb = coachAndAreaCoaches
      .filter(coach => coach.title === "Head Coach" || coach.title === "Co-Coach")
      .sort(sortByMany(sortBy(_ => _.lastname)))
      .map(v => v.lastname)
      .join(", ");

    return blurb ? `(${blurb})` : ""
  })();

  const asstCoachAndRefBlurb = (() => {
    const asstCoachBlurbs = coachAndAreaCoaches
      .filter(coach => coach.title === "Assistant")
      .sort(sortByMany(sortBy(_ => _.lastname)))
      .map((e,i,a) => `Asst. Coach ${e.lastname}`)

    const refBlurbs = (() => {
      if (which === "visitor") {
        // TODO: we don't show this for the visitor team, because it's the same as for the home team ...
        // really we ought to put it somewhere that is "for the game" rather than "for one of the game's teams"
        return []
      }

      const result : string[] = []
      const maybePushRef = (refPos: string, v: RefInfo | UserID | "") => {
        const m = typeof v !== "string" ? v : null;
        if (m) {
          result.push(`${refPos} ${m.LastName}`)
        }
      }

      maybePushRef("Ref1", game.ref1)
      maybePushRef("Ref2", game.ref2)
      maybePushRef("Ref3", game.ref3)
      maybePushRef("Ref4", game.ref4)

      return result;
    })();

    const merged : string[] = [asstCoachBlurbs, refBlurbs].flat()
    return merged.join(", ") || ""
  })();

  const showInfoPopup = !!asstCoachAndRefBlurb

  return (
    <div>
      <div style={`display:grid; grid-template-columns: ${showInfoPopup ? "auto 1fr" : "1fr"}; white-space:break-spaces; align-items:start;`}>
        {showInfoPopup
          ? <button
            type="button"
            class="ml-1.cursor-pointer px-2"
            v-tooltip={{content: asstCoachAndRefBlurb}}
          >
            <FontAwesomeIcon class="text-gray-400" icon={faInfoCircle}/>
          </button>
          : null
        }
        <span class="-mt-[2px]">{gameTeamDetail.team} {headAndCoCoachParenthetical}</span>
      </div>
    </div>
  )
}

/**
 * non-uniform casing here (camel and pascal case) is intentional to maintain existing names and object shapes
 */
export const ColName = {
  fieldID: "fieldID",
  gameStart: "gameStart",
  Home: "Home",
  Visitor: "Visitor",
  Comment: "Comment",
  scoreInfo: "scoreInfo"
} as const;

/**
 * coldefs for typical gamelike table layout
 */
export function GamelikeColDefs() : ColDef[] {
  return [
    {
      name: ColName.fieldID,
      required: true,
      label: 'Field',
      align: 'left',
      field: game => {
        const maybeFromLoggedInUserGame = (game as ilapi.LoggedInUserGame).fieldAbbreviation;
        const maybeFromGameScheduleInfo = (game as ilapi.GameScheduleInfo).fieldAbbrev;
        return maybeFromLoggedInUserGame || maybeFromGameScheduleInfo;
      },
      sortable: true,
      style: 'vertical-align: top; font-size: 16px',
      headerStyle: 'font-size: 16px',
    },
    {
      name: ColName.gameStart,
      required: false,
      label: 'Date',
      align: 'left',
      sortable: true,
      field: game => {
        return forwardedProps => (
          <>
            <div {...forwardedProps}>{dayjsFormatOr(game.gameStart, "M/D/YY")}</div>
            <div {...forwardedProps}>{dayjsFormatOr(game.gameStart, "h:mm a")}</div>
          </>
        )
      },
      style: 'vertical-align: top; font-size: 16px',
      headerStyle: 'font-size: 16px',
    },
    {
      name: ColName.Home,
      align: 'left',
      label: 'Home',
      field: game => {
        const homeTeam = flowCapture(game.homeTeam);
        return homeTeam
          ? () => teamWithMaybeTeamName(game, "home", homeTeam, game.homeCoaches, game.areaCoachDetail?.homeAreaCoaches ?? [])
          : "TBD";
      },
      style: 'vertical-align: top; font-size: 16px',
      headerStyle: 'font-size: 16px',
      sortable: true,
    },
    {
      name: ColName.Visitor,
      align: 'left',
      label: 'Visitor',
      field: game => {
        const visitorTeam = flowCapture(game.visitorTeam);
        return visitorTeam
          ? () => teamWithMaybeTeamName(game, "visitor", visitorTeam, game.visitorCoaches, game.areaCoachDetail?.visitorAreaCoaches ?? [])
          : "TBD";
      },
      style: 'vertical-align: top; font-size: 16px',
      headerStyle: 'font-size: 16px',
      sortable: true,
    },
    {
      name: ColName.Comment,
      align: 'left',
      label: 'Comment',
      field: game => {
        return game.comment;
      },
      style: 'vertical-align: top; font-size: 16px',
      headerStyle: 'font-size: 16px',
      sortable: false,
    },
    {
      name: ColName.scoreInfo,
      field: game => {
        return () => (
          <div>
            <div class="flex items-center">
              <span class="text-xs">Score:</span>
              <span class="ml-1">{ game.homeGoals } - { game.visitorGoals }</span>
            </div>
          </div>
        )
      }
    }
  ]
}
