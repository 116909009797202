<template lang="pug">
.t-page
  div
    .d-pa-md.d-gutter-sm(v-if='!loading')
      duplicate-user(
        v-if='emailDomainName',
        ref='DuplicateUser',
        :email='emailDomainName',
        v-on:close-popup='closePopup',
        v-on:clear-form='clearForm'
      )
      h1.text-4xl.self-end.font-medium
        font-awesome-icon.mr-2(:icon='["fas", "id-badge"]')
        | User Account Setup
      template(v-if="journeyOrigin?.type === 'unrecognized-oauth-login'")
        div(class="il-box-shadow-1 rounded-md mt-4 max-w-xl")
          div(class="px-2 py-1 bg-gray-200 rounded-t-md")
            font-awesome-icon.mr-2(:icon='["fa", "info-circle"]')
            span Login via {{ journeyOrigin.providerUiString }}
          div(class="p-2")
            div(v-if="journeyOrigin.unrecognizedOauthButDefinitelyInleagueUserEmail")
              div We see that you logged in with {{ journeyOrigin.providerUiString }}, but we don't have an inLeague account linked to your {{ journeyOrigin.providerUiString }} account.
              div(class="border-b border-slate-200 my-2")
              router-link(
                class="il-link"
                @click="setOnLoginRouteToAccountSecurity"
                :to="{path: '/login', query: {email: journeyOrigin.unrecognizedOauthButDefinitelyInleagueUserEmail}}"
                data-test="yeah-I-want-to-login"
              )
                | Follow this link
              span &nbsp;
              span to login normally as {{ journeyOrigin.unrecognizedOauthButDefinitelyInleagueUserEmail }}, and once logged in we'll route you to your security settings where you can connect your accounts.
            div(v-else)
              p We didn't find an inLeague account matching your {{ journeyOrigin.providerUiString }} account. You can create an inLeague account here.
              div(class="border-b border-slate-200 my-2")
              p
                | We've filled out what we could using the information you shared with us via {{ journeyOrigin.providerUiString }}.
                | Once created, we'll connect your new inLeague account to {{ journeyOrigin.providerUiString }}.
      div(v-if="journeyOrigin?.type === 'unrecognized-oauth-login' && journeyOrigin.unrecognizedOauthButDefinitelyInleagueUserEmail" data-test="noNewUserForm")
        //- nothing (this arm is "don't offer the create new user form")
      div(v-else data-test="newUserForm")
        h3.mb-3.font-medium.mt-6 Email Contacts
        p(class="my-2") Your primary email address will be your login to inLeague.
        p(v-if='instructions') {{ instructions }}
        FormKit(
          type='form',
          @submit='submitUserInfo',
          submit-label='Add New User',
          v-model='form'
        )
          FormKitSchema(
            :schema='schema',
            :data='form'
          )
          vue-recaptcha(
            :sitekey='recaptchaKey',
            size='normal',
            theme='light',
            @verify='callbackVerify',
            @expire='callbackExpired',
            @fail='callbackFail',
            ref='vueRecaptcha',
            data-cy="recaptcha"
          )
</template>

<script lang="ts">
import {
  NewUserForm,
} from 'src/interfaces/Store/user'

import { isLegalAge, isValidZip, isValidPhone } from 'src/helpers/validationHelpers'

import { AxiosErrorWrapper, axiosInstance, axiosNoAuthInstance, defaultSetGlobalSingletonLoadingStateFlagInterceptors, FALLBACK_ERROR_MESSAGE, freshAxiosInstance } from 'src/boot/axios'
import axios from "axios"
import { statesObject } from 'src/helpers/states'
import DuplicateUser from './DuplicateUser.vue'
import ContentChunk from 'src/components/Admin/ContentChunks/ContentChunkDisplay'
import * as FamilyProfile from "src/components/FamilyProfile/pages/FamilyProfile.ilx"
import { GoogleReCaptchaErrorCode, isGoogleRecaptchaErrorCode } from "src/helpers/GoogleRecaptcha"

import * as ilpublic from "src/composables/InleagueApiV1.Public"

import {
  defineComponent,
  ref,
  Ref,
  computed,
  getCurrentInstance,
  onMounted,
} from 'vue'

import { RouterHistoryTracker } from 'src/store/EventuallyPinia.RouterHistoryTracker'



import { useRouter, useRoute, RouteLocationNamedRaw, RouteLocationRaw } from 'vue-router'

import VueRecaptcha from 'vue3-recaptcha2'
import {
  isAxiosErrorLike,
  isInleagueApiError,
} from 'src/composables/InleagueApiV1'
import { exhaustiveCaseGuard, useIziToast, UiOption } from 'src/helpers/utils'
import { getLogger } from 'src/modules/LoggerService'
import { LoggedinLogWriter, PublicLogWriter } from "src/modules/Loggers"
import * as ilapi from "src/composables/InleagueApiV1"

import * as ilauth from "src/composables/InleagueApiV1.Authenticate"

import * as R_UserEditor from "src/components/User/Editor/R_UserEditor.route"
import { type FormKitNode } from "@formkit/core"
import * as R_TournamentTeamCreate from '../Tournaments/R_TournamentTeamCreate.route'
import { System } from 'src/store/System'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'
import { Public } from 'src/store/Public'
import { userCityValidityPattern } from './Common'

export default defineComponent({
  name: 'NewUser',
  components: {
    ContentChunk,
    DuplicateUser,
    VueRecaptcha,
  },
  setup() {
    const $toast = useIziToast();

    const $router = useRouter()
    const instructions = ref('')
    const regions = ref<UiOption[]>([]);
    const states = ref(statesObject())
    const emailDomainName = ref('')
    const loading = ref(true)
    const form = ref({}) as Ref<NewUserForm>
    const recaptchaWidget = ref(null) as Ref<unknown> as Ref<number>

    /**
     * "origin" being "why are we here"; what caused us to want to create a new user?
     * The most common is "not having an origin detail", meaning, some user directly clicked "create new user".
     *
     * TODO: this should probably be a an alias to, or subtype of, NewUserConfigQueryParam from ilapi.authenticate
     */
    type JourneyOrigin =
      | {
        type: "unrecognized-oauth-login",
        provider: ilauth.SupportedOauthProvider,
        providerUiString: string,
        unrecognizedOauthButDefinitelyInleagueUserEmail: null | string
      }
      | {
        type: "new-user-via-aysoID-claim",
        jwt: string,
      }

    const journeyOrigin = ref<null | JourneyOrigin>(null);
    /**
     * we want to know this in the form, to disable some fields which MUST NOT be changed from the StackApi supplied values,
     * when the user is creating an account linked to some existing AYSOID
     */
    const isNewUserViaAysoIdClaim = computed(() => journeyOrigin.value?.type === "new-user-via-aysoID-claim")

    const route = useRoute()
    const router = useRouter()

    const isLoggedIn = computed(() => {
      return User.isLoggedIn
    })

    const recaptchaKey = computed(()=> {
      return System.value.recaptchaKey
    })

    const clearForm = () => {
      form.value = {}
      emailDomainName.value = ''
    }

    const closePopup = () => {
      emailDomainName.value = ''
    }

    const requestRegions = async () : Promise<void> => {
      try {
        const regionList = await Public.getMasterRegionList();
        regions.value = Public.buildStandardRegionOptions(regionList, {includeNilOption: false});
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const callReCaptcha = () => {
      System.directCommit_setTriggerCaptcha(true)
    }

    /**
     * requests are hitting the "logged in" createNewUser endpoint (v1/users),
     * which requires a familyID. If the user is logged in, we 100% expect to have a familyID
     * included in the request, and we expect to pull it from route query params. However,
     * requests are failing with missing familyIDs, and breadcrumbs seem to indicate that the requests
     * originate from a "logged out" flow, which doesn't make sense.
     *
     * This grabs some trace info we can push to sentry to help figure this out.
     */
    function bughunt_wrapErrorWithTraceDetail(error: any) : any {
      return {
        vueRouterHistory: RouterHistoryTracker
          .getHistory()
          .map(routeLocation => routeLocation.fullPath),
        "document.referrer": document.referrer,
        "isLoggedIn.value": isLoggedIn.value,
        "User.isLoggedIn": User.isLoggedIn,
        "User.value.userData": User.value.userData,
        error
      }
    }

    const loggedOut_createNewUser = async (options: any) : Promise<void> => {
      try {
        //
        // we assume here that we're configured to point at a specific league
        //

        if (journeyOrigin.value?.type === "new-user-via-aysoID-claim") {
          await ilapi.public_.createNewUser(axiosNoAuthInstance, {...options, aysoIDClaim_JWT: journeyOrigin.value.jwt});
        }
        else {
          await ilapi.public_.createNewUser(axiosNoAuthInstance, options);
        }

        // because we are pointing at a specific league, we use the "login web" endpoint
        const result = await User.loginWeb(
          axiosNoAuthInstance,
          {
            username: form.value.email!,
            password: form.value.password!,
          }
        );

        if (result.ok) {
          switch (result.data.status) {
            case "complete":
              await User.loginUser(result.data);

              if (journeyOrigin.value) {
                if (journeyOrigin.value.type === "unrecognized-oauth-login") {
                  // Success, they're logged in as their new account, but they've indicated they want to link to some oauth provider.
                  // We'll hit the oauth provider one more time and reuse the existing "connect an existing account" flow.
                  // Maybe we could hold onto a cookie and say "on success if there's such-and-such cookie then link oauth account X to new account Y"
                  // but this seems like the most cautious approach with not too much added friction.

                  const onSuccessURL = `${window.location.origin}${router.resolve(FamilyProfile.asRouteLocationRaw({name: FamilyProfile.RouteName.default})).href}`
                  const oauthURL = await ilauth.notifyOfIntentToInit3rdPartyOauthConnectFlow(axiosInstance, {
                    userID: User.value.userID, // will be their new ID
                    provider: journeyOrigin.value.provider,
                    onSuccessURL
                  })
                  window.location.assign(oauthURL);
                  return; // always unreachable in every browser? assign doesn't return `never`, but rather `void` ...
                }
                else if (journeyOrigin.value.type === "new-user-via-aysoID-claim") {
                  // This is hardcoded because we know ahead of time that the only reason to have received a new-user-via-aysoID-claim is
                  // that we are part of the tournament-team-registration flow.
                  await router.push(R_TournamentTeamCreate.routeDetailToRouteLocation({name: R_TournamentTeamCreate.RouteNames.ChooseSeason}));
                }
                else {
                  exhaustiveCaseGuard(journeyOrigin.value);
                }
              }
              else {
                await defaultSuccess();
              }
              return;
            case "needs-mfa-challenge":
            case "needs-mfa-init":
              throw Error("it is not expected that a new user will ever require an mfa-challenge or mfa-init");
            default: exhaustiveCaseGuard(result.data);
          }
        }
        else {
          throw result.msg;
        }
      }
      catch (error: any) {
        const logit = () : void => void getLogger(PublicLogWriter).log("warning", "/new-user#public", bughunt_wrapErrorWithTraceDetail(error));

        //
        // note that this is different from "loggedIn_createNewUser_linkedToSomeFamily", in that we use "axiosInstance"
        // rather than "axiosNoAuthInstance";
        // "axiosInstance" carries with it its own iziToast generating response interceptors,
        // but "axiosNoAuthInstance" requires users to handle them manually
        //
        if (error instanceof AxiosErrorWrapper) {
          const unwrapped = error.unwrap();
          if (isAxiosErrorLike(unwrapped) && isInleagueApiError(unwrapped.response.data)) {
            const maybeRecaptchaErrors = maybeExtractGoogleRecaptchaErrorCodes(unwrapped.response.data.messages);
            if (maybeRecaptchaErrors) {
              // if we have a (some) recaptcha error(s), we might want to show a particular message
              // Which particular codes we specially handle is based on experience of what a particular error code
              // indicates about the pair (our configuration, user's actions).
              for (const err of maybeRecaptchaErrors) {
                if (err === "invalid-input-response") {
                  // Submit request was malformed?
                  // Probably we didn't include a captchaResponse value on form submit
                  // Note we don't log this, it's just noise to do so
                  $toast.warning({message: "Please check the box confirming that you're not a robot."})
                  return;
                }
              }
            }


            if (Math.floor(unwrapped.response.status / 100) === 4) { // 4xx
              if (unwrapped.response.data.messages.length === 1
                && /email address is unavailable or already belongs to/i.test(unwrapped.response.data.messages[0])
              ) {
                // don't log it
              }
              else {
                logit();
              }
              $toast.error({message: unwrapped.response.data.messages.join(", ")});
            }
            else {
              logit();
              $toast.error({message: FALLBACK_ERROR_MESSAGE});
            }
            return;
          }
          else {
            logit();
          }
        }
        else {
          logit();
          $toast.error({message: "Sorry, something went wrong. Please contact your league administrator."});
        }
      }

      async function defaultSuccess() : Promise<void> {
        $toast.success({ message: 'Account created successfully!' })
        await router.push(FamilyProfile.asRouteLocationRaw({name: FamilyProfile.RouteName.default}));
      }
    }

    const loggedIn_createNewUser_linkedToSomeFamily = async (options: any) : Promise<void> => {
      try {
        await ilapi.createNewUser(axiosInstance, options);
        $toast.success({ message: 'Account created successfully!' })
        await $router.push(FamilyProfile.asRouteLocationRaw({name: FamilyProfile.RouteName.default}));
      }
      catch (err: any) {
        // toast is expected to have been issed as part of axiosInstance response interceptors
        // but we are responsible for logging here

        const logIt = () : void => void getLogger(LoggedinLogWriter).log("warning", "/new-user#logged-in", bughunt_wrapErrorWithTraceDetail(err));

        //
        // some errors we might not want to log
        //
        if (axios.isAxiosError(err) && ilapi.isInleagueApiError2(err)) {
          if ("email" in err.response.data.data && Object.keys(err.response.data.data).length === 1 && err.response.data.data.email.length === 1) {
            if (/email address is unavailable/i.test(err.response.data.data.email[0].message)) {
              // "email" is the only field with an error, and it only has one error, and that error is the "sorry this email is not available" error
              return;
            }
          }
        }

        //
        // if we didn't match on a "hey don't log this" rule, log it
        //
        logIt();
      }
    }

    const submitUserInfo = async () => {
      // can we check that the captcha was clicked here (form.<captcha-field> is truthy?)
      // do we know it is always necessary? only in the new user case?
      const options : Record<string, any> = { ...form.value }

      if (isLoggedIn.value) {
        options['familyID']=route.query.familyID
        await loggedIn_createNewUser_linkedToSomeFamily(options)
      } else {
        await loggedOut_createNewUser(options)
      }
    }

    const callbackVerify = (token: string) => {
      form.value['g-recaptcha-response'] = token

      window.setTimeout(() => { // should this be the handler to "callbackExpired"?
        if (form.value?.['g-recaptcha-response']) {
          form.value['g-recaptcha-response'] = ''
        }
        else {
          // no-op
          // We can get mutated in such a way where form.value['g-recaptcha-response'] isn't a valid property path,
          // probably in response to form submit, where we clear the form state?
          // Anyway, goal is to not crash in that case.
        }
      }, 60 * 1000)
    }

    const callbackExpired = () => {
      // console.log("expired!")
    }

    const callbackFail = () => {
      // console.log("fail")
    }

    const actionReset = () => {
      // console.log('resetRecpatcha')
    }



    const genericModel = ref('')

    const validationErrors = ref({})
    const validationCallback = (errors: { [key: string]: string }) => {
      validationErrors.value = errors
    }

    onMounted(async () => {
      await requestRegions()

      loading.value = false;

      if (route.params.email) {
        form.value.email = decodeURIComponent(route.params.email as string)
      }

      if (Object.keys(Client.value.instanceConfig).length > 0) {
        // we assume strongly this will be a valid option in the <select> element
        form.value.region = Client.value.instanceConfig.region
      }
      else {
        form.value.region = "";
      }

      if (typeof route.query.config === "string") {
        const config = ilauth.deserializeNewUserConfigQueryParam(route.query.config);
        if (!config) {
          // bad config, maybe want to log it? the user can type in garbage though, so spurious error could happen
          return;
        }

        switch (config.what) {
          // got routed here from an attempt to login with oauth but we didn't know who the asserted user was
          // we can try to fill in some form info, and should inform the user that if they already have an account,
          // they can login as normal, and connect it.
          case "unrecognized-oauth-login":
            journeyOrigin.value = {
              type: "unrecognized-oauth-login",
              unrecognizedOauthButDefinitelyInleagueUserEmail: config.unrecognizedOauthButDefinitelyInleagueUserEmail,
              provider: config.provider,
              providerUiString: (() => {
                switch (config.provider) {
                  case "google": return "Google";
                  default: exhaustiveCaseGuard(config.provider);
                }
              })()
            };

            form.value.firstName = config.potentialUserInfo.firstName ?? "";
            form.value.middleName = config.potentialUserInfo.middleName ?? "";
            form.value.lastName = config.potentialUserInfo.lastName ?? "";
            form.value.gender = config.potentialUserInfo.gender ?? "";
            form.value.email = config.potentialUserInfo.email ?? "";
            form.value.primaryPhone = config.potentialUserInfo.phoneNumber ?? "";
            form.value.street = config.potentialUserInfo.address_street ?? "";
            form.value.city = config.potentialUserInfo.address_city ?? "";
            form.value.state = config.potentialUserInfo.address_state ?? "";
            form.value.zip = config.potentialUserInfo.address_zip ?? "";

            break;
          case "new-user-via-aysoID-claim":
            journeyOrigin.value = {
              type: config.what,
              jwt: config.jwt,
            }

            try {
              const noToastNoAuthWithGlobalSpinnerAxios = freshAxiosInstance({
                requestInterceptors: [defaultSetGlobalSingletonLoadingStateFlagInterceptors.request],
                responseInterceptors: [{
                  ok: defaultSetGlobalSingletonLoadingStateFlagInterceptors.responseOK,
                  error: defaultSetGlobalSingletonLoadingStateFlagInterceptors.responseError
                }]
              });

              const userInfo = await ilpublic.getCreateNewUserInfoForAysoIdClaim(noToastNoAuthWithGlobalSpinnerAxios, {jwt: config.jwt});
              form.value.email = userInfo.email;
              form.value.firstName = userInfo.firstName;
              form.value.lastName = userInfo.lastName;
              form.value.gender = userInfo.gender;
              form.value.primaryPhone = userInfo.primaryPhone
              form.value.street = userInfo.street;
              form.value.city = userInfo.city;
              form.value.state = userInfo.state;
              form.value.zip = userInfo.zip;
              form.value.region = userInfo.region;
              // Received gender value should always be exactly "M" | "F", but it's easy to test it, so we do
              form.value.gender = userInfo.gender.toUpperCase() === "M"
                ? "M"
                : userInfo.gender.toUpperCase() === "F"
                ? "F"
                : ""
            }
            catch (err) {
              // log it / no await
              void getLogger(PublicLogWriter).log("warning", "/new-user/via-stack-claim/auto-fill", err);
            }
            break;
          default: exhaustiveCaseGuard(config);
        }
      }
    })

    const validations = {
      isValidZip: {
        rule: {
          isValidZip: ({ value }: FormKitNode) => {
            return typeof value === "string"
              ? isValidZip.validate(value)
              : false;
          },
        },
        message: {
          isValidZip: `Please provide a valid zip code, ex. ${isValidZip.examples.map(v => "'" + v + "'").join(" or ")}`,
        }
      },
      isValidPhone: {
        rule: {
          isValidPhone: ({value}: FormKitNode) => {
            return typeof value === "string"
              ? isValidPhone.validate(value)
              : false;
          }
        },
        message: {
          isValidPhone: `Please provide a valid phone number, ex. ${isValidPhone.examples.map(v => "'" + v + "'").join(" or ")}`
        }
      }
    } as const;

    const schema = ref([
        {
          $formkit: 'text',
          name: 'email',
          label: 'Email',
          validation: 'required|email',
          autocomplete: "username",
          disabled: isNewUserViaAysoIdClaim
        },
        {
          $formkit: 'password',
          name: 'password',
          label: 'Password',
          validation: 'required|length:8,90',
          autocomplete: "new-password"
        },
        {
          $formkit: 'password',
          name: 'password2',
          label: 'Confirm password',
          validation: 'required|confirm:password',
          validationLabel: 'password confirmation',
          autocomplete: 'new-password'
        },
        {
          $formkit: 'select',
          model: 'region',
          name: 'region',
          label: 'Home Region*',
          options: computed(() => regions.value),
        },
        {
          $el: 'h3',
          model: 'genericModel',
          children: `Personal Information`, 'input-has-errors-class': 'border-red-500',
          class: 'mt-4 font-medium',
        },
        {
          $el: 'p',
          model: 'genericModel',
          attrs: {
            style: {
              paddingTop: '15px',
              paddingBottom: '20px'
            }
          },
          class: 'mt-4 mb-4', 'input-has-errors-class': 'border-red-500',
          children: `Enter your full, exact,  legal name for the first, middle, and last name fields.`,
        },
        {
          $formkit: 'text',
          model: 'firstName',
          name: 'firstName',
          label: 'First Name*', 'input-has-errors-class': 'border-red-500',
          validation: 'required|alpha_spaces:latin',
          validationLabel: 'first name',
          disabled: isNewUserViaAysoIdClaim,
        },
        {
          $formkit: 'text',
          model: 'middleName',
          name: 'middleName',
          label: 'Middle Name', 'input-has-errors-class': 'border-red-500',
          validation: 'optional|alpha_spaces:latin|length:1,50',
        },
        {
          $formkit: 'text',
          model: 'lastName',
          name: 'lastName',
          label: 'Last Name*', 'input-has-errors-class': 'border-red-500',
          validation: 'required|alpha_spaces:latin',
          validationLabel: 'last name',
          disabled: isNewUserViaAysoIdClaim,
        },
        {
          $el: 'p',
          model: 'genericModel',
          class: 'mt-4 mb-4',
          'input-has-errors-class': 'border-red-500',
          attrs: {
            style: {
              paddingTop: '15px',
              paddingBottom: '20px'
            }
          },
          children: `Your 'display name' will be used in inLeague messaging systems like the email manager.`,
        },
        {
          $formkit: 'text',
          model: 'nickname',
          name: 'nickname',
          label: 'Nickname / Display Name', 'input-has-errors-class': 'border-red-500',
          validation: 'optional|length:2,50',
        },
        {
          $formkit: 'radio',
          model: 'gender',
          name: 'gender',
          label: 'Gender*',
          'input-class': 'cursor-pointer formkit-invalid:border-red-500',
          validation: 'required',
          'input-has-errors-class': 'border-red-500',
          options: { M: 'Male', F: 'Female' },
        },
        {
          $formkit: 'text',
          model: 'street',
          name: 'street',
          label: 'Street Address*', 'input-has-errors-class': 'border-red-500',
          validation: 'required|length:2,120',
          validationLabel: 'address',
        },
        {
          $formkit: 'text',
          model: 'street2',
          name: 'street2',
          label: 'Street Address, Line 2', 'input-has-errors-class': 'border-red-500',
          validation: 'optional|length:1,120',
        },
        {
          $formkit: 'text',
          model: 'city',
          name: 'city',
          label: 'City*', 'input-has-errors-class': 'border-red-500',
          validation: [["required"], ["matches", userCityValidityPattern]],
          validationLabel: 'city',
          validationMessages: {
            matches: "City cannot contain numbers."
          }
        },
        {
          $formkit: 'select',
          model: 'state',
          name: 'state',
          label: 'State*', 'input-has-errors-class': 'border-red-500',
          validation: 'required|length:2,4',
          value: `${Client.value.instanceConfig.leaguestate}`,
          options: states.value,
        },
        {
          $formkit: 'text',
          model: 'zip',
          name: 'zip',
          label: 'Zip*',
          validation: "required|isValidZip",
          validationRules: {
            ...validations.isValidZip.rule
          },
          validationMessages: {
            ...validations.isValidZip.message
          },
          'input-has-errors-class': 'border-red-500',
        },
        {
          $formkit: 'text',
          model: 'occupation',
          name: 'occupation',
          label: 'Occupation',
          validation: 'optional|length:0,120',
          'input-has-errors-class': 'border-red-500',
        },
        {
          $formkit: 'text',
          model: 'businessEmployer',
          name: 'businessEmployer',
          label: 'Employer',
          validation: 'optional|length:0,120',
          'input-has-errors-class': 'border-red-500',
        },
        {
          $formkit: 'text',
          model: 'workZip',
          name: 'workZip',
          label: 'Business/Work Zip',
          validation: 'isValidZip',
          validationRules: {
            ...validations.isValidZip.rule
          },
          validationMessages: {
            ...validations.isValidZip.message
          },
          'input-has-errors-class': 'border-red-500',
        },
        {
          $el: 'h3',
          model: 'genericModel',
          class: 'mt-4 mb-4',
          children: `Phone Numbers`, 'input-has-errors-class': 'border-red-500',
        },
        {
          $formkit: 'tel',
          model: 'primaryPhone',
          name: 'primaryPhone',
          label: 'Primary Phone/Mobile Phone*',
          validation: 'required|isValidPhone',
          validationRules: {...validations.isValidPhone.rule},
          validationMessages: {...validations.isValidPhone.message},
          'input-has-errors-class': 'border-red-500',
          validationLabel: 'Phone number',
        },
        {
          $formkit: 'radio',
          model: 'SMSEnabled',
          name: 'SMSEnabled',
          label: `I permit ${Client.value.instanceConfig.shortname} to send weather and game-related updates to my mobile phone via text message (SMS). Standard rates may apply.`,
          options: { true: 'Yes', false: 'No' },
          validation:'required',
          'input-has-errors-class': 'border-red-500',
        },
        {
          $formkit: 'tel',
          model: 'homePhone',
          name: 'homePhone',
          label: 'Home Phone',
          validation: 'optional|isValidPhone',
          validationRules: {...validations.isValidPhone.rule},
          validationMessages: {...validations.isValidPhone.message},
          'input-has-errors-class': 'border-red-500',
        },
        {
          $formkit: 'tel',
          model: 'workPhone',
          name: 'workPhone',
          label: 'Work Phone',
          validation: 'optional|isValidPhone',
          validationRules: {...validations.isValidPhone.rule},
          validationMessages: {...validations.isValidPhone.message},
          'input-has-errors-class': 'border-red-500',
        },
        {
          $formkit: 'number',
          model: 'workPhoneExt',
          name: 'workPhoneExt',
          label: 'Work Extension',
          validation: 'optional|length:1,4',
          'input-has-errors-class': 'border-red-500',
        },
        {
          $el: "div",
          children: [
            {
              $el: "div",
              attrs: {
                class: "formkit-label",
              },
              children: ["Private profile"]
            },
            {
              $formkit: "checkbox",
              name: "privateProfile",
              label: "Ordinarily, all users in a family may access one another's profile information. If this option is enabled, other users in your family profile won't have access to this your contact information.",
            },
          ]
        }
    ])

    const setOnLoginRouteToAccountSecurity = () => {
      System.setRedirectOnLogin({
        type: "lazy-vue-router-route",
        // we don't know the user's ID now, but we will know it when this runs
        value: () => R_UserEditor.routeDetailToRouteLocation({name: R_UserEditor.RouteName.security, userID: User.value.userID})
      })
    }

    return {
      validationErrors,
      validationCallback,
      genericModel,
      form,
      instructions,
      emailDomainName,
      loading,
      clearForm,
      closePopup,
      schema,
      callReCaptcha,
      recaptchaWidget,
      callbackVerify,
      callbackFail,
      actionReset,
      callbackExpired,
      recaptchaKey,
      submitUserInfo,
      journeyOrigin,
      setOnLoginRouteToAccountSecurity,
    }
  },
})

function maybeExtractGoogleRecaptchaErrorCodes(ss: string[]) : GoogleReCaptchaErrorCode[] | null {
  const result = ss.filter(isGoogleRecaptchaErrorCode);
  return result.length > 0 ? result : null;
}
</script>
