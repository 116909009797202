let scrollOnRouteChangeIsDisabled = false;

export function getScrollOnRouteChangeIsDisabled() {
  return scrollOnRouteChangeIsDisabled;
}

export async function withNoScroll(f: () => Promise<void>) {
  try {
    scrollOnRouteChangeIsDisabled = true;
    await f()
  }
  finally {
    scrollOnRouteChangeIsDisabled = false;
  }
}
